import ButtonType3 from "../inputs/ButtonType3"
import { ContentDetail } from "./contentDetail"
import { ContentOriginalImage } from "./contentOriginalImage"
import moment from 'moment'
import ClearSelectedImage from '../../assets/images/clear_selection.svg';

export const EditCustomCollection = ({getWidthOfRow,selectedContents,createSessionForType2,activityList,getActivityListLength,unSelectActivity}) => {
    return (
        <>
        {getActivityListLength() &&
            <div className="fixed_custom_collection" style={{width: getWidthOfRow()}}>
                <div className='d-flex align-items-center col-12 justify-flex-end'>
                    <div className='col-2'>
                        <p className='selected_activity_count'>
                            {selectedContents.length == 0 ? '0' : selectedContents.length < 10 ? `${'0' + selectedContents.length}` : selectedContents.length } / 50 Selected
                        </p>
                    </div>
                    <div className='col-2 p-0'>
                        <ButtonType3
                            disabled={(selectedContents.length < 3 || selectedContents.length > 50) ? true : false}
                            btnName="Save"
                            disabledClassName="saveBtnType3Disabled"
                            className='buttonType1'
                            onClick={() => createSessionForType2()}
                        />
                    </div>
                </div>
                <div className="selected_custom_collection_container">
                    {selectedContents && selectedContents.map((data, index) => (
                        <div className="inner_container" key={index}>
                            <div className="relative-pos">
                                <div>
                                    <p className="selected_custom_collection_index">{index + 1}</p>
                                    <div className="selected_custom_collection_img_container">
                                        <ContentOriginalImage
                                            src={data.alphabetImage && data.alphabetImage?.original !== '' ? data.alphabetImage?.original : require("../../assets/images/noImage.png")}
                                            className="type2Image"
                                        />
                                    </div>
                                    <p className="tub-session-category text-center mt-1">{data.imageTitle}</p>
                                </div>
                                <div className="popup-header-close unSelectTubObject">
                                    <img 
                                        src={ClearSelectedImage} 
                                        className="popup-close" 
                                        onClick={() => unSelectActivity(data, index)} 
                                        alt="alphatub"
                                    />  
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        }
        </>
    )
}