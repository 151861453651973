import React from "react";
import ReactApexChart from "react-apexcharts";

const HorizontalBarchart = (props) => {
  const series = props.series;
  const options = {
    chart: {
      toolbar: {
        show: props.showToolBar,
      },
      background: "#FFFFFF",
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 4,
        color: "#000",
        opacity: 0.1,
      },
    },
    noData: {
      text: "No data Found",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#7f7575",
        fontSize: "14px",
        fontFamily: "Poppins",
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        borderRadiusApplication: "end",
        backgroundBarColors: "#000000",
      },
      dataLabels: {
        position: "top",
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: props.colors,
    grid: {
      borderColor: "#E0E0E0",
      show: true,
      strokeDashArray: 3,
      padding: {
        left: 13,
        bottom: 0,
      },
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    yaxis: {
      title: {
        text: props.y_axis_title,
        style: {
          color: "#000000",
          cssClass: "y_axis_label",
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: -2,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0.2,
      },
    },
    xaxis: {
      categories: props.y_axis,
      title: {
        text: props.x_axis_title,
        style: {
          cssClass: "x_axis_label",
        },
      },
      labels: {
        trim: true,
        formatter: (val) => {
          return val.toFixed(2);
        },
        style: {
          color: "#000000",
          cssClass: "x_axis_content",
        },
        trim: true,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: -1,
      },
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height="400" />
  );
};

export default HorizontalBarchart;
