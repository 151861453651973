import { React, Component } from "react";
import { connect } from "react-redux";
import ClassLeftComponent from "./classLeftComponent";
import ClassTable from "./classTable";
import { Input, Label } from "reactstrap";
import EnrolledStudents from "./EnrolledStudents";
import { ManageSeatAllocation } from "./ManageSeatAllocation/index";
import { CreateModel } from "../../../../components/Model/createModel";
import FormInput from "../../../../components/inputs/FormInput";
import ReactSelect from "../../../../components/inputs/ReactSelect";
import PopupButton from "../../../../components/inputs/PopupButton";
import * as galleryAction from "../../../../actions/tubgallery";
import * as classAction from "../../../../actions/class";
import * as profileAction from "../../../../actions/myProfile";
import * as sessionAction from "../../../../actions/session";
import * as subscription from "../../../../actions/subscriptionTeam";
import { bindActionCreators } from "redux";
import { API } from "aws-amplify";
import { ENVIRONMENT } from "../../../../common/constants";
import { loaders, main } from "../../../../service/actionType";
import toast from "../../../../helpers/Toast";
import moment from "moment";
import { differenceInCalendarDays } from "date-fns";
import errorMsgs from "../../../../utils/errorMsgs";
import {
  getDaysArray,
  getInbetweenDates,
  accessHeaders,
} from "../../../../helpers";
import PhoneInput from "react-phone-input-2";
import ButtonType1 from "../../../../components/inputs/ButtonType1";
import { isEmptyOrUndefine } from "../../../../utils/common.utils";
import _ from "lodash";
import StudentProfile from "./StudentProfile";
import { Country, State, City } from "country-state-city";
import validate from "../../../../helpers/validation";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import UpgradeSubscriptionPopup from "../../../../components/subscriptionPlan/upgradeSubscriptionPopup";
import { Invoice } from "../../../../components/Model/invoicePopup";
import { InvoiceColumns } from "../../../../helpers/tableDataMapper";
import { emitCustomEvent } from "react-custom-events";
import { AppLoader } from "../../../../components/loader/appLoader";
import ButtonType2 from "../../../../components/inputs/ButtonType2";
import Wrapper from "../../../../Hoc/wrapper";

class ClassManagementClass extends Component {
  defaultStCreatePayload = {
    playerId: null,
    profilePic: null,
    firstName: "",
    lastName: "",
    dob: null,
    email: "",
  };
  defaultStudentQueryPayload = {
    phno2: "",
    phno1: "",
    email: "",
    countryCode1: "",
    countryCode2: "",
  };
  clearClass = {
    class_Name: null,
    section_Name: null,
    teachers: null,
    substituteTeachers: null,
    trainees: null,
    allocated_Seats: null,
  };
  constructor(props) {
    const director = localStorage.getItem("schoolCategory");

    super(props);
    this.state = {
      showInvoicePopup: false,
      tab: 1,
      showClassPopup: false,
      showAcademicYearPopup: false,
      teacherList: [],
      traineeList: [],
      substituteTeacherList: [],
      btnClick: 0,
      subscriptionDetails: null,
      schoolAcademicYear: {
        start_Date: null,
        end_Date: null,
        days: [],
      },
      classPopupTitle: "",
      workingDaysOptions: [
        {
          label: "Sun",
          value: "Sun",
          checked: false,
          index: 0,
        },
        {
          label: "Mon",
          value: "Mon",
          checked: false,
          index: 1,
        },
        {
          label: "Tue",
          value: "Tue",
          checked: false,
          index: 2,
        },
        {
          label: "Wed",
          value: "Wed",
          checked: false,
          index: 3,
        },
        {
          label: "Thu",
          value: "Thu",
          checked: false,
          index: 4,
        },
        {
          label: "Fri",
          value: "Fri",
          checked: false,
          index: 5,
        },
        {
          label: "Sat",
          value: "Sat",
          checked: false,
          index: 6,
        },
      ],
      classDetail: {
        class_Name: null,
        section_Name: null,
        teachers: null,
        substituteTeachers: null,
        trainees: null,
        allocated_Seats: null,
        reading_Program_Id: null,
      },
      errors: {},
      disabledDates: [],
      disabledDaysInYear: [],
      calendarCompleteList: [],
      isEdit: false,
      classFilterDropdown: [
        {
          label: "All",
          value: "All",
        },
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "InActive",
          value: "InActive",
        },
      ],
      classQueryPayload: {
        status: {
          label: "Active",
          value: "Active",
        },
      },
      selectedDateRange: null,
      isShowToggler: false,
      toggleDate: true,
      selectedMonth: null,
      maxEndAcademicYear: null,
      reason: null,
      studentPopupTitle: null,
      studentAddTabs: null,
      studentPhQueryPayload: {
        phno2: null,
        phno1: null,
        countryCode1: null,
        countryCode2: null,
        email: "",
      },
      studentPhEmptyBtnClicks: 0,
      selectedClassDetail: null,
      studentAvailableList: [],
      studentCreatePayload: {
        playerId: null,
        profilePic: null,
        firstName: null,
        lastName: null,
        dob: null,
        phno1: null,
        email: director !== "TUBclass Lite" ? "null" : "null",
      },
      studentCreatePlError: {
        playerId: null,
        profilePic: null,
        firstName: null,
        lastName: null,
        dob: null,
        email: null,
      },
      studentCrEmptyBtnClicks: 0,
      studentListQueryPayload: {
        status: null,
      },
      studentTableList: [],
      particularStudentDetail: {
        _id: null,
        assignedStudentId: null,
        profilePic: null,
        street: null,
        street2: null,
        zipCode: null,
        state: null,
        district: null,
        country: null,
        firstName: null,
        middleName: null,
        lastName: null,
        notes: null,
        notes2: null,
        relationship: null,
        dob: null,
        gender: null,
        enrollmentDate: null,
        fatherFirstName: null,
        fatherMiddleName: null,
        fatherLastName: null,
        motherFirstName: null,
        motherMiddleName: null,
        motherLastName: null,
        firstContactNo: null,
        secondContactNo: null,
        emergencyContactNo: null,
        contactName: null,
        email: null,
      },
      particularStudentDetailError: {
        profilePic: null,
        street: null,
        street2: null,
        zipCode: null,
        state: null,
        district: null,
        relationshipship: null,
        country: null,
        firstName: null,
        middleName: null,
        lastName: null,
        notes: null,
        notes2: null,
        dob: null,
        gender: null,
        enrollmentDate: null,
        fatherFirstName: null,
        fatherMiddleName: null,
        fatherLastName: null,
        motherFirstName: null,
        motherMiddleName: null,
        motherLastName: null,
        firstContactNo: null,
        emergencyContactNo: null,
        secondContactNo: null,
        contactName: null,
        email: null,
      },
      genderOptions: [
        {
          label: "Male",
          value: "Male",
        },
        {
          label: "Female",
          value: "Female",
        },
        {
          label: "Others",
          value: "Others",
        },
      ],
      studentStatusList: [
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "In Active",
          value: "InActive",
        },
        {
          label: "Approval Pending",
          value: "Approval Pending",
        },
        {
          label: "Rejected",
          value: "Rejected",
        },
      ],
      studentrelationshipshipList: [
        {
          label: "Father",
          value: "Father",
        },
        {
          label: "Mother",
          value: "Mother",
        },
        {
          label: "Guardian",
          value: "Guardian",
        },
      ],
      countryList: [],
      statesList: [],
      districtList: [],
      studentProfileEmptyClicks: 0,
      showAnotherNote: false,
      studentCalendarSelectedMonth: null,
      readingProgramsList: [],
      curriculumList: [],
      studentCalendar: {
        selectedStudentDateRange: null,
        studentToggleDate: null,
        isShowToggler: false,
        reason: null,
        completeTeacherCalendarList: [],
      },
      teacherCalendarDates: [],
      studentFilterDropdown: [
        {
          label: "All",
          value: "All",
        },
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "InActive",
          value: "InActive",
        },
      ],
      studentQueryPayload: {
        status: {
          label: "All",
          value: "All",
        },
      },
      classNamesList: [],
      sectionsList: [],
      transferPayload: {
        to: null,
        tosection: null,
        reason: null,
      },
      importPopupType: null,
      sampleClassExcelData: [
        {
          class_Name: "tenth Std",
          section_Name: "A",
          total_Student: 10,
        },
        {
          class_Name: "First Std",
          section_Name: "A",
          total_Student: 20,
        },
      ],
      sampleStudentExcelData: [
        {
          firstName: "Eshwar",
          middleName: "",
          lastName: "K B",
          countryCode1: 91,
          firstContactNo: 9843166271,
          countryCode2: 91,
          secondContactNo: 9876543219,
          dob: moment().format("DD-MM-YYYY"),
        },
        {
          firstName: "Jency",
          middleName: "K",
          lastName: "Johnson",
          countryCode1: 91,
          firstContactNo: 9843166271,
          countryCode2: 91,
          secondContactNo: 9876543219,
          dob: moment().format("DD-MM-YYYY"),
        },
      ],
      showBuyMoreSeatsPopup: false,
      loading: false,
    };
  }

  async componentDidMount() {
    await this.init();
  }
  init = async () => {
    this.setState({ loading: true });

    await Promise.all([this.getSchoolCalenderDetails(), this.getAcademicYear()])
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ loading: false });
      });
  };
  getSchoolCalenderDetails = async () => {
    let params = {
      month: parseInt(moment(new Date()).format("MM")),
    };
    try {
      let url = "class/getSchoolCalendar";
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          month: params.month,
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        let completeList = response.data;
        let formattedList = [];
        completeList &&
          completeList.map((dt) =>
            dt.dates.map((date) => {
              if (date.isHoliday !== false) {
                formattedList.push(new Date(date.holiday_Dates));
              }
            })
          );
        this.setState({
          disabledDates: formattedList,
          calendarCompleteList: completeList,
        });
      }

      // dispatch({ type : main.CLASS_TABLE_LIST,payload: response.data})
    } catch (error) {
      console.log("err", error);
    }
  };
  getClassTable = async () => {
    let { classQueryPayload } = this.state;
    await this.props.classAction.getClassTableList(classQueryPayload);
  };
  getAcademicYear = async () => {
    let { workingDaysOptions } = this.state;
    try {
      let url = "class/getAcademicYear";
      const myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        workingDaysOptions.map((wk) => {
          if (response.data.days.includes(wk.value)) {
            wk.checked = true;
          }
        });
        let schoolAcademicYear = {
          ...response.data,
          start_Date: new Date(response.data.start_Date),
          end_Date: new Date(response.data.end_Date),
        };
        this.setState({
          schoolAcademicYear: schoolAcademicYear,
          workingDaysOptions,
        });
      }
    } catch (error) {}
  };
  getClassDropdownList = async (isEdit, editData) => {
    let { classDetail } = this.state;
    try {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
      let url = "class/v3.8/teacherTraineeDropdown";
      let schoolId = sessionStorage.getItem("schoolId");
      const myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          schoolId: schoolId,
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status == 200) {
        if (isEdit) {
          let selectedCurriculumDetail = response.data.curriculumList.find(
            (cl) => cl.value == editData.curriculum_Id
          );
          let readingProgramDetail = null;
          if (selectedCurriculumDetail) {
            readingProgramDetail =
              selectedCurriculumDetail.Reading_Programs_list.find(
                (rp) => rp.value == editData.reading_Program_Id?._id
              );
          }
          classDetail = {
            ...editData,
            curriculum_Id: selectedCurriculumDetail,
            reading_Program_Id: readingProgramDetail,
          };
          this.setState({
            classDetail: classDetail,
          });
        }

        this.setState({
          teacherList: response.data.teacherList,
          traineeList: response.data.traineeList,
          substituteTeacherList: response.data.substituteTeachers,
          curriculumList: response.data.curriculumList,
        });
        this.props.dispatch({
          type: main.CLASS_DROPDOWN_LIST,
          payload: response.data,
        });
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      }
    } catch (error) {
      console.log("error", error);
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };
  saveCalendar = async (type, data) => {
    let {
      selectedDateRange,
      toggleDate,
      selectedMonth,
      workingDaysOptions,
      reason,
    } = this.state;
    let payload = {
      reason: reason,
    };
    if (type === "edit") {
      payload.dates = data.dates.map((dt) => ({
        ...dt,
        isHoliday: false,
        month: parseInt(
          selectedMonth === null
            ? moment(new Date()).format("MM")
            : selectedMonth
        ),
      }));
      payload["group_Id"] = data.group_Id;
    } else {
      let isDateToggled = !toggleDate ? false : true;
      let filteredIndexes = workingDaysOptions
        .filter((fl) => fl.checked === false)
        .map((f) => f.index);
      // if (filteredIndexes.length > 0) {
      //     selectedDateRange = selectedDateRange.filter(dt => filteredIndexes.includes(dt.getDay()));
      // }
      let getDatesAndMonth = getDaysArray(
        selectedDateRange[0],
        selectedDateRange[1],
        isDateToggled,
        filteredIndexes
      );
      payload.dates = getDatesAndMonth;
      this.setState({
        toggleDate: !toggleDate,
      });
    }
    await this.props.classAction.saveSchoolCalendar(payload);
    await this.props.sessionAction.checkLauchTubEnabled();
    let params = {
      month:
        selectedMonth === null
          ? parseInt(moment(new Date()).format("MM"))
          : selectedMonth,
    };
    this.setState(
      {
        selectedDateRange: null,
        reason: "",
        isShowToggler: false,
        toggleDate: false,
      },
      () => this.getSchoolCalendar(params)
    );
  };
  onChangeAcademicYear = (name, value) => {
    let { workingDaysOptions, maxEndAcademicYear } = this.state;
    if (name === "days") {
      workingDaysOptions.map((op) => {
        if (op.value === value) {
          op.checked = !op.checked;
        }
      });
      this.setState({ workingDaysOptions: workingDaysOptions });
    } else {
      let { schoolAcademicYear } = this.state;
      if (name === "end_Date") {
        if (
          schoolAcademicYear["start_Date"] === null ||
          schoolAcademicYear["start_Date"] === ""
        ) {
          toast("Please choose start date before selecting end date", "error");
        }
      }
      if (name === "start_Date") {
        let date = new Date(value);
        date.setFullYear(date.getFullYear() + 1);
        maxEndAcademicYear = date;
        schoolAcademicYear["end_Date"] = null;
      }
      schoolAcademicYear[name] = value;
      this.setState({ schoolAcademicYear, maxEndAcademicYear });
    }
  };
  onChange = (name, value) => {
    let { classDetail, errors } = this.state;

    classDetail[name] = value;
    if (name == "curriculum_Id") {
      classDetail["reading_Program_Id"] = null;
      this.setState({ readingProgramsList: value?.Reading_Programs_list });
    }
    this.setState({ classDetail, errors });
    this.checkEmptyValues(classDetail);
  };

  verifySeatsAddedToClass = (available, classDetail) => {
    let canBeAdded = true;
    let message = "";
    if (available == 0) {
      canBeAdded = false;
      message = `Upgrade Subscription Plan To Add Class`;
    } else {
      if (parseInt(classDetail.allocated_Seats) > {available}) {
        canBeAdded = false;
        message = "Only 50 seats can be added";
      } else if (parseInt(classDetail.allocated_Seats) > available) {
        canBeAdded = false;
        message = `Only ${available} seats can be added`;
      }
    }
    if (message != "") {
      toast(message, "error");
    }

    return canBeAdded;
  };
  checkEmptyValues(data) {
    let { errors } = this.state;

    if (!data?.reading_Program_Id?.value) {
      errors["reading_Program_Id"] = "Required";
    } else if (!data?.curriculum_Id?.value) {
      errors["curriculum_Id"] = "Required";
    }
    delete data.substituteTeachers;
    delete data.trainees;
    for (let key in data) {
      console.log(data[key], "datacheck");
      if (!data[key]) {
        errors[key] = "Required";
      } else {
        errors[key] = "";
      }
    }
  }
  createClass = async (available) => {
    let { btnClick, classDetail, isEdit } = this.state;
    console.log(classDetail, "classDetail");
    this.checkEmptyValues(classDetail);
    this.setState({ btnClick: btnClick + 1 });
    if (this.validateClass(classDetail, btnClick)) {
      this.setState({ btnClick: 0 });
      let payload = {
        ...classDetail,
        curriculum_Id: classDetail.curriculum_Id?.value,
        reading_Program_Id: classDetail?.reading_Program_Id?.value,
        teachers: classDetail.teachers,
        substituteTeachers: classDetail.substituteTeachers,
        trainees: classDetail.trainees,
      };
      if (isEdit) {
        await this.props.classAction.editClass(payload);
        this.props.profileAction.getSchoolBasedClassDropdown();
        this.props.subscription.getSubscriptionData();
        this.setState({ classDetail: this.clearClass, showClassPopup: false });
      } else {
        if (this.verifySeatsAddedToClass(available, classDetail)) {
          await this.props.classAction.createClass(payload);
          this.props.profileAction.getSchoolBasedClassDropdown();
          this.props.subscription.getSubscriptionData();
          this.setState({
            classDetail: this.clearClass,
            showClassPopup: false,
          });
        }
      }
    }
  };
  validateClass = (payload, btnClick) => {
    if (
      !payload.class_Name ||
      !payload.reading_Program_Id ||
      !payload.section_Name ||
      _.isEmpty(payload.teachers) ||
      !payload.allocated_Seats
    ) {
      switch (btnClick) {
        case 1:
          toast(errorMsgs.TCW001[0], "error");
          return false;
        case 2:
          toast(errorMsgs.TCW001[1], "error");
          return false;
        case 3:
          toast(errorMsgs.TCW001[2], "error");
          return false;
        case 4:
          toast(errorMsgs.TCW001[3], "error");
          return false;
        default:
          toast(errorMsgs.TCW001[3], "error");
          return false;
      }
    }
    // else {
    //     let { subscriptionTeam } = this.props
    //     let available = 0;
    //     if (subscriptionTeam && subscriptionTeam.subscriptionInfo) {
    //         available = subscriptionTeam.subscriptionInfo.SeatsPurchased - subscriptionTeam.subscriptionInfo.UsedSeats
    //     }
    //     if (available == 0) {
    //         switch (btnClick) {
    //             case 1:
    //                 toast(errorMsgs.TCW016[0], 'error');
    //                 return false;
    //             case 2:
    //                 toast(errorMsgs.TCW016[1], 'error');
    //                 return false;
    //             case 3:
    //                 toast(errorMsgs.TCW016[2], 'error');
    //                 return false;
    //             case 4:
    //                 toast(errorMsgs.TCW016[3], 'error');
    //                 return false;
    //             default:
    //                 toast(errorMsgs.TCW016[3], 'error');
    //                 return false;
    //         }
    //     }
    //     if (payload.allocated_Seats && payload.allocated_Seats > available) {
    //         toast(errorMsgs.TCW099[0], 'error');
    //         return false;
    //     }
    // }
    return true;
  };
  saveAcademicYear = async () => {
    let { schoolAcademicYear, workingDaysOptions } = this.state;
    let payload = {
      ...schoolAcademicYear,
      days: workingDaysOptions
        .filter((op) => op.checked === true)
        .map((opt) => opt.value),
    };
    this.setState({
      showAcademicYearPopup: false,
      isShowToggler: false,
      loading: true,
    });
    await this.props.classAction.saveSchoolAcademicYear(payload);
    await this.getSchoolCalenderDetails();
    this.setState({ loading: false });
    await this.props.sessionAction.checkLauchTubEnabled();
  };
  onChangeReason = (name, value, type) => {
    let { studentCalendar } = this.state;
    if (type === 1) {
      this.setState({
        [name]: value,
      });
    } else {
      studentCalendar.reason = value;
      this.setState({
        studentCalendar: studentCalendar,
      });
    }
  };
  getSchoolCalendar = async (params) => {
    try {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
      let url = "class/getSchoolCalendar";
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          month: params.month,
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        let completeList = response.data;
        let formattedList = [];
        completeList &&
          completeList.map((dt) =>
            dt.dates.map((date) => {
              if (date.isHoliday !== false) {
                formattedList.push(new Date(date.holiday_Dates));
              }
            })
          );
        this.setState({
          disabledDates: formattedList,
          calendarCompleteList: completeList,
        });
      }

      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    } catch (error) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };
  isSameDay = (disDate, date) => {
    return differenceInCalendarDays(disDate, date) === 0;
  };
  tileDisabled = ({ date, view }) => {
    let isDisabled = this.isDisabled();
    let { workingDaysOptions } = this.state;
    let filteredIndexes = workingDaysOptions
      .filter((fl) => fl.checked === false)
      .map((f) => f.index);
    let filteredDays;
    // if (disabledDates.length > 0) {
    //     getDisableDates = disabledDates.find(dDate => this.isSameDay(dDate, date))
    // }
    if (filteredIndexes.length > 0) {
      filteredDays = filteredIndexes.includes(date.getDay());
    }
    return isDisabled ? true : filteredDays;
  };

  onChangeDate = (value, type) => {
    let {
      disabledDates,
      workingDaysOptions,
      studentCalendar,
      teacherCalendarDates,
    } = this.state;
    if (type === 1) {
      let isShowToggler = true,
        toggleDate = true;
      let filteredIndexes = workingDaysOptions
        .filter((fl) => fl.checked === false)
        .map((f) => f.index);
      let inbetweendays = getInbetweenDates(
        value[0],
        value[1],
        filteredIndexes
      );
      let checkDatesAreInBetween = [];
      disabledDates &&
        disabledDates.map((dt) =>
          inbetweendays.map((date) => {
            if (moment(moment(dt).format("YYYY-MM-DD")).isSame(date)) {
              checkDatesAreInBetween.push(date);
            }
          })
        );
      if (
        checkDatesAreInBetween.length > 0 &&
        checkDatesAreInBetween.length === inbetweendays.length
      ) {
        toggleDate = true;
        isShowToggler = true;
      } else {
        let checkIfDaySelected =
          disabledDates &&
          disabledDates.filter((dt) =>
            inbetweendays.some((ibt) =>
              moment(moment(dt).format("YYYY-MM-DD")).isSame(ibt)
            )
          );
        if (checkIfDaySelected.length !== 0) {
          isShowToggler = false;
        }
      }
      this.setState({
        selectedDateRange: value,
        isShowToggler: isShowToggler,
        toggleDate: toggleDate,
      });
    } else {
      let isShowToggler = true,
        toggleDate = true;
      let filteredIndexes = workingDaysOptions
        .filter((fl) => fl.checked === false)
        .map((f) => f.index);
      let inbetweendays = getInbetweenDates(
        value[0],
        value[1],
        filteredIndexes
      );
      let checkDatesAreInBetween = [];
      teacherCalendarDates &&
        teacherCalendarDates.map((dt) =>
          inbetweendays.map((date) => {
            if (moment(moment(dt).format("YYYY-MM-DD")).isSame(date)) {
              checkDatesAreInBetween.push(date);
            }
          })
        );
      if (
        checkDatesAreInBetween.length > 0 &&
        checkDatesAreInBetween.length === inbetweendays.length
      ) {
        toggleDate = true;
        isShowToggler = true;
      } else {
        let checkIfDaySelected =
          teacherCalendarDates &&
          teacherCalendarDates.filter((dt) =>
            inbetweendays.some((ibt) =>
              moment(moment(dt).format("YYYY-MM-DD")).isSame(ibt)
            )
          );
        if (checkIfDaySelected.length !== 0) {
          isShowToggler = false;
        }
      }
      studentCalendar.selectedStudentDateRange = value;
      studentCalendar.isShowToggler = isShowToggler;
      studentCalendar.studentToggleDate = toggleDate;
      this.setState({
        studentCalendar: studentCalendar,
        toggleDate: toggleDate,
      });
    }
  };
  onNavigate = async (tab, data) => {
    if (tab === 3 && data != null) {
      this.setState(
        {
          selectedClassDetail: data,
          tab: tab,
        },
        () => this.getStudentsCompleteList()
      );
    } else if (tab === 3 && data == null) {
      this.setState({
        tab: tab,
      });
      this.getStudentsCompleteList();
    } else if (tab === 4) {
      this.setState({
        tab: tab,
      });
      await this.getOneStudent(data);
    } else if (tab === 1) {
      this.setState({
        tab: tab,
      });
      let params = {
        month: parseInt(moment(new Date()).format("MM")),
      };
      await this.getSchoolCalendar(params);
      await this.props.subscription.getSubscriptionData();
      this.getClassTable();
    } else {
      this.setState({
        tab: tab,
      });
    }
  };
  checkForApiTrigger = async (name, type) => {
    if (name === "showInvoicePopup" && type) {
      await this.props.subscription.getInvoicesList();
    }
  };
  triggerPopup = (name, type) => {
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => this.checkForApiTrigger(name, type)
    );
  };
  triggerClassPopup = async (title, row) => {
    let { classDetail } = this.state;
    if (title === "Edit Class") {
      await this.getClassDropdownList(true, row);

      this.setState({
        isEdit: true,
      });
    } else if (title === "Add Class" && row === false) {
      this.setState({ classDetail: { ...this.clearClass }, isEdit: false });
    } else if (title === "Add Class" && row === true) {
      await this.getClassDropdownList(false, null);
      this.setState({ classDetail: { ...this.clearClass }, isEdit: false });
    }
    this.setState({
      classPopupTitle: title,
      showClassPopup: !this.state.showClassPopup,
    });
  };

  onChangeStatusFilter = (name, value, type) => {
    if (type === 1) {
      let { classQueryPayload } = this.state;
      classQueryPayload[name] = value;
      this.setState(
        { classQueryPayload: classQueryPayload },
        this.getClassTable
      );
    } else {
      let { studentQueryPayload } = this.state;
      studentQueryPayload[name] = value;
      this.setState(
        { studentQueryPayload: studentQueryPayload },
        this.getStudentsTableList
      );
    }
  };
  changeMonth = (value, type) => {
    if (type === 1) {
      let params = {
        month: value,
      };
      this.setState({ selectedMonth: value }, () =>
        this.getSchoolCalendar(params)
      );
    } else {
      let params = {
        month: value,
      };
      this.setState({ studentCalendarSelectedMonth: value }, () =>
        this.getStudentCalendar(params)
      );
    }
  };
  isDisabled = () => {
    let role = localStorage.getItem("role");
    let disabled = role === "R01" || role === "R02" ? false : true;
    return disabled;
  };

  editDisabled = () =>
    !["R01", "R02", "R03"].includes(localStorage.getItem("role"));

  import = async (target, type) => {
    let { selectedClassDetail } = this.state;
    const formData = new FormData();
    formData.append("", target.files[0]);
    if (type === 1) {
      try {
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
        let url = "class/bulk/insert/class";
        const myInit = {
          body: formData,
          headers: accessHeaders(),
          queryStringParameters: {
            schoolId: sessionStorage.getItem("schoolId"),
            userName: localStorage.getItem("username"),
          },
        };
        const response = await API.post(ENVIRONMENT, url, myInit);
        if (response.status === 200) {
          await this.getClassTable();
          this.props.dispatch({
            type: loaders.IS_AUTH_FETCHING,
            payload: false,
          });
          this.setState({ importPopupType: 0 });
          toast(response.message, "success");
        } else {
          this.props.dispatch({
            type: loaders.IS_AUTH_FETCHING,
            payload: false,
          });
          this.setState({ importPopupType: 0 });
          toast(response.message, "error");
        }
      } catch (error) {
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
        this.setState({ importPopupType: 0 });
        toast(error.message, "error");
      }
    } else {
      try {
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
        let url = "student/bulk/insert/student";
        const myInit = {
          body: formData,
          headers: accessHeaders(),
          queryStringParameters: {
            userName: localStorage.getItem("username"),
            classId: selectedClassDetail?._id,
          },
        };
        const response = await API.post(ENVIRONMENT, url, myInit);
        if (response.status === 200) {
          await this.getStudentsTableList();
          this.setState({ importPopupType: 0 });
          this.props.dispatch({
            type: loaders.IS_AUTH_FETCHING,
            payload: false,
          });
          toast(response.message, "success");
        } else {
          this.props.dispatch({
            type: loaders.IS_AUTH_FETCHING,
            payload: false,
          });
          this.setState({ importPopupType: 0 });
          toast(response.message, "error");
        }
      } catch (error) {
        console.log("error", error);
        this.setState({ importPopupType: 0 });
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
        toast(error?.response?.data?.message, "error");
      }
    }
  };
  setAcademicMaxDate = (schoolAcademicYear) => {
    if (schoolAcademicYear.start_Date != null) {
      let date = new Date(schoolAcademicYear.start_Date);
      date.setFullYear(date.getFullYear() + 1);
      return date;
    }
  };
  setAcademicMinDate = (schoolAcademicYear) => {
    if (schoolAcademicYear.start_Date != null) {
      let date = new Date(schoolAcademicYear.start_Date);
      date.setMonth(date.getMonth() + 3);
      return date;
    }
  };
  // STUDENT
  getStudentsCompleteList = async () => {
    let { studentCalendarSelectedMonth } = this.state;
    await this.getStudentsTableList();
    let params = {
      month:
        studentCalendarSelectedMonth === null
          ? parseInt(moment(new Date()).format("MM"))
          : studentCalendarSelectedMonth,
    };
    await this.getStudentCalendar(params);
  };
  getStudentCalendar = async (params) => {
    let { studentCalendar, selectedClassDetail } = this.state;
    try {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
      let url = "student/getTeacherCalendar";
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          month: params.month,
          classId: selectedClassDetail._id,
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200 && !_.isEmpty(response.data)) {
        let completeList = response.data;
        let disableSchoolCalendarList = [],
          newteacherCalendar = [];
        completeList &&
          completeList.schoolCalendar.map((dt) =>
            dt.dates.map((date) => {
              if (date.isHoliday !== false) {
                disableSchoolCalendarList.push(new Date(date.holiday_Dates));
              }
            })
          );
        completeList &&
          completeList.teacherCalendar.map((dt) =>
            dt.dates.map((date) => {
              if (date.isHoliday !== false) {
                newteacherCalendar.push(new Date(date.holiday_Dates));
              }
            })
          );
        studentCalendar.completeTeacherCalendarList =
          response.data.teacherCalendar;
        this.setState({
          disabledDates: disableSchoolCalendarList,
          teacherCalendarDates: newteacherCalendar,
          studentCalendar: studentCalendar,
        });
      }

      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    } catch (error) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };
  saveTeacherCalendar = async (type, data) => {
    let {
      workingDaysOptions,
      studentCalendar,
      studentCalendarSelectedMonth,
      selectedClassDetail,
    } = this.state;
    let payload = {
      reason: studentCalendar.reason,
    };
    if (type === "edit") {
      payload["dates"] = data.dates.map((dt) => ({
        ...dt,
        isHoliday: false,
        month: parseInt(
          studentCalendarSelectedMonth === null
            ? moment(new Date()).format("MM")
            : studentCalendarSelectedMonth
        ),
      }));
      payload["group_Id"] = data.group_Id;
    } else {
      let isDateToggled = !studentCalendar.studentToggleDate ? false : true;
      let filteredIndexes = workingDaysOptions
        .filter((fl) => fl.checked === false)
        .map((f) => f.index);
      // if (filteredIndexes.length > 0) {
      //     selectedDateRange = selectedDateRange.filter(dt => filteredIndexes.includes(dt.getDay()));
      // }
      let getDatesAndMonth = getDaysArray(
        studentCalendar.selectedStudentDateRange[0],
        studentCalendar.selectedStudentDateRange[1],
        isDateToggled,
        filteredIndexes
      );
      payload["dates"] = getDatesAndMonth;
      studentCalendar.studentToggleDate = !studentCalendar.studentToggleDate;
      this.setState({
        studentCalendar,
        toggleDate: isDateToggled,
      });
    }
    payload["classId"] = selectedClassDetail._id;
    await this.props.classAction.saveTeacherCalendar(payload);
    await this.props.sessionAction.checkLauchTubEnabled();
    let params = {
      month:
        studentCalendarSelectedMonth === null
          ? parseInt(moment(new Date()).format("MM"))
          : studentCalendarSelectedMonth,
    };
    studentCalendar.selectedStudentDateRange = null;
    studentCalendar.reason = "";
    studentCalendar.isShowToggler = false;
    studentCalendar.studentToggleDate = false;
    this.setState(
      {
        studentCalendar,
        toggleDate: false,
      },
      () => this.getStudentCalendar(params)
    );
  };
  getStudentsTableList = async () => {
    let { studentQueryPayload, selectedClassDetail } = this.state;
    try {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
      let url = "student/getStudents";
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          status: studentQueryPayload.status?.value,
          classId: selectedClassDetail._id,
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200 && !_.isEmpty(response.data)) {
        this.setState({
          studentTableList: response.data,
        });
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      } else {
        this.setState({
          studentTableList: [],
        });
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      }
    } catch (error) {
      console.log("error", error);
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };
  checkStudentRequiredFields = (type) => {
    let {
      studentPhEmptyBtnClicks,
      studentPhQueryPayload,
      studentCreatePayload,
      studentCrEmptyBtnClicks,
    } = this.state;
    console.log(studentPhEmptyBtnClicks, "studentPhEmptyBtnClicks");
    if (type === 1) {
      if (
        isEmptyOrUndefine(studentPhQueryPayload["phno1"])
        // ||
        // isEmptyOrUndefine(studentPhQueryPayload["phno2"])
      ) {
        let newBtnClicks =
          studentPhEmptyBtnClicks === 4 ? 1 : studentPhEmptyBtnClicks + 1;
        this.setState(
          {
            studentPhEmptyBtnClicks: newBtnClicks,
          },
          () => this.showToast(newBtnClicks, 2)
        );
        return false;
      }
    } else if (type === 3) {
      if (
        isEmptyOrUndefine(studentCreatePayload["firstName"]) ||
        isEmptyOrUndefine(studentCreatePayload["email"]) ||
        isEmptyOrUndefine(studentCreatePayload["lastName"]) ||
        isEmptyOrUndefine(
          moment(studentCreatePayload["dob"]).format("DD-MM-YYYY")
        )
      ) {
        let newBtnClicks =
          studentCrEmptyBtnClicks === 4 ? 1 : studentCrEmptyBtnClicks + 1;
        this.setState(
          {
            studentCrEmptyBtnClicks: newBtnClicks,
          },
          () => this.showToast(newBtnClicks, 2)
        );
        return false;
      }
    }
    return true;
  };
  showToast = (btnClicks, type) => {
    let errMsg = "";
    if (type === 2) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW001[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW001[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW001[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW001[3];
          break;
        default:
          break;
      }
    }
    toast(errMsg, "error");
  };
  handleChange = (fieldName, value) => {
    this.setState((prevState) => ({
      studentPhQueryPayload: {
        ...prevState.studentPhQueryPayload,
        [fieldName]: value,
      },
    }));
  };

  createStudent = async (tab, title, data, type) => {
    let director = localStorage.getItem("schoolCategory");
    if (director === "TUBclass Senior") {
      let { studentPhQueryPayload, studentCreatePayload } = this.state;
      if (type === "student") {
        console.log("Student", tab);
        if (tab === 2) {
          try {
            this.props.dispatch({
              type: loaders.IS_AUTH_FETCHING,
              payload: true,
            });
            let url = "player/getplayers";
            let myInit = {
              headers: accessHeaders(),
              queryStringParameters: {
                phno1: studentPhQueryPayload.phno1?.slice(
                  studentPhQueryPayload.countryCode1.length
                ),
                phno2: studentPhQueryPayload?.phno2?.slice(
                  studentPhQueryPayload?.countryCode2?.length
                ),
                countryCode1: studentPhQueryPayload.countryCode1,
                countryCode2: studentPhQueryPayload?.countryCode2,
                schoolId: sessionStorage.getItem("schoolId"),
                userName: localStorage.getItem("username"),
                email: studentPhQueryPayload.email || "",
              },
            };
            const response = await API.get(ENVIRONMENT, url, myInit);
            if (response.status === 200 && !_.isEmpty(response.data)) {
              this.setState({
                studentAvailableList: response.data[0].data,
                studentAddTabs: 2, // Enable Associated Players tab
              });
            } else {
              this.setState({
                studentAvailableList: [],
                studentAddTabs: 1, // Open Add New Student tab
                studentPopupTitle: title,
              });
            }
          } catch (error) {
            console.error("Error fetching students:", error);
            toast("Student not Found!!", "error");
          } finally {
            this.props.dispatch({
              type: loaders.IS_AUTH_FETCHING,
              payload: false,
            });
          }
          // this.setState({
          //   studentPopupTitle: title,
          // });
        } else if (tab === 5) {
          await this.getClassAndSectionsDpList(1);
          this.setState({
            studentAddTabs: tab,
            studentPopupTitle: title,
          });
        } else if (tab === 4) {
          let validateSt = validate(
            studentCreatePayload,
            studentCreateInputValidation
          );
          if (validateSt) {
            this.setState({
              studentCreatePlError: validateSt,
            });
          } else {
            this.setState({
              studentAddTabs: tab,
              studentPopupTitle: title,
            });
          }
        } else {
          this.setState({
            studentAddTabs: tab,
            studentPopupTitle: title,
          });
        }
      } else {
        this.setState({
          importPopupType: tab,
          studentPopupTitle: title,
        });
      }
      this.props.dispatch({
        type: loaders.IS_AUTH_FETCHING,
        payload: false,
      });
    } else {
      let { studentPhQueryPayload, studentCreatePayload } = this.state;
      if (type === "student") {
        console.log("Student", tab);
        if (tab === 2) {
          try {
            this.props.dispatch({
              type: loaders.IS_AUTH_FETCHING,
              payload: true,
            });
            let url = "player/getplayers";
            let myInit = {
              headers: accessHeaders(),
              queryStringParameters: {
                phno1: studentPhQueryPayload.phno1.slice(
                  studentPhQueryPayload.countryCode1.length
                ),
                phno2: studentPhQueryPayload?.phno2?.slice(
                  studentPhQueryPayload?.countryCode2?.length
                ),
                countryCode1: studentPhQueryPayload.countryCode1,
                countryCode2: studentPhQueryPayload?.countryCode2,
                schoolId: sessionStorage.getItem("schoolId"),
                userName: localStorage.getItem("username"),
                email: this.state.email,
              },
            };
            const response = await API.get(ENVIRONMENT, url, myInit);
            if (response.status === 200 && !_.isEmpty(response.data)) {
              this.setState({
                studentAvailableList: response.data[0].data,
              });
              this.props.dispatch({
                type: loaders.IS_AUTH_FETCHING,
                payload: false,
              });
            } else {
              this.setState({
                studentAvailableList: [],
              });
              this.props.dispatch({
                type: loaders.IS_AUTH_FETCHING,
                payload: false,
              });
            }
          } catch (error) {
            this.props.dispatch({
              type: loaders.IS_AUTH_FETCHING,
              payload: false,
            });
          }
          this.setState({
            studentAddTabs: tab,
            studentPopupTitle: title,
          });
        } else if (tab === 5) {
          await this.getClassAndSectionsDpList(1);
          this.setState({
            studentAddTabs: tab,
            studentPopupTitle: title,
          });
        } else if (tab === 4) {
          let validateSt = validate(
            studentCreatePayload,
            studentCreateInputValidation
          );
          if (validateSt) {
            this.setState({
              studentCreatePlError: validateSt,
            });
          } else {
            this.setState({
              studentAddTabs: tab,
              studentPopupTitle: title,
            });
          }
        } else {
          this.setState({
            studentAddTabs: tab,
            studentPopupTitle: title,
          });
        }
      } else {
        this.setState({
          importPopupType: tab,
          studentPopupTitle: title,
        });
      }
      this.props.dispatch({
        type: loaders.IS_AUTH_FETCHING,
        payload: false,
      });
    }
  };

  getClassAndSectionsDpList = async (type) => {
    try {
      let { selectedClassDetail } = this.state;
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });

      let url = "student/getClassesAndSections";
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          type: type,
          classId: selectedClassDetail._id,
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200 && !_.isEmpty(response.data)) {
        if (type === 1) {
          this.setState({
            classNamesList: response.data,
          });
          this.props.dispatch({
            type: loaders.IS_AUTH_FETCHING,
            payload: false,
          });
        } else {
          this.setState({
            sectionsList: response.data,
          });
          this.props.dispatch({
            type: loaders.IS_AUTH_FETCHING,
            payload: false,
          });
        }
      }
    } catch (error) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };
  triggerStudentListApi = (type) => {
    if (type === "studentAddTabs") {
      this.getStudentsTableList();
    }
  };
  closeStudentPopups = (type) => {
    this.setState(
      {
        [type]: null,
        studentPhQueryPayload: {
          ...this.state.studentPhQueryPayload,
          ...this.defaultStudentQueryPayload,
        },
      },
      () => this.triggerStudentListApi(type)
    );
  };
  onChangeMobile = (name, countryCodeName, value, country) => {
    let { studentPhQueryPayload } = this.state;
    let onlyPhNumber = value.slice(country.dialCode.length);
    value = country.dialCode + onlyPhNumber.replace(/^0+/, "");
    studentPhQueryPayload[name] = value;
    studentPhQueryPayload[countryCodeName] = country.dialCode;

    this.setState({ studentPhQueryPayload: studentPhQueryPayload });
  };
  onChangeStudent = (name, value) => {
    let { studentCreatePayload, studentCreatePlError } = this.state;
    studentCreatePayload[name] = value;
    studentCreatePlError[name] = undefined;
    this.setState({ studentCreatePayload, studentCreatePlError });
  };
  addStudent = async (student) => {
    let { studentPhQueryPayload, studentCreatePayload } = this.state;

    let { selectedClassDetail, studentAvailableList } = this.state;
    this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
    try {
      let url = "student/create";
      let myInit = {
        headers: accessHeaders(),
        body: {
          playerId: student._id,
          classId: selectedClassDetail._id,
          email: studentPhQueryPayload.email || "",
        },
        queryStringParameters: {
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200 && !_.isEmpty(response.data)) {
        studentAvailableList &&
          studentAvailableList.map((st) => {
            if (st._id === response.data.playerId) {
              st["isDisabled"] = true;
            }
          });
        await this.props.galleryAction.getStudentsList();
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
        this.setState({ studentAvailableList: studentAvailableList });
      }
    } catch (err) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };

  deleteStudent = async (studentid) => {
    try {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
      let url = "student/deleteStudent";
      let myInit = {
        headers: accessHeaders(),
        body: {
          studentId: studentid,
        },
        queryStringParameters: {
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        this.setState(
          { tab: 3, studentAddTabs: null },
          this.getStudentsTableList
        );
        this.props.galleryAction.getStudentsList();
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
        toast(response.message, "success");
      } else {
        toast(response.message, "error");
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      }
    } catch (error) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };
  deleteClass = async () => {
    let { selectedClassDetail } = this.state;
    try {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
      let url = "class/delete";
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          id: selectedClassDetail?._id,
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        this.onNavigate(1, null);
        this.setState({ studentAddTabs: 0 }, () =>
          this.props.profileAction.getSchoolBasedClassDropdown()
        );
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
        toast(response.message, "success");
      } else {
        toast(response.message, "error");
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      }
    } catch (error) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };
  uploadPhoto = async (target) => {
    let { studentCreatePayload } = this.state;
    try {
      // IMAGE UPLOAD API
      let formData = new FormData();
      formData.append("image", target.files[0]);
      let url = "user/uploadImage";
      const myInit = {
        body: formData,
        headers: accessHeaders(),
      };
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200 && response.message !== "Invalid Image.") {
        studentCreatePayload["profilePic"] =
          response.data?.originalImage?.original;
        this.setState({
          studentCreatePayload: studentCreatePayload,
        });
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      } else {
        toast(response?.message, "error");
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      }
    } catch (err) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };
  createNewStudent = async () => {
    let { studentCreatePayload, selectedClassDetail, studentPhQueryPayload } =
      this.state;

    if (this.checkStudentRequiredFields(3)) {
      try {
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
        let url = "student/create";
        let myInit = {
          headers: accessHeaders(),
          body: {
            ...studentCreatePayload,
            ...studentPhQueryPayload,
            phno1: studentPhQueryPayload.phno1.slice(
              studentPhQueryPayload.countryCode1.length
            ),
            phno2: studentPhQueryPayload?.phno2?.slice(
              studentPhQueryPayload?.countryCode2?.length
            ),
            classId: selectedClassDetail._id,
          },
          queryStringParameters: {
            userName: localStorage.getItem("username"),
          },
        };
        const response = await API.post(ENVIRONMENT, url, myInit);
        if (response.status === 200 && !_.isEmpty(response.data)) {
          this.setState(
            {
              studentAddTabs: 0,
              studentCreatePayload: this.defaultStCreatePayload,
              studentPhQueryPayload: {} 
            },
            this.getStudentsTableList
          );
          await this.props.galleryAction.getStudentsList();
          this.props.dispatch({
            type: loaders.IS_AUTH_FETCHING,
            payload: false,
          });
        } else {
          this.props.dispatch({
            type: loaders.IS_AUTH_FETCHING,
            payload: false,
          });
        }
      } catch (err) {
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      }
    }
  };
  getOneStudent = async (student) => {
    let { countryList, particularStudentDetail } = this.state;
    try {
      this.setState({ loading: true });
      let url = "student/getStudentsById";
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          studentId: student._id,
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200 && !_.isEmpty(response.data)) {
        let countries = Country.getAllCountries();
        countries &&
          countries.map((country) => {
            countryList.push({
              label: country.name,
              value: country.isoCode,
            });
          });
        if (!_.isEmpty(response.data.country)) {
          particularStudentDetail.country = response.data.country;
          this.getStatesList(response.data.country);
          particularStudentDetail.state = response.data?.state;
          let cCode = {
            value: response.data?.state?.value,
            countryCode: response.data.country.value,
          };
          this.getCititesList(cCode);
        }
        let profileDetail = {
          ...response.data,
          isObjectUrlImage:
            response.data.profilePic?.original === "" ? false : true,
          dob: _.isEmpty(response.data.dob) ? "" : new Date(response.data.dob),
          firstContactNo:
            response.data.countryCode1 != undefined &&
            response.data.countryCode1 != ""
              ? response.data.countryCode1 + response.data.firstContactNo
              : "",
          secondContactNo:
            response.data.countryCode2 != undefined &&
            response.data.countryCode2 != ""
              ? response.data.countryCode2 + response.data.secondContactNo
              : "",
        };
        this.setState({
          countryList: countryList,
          particularStudentDetail: profileDetail,
        });
      }
      this.setState({ loading: false });
    } catch (error) {
      console.log("error", error);
      this.setState({ loading: false });
    }
  };
  onChangeStudentProfile = (name, value, id) => {
    let { particularStudentDetail, particularStudentDetailError } = this.state;
    if (name === "country") {
      particularStudentDetail["state"] = null;
      particularStudentDetail["district"] = null;
      particularStudentDetail[name] = value;
      particularStudentDetailError[name] = undefined;
      this.getStatesList(value);
    } else if (name === "state") {
      particularStudentDetail["district"] = null;
      particularStudentDetail[name] = value;
      particularStudentDetailError[name] = undefined;
      this.getCititesList(value);
    }
    //  else if(name === "dob" && value != null) {
    //     let checkForMinimumAge = moment().diff(value, 'years')
    //     if(checkForMinimumAge >= 12){
    //         particularStudentDetail[name] = value
    //         particularStudentDetailError[name] = undefined
    //     } else {
    //         toast("User needs to be minimum of 12 years old to access the TUB Class", "error")
    //     }
    // }
    else {
      particularStudentDetail[name] = value;
      particularStudentDetailError[name] = undefined;
    }

    this.setState({
      particularStudentDetail: particularStudentDetail,
      particularStudentDetailError: particularStudentDetailError,
    });
  };
  onChangeStudentMobile = (name, value) => {
    let { particularStudentDetail, particularStudentDetailError } = this.state;
    particularStudentDetail[name] = value;
    particularStudentDetailError[name] = undefined;
    this.setState({
      particularStudentDetail: particularStudentDetail,
      particularStudentDetailError: particularStudentDetailError,
    });
  };
  getStatesList = async (country) => {
    let statesList = [];
    let states = await State.getStatesOfCountry(country.value);
    states.map((state) => {
      statesList.push({
        label: state.name,
        value: state.isoCode,
        countryCode: state.countryCode,
      });
    });
    this.setState({
      statesList: statesList,
    });
  };
  getCititesList = (state) => {
    let citiesList = [];
    let cities = City.getCitiesOfState(state.countryCode, state.value);
    cities.map((city) => {
      citiesList.push({
        label: city.name,
        value: city.stateCode,
        countryCode: city.countryCode,
      });
    });
    this.setState({
      districtList: citiesList,
    });
  };
  updateStudentProfile = async () => {
    let { particularStudentDetail, statesList } = this.state;
    let validation = {
      ...studentInputValidation,
      ...(particularStudentDetail["country"] != null && statesList.length === 0
        ? { state: undefined }
        : 0),
      ...(particularStudentDetail["country"] != null && statesList.length === 0
        ? { district: undefined }
        : 0),
    };
    const notValid = validate(particularStudentDetail, validation);
    if (notValid) {
      // let newBtnClicks = studentProfileEmptyClicks === 4 ? 1 : studentProfileEmptyClicks + 1;
      // this.setState({
      //     studentProfileEmptyClicks: newBtnClicks
      // }, () => this.showToast(newBtnClicks, 2))
      this.setState({
        particularStudentDetailError: notValid,
      });
    } else {
      let payload = {
        ...particularStudentDetail,
        studentId: particularStudentDetail._id,
        firstContactNo: particularStudentDetail.firstContactNo.slice(
          particularStudentDetail?.countryCode1?.length
        ),
        secondContactNo: particularStudentDetail.secondContactNo.slice(
          particularStudentDetail?.countryCode2?.length
        ),
        state: {
          label: particularStudentDetail?.state?.label,
          value: particularStudentDetail?.state?.value,
        },
        district: {
          label: particularStudentDetail?.district?.label,
          value: particularStudentDetail?.district?.value,
        },
      };
      this.setState({ loading: true });
      let url = "student/update";
      let myInit = {
        headers: accessHeaders(),
        body: payload,
        queryStringParameters: {
          userName: localStorage.getItem("username"),
        },
      };
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        this.setState({ loading: false });
        // toast(response?.message,'success')
        await this.props.galleryAction.getStudentsList();
      }
    }
  };
  removePhoto = () => {
    let { particularStudentDetail } = this.state;
    particularStudentDetail["profilePic"]["original"] = "";
    particularStudentDetail["isObjectUrlImage"] = false;
    this.setState({ particularStudentDetail: particularStudentDetail }, () =>
      this.updateStudentProfile()
    );
  };
  replacePhoto = async (file) => {
    let { particularStudentDetail } = this.state;
    // IMAGE UPLOAD API
    try {
      let formData = new FormData();
      formData.append("image", file);
      this.setState({ loading: true });
      let url = "user/uploadImage";
      const myInit = {
        body: formData,
        headers: accessHeaders(),
      };
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200 && response.message !== "Invalid Image.") {
        particularStudentDetail["profilePic"]["original"] =
          response.data?.originalImage?.original;
        particularStudentDetail["isObjectUrlImage"] = true;
        this.setState(
          {
            particularStudentDetail: particularStudentDetail,
          },
          () => this.updateStudentProfile()
        );
        this.setState({ loading: false });
        return true;
      } else {
        this.setState({ loading: false });
        return false;
      }
    } catch (error) {
      toast("Error processing request", "error");
      return false;
    }
  };
  toggleAnotherNote = () => {
    this.setState({
      showAnotherNote: true,
    });
  };
  studentCalendarTileDisabled = ({ date, view }) => {
    let isDisabled = this.isDisabled();
    let { disabledDates, workingDaysOptions } = this.state;
    let filteredIndexes = workingDaysOptions
      .filter((fl) => fl.checked === false)
      .map((f) => f.index);
    let getDisableDates =
      (disabledDates &&
        disabledDates.find((dDate) => this.isSameDay(dDate, date))) ||
      filteredIndexes.includes(date.getDay());
    return isDisabled ? true : getDisableDates;
  };
  onChangeTransfer = (name, value) => {
    let { transferPayload } = this.state;
    transferPayload[name] = value;
    if (name === "to") {
      this.getClassAndSectionsDpList(2);
    }
    this.setState({
      transferPayload,
    });
  };
  transferStudent = async () => {
    try {
      let { transferPayload, particularStudentDetail, selectedClassDetail } =
        this.state;
      let payload = {
        ...transferPayload,
        to: transferPayload.to?.label,
        tosection: transferPayload.tosection?.value,
        studentid: particularStudentDetail._id,
        from: selectedClassDetail.class_Name,
        fromsection: selectedClassDetail.section_Name,
        toClassId: transferPayload?.to?._id,
        fromClassId: selectedClassDetail?._id,
      };
      let url = "student/transferStudent";
      let myInit = {
        headers: accessHeaders(),
        body: payload,
        queryStringParameters: {
          userName: localStorage.getItem("username"),
        },
      };
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        toast(response.message, "success");
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
        this.setState({
          studentAddTabs: 0,
          tab: 1,
        });
      } else {
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
      }
      window.location.reload();
    } catch (error) {
      toast(error?.message, "error");
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  };
  getExcelFunc = (filename, data) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = filename;
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, fileName + fileExtension);
  };
  downloadSampleExcel = (type) => {
    let { sampleClassExcelData, sampleStudentExcelData } = this.state;
    if (type === 1) {
      this.getExcelFunc("Class Sample Excel", sampleClassExcelData);
    } else {
      this.getExcelFunc("Student Sample Excel", sampleStudentExcelData);
    }
  };
  resendStudentRequest = async (data) => {
    this.setState({ loading: true });
    let url = "student/resendStudentLinkRequest";
    let myInit = {
      headers: accessHeaders(),
      body: {
        studentId: data._id,
      },
      queryStringParameters: {
        userName: localStorage.getItem("username"),
      },
    };
    const response = await API.post(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      toast(response.message, "success");
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  };
  getEnrolledStudentsDisabledBtns = () => {
    let role = localStorage.getItem("role");
    let disabled = role === "R04" || role === "R07" ? true : false;
    return disabled;
  };
  getMaxSeat = (available) => {
    let { classDetail } = this.state;

    if (parseInt(classDetail["allocated_Seats"]) > parseInt(available)) {
      return available;
    }
  };

  render() {
    let {
      tab,
      showClassPopup,
      showAcademicYearPopup,
      workingDaysOptions,
      teacherList,
      traineeList,
      schoolAcademicYear,
      classDetail,
      errors,
      classPopupTitle,
      classFilterDropdown,
      classQueryPayload,
      disabledDates,
      calendarCompleteList,
      isShowToggler,
      selectedDateRange,
      toggleDate,
      selectedMonth,
      studentPopupTitle,
      studentAddTabs,
      studentPhQueryPayload,
      studentAvailableList,
      studentCreatePayload,
      studentTableList,
      selectedClassDetail,
      particularStudentDetail,
      countryList,
      statesList,
      districtList,
      studentStatusList,
      studentrelationshipshipList,
      genderOptions,
      particularStudentDetailError,
      showAnotherNote,
      studentFilterDropdown,
      studentQueryPayload,
      teacherCalendarDates,
      studentCalendar,
      classNamesList,
      transferPayload,
      curriculumList,
      importPopupType,
      showBuyMoreSeatsPopup,
      substituteTeacherList,
      showInvoicePopup,
      isEdit,
      studentCreatePlError,
      loading,
      readingProgramsList,
    } = this.state;
    let { classes, loader, subscriptionTeam } = this.props;
    let disabled = this.isDisabled();
    let available = 0;
    let isEnrolledStudentsBtnsDisabled = this.getEnrolledStudentsDisabledBtns();
    if (subscriptionTeam && subscriptionTeam.subscriptionInfo) {
      available =
        subscriptionTeam.subscriptionInfo.SeatsPurchased -
        subscriptionTeam.subscriptionInfo.UsedSeats;
    }
    console.log(errors, "errororrorro");
    let pathname = window.location.pathname;
    console.log(pathname, "pathname");

    let director = localStorage.getItem("schoolCategory");
    console.log(studentAvailableList, "studentAvailableList");
    return (
      <>
        {loading ? (
          <AppLoader />
        ) : (
          <div className="row no-gutters">
            {tab === 1 && (
              <>
                <ClassLeftComponent
                  showBack={false}
                  isClassPage={true}
                  isStudentPage={false}
                  triggerPopup={this.triggerPopup}
                  onChangeDate={this.onChangeDate}
                  saveCalendar={this.saveCalendar}
                  tileDisabled={this.tileDisabled}
                  changeMonth={this.changeMonth}
                  academicYear={schoolAcademicYear}
                  selectedDateRange={selectedDateRange}
                  toggleDate={toggleDate}
                  selectedMonth={selectedMonth}
                  disabledDates={disabledDates}
                  isShowToggler={isShowToggler}
                  calendarCompleteList={calendarCompleteList}
                  disabled={disabled}
                  subscriptionInfo={subscriptionTeam.subscriptionInfo}
                  onChangeReason={this.onChangeReason}
                  onNavigate={this.onNavigate}
                />
                <ClassTable
                  disabled={disabled}
                  onNavigate={this.onNavigate}
                  import={this.import}
                  createStudent={this.createStudent}
                  triggerPopup={this.triggerPopup}
                  triggerClassPopup={this.triggerClassPopup}
                  classFilterDropdown={classFilterDropdown}
                  onChangeStatusFilter={this.onChangeStatusFilter}
                  classQueryPayload={classQueryPayload}
                  classes={classes}
                  loader={loader}
                />
              </>
            )}
            {tab === 2 && (
              <ManageSeatAllocation
                onNavigate={this.onNavigate}
                classes={classes}
                loader={loader}
                available={available}
              />
            )}
            {tab === 3 && (
              <>
                <ClassLeftComponent
                  showBack={true}
                  isClassPage={false}
                  isStudentPage={true}
                  onNavigate={this.onNavigate}
                  triggerPopup={this.triggerPopup}
                  onChangeDate={this.onChangeDate}
                  saveTeacherCalendar={this.saveTeacherCalendar}
                  changeMonth={this.changeMonth}
                  selectedClassDetail={selectedClassDetail}
                  academicYear={schoolAcademicYear}
                  studentCalendar={studentCalendar}
                  disabledDates={disabledDates}
                  teacherCalendarDates={teacherCalendarDates}
                  calendarCompleteList={calendarCompleteList}
                  disabled={disabled}
                  subscriptionInfo={subscriptionTeam.subscriptionInfo}
                  onChangeReason={this.onChangeReason}
                  studentCalendarTileDisabled={this.studentCalendarTileDisabled}
                />
                <EnrolledStudents
                  onNavigate={this.onNavigate}
                  createStudent={this.createStudent}
                  onChangeStatusFilter={this.onChangeStatusFilter}
                  resendStudentRequest={this.resendStudentRequest}
                  studentQueryPayload={studentQueryPayload}
                  studentTableList={studentTableList}
                  studentFilterDropdown={studentFilterDropdown}
                  import={this.import}
                  disabled={this.editDisabled()}
                  isEnrolledStudentsBtnsDisabled={
                    isEnrolledStudentsBtnsDisabled
                  }
                />
              </>
            )}
            {tab === 4 && (
              <>
                <StudentProfile
                  studentDetail={particularStudentDetail}
                  particularStudentDetailError={particularStudentDetailError}
                  countryList={countryList}
                  statesList={statesList}
                  districtList={districtList}
                  studentStatusList={studentStatusList}
                  studentrelationshipshipList={studentrelationshipshipList}
                  genderOptions={genderOptions}
                  showAnotherNote={showAnotherNote}
                  selectedClassDetail={selectedClassDetail}
                  toggleAnotherNote={this.toggleAnotherNote}
                  onChangeStudentProfile={this.onChangeStudentProfile}
                  onChangeStudentMobile={this.onChangeStudentMobile}
                  removePhoto={this.removePhoto}
                  replacePhoto={this.replacePhoto}
                  saveStudentProfile={this.updateStudentProfile}
                  onNavigate={this.onNavigate}
                  createStudent={this.createStudent}
                />
              </>
            )}
          </div>
        )}
        {showClassPopup && (
          <CreateModel
            isOpen={showClassPopup}
            toggle={() => this.triggerClassPopup("Add Class", false)}
            title={classPopupTitle}
            className="max-600"
            // onSubmit={this.onSubmitDelete}
          >
            <div className="row">
              <div className="col-7">
                <FormInput
                  type="text"
                  label="Grade/Class Name"
                  name="class_Name"
                  placeholder="Class Name"
                  onChange={(...params) => this.onChange(...params)}
                  value={classDetail["class_Name"]}
                  error={[errors["class_Name"]]}
                />
              </div>
              <div className="col-5">
                <FormInput
                  type="only-text"
                  label="Section"
                  name="section_Name"
                  placeholder="Section"
                  onChange={(...params) => this.onChange(...params)}
                  value={classDetail["section_Name"]}
                  // error={errors["Last_name"]}
                  error={[errors["section_Name"]]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 custom-reselect">
                <ReactSelect
                  label="Curriculum"
                  name="curriculum_Id"
                  isClearable={false}
                  options={curriculumList}
                  onChange={(...params) => this.onChange(...params)}
                  value={classDetail?.curriculum_Id}
                  error={[errors["curriculum_Id"]]}
                  placeholder={"Select a Curriculum"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 custom-reselect">
                <ReactSelect
                  label="Program / Reading Program"
                  name="reading_Program_Id"
                  placeholder="Choose Program Name"
                  options={readingProgramsList}
                  isMulti={false}
                  isClearable={false}
                  onChange={(...params) => this.onChange(...params)}
                  value={classDetail["reading_Program_Id"]}
                  error={[errors["reading_Program_Id"]]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 custom-reselect">
                <ReactSelect
                  label="Assign Class Teachers"
                  name="teachers"
                  placeholder="Enter Teacher Name"
                  options={teacherList}
                  isMulti={true}
                  isClearable={false}
                  onChange={(...params) => this.onChange(...params)}
                  value={classDetail["teachers"]}
                  // error={errors["Last_name"]}
                  error={[errors["teachers"]]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 custom-reselect">
                <ReactSelect
                  label="Assign Substitute Teachers (Optional)"
                  name="substituteTeachers"
                  placeholder="Enter Teacher Name"
                  options={substituteTeacherList}
                  isMulti={true}
                  isClearable={false}
                  onChange={(...params) => this.onChange(...params)}
                  value={classDetail["substituteTeachers"]}
                  // error={errors["Last_name"]}
                  error={[errors["substituteTeachers"]]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 custom-reselect">
                <ReactSelect
                  label="Assign Volunteers/Trainees (Optional)"
                  name="trainees"
                  placeholder="Enter Trainee Name"
                  options={traineeList}
                  isMulti={true}
                  isClearable={false}
                  onChange={(...params) => this.onChange(...params)}
                  value={classDetail["trainees"]}
                  // error={errors["Last_name"]}
                  error={[errors["trainees"]]}
                />
              </div>
            </div>
            {!isEdit && (
              <div className="row align-items-center">
                <div className="col-7">
                  <FormInput
                    type="percent"
                    label="Allocated Seats"
                    name="allocated_Seats"
                    placeholder="Enter a Number"
                    max={this.getMaxSeat(available)}
                    onChange={(...params) => this.onChange(...params)}
                    value={classDetail["allocated_Seats"]}
                    disabled={false}
                    // error={errors["Last_name"]}
                    error={[errors["allocated_Seats"]]}
                  />
                </div>
                <div className="col-5">
                  <p className="text-right">Available Seats - {available}</p>
                </div>
              </div>
            )}
            <div className="row align-items-center">
              <div className="col-3 mx-auto">
                <ButtonType2
                  // disabled={
                  //   classDetail.allocated_Seats == null ||
                  //   classDetail.class_Name == null ||
                  //   classDetail.section_Name == null ||
                  //   classDetail.substituteTeachers == null ||
                  //   classDetail.teachers == null ||
                  //   classDetail.trainees == null
                  //     ? true
                  //     : false
                  // }
                  btnName="Save"
                  className="buttonType1"
                  onClick={() => this.createClass(available)}
                />
              </div>
            </div>
          </CreateModel>
        )}
        {showAcademicYearPopup && (
          <CreateModel
            isOpen={showAcademicYearPopup}
            toggle={() => this.triggerPopup("showAcademicYearPopup", null)}
            title="School Academic Year"
            className="max-600 CalenDer_Pop"
            // onSubmit={this.onSubmitDelete}
          >
            <div className="row no-gutters">
              <div className="col-10 mx-auto">
                <FormInput
                  type="date-picker"
                  label="Start Date"
                  name="start_Date"
                  style={{ width: "100%" }}
                  onChange={(...params) => this.onChangeAcademicYear(...params)}
                  onChangeRaw={(...params) =>
                    this.onChangeAcademicYear(...params)
                  }
                  value={schoolAcademicYear["start_Date"]}
                  // error={errors["DOB"]}
                  // disabled={disabled}
                />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-10 mx-auto">
                <FormInput
                  type="date-picker"
                  label="End Date"
                  name="end_Date"
                  style={{ width: "100%" }}
                  onChange={(...params) => this.onChangeAcademicYear(...params)}
                  onChangeRaw={(...params) =>
                    this.onChangeAcademicYear(...params)
                  }
                  value={schoolAcademicYear["end_Date"]}
                  maxDate={this.setAcademicMaxDate(schoolAcademicYear)}
                  minDate={this.setAcademicMinDate(schoolAcademicYear)}
                  // error={errors["DOB"]}
                  // disabled={disabled}
                />
              </div>
            </div>

            <div className="row no-gutters">
              <div className="col-10 mx-auto">
                <Label for="exampleEmail">Working Days</Label>
                <div className="row no-gutters space-between">
                  {workingDaysOptions.map((op, i) => (
                    <div key={i}>
                      <FormInput
                        type="checkbox"
                        label="Working Days"
                        radiolabel={op.label}
                        radiolabelClass="mb-0 ml-1"
                        name="days"
                        style={{ width: "100%" }}
                        checked={op.checked}
                        onChange={(...params) =>
                          this.onChangeAcademicYear(...params)
                        }
                        value={op.value}
                        // error={errors["DOB"]}
                        // disabled={disabled}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-3">
              <div className="col-3 mx-auto">
                <ButtonType2
                  disabled={false}
                  btnName="Save"
                  className="buttonType1"
                  onClick={() => this.saveAcademicYear()}
                />
              </div>
            </div>
          </CreateModel>
        )}
        {studentAddTabs === 1 && (
          <CreateModel
            isOpen={studentAddTabs === 1 ? true : false}
            toggle={() => this.closeStudentPopups("studentAddTabs")}
            title={studentPopupTitle}
            className={`max-600  ${
              director === "TUBclass Senior"
                ? "emailInputPopUp"
                : "PhoneInput_Pop"
            }`}
            // onSubmit={this.onSubmitDelete}
          >
            {director !== "TUBclass Senior" ? (
              <div className="row  a1">
                <div className="col-12">
                  <Label>Enter 1st Parent/Guardian’s Mobile Number</Label>
                  <PhoneInput
                    country={"in"}
                    name="Mobile"
                    countryCodeEditable={false}
                    value={studentPhQueryPayload["phno1"]?.replace(/^0+/, "")}
                    onChange={(phone, country) =>
                      this.onChangeMobile(
                        "phno1",
                        "countryCode1",
                        phone,
                        country
                      )
                    }
                    placeholder=""
                    // disabled={disabled}
                  />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 mt-5">
                  <Label>Enter Student Email</Label>
                  <br />
                  <Input
                    className="w-100 px-1 py2 mb-5"
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={studentPhQueryPayload["email"]}
                    onChange={(e) => this.handleChange("email", e.target.value)}
                  />
                </div>
              </div>
            )}
            {director === "TUBclass Senior" ? null : (
              <div className="row mt-4 mb-1">
                <div className="col-12">
                  <Label>
                    Enter 2nd Parent/Guardian’s Mobile Number (Optional)
                  </Label>
                  <PhoneInput
                    country={"in"}
                    countryCodeEditable={false}
                    value={studentPhQueryPayload["phno2"]?.replace(/^0+/, "")}
                    onChange={(phone, country) =>
                      this.onChangeMobile(
                        "phno2",
                        "countryCode2",
                        phone,
                        country
                      )
                    }

                    // disabled={disabled}
                  />
                </div>
              </div>
            )}
            {director === "TUBclass Senior" ? null : (
              <>
                <span className="optionalText">
                  Please do not add '0' before the phone number
                </span>
                <span className="optionalText">
                  If only one number is available, please enter it twice.
                </span>
              </>
            )}
            <div className="row align-items-center">
              <div className="col-3 mx-auto">
                {/* <ButtonType1
                  btnName="Next"
                  className="buttonType1"
                  onClick={() =>
                    this.createStudent(2, "Associated players", null, "student")
                  }
                /> */}
                {director === "TUBclass Senior" ? (
                  <ButtonType1
                    btnName="Next"
                    className="buttonType1"
                    onClick={() =>
                      this.createStudent(
                        2,
                        "Associated Players",
                        null,
                        "student"
                      )
                    }
                  />
                ) : (
                  <ButtonType1
                    btnName="Next"
                    className="buttonType1"
                    onClick={() => {
                      if (!studentPhQueryPayload["phno1"]?.replace(/^0+/, "")) {
                        toast(
                          "Please fill the first phone number field.",
                          "error"
                        );
                      } else {
                        this.createStudent(
                          2,
                          "Associated Players",
                          null,
                          "student"
                        );
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </CreateModel>
        )}
        {studentAddTabs === 2 && (
          <CreateModel
            isOpen={studentAddTabs === 2 ? true : false}
            toggle={() => this.closeStudentPopups("studentAddTabs")}
            title={studentPopupTitle}
            className="max-600"
            // onSubmit={this.onSubmitDelete}
          >
            <div className="row align-items-center center-content mt-3 mb-3">
              {studentAvailableList.length > 0 ? (
                studentAvailableList &&
                studentAvailableList.map((st, i) => (
                  <div className="col-4" key={i}>
                    <div className="righttub-members">
                      <img
                        src={
                          st.profilePic !== undefined &&
                          st.profilePic?.original !== ""
                            ? st.profilePic?.original
                            : require("../../../../assets/images/noImage.png")
                        }
                        className="player_img"
                        alt="alphatub"
                      />
                      <h5>{st.playerName}</h5>
                    </div>
                    <div className="col-12 mt-3">
                      <ButtonType1
                        btnName={st?.isDisabled ? "Added" : "Add"}
                        className="buttonType1"
                        disabled={st?.isDisabled}
                        onClick={() => this.addStudent(st)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <p
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      textAlign: "center",
                    }}
                  >
                    {/* No students are associated with these mobile numbers. */}
                    No students are associated with these phone numbers. Please
                    add a new student by clicking the 'Add New' button.
                  </p>
                </div>
              )}
            </div>
            <div className="row d-flex align-items-center mt-5">
              <div className="col-3 mx-auto">
                <ButtonType1
                  btnName="Add New"
                  className="buttonType1"
                  onClick={() =>
                    this.createStudent(3, "Add Student", null, "student")
                  }
                />
              </div>
              <div className="col-3 mx-auto">
                <ButtonType1
                  btnName="Close"
                  className="buttonTypecancle"
                  onClick={() => this.closeStudentPopups("studentAddTabs")}
                />
              </div>
            </div>
            {/* <div className="row align-items-center mt-5">
             
            </div> */}
          </CreateModel>
        )}
        {/* create new student popup */}
        {studentAddTabs === 3 && (
          <CreateModel
            isOpen={studentAddTabs === 3 ? true : false}
            toggle={() => this.closeStudentPopups("studentAddTabs")}
            title={"New Student Details"}
            className="mx-600 PhoneInput_Pop popup-calendar"
          >
            <div className="row">
              <div className="col-4">
                <div className="row no-gutter center-content">
                  <div className="col-12 text-center">
                    <img
                      className="noImage br-50"
                      src={
                        studentCreatePayload["profilePic"] != null
                          ? studentCreatePayload["profilePic"]
                          : require("../../../../assets/images/noImage.png")
                      }
                      alt="alphatub"
                    />
                    <div className="mt-3">
                      <label
                        for="file"
                        className="btn btn-primary small-button"
                      >
                        Upload Photo
                      </label>
                      <input
                        type="file"
                        id="file"
                        onChange={({ target }) => this.uploadPhoto(target)}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-12">
                    <FormInput
                      type="only-text"
                      label="First Name"
                      name="firstName"
                      placeholder="First Name"
                      onChange={(...params) => this.onChangeStudent(...params)}
                      value={studentCreatePayload["firstName"]}
                      error={studentCreatePlError["firstName"]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <FormInput
                      type="only-text"
                      label="Last Name"
                      name="lastName"
                      placeholder="Last Name"
                      onChange={(...params) => this.onChangeStudent(...params)}
                      value={studentCreatePayload["lastName"]}
                      error={studentCreatePlError["lastName"]}
                    />
                  </div>
                </div>
                {director === "TUBclass Senior" || "TUBclass Lite" ? (
                  <div className="row">
                     {director === "TUBclass Senior" ?
                    <div className="col-12">
                      <FormInput
                        type="text"
                        label="Email"
                        name="email"
                        placeholder="Email"
                        onChange={(...params) =>
                          this.onChangeStudent(...params)
                        }
                        value={studentCreatePayload["email"]}
                        error={studentCreatePlError["email"]}
                      />
                    </div>:
                    <div className="col-12">
                      <Label>Enter Mobile Number</Label>
                      <PhoneInput
                        country={"in"}
                        name="Mobile"
                        countryCodeEditable={false}
                        value={studentPhQueryPayload["phno1"]?.replace(
                          /^0+/,
                          ""
                        )}
                        onChange={(phone, country) =>
                          this.onChangeMobile(
                            "phno1",
                            "countryCode1",
                            phone,
                            country
                          )
                        }
                        placeholder="Enter Mobile Number"
                      />
                    </div>}
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-12">
                    <FormInput
                      type="date-picker"
                      label="Date of Birth"
                      name="dob"
                      style={{ width: "100%" }}
                      onChange={(...params) =>
                        this.onChangeStudent(...params, null)
                      }
                      value={studentCreatePayload["dob"]}
                      error={studentCreatePlError["dob"]}
                      maxDate={new Date()}
                      // disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-3 mx-auto">
                <ButtonType1
                  btnName="Submit"
                  className="buttonType1"
                  onClick={() =>
                    this.createStudent(4, "Add Student", null, "student")
                  }
                />
              </div>
            </div>
          </CreateModel>
        )}
        {studentAddTabs === 4 && (
          <CreateModel
            isOpen={studentAddTabs === 4 ? true : false}
            toggle={() => this.closeStudentPopups("studentAddTabs")}
            title={studentPopupTitle}
            // onSubmit={this.onSubmitDelete}
          >
            <div className="alphatub-card-border"></div>
            <div className="row mt-4 mb-4">
              <div className="col-12">
                <p>
                  Are you sure you want to add{" "}
                  {studentCreatePayload["firstName"]}? All Data will be
                  transferred only after the confirmation of parent/guardian.
                </p>
              </div>
            </div>
            <div className="alphatub-card-border"></div>
            <div className="row align-items-center mt-3 content-end">
              <div className="col-3">
                <ButtonType1
                  btnName="Close"
                  className="popup-close-btn"
                  onClick={() => this.closeStudentPopups("studentAddTabs")}
                />
              </div>
              <div className="col-3">
                <ButtonType1
                  btnName="Confirm"
                  className="buttonType1"
                  onClick={() => this.createNewStudent()}
                />
              </div>
            </div>
          </CreateModel>
        )}

        {/* transfer student popup */}
        {studentAddTabs === 5 && (
          <CreateModel
            isOpen={studentAddTabs === 5 ? true : false}
            toggle={() => this.closeStudentPopups("studentAddTabs")}
            title={studentPopupTitle}
          >
            <div className="row align-items-center">
              <div className="col-8">
                <FormInput
                  type="text"
                  label="Transfer From"
                  name="transferFrom"
                  placeholder="Select Class"
                  disabled={true}
                  // onChange={(...params) => this.onChangeStudent(...params)}
                  value={selectedClassDetail["class_Name"]}
                />
              </div>
              <div className="col-4">
                <FormInput
                  type="text"
                  label="Section"
                  labelClassName="m-0"
                  name="section_Name"
                  groupclassName="mt-2"
                  placeholder="section"
                  disabled={true}
                  // onChange={(...params) => this.onChangeStudent(...params)}
                  value={selectedClassDetail["section_Name"]}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-12 custom-reselect">
                <ReactSelect
                  label="Transfer To"
                  name="to"
                  placeholder="Select Class"
                  options={classNamesList}
                  onChange={(name, value) => this.onChangeTransfer(name, value)}
                  value={transferPayload["to"]}
                  // error={errors["Last_name"]}
                />
              </div>
              {/* <div className="col-4 custom-reselect mt-1">
                                <ReactSelect
                                    label=""
                                    name="tosection"
                                    placeholder="A"
                                    options={sectionsList}
                                    onChange={(name, value) => this.onChangeTransfer(name, value)}
                                    value={transferPayload["tosection"]}
                                // error={errors["Last_name"]}
                                />
                            </div> */}
            </div>
            <div className="row">
              <div className="col-12">
                <FormInput
                  type="text"
                  label="Reason For Transfer (Optional)"
                  name="reasom"
                  placeholder="Enter Text"
                  onChange={(...params) => this.onChangeTransfer(...params)}
                  value={transferPayload["reason"]}
                  // error={errors["Last_name"]}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-3 mx-auto">
                <ButtonType1
                  btnName="Transfer"
                  className="buttonType1"
                  onClick={() => this.transferStudent()}
                />
              </div>
            </div>
          </CreateModel>
        )}
        {/* Delete student popup */}
        {studentAddTabs === 6 && (
          <CreateModel
            isOpen={studentAddTabs === 6 ? true : false}
            toggle={() => this.closeStudentPopups("studentAddTabs")}
            title={studentPopupTitle}
          >
            <div className="alphatub-card-border"></div>
            <div className="row pt-3 pb-5">
              <div className="col-12">
                <p className="f-16px alphatub-card-normal-p">
                  Do you want to delete {particularStudentDetail?.firstName}?
                </p>
              </div>
            </div>
            <div className="alphatub-card-border"></div>
            <div className="row align-items-center mt-3 content-end">
              <div className="col-3">
                <ButtonType1
                  btnName="Close"
                  className="popup-close-btn"
                  onClick={() => this.closeStudentPopups("studentAddTabs")}
                />
              </div>
              <div className="col-4">
                <ButtonType1
                  btnName="Confirm"
                  className="bg-tub c-white buttonType1 "
                  onClick={() =>
                    this.deleteStudent(particularStudentDetail._id)
                  }
                />
              </div>
            </div>
          </CreateModel>
        )}
        {/* import class popup */}
        {importPopupType === 1 && (
          <CreateModel
            isOpen={importPopupType === 1 ? true : false}
            toggle={() => this.closeStudentPopups("importPopupType")}
            title={studentPopupTitle}
            // onSubmit={this.onSubmitDelete}
          >
            <div className="row mt-3 mb-3">
              <div className="col-12">
                <Label>Upload a CSV, XLS, or XLSX File From Computer</Label>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-3 mx-auto">
                <label for="file" className="buttonType1 cursor-pointer">
                  Upload
                </label>
                <input
                  type="file"
                  id="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={({ target }) => this.import(target, 1)}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3 content-end no-gutters">
              <p
                className="f-12 alphatub-card-normal-link"
                onClick={() => this.downloadSampleExcel(1)}
              >
                Download Sample File
              </p>
            </div>
          </CreateModel>
        )}
        {/* import student popup*/}
        {importPopupType === 2 && (
          <CreateModel
            isOpen={importPopupType === 2 ? true : false}
            toggle={() => this.closeStudentPopups("importPopupType")}
            title={studentPopupTitle}
            // onSubmit={this.onSubmitDelete}
          >
            <div className="row mt-3 mb-3">
              <div className="col-12">
                <Label>Upload a CSV, XLS, or XLSX File From Computer</Label>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-3 mx-auto">
                <label for="file" className="buttonType1 cursor-pointer">
                  Upload
                </label>
                <input
                  type="file"
                  id="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={({ target }) => this.import(target, 2)}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3 content-end no-gutters">
              <p
                className="f-12 alphatub-card-normal-link"
                onClick={() => this.downloadSampleExcel(2)}
              >
                Download Sample File
              </p>
            </div>
          </CreateModel>
        )}
        {/* Delete student popup */}
        {studentAddTabs === 7 && (
          <CreateModel
            isOpen={studentAddTabs === 7 ? true : false}
            toggle={() => this.closeStudentPopups("studentAddTabs")}
            title={studentPopupTitle}
          >
            <div className="alphatub-card-border"></div>
            <div className="row pt-3 pb-5">
              <div className="col-12">
                <p className="f-16px alphatub-card-normal-p">
                  Do you want to delete this Class ?
                </p>
              </div>
            </div>
            <div className="alphatub-card-border"></div>
            <div className="row align-items-center mt-3 content-end">
              <div className="col-3">
                <ButtonType1
                  btnName="Close"
                  className="popup-close-btn"
                  onClick={() => this.closeStudentPopups("studentAddTabs")}
                />
              </div>
              <div className="col-4">
                <ButtonType1
                  btnName="Confirm"
                  className="bg-tub c-white buttonType1"
                  onClick={() => this.deleteClass()}
                />
              </div>
            </div>
          </CreateModel>
        )}
        <div>
          <UpgradeSubscriptionPopup
            showBuyMoreSeatsPopup={showBuyMoreSeatsPopup}
            triggerPopup={this.triggerPopup}
            subscriptionTeam={subscriptionTeam}
            page="class"
            {...this.props}
          />
        </div>
        <Invoice
          columns={InvoiceColumns}
          rows={subscriptionTeam.invoicesList}
          loader={loader}
          showInvoicePopup={showInvoicePopup}
          triggerPopup={this.triggerPopup}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  classes,
  subscriptionTeam,
  loader,
  dataToPersist,
}) => {
  return {
    classes,
    subscriptionTeam,
    loader,
    dataToPersist,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    classAction: bindActionCreators(classAction, dispatch),
    galleryAction: bindActionCreators(galleryAction, dispatch),
    sessionAction: bindActionCreators(sessionAction, dispatch),
    subscription: bindActionCreators(subscription, dispatch),
    profileAction: bindActionCreators(profileAction, dispatch),
    dispatch,
  };
};

const Classes = Wrapper(
  connect(mapStateToProps, mapDispatchToProps)(ClassManagementClass)
);

export { Classes };

let studentInputValidation = {};

let studentCreateInputValidation = {
  dob: {
    presence: {
      allowEmpty: false,
    },
  },
  email: {
    presence: {
      allowEmpty: false,
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
  },

  firstName: {
    presence: {
      allowEmpty: false,
    },
  },
};
