import { React, Component } from "react";
import { connect } from "react-redux";
import * as gameAction from "../../../actions/game";
import { bindActionCreators } from "redux";
import moment from "moment";
import Carousel from "react-grid-carousel";
import { history, timeSince, getGamePath } from "../../../helpers/index";
import ButtonType1 from "../../../components/inputs/ButtonType1";
import { main } from "../../../service/actionType";
import _ from "lodash";
import toast from "../../../helpers/Toast";
import RightArrow from "../../../assets/images/rightarrow.svg";
import LeftArrow from "../../../assets/images/leftarrow.svg";
import { ObjectCount } from "../../../components/tubClass/ObjectCount";
import ClassPresentationWrapper from "../../../Hoc/wrapper";

const getProfilePic = (game) => {
  if (game?.activityDetail?.purchaseType === 1) {
    return require("../../../assets/images/noImage.png");
  }

  return game?.activityDetail?.studentDetail != null &&
    game?.activityDetail?.studentDetail?.profilePic !== undefined &&
    game?.activityDetail?.studentDetail?.profilePic?.original !== undefined &&
    game?.activityDetail?.studentDetail?.profilePic?.original !== ""
    ? game?.activityDetail?.studentDetail?.profilePic?.original
    : game?.activityDetail?.userDetails?.Profile_img?.original
    ? game?.activityDetail?.userDetails?.Profile_img?.original
    : !game?.activityDetail ||
      game?.activityDetail?.studentDetail?.profilePic?.original == "" ||
      game?.activityDetail?.studentDetail?.profilePic?.original == ""
    ? require("../../../assets/images/noImage.png")
    : require("../../../assets/images/noImage.png");
};

const getContentName = (game) => {
  if (game?.activityDetail?.purchaseType === 1) {
    return "alphaTUB Originals";
  }

  return game.activityDetail?.studentDetail?.firstName
    ? game.activityDetail?.studentDetail?.firstName
    : game.activityDetail?.userDetails?.First_name
    ? game.activityDetail?.userDetails?.First_name
    : "alphaTUB Originals";
};

class ClassPresentationHomeClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentDetail: null,
    };
  }

  explore = (game) => {
    this.props.gameAction.storeGameModes("explore");
    this.props.dispatch({
      type: main.USER_SELECTED_GAME,
      payload: {
        type: "explore",
        object: game.ObjectName,
        typeOfObject: game.ObjectType,
      },
    });
    history.push({
      pathname: `/class/presentation/explore`,
    });
  };
  play = (game) => {
    // this.props.gameAction.storeGameModes('play')
    this.props.dispatch({
      type: main.USER_SELECTED_GAME,
      payload: { type: "play", object: game.ObjectName },
    });
    history.push({
      pathname: "/class/presentation/select/game",
    });
  };

  render() {
    let { game } = this.props;
    console.log(game?.activityDetail, "activedetail");
    return (
      <div className="clp-col">
        {game.activityDetail != null && (
          <>
            <div className="row">
              <div className="col-12">
                <div className="pt-3 pb-4 plr-35 row">
                  <div className="col-xl-3 col-lg-4 col-md-4 col-4">
                    <div className="tub-bg-type-1 p-2 cursor-pointer">
                      <div className="pt-12">
                        <div className="d-flex justify-flex-end">
                          <span className="tub-day cl_object_type">
                            ({" "}
                            {game.activityDetail.ObjectType === "Card"
                              ? "TUB Cards"
                              : game.activityDetail.ObjectType === "Sheet"
                              ? "TUB Sheet"
                              : game.activityDetail.ObjectType === "Mix"
                              ? "TUB Mix"
                              : game.activityDetail.ObjectType === "Word"
                              ? "Word Collection"
                              : game.activityDetail.ObjectType === "Tag"
                              ? "Tag Collection"
                              : game.activityDetail.ObjectType === "Letter"
                              ? "Letter Collection"
                              : game.activityDetail.ObjectType === "Assignment"
                              ? "Assignment"
                              : game.activityDetail.ObjectType ===
                                "Reading_Program"
                              ? "Reading Program"
                              : ""}{" "}
                            )
                          </span>
                        </div>
                        <div className="relative-pos">
                          <div
                            className={`tub-session-card ${
                              game.activityDetail.ObjectType === "Sheet" &&
                              "addFilter"
                            }`}
                          >
                            <div>
                              {game.activityDetail.ObjectType === "Word" ||
                              game.activityDetail.ObjectType === "Letter" ||
                              game.activityDetail.ObjectType === "Tag" ||
                              game.activityDetail.ObjectType === "Mix" ||
                              game.activityDetail.ObjectType ===
                                "Reading_Program" ? (
                                <div className="position_relative h-100">
                                  {game.activityDetail.data.map((dt, i) => (
                                    <div className={`typeTwoImage`} key={i}>
                                      <img
                                        src={dt.alphabetImage?.original}
                                        alt="alphatub"
                                      />
                                      {i === 1 && (
                                        <ObjectCount
                                          data={game.activityDetail.data}
                                          className="clp_home_count"
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div>
                                  <img
                                    src={
                                      game.activityDetail.ObjectImage?.original
                                        ? game.activityDetail.ObjectImage
                                            ?.original
                                        : require("../../../assets/images/fullImage.png")
                                    }
                                    className={`height_inherit ${
                                      (game.activityDetail.ObjectType ===
                                        "Card" ||
                                        game.activityDetail.ObjectType ===
                                          "Assignment") &&
                                      "card_img"
                                    }`}
                                    alt="alphatub"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {(game.activityDetail.ObjectType == "Card" ||
                            game.activityDetail.ObjectType == "Sheet" ||
                            game.activityDetail.ObjectType == "Assignment") && (
                            <div>
                              <div className="row align-items-center no-gutters inner-content-spnImg">
                                <img
                                  className="whbr-40"
                                  src={getProfilePic(game)}
                                  alt="alphatub"
                                />
                                <p className="object_student_name ml-2">
                                  {getContentName(game)}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row no-gutters mt-2 justify-content-between align-center">
                        <p className="tub-session-category">
                          {game.activityDetail.ObjectType == "Letter" &&
                            "Letter"}{" "}
                          {game.activityDetail.ObjectName}
                        </p>
                        {(game.activityDetail.ObjectType === "Sheet" ||
                          game.activityDetail.ObjectType === "Card" ||
                          game.activityDetail.ObjectType === "Assignment") && (
                          <p className="tub-day">
                            {timeSince(game?.activityDetail?.createdOn, true)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-8 col-md-8 col-8">
                    <div className="d-flex column-direction h-90 space-between">
                      <div className="mt-2">
                        <h4 className="clp-heading-1">
                          {game.activityDetail.ObjectType === "Letter" &&
                            "Letter"}{" "}
                          {game.activityDetail.ObjectName}
                        </h4>
                        <div className="mt-2">
                          <p className="clp-tub-day">
                            {game.activityDetail.tags &&
                              game.activityDetail.tags.map((tg) => " #" + tg)}
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 mb-4">
                        <p className="clp-tub-day">
                          Content Type :{" "}
                          {game.activityDetail.ObjectType === "Card"
                            ? "TUB Card"
                            : game.activityDetail.ObjectType === "Sheet"
                            ? "TUB Sheet"
                            : game.activityDetail.ObjectType === "Mix"
                            ? "TUB Mix"
                            : game.activityDetail.ObjectType === "Word"
                            ? "Word"
                            : game.activityDetail.ObjectType === "Tag"
                            ? "Tag"
                            : game.activityDetail.ObjectType === "Letter"
                            ? "Letter"
                            : game.activityDetail.ObjectType === "Assignment"
                            ? "Assignment"
                            : game.activityDetail.ObjectType ===
                              "Reading_Program"
                            ? "Reading Program"
                            : ""}
                        </p>
                        {(game.activityDetail.ObjectType === "Card" ||
                          game.activityDetail.ObjectType === "Sheet" ||
                          game.activityDetail.ObjectType === "Assignment") && (
                          <p className="clp-tub-day mt-2">
                            Content From : {getContentName(game)}
                          </p>
                        )}
                        {game.activityDetail.ObjectType != "Sheet" && (
                          <p className="clp-tub-day mt-2">
                            Total Objects :{" "}
                            {game.activityDetail.data != undefined &&
                            game.activityDetail.data.length < 10
                              ? 0
                              : ""}
                            {game.activityDetail?.data?.length}
                          </p>
                        )}
                        <div className="mt-4">
                          <p className="clp-tub-day">
                            Explored :{" "}
                            {game.activityDetail.performance != undefined &&
                            game.activityDetail.performance.length > 0 &&
                            game.activityDetail.performance[0].data.explore !=
                              undefined
                              ? game.activityDetail.performance[0].data
                                  .explore === 1
                                ? "1 Time"
                                : game.activityDetail.performance[0].data
                                    .explore + " Times"
                              : 0 + " Time"}
                          </p>
                          <p className="clp-tub-day mt-2">
                            Played :{" "}
                            {game.activityDetail.performance != undefined &&
                            game.activityDetail.performance.length > 0 &&
                            game.activityDetail.performance[0].data.play !=
                              undefined
                              ? game.activityDetail.performance[0].data.play ===
                                1
                                ? "1 Time"
                                : game.activityDetail.performance[0].data.play +
                                  " Times"
                              : 0 + " Time"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <ButtonType1
                            btnName="Explore"
                            className="buttonType2"
                            onClick={() => this.explore(game.activityDetail)}
                            disabled={false}
                          />
                        </div>
                        <div className="col-3">
                          <ButtonType1
                            btnName="Play"
                            className="buttonType2"
                            onClick={() => this.play(game.activityDetail)}
                            disabled={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="pt-3 pb-4 px-10">
                  <Carousel
                    cols={5}
                    rows={1}
                    gap={15}
                    showStatus={false}
                    emulateTouch={false}
                    useKeyboardArrows={true}
                    showIndicators={false}
                    arrowRight={
                      <div className="clp_arrow_right arrowRightStyles">
                        <img src={RightArrow} className="clp_arrow" />
                      </div>
                    }
                    arrowLeft={
                      <div className="clp_arrow_left arrowLeftStyles">
                        <img src={LeftArrow} className="clp_arrow" />
                      </div>
                    }
                  >
                    {game.activityDetail.data &&
                      game.activityDetail.data.map((dt, i) => (
                        <Carousel.Item>
                          <div
                            className="relative-pos cursor-pointer object-detail-list"
                            key={i}
                          >
                            <img
                              src={
                                dt.alphabetImage?.original
                                  ? dt.alphabetImage?.original
                                  : require("../../../assets/images/tubobject1.png")
                              }
                              className="w-100 inheritHeight"
                              alt="alphatub"
                            />
                          </div>
                          <div className="mt-2">
                            <p className="clp-tub-day text-center">
                              {dt.imageTitle}
                            </p>
                          </div>
                        </Carousel.Item>
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ tubGallery, myProfile, loader, game, tubinbox }) => {
  return {
    tubGallery,
    myProfile,
    tubinbox,
    loader,
    game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gameAction: bindActionCreators(gameAction, dispatch),
    dispatch,
  };
};

export const TubObjectDetailView = ClassPresentationWrapper(
  connect(mapStateToProps, mapDispatchToProps)(ClassPresentationHomeClass)
);
