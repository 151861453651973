import React, { Component } from "react";
//import { Row, Col, Grid, Navbar } from "react-bootstrap";
import { Navbar, Container, NavDropdown, Nav } from 'react-bootstrap';
import "../../assets/css/main.scss"
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as galleryAction from '../../actions/tubgallery';
import * as sessionAction from '../../actions/session';
import * as tubInboxAction from '../../actions/tubInbox';
import * as profileAction from '../../actions/myProfile';
import noImage from '../../assets/images/noImage.png'
import moment from 'moment';
import FormInput from '../inputs/FormInput';
import { API } from 'aws-amplify';
import { ENVIRONMENT } from '../../common/constants';
import AppLoader from "../loader/appLoader";

const BottomNavBar = (props) => {
    return (
        <div className="bottom_tab_container">
            <div className="bt_tab_inner">
                <div onClick={() => props.selectedBottomTab(1)}>
                    <img 
                        src={require('../../assets/images/bt_tab_dashboard.svg').default} 
                        alt="alphatub"
                        className="bt_tab_img"
                      />
                </div>
                <div onClick={() => props.selectedBottomTab(2)}>
                    <img 
                        src={require('../../assets/images/bt_tab_2.svg').default} 
                        alt="alphatub"
                        className="bt_tab_img"
                      />
                </div>
            </div>
        </div>
    )
}



export default BottomNavBar