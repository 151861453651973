import { React, Component } from "react";
import FormInput from "../../../../../components/inputs/FormInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactSelect from "../../../../../components/inputs/ReactSelect";
import ButtonType1 from "../../../../../components/inputs/ButtonType1";
import _ from "lodash";
import ImageCrop from "../../../../../components/cards/ImageCrop";
class StudentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isDisabled = () => {
    let role = localStorage.getItem("role");
    let disabled = role === "R01" || role === "R02" ? false : true;
    return disabled;
  };

  render() {
    let disabled = this.isDisabled();
    let {
      selectedClassDetail,
      studentDetail,
      particularStudentDetailError,
      genderOptions,
      countryList,
      statesList,
      districtList,
      studentrelationshipshipList,
      studentStatusList,
      showAnotherNote,
    } = this.props;
    console.log("studentDetail", studentDetail);
    return (
      <>
        <div className="col-md-3">
          <div className="row no-gutters">
            <img
              src={
                require("../../../../../assets/images/left-arrow.svg").default
              }
              className="back-btn"
              onClick={() => this.props.onNavigate(3, null)}
              alt="alphatub"
            />
            <p
              className="global_color privacy-content ml-3 cursor-pointer"
              onClick={() => this.props.onNavigate(3, null)}
            >
              Back To Students
            </p>
          </div>
          <div className="row no-gutters center-content mt-3">
            <ImageCrop
              profileDetails={{
                Profile_img: { original: studentDetail.profilePic?.original },
                isObjectUrlImage: studentDetail.isObjectUrlImage,
              }}
              removePhoto={this.props.removePhoto}
              saveProfileImage={this.props.replacePhoto}
            />
            <div className="col-11 mt-4">
              <div className="alphatub-card">
                <div className="alphatub-card-top-spacing">
                  <div className="row no-gutters space-between">
                    <div className="col-6">
                      <h1 className="student_profile_name">
                        {studentDetail.firstName}
                      </h1>
                    </div>
                    <div className="col-6">
                      <div className="row no-gutters align-items-center content-end">
                        <div
                          className={`${
                            studentDetail?.status === "Active"
                              ? "roundBlue"
                              : "roundRed"
                          } mr-1`}
                        ></div>
                        <p>{studentDetail?.status}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="alphatub-card-border"></div>
                <div className="alphatub-card-bottom-spacing">
                  <div className="row no-gutters space-between ">
                    <span className="profile_lt_label">Class</span>
                    <p className="profile_lt_value">
                      {selectedClassDetail?.class_Name}
                    </p>
                  </div>
                  <div className="row no-gutters space-between align-items-center">
                    <div className="col-4">
                      <span className="profile_lt_label">Student ID</span>
                    </div>
                    <div className="col-8">
                      <p className="p-bold-text11 profile_lt_value">
                        {studentDetail?.studentId}
                      </p>
                    </div>
                  </div>
                  <div className="row no-gutters center-content mt-3">
                    <div className="col-md-5 mx-auto">
                      <ButtonType1
                        onClick={() =>
                          this.props.createStudent(
                            5,
                            "Transfer Student",
                            null,
                            "student"
                          )
                        }
                        disabled={disabled}
                        btnName="Transfer"
                        className="buttonType1  small-button"
                      />
                    </div>
                    <div className="col-md-5 mx-auto">
                      <ButtonType1
                        onClick={() =>
                          this.props.createStudent(
                            6,
                            "Delete Student",
                            null,
                            "student"
                          )
                        }
                        btnName="Delete"
                        className="buttonType1  small-button"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9 bg-white">
          <div style={{ padding: "25px" }}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="alphatub-card-h2">Profile Information</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="First Name"
                    name="firstName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["firstName"]}
                    error={particularStudentDetailError["firstName"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Middle Name (optional)"
                    name="middleName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["middleName"]}
                    // error={particularStudentDetailError["middleName"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Last Name"
                    name="lastName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["lastName"]}
                    error={particularStudentDetailError["lastName"]}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <FormInput
                    type="date-picker"
                    label="Date of Birth"
                    name="dob"
                    style={{ width: "100%" }}
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["dob"]}
                    error={particularStudentDetailError["dob"]}
                    maxDate={new Date()}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="select"
                    label="Gender"
                    name="gender"
                    options={genderOptions}
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["gender"]}
                    error={particularStudentDetailError["gender"]}
                    disabled={disabled}
                    placeholder="Select Gender"
                    showDownArrow={true}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="select"
                    label="Status"
                    name="status"
                    options={studentStatusList}
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["status"]}
                    error={particularStudentDetailError["status"]}
                    disabled={true}
                    placeholder="Select Status"
                    showDownArrow={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <FormInput
                    type="text"
                    label="Student ID"
                    name="assignedStudentId"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["assignedStudentId"]}
                    // error={particularStudentDetailError["assignedStudentId"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Class"
                    name="className"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={selectedClassDetail["class_Name"]}
                    // error={particularStudentDetailError["className"]}
                    disabled={true}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="date-picker"
                    label="Enrollment Date"
                    name="enrollmentDate"
                    style={{ width: "100%" }}
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["enrollmentDate"]}
                    error={particularStudentDetailError["enrollmentDate"]}
                    maxDate={new Date()}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FormInput
                    type="textarea"
                    label="Notes (optional)"
                    name="notes"
                    areaGroupClass="m-0"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["notes"]}
                    error={particularStudentDetailError["notes"]}
                    disabled={disabled}
                  />
                </div>
              </div>
              {showAnotherNote && (
                <div className="row">
                  <div className="col-md-12">
                    <FormInput
                      type="textarea"
                      label="Notes (optional)"
                      name="notes2"
                      areaGroupClass="m-0"
                      onChange={(...params) =>
                        this.props.onChangeStudentProfile(...params, null)
                      }
                      value={studentDetail["notes2"]}
                      error={particularStudentDetailError["notes2"]}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {!showAnotherNote && (
                <div className="row mb-4 no-gutters content-end">
                  <div>
                    <p
                      className="addnote"
                      onClick={() => this.props.toggleAnotherNote()}
                    >
                      Add another note
                    </p>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Father's First Name"
                    name="fatherFirstName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["fatherFirstName"]}
                    error={particularStudentDetailError["fatherFirstName"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Father's Middle Name"
                    name="fatherMiddleName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["fatherMiddleName"]}
                    error={particularStudentDetailError["fatherMiddleName"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Father's Last Name"
                    name="fatherLastName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["fatherLastName"]}
                    error={particularStudentDetailError["fatherLastName"]}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Mother's First Name"
                    name="motherFirstName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["motherFirstName"]}
                    error={particularStudentDetailError["motherFirstName"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Mother's Middle Name"
                    name="motherMiddleName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["motherMiddleName"]}
                    error={particularStudentDetailError["motherMiddleName"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Mother's Last Name"
                    name="motherLastName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["motherLastName"]}
                    error={particularStudentDetailError["motherLastName"]}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6">
                  <label>1st Parent’s Contact Number</label>
                  <PhoneInput
                    country={"in"}
                    name="firstContactNo"
                    countryCodeEditable={false}
                    value={studentDetail["firstContactNo"]}
                    onChange={(phone) =>
                      this.props.onChangeStudentMobile("firstContactNo", phone)
                    }
                    placeholder=""
                    disabled={
                      _.isEmpty(studentDetail["firstContactNo"]) ? false : true
                    }
                  />
                  <strong className="text-danger small">
                    {particularStudentDetailError["firstContactNo"]
                      ? particularStudentDetailError["firstContactNo"][0]
                      : ""}
                  </strong>
                </div>
                <div className="col-md-6">
                  <label>2nd Parent’s Contact Number</label>
                  <PhoneInput
                    country={"in"}
                    name="secondContactNo"
                    countryCodeEditable={true}
                    value={studentDetail["secondContactNo"]}
                    onChange={(phone) =>
                      this.props.onChangeStudentMobile("secondContactNo", phone)
                    }
                    disabled={
                      _.isEmpty(studentDetail["secondContactNo"]) ? false : true
                    }
                  />
                  <strong className="text-danger small">
                    {particularStudentDetailError["secondContactNo"]
                      ? particularStudentDetailError["secondContactNo"][0]
                      : ""}
                  </strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h3 className="alphatub-card-h2">Contact Details</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    label="Contact Name"
                    name="contactName"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["contactName"]}
                    error={particularStudentDetailError["contactName"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    type="select"
                    label="Relationship"
                    name="relationship"
                    options={studentrelationshipshipList}
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["relationship"]}
                    error={particularStudentDetailError["relationship"]}
                    disabled={disabled}
                    placeholder="Select relationship"
                    showDownArrow={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    label="Email Id"
                    name="email"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["email"]}
                    error={particularStudentDetailError["email"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-6">
                  <label>Emergency Contact Number</label>
                  <PhoneInput
                    country={"in"}
                    name="emergencyContactNo"
                    countryCodeEditable={true}
                    value={studentDetail["emergencyContactNo"]}
                    onChange={(phone) =>
                      this.props.onChangeStudentMobile(
                        "emergencyContactNo",
                        phone
                      )
                    }
                    disabled={disabled}
                  />
                  <strong className="text-danger small">
                    {particularStudentDetailError["emergencyContactNo"]
                      ? particularStudentDetailError["emergencyContactNo"][0]
                      : ""}
                  </strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FormInput
                    type="text"
                    label="Street"
                    name="street"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["street"]}
                    error={particularStudentDetailError["street"]}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FormInput
                    type="text"
                    label="Street 2/Landmark (Optional)"
                    name="street2"
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["street2"]}
                    error={particularStudentDetailError["street2"]}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 custom-reselect">
                  <ReactSelect
                    label="Country"
                    name="country"
                    options={countryList}
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["country"]}
                    error={particularStudentDetailError["country"]}
                    disabled={disabled}
                  />
                </div>
                {studentDetail["country"] != null && statesList.length > 0 && (
                  <div className="col-md-6 custom-reselect">
                    <ReactSelect
                      label="State"
                      options={statesList}
                      name="state"
                      onChange={(...params) =>
                        this.props.onChangeStudentProfile(...params, null)
                      }
                      value={studentDetail["state"]}
                      error={particularStudentDetailError["state"]}
                      disabled={disabled}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                {studentDetail["country"] != null && statesList.length > 0 && (
                  <div className="col-md-6 custom-reselect">
                    <ReactSelect
                      label="District"
                      options={districtList}
                      name="district"
                      onChange={(...params) =>
                        this.props.onChangeStudentProfile(...params, null)
                      }
                      value={studentDetail["district"]}
                      error={particularStudentDetailError["district"]}
                      disabled={disabled}
                    />
                  </div>
                )}
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    label="Zip Code/Pin Code"
                    name="zipCode"
                    maxLength={10}
                    onChange={(...params) =>
                      this.props.onChangeStudentProfile(...params, null)
                    }
                    value={studentDetail["zipCode"]}
                    error={particularStudentDetailError["zipCode"]}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-12 text-right">
                  <button
                    className="btn btn-primary w-200px"
                    onClick={() => this.props.saveStudentProfile()}
                  >
                    {" "}
                    Save Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StudentProfile;
