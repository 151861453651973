import { React, Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ButtonType1 from '../../../../../../components/inputs/ButtonType1';

class SetPlayersPerGroupClass extends Component {
    constructor(props) {
        super(props);
        this.state = { 
           
        };
    }

    render() {
        let props = this.props
        return (
            <div className='clpContainer container clp-col pb-1'>
                <div className="row ">
                    <div className="col-12">
                        <div className="mt-2">
                            <p className="clp_heading1 text-center">Set Players Per Group</p>
                        </div>
                        <div className="mt-2 row align-items-center justify-content-center">
                            {
                                props.groups && props.groups.map((gp, index) => (
                                    <div className='m-2' key={index}>
                                        <p className={`${gp === props.selectedGroupNo ? 'group_numbers-active group_numbers' : 'group_numbers'}`} onClick={() => props.selectedGroup(gp)}>{gp}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={`${props.disableGroup ? "hidden-grps col-12 mt-3 pt-2 pb-2" : "col-12 mt-3 pt-2 pb-2"}`} >
                        <div>
                            {props.selectedGroupNo !== 0 ?
                                <p className="clp_heading1  text-center">Add Players to Group</p> // {props.selectedGroupData.grpNo}
                                :
                                <p className="clp_heading1 text-center">Players Available for Group Play</p>
                            }
                        </div>
                        <div className="row no-gutters justify-content-center">
                            <div className="col-12">
                                <div className="row align-items-center justify-content-center mt-3">
                                    {props.selectedPlayerList && props.selectedPlayerList.map((dt, i) => (
                                        <div className='relative-pos' key={i}>
                                            <div 
                                                className='cursor-pointer game_player_img w-85 mt-3 text-center' 
                                                onClick={() => props.addPlayersToGroup(dt, i)}
                                            >
                                                <div>
                                                    <img
                                                        src={(dt.profilePic != undefined && dt.profilePic?.original != undefined && dt.profilePic?.original != "") ? dt.profilePic?.original : require("../../../../../../assets/images/noImage.png")}
                                                        className="whbr-70"
                                                        alt="alphatub"
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <p className='clpSelectPlayer text-center'>{dt.label !== '' ? dt.label : '-'}</p>
                                                </div>
                                            </div>
                                            {dt.isSelected &&
                                                <div className="selectedPlayer">
                                                    <div className='player_bg'>
                                                        <p>{dt?.groupNo}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {props.groupPartitionData && props.groupPartitionData.length > 0 &&
                    <div className='row mt-4'>
                        <div className="col-12">
                            <div>
                                <p className="clp_heading1  text-center">Groups</p>
                            </div>
                            <div className="row no-gutters justify-content-center">
                                <div className="col-12">
                                    <div className="row align-items-center justify-content-center mt-4">
                                        {props.groupPartitionData && props.groupPartitionData.map((dt, i) => (
                                            <div className='column-direction'>
                                                <div className='m-2 p-1 group-box row no-gutters'>
                                                    { 
                                                        dt.data && dt.data.map((pl,index) => (
                                                            <div key={index}>
                                                                <div 
                                                                    className='cursor-pointer w-75px text-center game_player_img' 
                                                                >
                                                                    {pl.isAdded ?
                                                                        <img
                                                                            src={pl.playerImage ? pl.playerImage : require("../../../../../../assets/images/noImage.png")}
                                                                            className="whbr-70"
                                                                            alt="alphatub"
                                                                        />
                                                                        :
                                                                        <img
                                                                            src={pl.staticImage}
                                                                            className="whbr-70"
                                                                            alt="alphatub"
                                                                        />
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className="mt-2">
                                                    <p className='clpSelectPlayer text-center'>{dt.groupName}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* <div className='row mt-5'>
                    <div className="col-12">
                        {!props.isBtnShown  && 
                            
                        }
                    </div>
                </div> */}
                {/* fixedGameButtons */}
                <div className={`col-12 ${props.selectedGroupNo != null ? 'mt-2' : 'mt-5'} mb-3`}>
                    <div className='row justify-flex-end'>
                        <div className="col-2">
                            <ButtonType1
                                btnName="Auto select"
                                className='buttonType2'
                                onClick={() => props.selectAll()}
                                disabledClassName="l-lh-42"
                                disabled={props.selectedGroupNo == null ? true : false}
                            />
                        </div>
                        <div className="col-2">
                            <ButtonType1
                                btnName="Start"
                                className={'buttonType2'}
                                onClick={() => props.next()}
                                disabledClassName="l-lh-42"
                                disabled={props.startBtnDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export const SetPlayersPerGroup = SetPlayersPerGroupClass

