import { history } from '../helpers'
import { axios } from "./axios";
import toast from '../helpers/Toast'

// 3.135.249.124
export const API = async ({ url, method, headers = null, responseType, baseUrlType, data, params }) => {
    let axiosRequestObject = {
      method,
      headers,
      url,
      baseURL: "http://localhost:8000",
      data,
      responseType:responseType,
      params
    };
  
    let request = await axios(axiosRequestObject)
    .then(handleSuccessRequest)
    .catch(handleErrorRequest);

    return request;
};

const handleErrorRequest = (err) => {
  if(!err.response) return Promise.reject()
  console.log('err', err)
  if(err.response?.status === 401){
    if(parseInt(localStorage.getItem('role')) && localStorage.getItem('role') !== null){
      history.replace('/login')
    } else {
      history.replace('/login')
    }
    localStorage.clear()
    toast('Unauthorized!!','error')
  }
  if (err.response) return err.response;
  else
    return {
      status: "500",
      data: {
        message: "Internal server error!!",
      },
    };
};

const handleSuccessRequest = (response) => 
(
  {
    status: response.status,
    data: response.data.data,
});
