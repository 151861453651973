import { React, Component } from "react";
import ReactSelect from "../../../../components/inputs/ReactSelect";
import ButtonType2 from "../../../../components/inputs/ButtonType2";
import { CustomBootstrapTable } from "../../../../components/Table/CustomBootstrapTable";
import { ClassTableColumns } from "../../../../helpers/tableDataMapper";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import toast from "../../../../helpers/Toast";
import { history } from "helpers";

class ClassTable extends Component {
  exportData = () => {
    let { classes } = this.props;
    if (
      classes &&
      classes !== undefined &&
      classes.classTableList &&
      classes.classTableList !== undefined &&
      classes.classTableList.length > 0
    ) {
      let classdata = classes.classTableList;
      let newArray = [];
      classdata.map((data) => {
        newArray.push({
          class_Name: data.class_Name,
          section_Name: data.section_Name,
          allocated_Seats: data.allocated_Seats,
          teachers:
            data.teachers && data.teachers.map((dt) => dt.label).join(","),
          substituteTeachers:
            data.substituteTeachers &&
            data.substituteTeachers.map((dt) => dt.label).join(","),
          trainees:
            data.trainees != null
              ? data.trainees && data.trainees.map((dt) => dt.label).join(",")
              : null,
        });
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileName = "Class details";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(newArray);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast("Please create class to export", "error");
    }
  };
  navigate = (row) => {
    this.props.onNavigate(3, row);
    // history.push("/dashboard/settings/students");
  };

  getTableColumns = (disabled) => {
    let action = {
      text: "Action",
      dataField: "action",
      headerClasses: "columnHeader",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="row align-items-center no-gutters justify-content-center">
            <div
              className={row.status === "Active" ? "roundBlue" : "roundRed"}
            ></div>
            {!disabled && (
              <div className="ml-4">
                <div className="class_tooltip position_relative">
                  <img
                    src={require("../../../../assets/images/edit.png")}
                    onClick={() =>
                      this.props.triggerClassPopup("Edit Class", row)
                    }
                    alt="alphatub"
                  />
                  <span class="tooltiptext">Edit</span>
                </div>
              </div>
            )}
            <div className="ml-2">
              <div className="class_tooltip position_relative">
                <img
                  src={require("../../../../assets/images/rightAction.png")}
                  onClick={() => this.navigate(row)}
                  className="action-btn"
                  alt="alphatub"
                />
                <span class="tooltiptext">Enter</span>
              </div>
            </div>
          </div>
        );
      },
    };
    return [...ClassTableColumns, action];
  };

  render() {
    let { loader, classes, classFilterDropdown, classQueryPayload, disabled } =
      this.props;
    return (
      <div className="col-md-9 classTable">
        <div className="row no-gutters">
          <div className="col-7">
            <h3 className="login-text-login text-left">Your Classes</h3>
          </div>
          {!disabled && (
            <div className="col-5">
              <p
                className="classes-seats-link text-right global_color"
                onClick={() => this.props.onNavigate(2, null)}
              >
                Manage Seats Allocation
              </p>
            </div>
          )}
        </div>
        <div className="row mt-3">
          <div className="col-lg-3 col-6 col-xl-3">
            <div className="row no-gutters">
              <div className="col-12 custom-reselect1">
                <ReactSelect
                  name="status"
                  options={classFilterDropdown}
                  isClearable={false}
                  onChange={(...params) =>
                    this.props.onChangeStatusFilter(...params, 1)
                  }
                  value={classQueryPayload["status"]}
                />
              </div>
            </div>
          </div>
          {!disabled && (
            <div className="col-xl-9 col-lg-9 col-12">
              <div className="row no-gutters justify-end">
                <div className="col-12 col-lg-12 col-xl-10">
                  <div className="row justify-end">
                    <div className="col-4">
                      <ButtonType2
                        disabled={disabled}
                        btnName="Add Class"
                        className="buttonType1"
                        onClick={() =>
                          this.props.triggerClassPopup("Add Class", true)
                        }
                      />
                    </div>

                    <div className="col-4">
                      <ButtonType2
                        btnName="Export"
                        className="buttonType1"
                        onClick={() => this.exportData()}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row no-gutters">
          <div className="col-12">
            <CustomBootstrapTable
              rows={classes?.classTableList}
              columns={this.getTableColumns(disabled)}
              isLoading={loader.isTableDetailsFetching}
              keyField="shopId"
              tableClass="custom-row-class"
              tableMainClass="custom_class_table"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ClassTable;
