import { CustomBootstrapTable } from '../Table/CustomBootstrapTable';
import { CreateModel  } from './createModel';

const Invoice  = (props) => {
    return (
        <CreateModel
            isOpen={props.showInvoicePopup}
            toggle={() => props.triggerPopup('showInvoicePopup', false)}
            title={"Invoices"}
            className="max-900"
        >
            <div className="row mt-3 mb-3">
                {/* <div className="col-12">
                    <p className='invoice_content'>You are on a yearly TUB Class plan. Your next subscription bill is scheduled for 30 April 2022. Click here to manage your subscription.</p>
                </div> */}
            </div>
            <div className="row no-gutters">
                <div className="col-12">
                    <CustomBootstrapTable
                        rows={props.rows}
                        columns={props.columns}
                        isLoading={props.loader.isTableDetailsFetching}
                        tableClass="custom-row-class"
                        tableMainClass="invoice_table"
                        keyField="invoiceId"
                    />
                </div>
            </div>
        </CreateModel>
    )
}

export { Invoice }