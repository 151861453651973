import React from "react"
import ReactApexChart from "react-apexcharts"

const PieChart = (props) => {
  const series = props.y_axis
  const options = {
    labels: props.x_axis,
    colors: props.colors,
    legend: {
      show: props.showLegend,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      fontSize: "12px",
      offsetX: 0,
      offsetY: 0
    },
    noData: {
      text: "No data Found",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#7f7575",
        fontSize: '14px',
        fontFamily: 'Poppins'
      }
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 280,
          }
        },
      },
    ]
  }

  return (
    <ReactApexChart options={options} series={series} type="pie" height="380"/>
  )
}

export default PieChart
