let endpoints = {
    // example
    // DASHBOARD_COUNT: '/api/v1/admin/dashboard/count',  
}

export let API_BOOK = {
    ADMIN_MANAGEMENT: {
        
    },
    MAIN_APP : {
        DASHBOARD_COUNT_API: {
            url: endpoints.DASHBOARD_COUNT,
            method : 'GET',
            data : null,
            headers : {},
            baseUrlType : 'BASE_URL'
        },
    }
}