import React from 'react';

const AuthLoader = ({ className }) => {
    return ( 
        <>
            <div className="auth-loader"></div>
            <div className={`d-flex align-items-center justify-content-center loader loader-container ${className}`}>
                <div className="lds-ripple">
                    <img src={require('../../assets/images/Alogo.png')} className="logo_loader" />
                </div>
            </div>
        </>
    );
}
 
export default AuthLoader;