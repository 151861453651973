import { main } from '../service/actionType'

const initialState = {
    tubInboxList: []
}
const tubinbox =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.TUB_INBOX_LIST:
            return {
                ...state,
                tubInboxList: payload
            }
        default:
            return state
    }

}

export default tubinbox;
