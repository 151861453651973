import React, { Component } from "react";
import Calender from "react-calendar";
import Carousel from "react-grid-carousel";
import moment from "moment";
import { CreateModel } from "../../../../../components/Model/createModel";
import ButtonType1 from "../../../../../components/inputs/ButtonType1";
import RightArrow from "../../../../../assets/images/rightarrow.svg";
import LeftArrow from "../../../../../assets/images/leftarrow.svg";
import NoImg from "../../../../../assets/images/noImage.png"
import { ObjectCount } from "../../../../../components/tubClass/ObjectCount";
import _ from "lodash";
import { ActivitiesPlusCard } from "../../../../../components/tubClass/activitesPlusCard";
import { AppLoader } from "../../../../../components/loader/appLoader";
import { ContentOriginalImage } from "../../../../../components/tubClass/contentOriginalImage";
import { ContentStudentDetail } from "../../../../../components/tubClass/contentStudentDetail";
import { ContentDetail } from "../../../../../components/tubClass/contentDetail";
import { ContentSelectionImagesSlider } from "../../../../../components/tubClass/contentSelectionImagesSlider";
import AuthLoader from "components/loader/authloader";
var days = 15;

const getProfilePic = (item) => {
  // return item?.alphatub_content_result?.purchaseType === 1 ? require("../../../../../assets/images/Alogo.png") : item?.alphatub_content_result?.ObjectType !== "Assignment" ? (item?.teacherDetails?.Profile_img?.original || NoImg) : (item?.student_list?.profilePic?.original || NoImg)
  // return item?.alphatub_content_result?.purchaseType === 1 ? require("../../../../../assets/images/Alogo.png") : (item?.student_list?.profilePic?.original == undefined || null || "" ? NoImg : item?.student_list?.profilePic?.original)
  return item?.alphatub_content_result?.purchaseType === 1 
  ? require("../../../../../assets/images/Alogo.png") 
  : (!item?.student_list?.profilePic?.original 
      ? NoImg 
      : item?.student_list?.profilePic?.original)// student dp
};

const getStudentName = (item) => {
  return item.alphatub_content_result
  ?.purchaseType === 1
  ? "alphaTUB Original"
  : item?.student_list?.firstName
  ? item?.student_list?.firstName
  : item?.TeacherName
  ? item?.TeacherName
  : item?.teacherDetails?.First_name
  ? item?.teacherDetails?.First_name
  : "alphaTUB Original"
}

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxDate: new Date(Date.now() + days * 24 * 60 * 60 * 1000),
      minDate: new Date(Date.now() - days * 24 * 60 * 60 * 1000),
      contentSelectedDate: new Date(),
      activeStartDate: null,
      showDeletePopup: false,
      selectedTub: null,
      deletedObjectName: null,
      loading: false,
      showImagesSlider: false,
      objectImagesList: [],
    };
  }
  changeSessionCalendarMonth = (value) => {
    this.setState({
      activeStartDate: value.activeStartDate,
    });
    this.props.onChangeSessionCalendarMonth(value.activeStartDate);
  };
  toggleImagesPopup = (data) => {
    this.setState({
      showImagesSlider: true,
      objectImagesList: data.alphatub_content_result.data,
    });
  };

  onChangeDate = (value) => {
    let now = new Date();
    sessionStorage.setItem("session_date", moment(value).format("YYYY-MM-DD"));
    if (moment(value).format("YYYY-MM-DD") < moment(now).format("YYYY-MM-DD")) {
      this.props.onNavigate(3, value);
      this.props.sessionAction.toggleReviewTubClass(true);
    } else {
      this.props.sessionAction.toggleReviewTubClass(false);
      this.setState(
        {
          contentSelectedDate: value,
        },
        () => this.getContentSelected(value)
      );
    }
  };
  switchToCreateSession = (date) => {
    this.props.onNavigate(3, date);
  };
  getContentSelected = async (date) => {
    this.setState({ loading: true });
    await this.props.getCreatedSessionList(
      moment(date).startOf("day").format("YYYY-MM-DD")
    );
    this.setState({ loading: false });
  };
  triggerPopup = (item, data) => {
    let deletedObjectName;
    if (data != null)
      deletedObjectName =
        data.Session_Type === "Card"
          ? "TUB Card"
          : data.Session_Type === "Mix"
          ? "TUB Mix"
          : data.Session_Type === "Sheet"
          ? "TUB Sheet"
          : data.Session_Type === "Word"
          ? "Word"
          : data.Session_Type === "Tag"
          ? "Tag"
          : "Letter";
    this.setState({
      [item]: !this.state[item],
      selectedTub: data,
      deletedObjectName: deletedObjectName,
    });
  };
  deleteTubObject = async () => {
    let { selectedTub, contentSelectedDate } = this.state;
    let payload = {
      _id: selectedTub._id,
    };
    await this.props.deleteTubObject(payload, contentSelectedDate);
    this.setState({
      showDeletePopup: false,
    });
  };
  selectActivity = (activity) => {
    let { contentSelectedDate } = this.state;
    this.props.selectActivity(activity, contentSelectedDate);
  };
  render() {
    let state = this.state;
    let { session, loader } = this.props;
    console.log("SESSION UPCOMING LIST -->", session.upcommingSessionList);

    return (
      <>
        {state.loading || loader.sessionAssignedDatesFetching ? (
          <AuthLoader />
        ) : (
          <div className="col-12 " ref={this.mainRow}>
            <div className="row no-gutters">
              <div className="col-md-4 session-calendar">
                <Calender
                  onChange={(value) => this.onChangeDate(value)}
                  onActiveStartDateChange={(value) =>
                    this.changeSessionCalendarMonth(value)
                  }
                  tileClassName={this.props.tileClassName}
                  activeStartDate={state.activeStartDate}
                  value={state.contentSelectedDate}
                  selectRange={false}
                  className="assignment_calendar"
                  maxDate={state.maxDate}
                  tileDisabled={this.props.tileDisabled}
                />
              </div>
              <ActivitiesPlusCard
                createdSessionList={session.createdSessionList}
                selectActivity={this.selectActivity}
                tab={1}
              />
            </div>
            <div className="bg-white mt-4 py-4 px-3">
              <p className="tub-content-1">Upcoming Sessions</p>
            </div>
            {session.upcommingSessionList?.length > 0 ? (
              <div className="row no-gutters pt-3">
                {session.upcommingSessionList &&
                  session.upcommingSessionList.map((data, i) => (
                    <div className="col-12" key={i}>
                      <div className="row no-gutters">
                        <div className="col-12">
                          <div className="row no-gutters justify-content-between">
                            <div className="row no-gutters align-items-center">
                              <p className="tub-content-2">
                                {moment(data.date).format("DD MMM YYYY")}
                              </p>
                              <div className="w-100px ml-4">
                                <ButtonType1
                                  btnName="Edit"
                                  className="btn-bg1 h-24-lh-12 buttonType1"
                                  onClick={() =>
                                    this.switchToCreateSession(
                                      new Date(data.date)
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <p
                                className="tub-seeall cursor-pointer"
                                onClick={() =>
                                  this.switchToCreateSession(
                                    new Date(data.date)
                                  )
                                }
                              >
                                See all
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`pt-3 pb-4 upcoming_session_container`}>
                        <Carousel
                          cols={3}
                          rows={1}
                          gap={15}
                          showStatus={false}
                          emulateTouch={false}
                          showIndicators={false}
                          arrowRight={
                            <div className="session_arrow_right arrowRightStyles">
                              <img src={RightArrow} className="clp_arrow" />
                            </div>
                          }
                          arrowLeft={
                            <div className="arrowLeftStyles session_arrow_left">
                              <img src={LeftArrow} className="clp_arrow" />
                            </div>
                          }
                          mobileBreakpoint={900}
                          responsiveLayout={[
                            {
                              breakpoint: 1100,
                              cols: 2,
                            },
                            {
                              breakpoint: 1400,
                              cols: 3,
                            },
                          ]}
                        >
                          {data.items &&
                            data.items.map((item, i) => (
                              <Carousel.Item>
                                <div
                                  key={i}
                                  className={`tub-bg-type-1 session_upcoming_objects ${
                                    item.Session_Type == "Word" ||
                                    item.Session_Type == "Tag" ||
                                    item.Session_Type == "Mix" ||
                                    item.Session_Type == "Letter"
                                      ? "h_94_half"
                                      : ""
                                  }`}
                                >
                                  <div className="d-flex justify-flex-end">
                                    <span className="tub-day cl_object_type">
                                      ({" "}
                                      {item.Session_Type === "Card"
                                        ? "TUB Cards"
                                        : item.Session_Type === "Sheet"
                                        ? "TUB Sheet"
                                        : item.Session_Type === "Mix"
                                        ? "TUB Mix"
                                        : item.Session_Type === "Word"
                                        ? "Word"
                                        : item.Session_Type === "Tag"
                                        ? "Tag"
                                        : item.Session_Type === "Letter"
                                        ? "Letter"
                                        : item.Session_Type === "Assignment"
                                        ? "Assignment"
                                        : item.Session_Type ===
                                          "Reading_Program"
                                        ? "Reading Program"
                                        : ""}{" "}
                                      )
                                    </span>
                                  </div>
                                  <div
                                    className={`relative-pos ${
                                      item.Session_Type == "Word" ||
                                      item.Session_Type == "Tag" ||
                                      item.Session_Type == "Mix" ||
                                      item.Session_Type == "Letter"
                                        ? "h_86"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className={`tub-session-card ${
                                        item.Session_Type === "Sheet" &&
                                        "addFilter p-1"
                                      } ${
                                        item.Session_Type == "Word" ||
                                        item.Session_Type == "Tag" ||
                                        item.Session_Type == "Mix" ||
                                        item.Session_Type == "Letter"
                                          ? "h_100"
                                          : ""
                                      }`}
                                    >
                                      {/* <div className={`row flex-wrap no-gutters w-100 ${(item.Session_Type == 'Word' || item.Session_Type == 'Tag' || item.Session_Type == 'Mix' || item.Session_Type == 'Letter') ? 'h_inherit' : ''}`}> */}
                                      {item.Session_Type === "Word" ||
                                      item.Session_Type === "Letter" ||
                                      item.Session_Type === "Tag" ||
                                      item.Session_Type === "Mix" ? (
                                        <div
                                          className="position_relative h_inherit"
                                          onClick={() =>
                                            this.toggleImagesPopup(item)
                                          }
                                        >
                                          {item.alphatub_content_result.data.map(
                                            (dt, i) => (
                                              <div
                                                className={`typeTwoImage`}
                                                key={i}
                                              >
                                                <ContentOriginalImage
                                                  src={
                                                    dt.alphabetImage?.original
                                                  }
                                                  className=""
                                                  alt="alphatub"
                                                />
                                                {i === 1 && (
                                                  <ObjectCount
                                                    data={
                                                      item
                                                        .alphatub_content_result
                                                        .data
                                                    }
                                                    className="clp_home_count"
                                                  />
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() =>
                                            this.toggleImagesPopup(item)
                                          }
                                        >
                                          <ContentOriginalImage
                                            src={
                                              item.alphatub_content_result
                                                ?.ObjectImage?.original ||
                                                item?.alphatub_content_result?.data[0]
                                                  ?.alphabetImage?.original
                                            }
                                            className={`minh_inherit ${
                                              (item.Session_Type === "Card" ||
                                                item.Session_Type ===
                                                  "Assignment") &&
                                              "card_img"
                                            }`}
                                          />
                                        </div>
                                      )}
                                      {/* </div> */}
                                    </div>
                                    {(item.Session_Type === "Card" ||
                                      item.Session_Type === "Sheet" ||
                                      item.Session_Type === "Reading_Program" ||
                                      item.Session_Type === "Assignment") && (
                                      <div className="row align-items-center no-gutters inner-content-spnImg">
                                        <ContentStudentDetail
                                          src={
                                            getProfilePic(item)
                                          }
                                          className="whbr-40"
                                          studentName={getStudentName(item)}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className="row no-gutters mt-2 justify-content-between">
                                    <ContentDetail
                                      ObjectName={`${
                                        item.Session_Type === "Letter"
                                          ? "Letter"
                                          : ""
                                      } ${
                                        item.alphatub_content_result.ObjectName
                                      }`}
                                      createdAt={`${
                                        item.Session_Type === "Card" ||
                                        item.Session_Type === "Sheet"
                                          ? moment(item.updatedAt).format(
                                              "DD MMM YYYY"
                                            )
                                          : ""
                                      }`}
                                    />
                                  </div>
                                </div>
                              </Carousel.Item>
                            ))}
                        </Carousel>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="row no-gutters align-items-center center-content h-100px">
                <p className="tub-content-4">No Upcoming Sessions</p>
              </div>
            )}
          </div>
        )}
        <ContentSelectionImagesSlider
          showImagesSlider={state.showImagesSlider}
          objectImagesList={state.objectImagesList}
          triggerPopup={this.triggerPopup}
        />
        {/* Delete Tub Object */}
        {state.showDeletePopup && (
          <CreateModel
            isOpen={state.showDeletePopup}
            toggle={() => this.triggerPopup("showDeletePopup", null)}
            title={"Delete TUB Content"}
          >
            <div className="alphatub-card-border"></div>
            <div className="row pt-3 pb-5">
              <div className="col-12">
                <p className="f-16px alphatub-card-normal-p">
                  Do you want to remove this {state.deletedObjectName}{" "}
                  collection from the session ?
                </p>
              </div>
            </div>
            <div className="alphatub-card-border"></div>
            <div className="row align-items-center mt-3 content-end">
              <div className="col-4">
                <ButtonType1
                  btnName="Close"
                  className="popup-close-btn"
                  onClick={() => this.triggerPopup("showDeletePopup", null)}
                />
              </div>
              <div className="col-4">
                <ButtonType1
                  btnName="Confirm"
                  className="bg-tub c-white buttonType1 "
                  onClick={() => this.deleteTubObject()}
                />
              </div>
            </div>
          </CreateModel>
        )}
      </>
    );
  }
}

// const MainPage = connect(null, null)(MainPageClass)

export default MainPage;
