import { CreateModel } from "../Model/createModel"
import DateRangePicker from "react-daterange-picker";
import "../../../node_modules/react-daterange-picker/dist/css/react-calendar.css";
import { useState } from "react";
import moment from 'moment'
import ButtonType1 from "../inputs/ButtonType1";

export const DateRangeFilterPopup = ({showFilterDatePopup, triggerPopup,onChangeFilterDate}) => {
    const [selectionRange,setSelectionRanges] = useState({start: null,end: null})

    const selectDateRange = (ranges) => {
        setSelectionRanges(ranges)
    }
    const confirm = () => {
        let queryPayload = {
            startDate : moment(selectionRange.start).format("YYYY-MM-DD"),
            endDate : moment(selectionRange.end).format('YYYY-MM-DD')
        }
        onChangeFilterDate(queryPayload)
    }
    return (
        <>
            <CreateModel
                isOpen={showFilterDatePopup}
                toggle={() => triggerPopup('showFilterDatePopup', null)}
                title={"Custom Date"}
                className="mx-800"
            >
                <div className="row pt-3 pb-5">
                    <div className="col-12 session-calendar gallery-cal">
                        <DateRangePicker
                            className="react_daterange_picker"
                            selectionType="range"
                            value={selectionRange}
                            numberOfCalendars={2}
                            appendToBody={true}
                            maximumDate = {new Date()}
                            onSelect={(ranges) => selectDateRange(ranges)}
                            // singleDateRange={true}
                            
                        />
                    </div>
                </div>
                <div className="row align-items-center mt-3 content-end">
                    <div className="col-3">
                        <ButtonType1
                            btnName="Close"
                            className='popup-close-btn'
                            onClick={() => triggerPopup('showFilterDatePopup', null)}
                        />
                    </div>
                    <div className="col-3">
                        <ButtonType1
                            btnName="Confirm"
                            className='bg-tub c-white buttonType1 '
                            onClick={() => confirm()}
                        />
                    </div>
                </div>
            </CreateModel>
        </>
    )
}