import React, { Component, Suspense } from "react";
import { Route, Router } from "react-router-dom";
import routers from "./routes";
import { history, ThemeContext } from "../helpers";
import * as Layout from "../layout";
import * as Pages from "../pages";
import { connect } from "react-redux";
import AuthLoader from "../components/loader/authloader";
import ScrollToTop from "../components/scrollToTop";

class RoutesClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderRoute: false,
      pathname: null,
      loading: true,
    };
  }

  render() {
    return (
      <Suspense fallback={<AuthLoader />}>
        <ThemeContext.Provider>
          <Router history={history}>
            <ScrollToTop />
            {routers.map(
              ({
                component,
                redirect,
                path,
                exact = false,
                auth = true,
                childrens = [],
              }) => {
                if (childrens.length > 0) {
                  return (
                    <Route
                      path={path}
                      exact={exact}
                      key={path}
                      render={(props) => {
                        if (redirect) {
                          if (props.location.pathname === path) {
                            props.history.push(redirect);
                          }
                        }
                        const LayoutComponent = Layout[component];
                        return (
                          <LayoutComponent {...props}>
                            {childrens.map(
                              ({
                                component: ChildrenComponent,
                                path: childrenPath,
                                exact = false,
                                auth = true,
                              }) => {
                                return (
                                  <Route
                                    path={path + childrenPath}
                                    exact={exact}
                                    key={path + childrenPath}
                                    render={(props) => {
                                      let PageComponent =
                                        Pages[ChildrenComponent];
                                      return <PageComponent {...props} />;
                                    }}
                                  />
                                );
                              }
                            )}
                          </LayoutComponent>
                        );
                      }}
                    />
                  );
                }
              }
            )}
          </Router>
        </ThemeContext.Provider>
      </Suspense>
    );
  }
}

// const mapStateToProps = (state) => {
//     return state.theme
// }

export default connect()(RoutesClass);
