import {AppLoader} from '../loader/appLoader';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

const hiddenRows = [1, 4];

export const CustomBootstrapTable = ({ keyField, rows,columns,sort, isLoading , selectRows , onRowClicked , isRowClickEnabled, tableClass, tableMainClass}) => {
    
    const rowEvents = () => {
        if(isRowClickEnabled){
            return {
                onClick: (e, row, rowIndex) => {
                    console.log({e, row, rowIndex})
                    onRowClicked(row)
                }
            }
        }
    }
    
    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (row.status ===  1) {
          style.backgroundColor = '#c8e6c9';
        } 
        return style;
    };

    const emptyDataMessage = () => { return <p className='table_empty_text'>No Data Found</p>}
    
    return (
        <>
        {isLoading ? 
            <AppLoader />
        :
            <BootstrapTable 
                bootstrap4 
                keyField={keyField} 
                data={rows}  
                columns={columns} 
                defaultSorted={sort} 
                hiddenRows={ hiddenRows }
                selectRow = {selectRows}
                rowEvents = {rowEvents()}
                loading={ true }  
                striped
                hover
                // condensed
                noDataIndication={emptyDataMessage}
                rowStyle={ rowStyle }
                rowClasses={tableClass}
                wrapperClasses={`bootstrap-table ${tableMainClass}`}
            />
    }

        </>
    )
}