import React from "react";
import tubbg from "../../assets/images/tubbg.png"
export const TubPage = () => {
  return (
    <div className="container-fluid">
      <div className="tubbg">

<img src={tubbg} alt="" className="img-fluid w-100" />
      </div>
      <div className="coming-soon w-50">
        <p className="coming-soon-title mb-4">TUB Page is Coming Soon</p>
        <h3>
          TUB Page is a community page where teachers can share their TUB Sheet
          and TUB Cards with other teachers..... <br/><br/><br/> Remember, it takes a village to
          raise a child...
        </h3>
      </div>
    </div>
  );
};


