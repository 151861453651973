import { useState } from "react";
import { timeSince } from "../../helpers";
import ButtonType1 from "../inputs/ButtonType1";
import ButtonType3 from "../inputs/ButtonType3";
import { RejectedPendingObject } from "./rejectedPendingObject";
import { Modal, ModalBody } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import RightArrow from "../../assets/images/rightarrow.svg";
import LeftArrow from "../../assets/images/leftarrow.svg";
import { CreateSessionDatePopup } from "./createSessionDatePopup";
import _ from "lodash";
import { ContentOriginalImage } from "./contentOriginalImage";

export const InboxPendingCard = ({
  tubInboxList,
  showDatePopup,
  triggerPopup,
  updateTubObject,
  type,
  addToCreateSession,
  session,
  deleteTubObject,
}) => {
  const [showmore, toggleShowMore] = useState(false);
  const [showImagesPopup, setShowImagesPopup] = useState(false);
  const [currentImageToShow, setCurrentImageToShow] = useState(null);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [objectImagesList, setObjectImagesList] = useState([]);
  let [pendingList, setPendingList] = useState(tubInboxList);
  let [rejectedObjects, setRejectedObjects] = useState({});

  const getTubObjectCardDeMix = (data, type) => {
    let objects;
    if (type == 1) {
      if (
        data.TubObjectId != null &&
        !_.isEmpty(data.TubObjectId.data) &&
        Array.isArray(data.TubObjectId.data)
      ) {
        objects = data.TubObjectId.data;
      } else objects = [];
    } else {
      objects = data;
    }
    if (objects.length === 2) {
      return (
        <div className="tub_inbox_pending_card">
          <div className="row no-gutters h_inherit">
            {objects.map((ob, i) => {
              return (
                <div
                  key={i}
                  className="position-relative col-6"
                  onClick={() => toggleImagesPopup(data, i, type)}
                >
                  <div className={`ib_pending_full_h`}>
                    <img
                      src={ob.alphabetImage?.original}
                      className="ib_pending_img"
                      alt="alphatub"
                    />
                  </div>
                  <RejectedPendingObject
                    rejectedObjects={rejectedObjects}
                    _id={ob._id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (objects.length === 3) {
      return (
        <div className="tub_inbox_pending_card">
          <div className="d-flex w-100">
            <div
              className="position-relative w-50 d-flex"
              onClick={() => toggleImagesPopup(data, 1, type)}
            >
              <img
                src={objects[0].alphabetImage?.original}
                className="fit_cover"
                alt="alphatub"
              />
              <RejectedPendingObject
                rejectedObjects={rejectedObjects}
                _id={objects[0]._id}
              />
            </div>
            <div className="d-flex flex-column w_50 ">
              <div
                className="position-relative card_h_half"
                onClick={() => toggleImagesPopup(data, 0, type)}
              >
                <img
                  src={objects[1].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[1]._id}
                />
              </div>
              <div
                className="position-relative card_h_half"
                onClick={() => toggleImagesPopup(data, 2, type)}
              >
                <img
                  src={objects[2].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[2]._id}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (objects.length == 4) {
      return (
        <div className="tub_inbox_pending_card">
          <div className="row no-gutters">
            {objects.map((ob, i) => {
              return (
                <div
                  key={i}
                  className="position-relative col-6"
                  onClick={() => toggleImagesPopup(data, i, type)}
                >
                  <div className={`ib_pending_obj_two_col card_h_half`}>
                    <img
                      src={ob.alphabetImage?.original}
                      className="ib_pending_img"
                      alt="alphatub"
                    />
                    <RejectedPendingObject
                      rejectedObjects={rejectedObjects}
                      _id={ob._id}
                    />
                  </div>
                  {/* {ob.isApprovedBySchool &&
                                        <div className='approved_inbox_content'>
                                            <img
                                                src={require('../../../../assets/images/selectedPlayer.png')}
                                                alt="alphatub"
                                            />
                                        </div>
                                    } */}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (objects.length === 5) {
      return (
        <div className="tub_inbox_pending_card">
          <div className="w-100">
            <div className="d-flex">
              <div
                className={`position-relative w-50`}
                onClick={() => toggleImagesPopup(data, 0, type)}
              >
                <img
                  src={objects[0].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[0]._id}
                />
              </div>
              <div
                className={`position-relative w-50`}
                onClick={() => toggleImagesPopup(data, 1, type)}
              >
                <img
                  src={objects[1].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[1]._id}
                />
              </div>
            </div>
            <div className="d-flex">
              <div
                className={`position-relative width_one_third card_h_half`}
                onClick={() => toggleImagesPopup(data, 2, type)}
              >
                <img
                  src={objects[2].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[2]._id}
                />
              </div>
              <div
                className={`position-relative width_one_third card_h_half`}
                onClick={() => toggleImagesPopup(data, 3, type)}
              >
                <img
                  src={objects[3].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[3]._id}
                />
              </div>
              <div
                className={`position-relative width_one_third card_h_half`}
                onClick={() => toggleImagesPopup(data, 4, type)}
              >
                <img
                  src={objects[4].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[4]._id}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (objects.length === 6) {
      return (
        <div className="tub_inbox_pending_card">
          <div className="row no-gutters">
            {objects.map((ob, i) => {
              return (
                <div
                  key={i}
                  className="position-relative col-4 "
                  onClick={() => toggleImagesPopup(data, i, type)}
                >
                  <div className={`ib_pending_obj_two_col card_h_half`}>
                    <img
                      src={ob.alphabetImage?.original}
                      className="ib_pending_img"
                      alt="alphatub"
                    />
                  </div>
                  <RejectedPendingObject
                    rejectedObjects={rejectedObjects}
                    _id={ob._id}
                  />
                  {/* {ob.isApprovedBySchool &&
                                        <div className='approved_inbox_content'>
                                            <img
                                                src={require('../../../../assets/images/selectedPlayer.png')}
                                                alt="alphatub"
                                            />
                                        </div>
                                    } */}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (objects.length === 7) {
      return (
        <div className="tub_inbox_pending_card">
          <div className="w-100">
            <div className="d-flex">
              <div
                className={`position-relative w-50 card_h_quarter`}
                onClick={() => toggleImagesPopup(data, 0, type)}
              >
                <img
                  src={objects[0].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[0]._id}
                />
              </div>
              <div
                className={`position-relative w-50 card_h_quarter`}
                onClick={() => toggleImagesPopup(data, 1, type)}
              >
                <img
                  src={objects[1].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[1]._id}
                />
              </div>
            </div>
            <div className="d-flex">
              <div
                className={`position-relative w-50 card_h_quarter`}
                onClick={() => toggleImagesPopup(data, 2, type)}
              >
                <img
                  src={objects[2].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[2]._id}
                />
              </div>
              <div
                className={`position-relative w-50 card_h_quarter`}
                onClick={() => toggleImagesPopup(data, 3, type)}
              >
                <img
                  src={objects[3].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[3]._id}
                />
              </div>
            </div>
            <div className="d-flex">
              <div
                className={`position-relative width_one_third card_h_quarter`}
                onClick={() => toggleImagesPopup(data, 4, type)}
              >
                <img
                  src={objects[4].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[4]._id}
                />
              </div>
              <div
                className={`position-relative width_one_third card_h_quarter`}
                onClick={() => toggleImagesPopup(data, 5, type)}
              >
                <img
                  src={objects[5].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[5]._id}
                />
              </div>
              <div
                className={`position-relative width_one_third card_h_quarter`}
                onClick={() => toggleImagesPopup(data, 6, type)}
              >
                <img
                  src={objects[6].alphabetImage?.original}
                  className="ib_pending_img"
                  alt="alphatub"
                />
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={objects[6]._id}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (objects.length === 8) {
      return (
        <div className="d-flex w-100">
          <div className="d-flex flex-column width_one_third">
            <div
              className="position-relative"
              onClick={() => toggleImagesPopup(data, 0, type)}
            >
              <img
                src={objects[0].alphabetImage?.original}
                className="ib_pending_img"
                alt="alphatub"
              />
              <RejectedPendingObject
                rejectedObjects={rejectedObjects}
                _id={objects[0]._id}
              />
            </div>
            <div
              className="position-relative"
              onClick={() => toggleImagesPopup(data, 2, type)}
            >
              <img
                src={objects[2].alphabetImage?.original}
                className="ib_pending_img"
                alt="alphatub"
              />
              <RejectedPendingObject
                rejectedObjects={rejectedObjects}
                _id={objects[2]._id}
              />
            </div>
            <div
              className="position-relative"
              onClick={() => toggleImagesPopup(data, 4, type)}
            >
              <img
                src={objects[4].alphabetImage?.original}
                className="ib_pending_img"
                alt="alphatub"
              />
              <RejectedPendingObject
                rejectedObjects={rejectedObjects}
                _id={objects[4]._id}
              />
            </div>
          </div>
          <div className="d-flex flex-column width_one_third">
            <div
              className="position-relative"
              onClick={() => toggleImagesPopup(data, 1, type)}
            >
              <img
                src={objects[1].alphabetImage?.original}
                className="ib_pending_img"
                alt="alphatub"
              />
              <RejectedPendingObject
                rejectedObjects={rejectedObjects}
                _id={objects[1]._id}
              />
            </div>
            <div
              className="position-relative"
              onClick={() => toggleImagesPopup(data, 3, type)}
            >
              <img
                src={objects[3].alphabetImage?.original}
                className="ib_pending_img"
                alt="alphatub"
              />
              <RejectedPendingObject
                rejectedObjects={rejectedObjects}
                _id={objects[3]._id}
              />
            </div>
            <div
              className="position-relative"
              onClick={() => toggleImagesPopup(data, 5, type)}
            >
              <img
                src={objects[5].alphabetImage?.original}
                className="ib_pending_img"
                alt="alphatub"
              />
              <RejectedPendingObject
                rejectedObjects={rejectedObjects}
                _id={objects[5]._id}
              />
            </div>
          </div>
          <div className="d-flex flex-column width_one_third">
            <div
              className="position-relative d-flex h_100"
              onClick={() => toggleImagesPopup(data, 6, type)}
            >
              <img
                src={objects[6].alphabetImage?.original}
                className="fit_cover"
                alt="alphatub"
              />
              <RejectedPendingObject
                rejectedObjects={rejectedObjects}
                _id={objects[6]._id}
              />
            </div>
            <div
              className="position-relative d-flex h_100"
              onClick={() => toggleImagesPopup(data, 7, type)}
            >
              <img
                src={objects[7].alphabetImage?.original}
                className="fit_cover"
                alt="alphatub"
              />
              <RejectedPendingObject
                rejectedObjects={rejectedObjects}
                _id={objects[7]._id}
              />
            </div>
          </div>
        </div>
      );
    } else if (objects.length === 9) {
      return (
        <div className="tub_card_layout_container">
          {objects.map((ob, i) => {
            return (
              <div
                key={i}
                className="position-relative flex-col-divide-3"
                onClick={() => toggleImagesPopup(data, i, type)}
              >
                <div className={`ib_pending_obj_default_h `}>
                  <img
                    src={ob.alphabetImage?.original}
                    className="ib_pending_img"
                    alt="alphatub"
                  />
                </div>
                <RejectedPendingObject
                  rejectedObjects={rejectedObjects}
                  _id={ob._id}
                />
                {/* {ob.isApprovedBySchool &&
                                    <div className='approved_inbox_content'>
                                        <img
                                            src={require('../../../../assets/images/selectedPlayer.png')}
                                            alt="alphatub"
                                        />
                                    </div>
                                } */}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const discardContent = (item, type) => {
    let rejectedContent = objectImagesList[currentImageToShow];
    if (rejectedObjects.hasOwnProperty(rejectedContent._id)) {
      delete rejectedObjects[rejectedContent._id];

      objectImagesList[currentImageToShow]["isSelected"] = type;
      pendingList.map((pd) => {
        if (pd._id == selectedObjectId) {
          let checkForApproveDisable = pd.TubObjectId.data.filter(
            (dt) => dt["isSelected"] === true
          );
          if (checkForApproveDisable.length === 0) {
            pd["isApprovedDisable"] = false;
          } else {
            pd["isApprovedDisable"] = true;
          }
        }
      });
      setPendingList(pendingList);
      setRejectedObjects({ ...rejectedObjects });
      setObjectImagesList(objectImagesList);
    } else {
      rejectedObjects = {
        ...rejectedObjects,
        [rejectedContent._id]: type,
      };
      pendingList.map((pd) => {
        if (pd._id == selectedObjectId) {
          pd["isApprovedDisable"] = true;
        }
      });
      objectImagesList[currentImageToShow]["isSelected"] = type;

      setPendingList(pendingList);
      setRejectedObjects(rejectedObjects);
      setObjectImagesList(objectImagesList);
    }
  };

  const toggleImagesPopup = (data, index, type) => {
    setShowImagesPopup(true);
    setCurrentImageToShow(index);
    if (type == 1) {
      setSelectedObjectId(data._id);
      setObjectImagesList(data.TubObjectId.data);
    } else {
      setObjectImagesList(data);
    }
  };
  const clickNext = () => {
    if (objectImagesList.length - 1 === currentImageToShow) {
      setCurrentImageToShow(0);
    } else {
      setCurrentImageToShow(currentImageToShow + 1);
    }
  };
  const clickPrev = () => {
    if (currentImageToShow === 0) {
      setCurrentImageToShow(objectImagesList.length - 1);
    } else {
      setCurrentImageToShow(currentImageToShow - 1);
    }
  };
  const capitalize = (str) => {
    // Check if the input is a string and is not empty
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }

    // Capitalize the first letter and concatenate it with the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  console.log(tubInboxList, "tubInboxList");
  return (
    <>
      {type == 1 ? (
        tubInboxList.map((data, i) => (
          <div className="tub-bg-type-2 pb-2 d-flex mb-4 mt-2" key={i}>
            <div className="col-md-8">
              <div className="pt-50">
                <div className="row no-gutters justify-content-end align-items-center px-2 tub-inner-heading">
                  <div className="col-3 mb-1">
                    <p className="f-14 tub-day text-right">
                      {data.TubObjectType === "Card"
                        ? "TUB Card"
                        : data.TubObjectType === "Sheet"
                        ? "TUB Sheet"
                        : data.TubObjectType === "Mix"
                        ? "TUB Mix"
                        : data.TubObjectType === "Letter"
                        ? "Letter"
                        : data.TubObjectType === "Tags"
                        ? "Tags"
                        : data.TubObjectType === "Assignment"
                        ? "Assignment"
                        : data.TubObjectType === "Word"
                        ? "Word"
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="inbox-inner-content relative-pos">
                  <div
                    className={`tub-session-card h_inherit ${
                      data.TubObjectType === "Sheet" && "addFilter p-1"
                    }`}
                  >
                    {data.TubObjectType === "Card" ||
                    data.TubObjectType === "Assignment" ? (
                      getTubObjectCardDeMix(data, 1)
                    ) : (
                      <div className="row flex-wrap no-gutters cursor-pointer h_inherit ">
                        {data.TubObjectId.data.map((dt, i) => (
                          <div
                            key={i}
                            className="col-2 p-1 position-relative"
                            onClick={() => toggleImagesPopup(data, i, type)}
                          >
                            <img
                              src={dt.stencilImage?.original}
                              className="inbox-pending-stencilImage "
                              alt="alphatub"
                            />

                            <RejectedPendingObject
                              rejectedObjects={rejectedObjects}
                              _id={dt._id}
                              className=""
                            />
                          </div>
                        ))}
                        {/* <div
                          key={i}
                          onClick={() => toggleImagesPopup(data, i, type)}
                        >
                          <img
                            src={data.TubObjectId?.ObjectImage?.original}
                            className="inbox-pending-stencilImage "
                            alt="alphatub"
                          />

                          <RejectedPendingObject
                            rejectedObjects={rejectedObjects}
                            _id={data._id}
                            className=""
                          />
                        </div> */}
                      </div>
                    )}
                  </div>

                  <div className="row align-items-center no-gutters tub_inbox_std">
                    {data.Parent_Details.Profile_img?.original != undefined ? (
                      <img
                        src={
                          data.Parent_Details != null &&
                          data.Parent_Details.Profile_img != null &&
                          data.Parent_Details.Profile_img?.original !=
                            undefined &&
                          data.Parent_Details.Profile_img?.original !== ""
                            ? data.Parent_Details.Profile_img?.original
                            : require("../../assets/images/noImage.png")
                        }
                        className="whbr-60"
                        alt="alphatub"
                      />
                    ) : (
                      <img
                        src={
                          data?.SharedByTeacherId?.Profile_img?.original !== ""
                            ? data?.SharedByTeacherId?.Profile_img?.original
                            : require("../../assets/images/noImage.png")
                        }
                        className="whbr-60"
                        alt="alphatub"
                      />
                    )}

                    <p className="object_student_name pl-2">
                      {data?.Parent_Details?.First_name
                        ? data?.Parent_Details?.First_name
                        : data?.SharedByTeacherId?.First_name}{" "}
                      {data?.RelationWithParent !== 'Self' && `( ${
                        data?.RelationWithParent !== ""
                          ? data?.RelationWithParent
                          : "Teacher"
                      } )`}
                    </p>
                  </div>
                </div>
                <div className="row no-gutters mt-2 justify-content-between align-items-center">
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <p className="alphatub-card-h2">{data.TubObjectName}</p>
                      <p className="f-14 tub-day pt-1">
                        {timeSince(data.updatedAt, false)}
                      </p>
                    </div>
                    <p className="f-14 tub-content-3">
                      {showmore && !_.isEmpty(data.TubObjectId)
                        ? data.TubObjectId?.tags &&
                          data.TubObjectId?.tags.map((tg) => " #" + tg)
                        : data.TubObjectId?.tags &&
                          data.TubObjectId?.tags
                            .slice(0, 2)
                            .map((tg) => " #" + tg)}
                    </p>
                    {!_.isEmpty(data.TubObjectId) &&
                    data.TubObjectId?.tags &&
                    data.TubObjectId?.tags.length > 2 ? (
                      <p
                        className="show_more"
                        onClick={() => toggleShowMore(!showmore)}
                      >
                        {showmore ? "show less" : "show more"}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inbox_pending_right_container">
                <div className="pt-30">
                  <div className="d-flex align-items-center justify-content-center column-direction">
                    <img
                      className="whbr-75 studentBorder"
                      src={
                        data.Student_ProfileImage?.original !== ""
                          ? data?.Student_ProfileImage?.original
                          : require("../../assets/images/noImage.png")
                      }
                      alt="alphatub"
                    />
                    <p className="f-18 tub-content-3">{data?.StudentName}</p>
                  </div>
                  <div className="mt-4">
                    <p className="f-18 tub-content-4">
                      {'"' + data.TubObjectName + '"'}{" "}
                      {data.TubObjectType === "Card"
                        ? "TUB Card"
                        : data.TubObjectType === "Sheet"
                        ? "TUB Sheet"
                        : data.TubObjectType === "Assignment"
                        ? "Assignment"
                        : ""}{" "}
                      Recieved From{" "}
                      <span className="tub-content-3">
                        {data.StudentName}
                        {(data.StudentName && data.RelationWithParent !== 'Self') ? "’s" : ''}
                      </span>{" "}
                      {data?.RelationWithParent !== 'Self' && (data.RelationWithParent 
                        ? data?.RelationWithParent
                        : capitalize(data?.contentType))}
                    </p>
                  </div>
                  {/* <div className='mt-4'>
                                        <p className="f-14 tub-content-2">{getApprovedCount(data.TubObjectId.data)} / {data.TubObjectId.data.length} Objects Pending For Approval</p>
                                    </div> */}
                </div>
                <div className="mt-3 mb-3">
                  <div
                    className={`mt-4 w-230 position_relative ${
                      data["isApprovedDisable"] ? "approve_disabled" : ""
                    }`}
                  >
                    <ButtonType3
                      btnName="Approve"
                      disabled={data["isApprovedDisable"] ? true : false}
                      disabledClassName="buttonType3disabled"
                      className={`bg-white py-2 ButtonType3 cursor-pointer`}
                      onClick={() => updateTubObject("approved", data)}
                    />
                    <span class="approve_tooltip">
                      Collections can be approved only when no images are
                      rejected
                    </span>
                  </div>
                  <div className="mt-4 w-230">
                    <ButtonType1
                      btnName="Reject"
                      className="bg-white py-2 buttonType4 cursor-pointer"
                      onClick={() => triggerPopup("isRejectPopupOpen", data)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="tub-bg-type-2 pb-2 d-flex mb-4 mt-2">
          <div className="col-md-8">
            <div className="pt-50">
              <div className="row no-gutters justify-content-end align-items-center px-2 tub-inner-heading">
                <div className="col-3 mb-1">
                  <p className="f-14 tub-day text-right">
                    {tubInboxList?.TubObjectType === "Card"
                      ? "TUB Card"
                      : tubInboxList?.TubObjectType === "Sheet"
                      ? "TUB Sheet"
                      : tubInboxList?.TubObjectType === "Mix"
                      ? "TUB Mix"
                      : tubInboxList?.TubObjectType === "Letter"
                      ? "Letter"
                      : tubInboxList?.TubObjectType === "Tags"
                      ? "Tags"
                      : tubInboxList?.TubObjectType === "Word"
                      ? "Word"
                      : tubInboxList?.TubObjectType === "Assignment"
                      ? "Assignment"
                      : ""}
                  </p>
                </div>
              </div>
              <div className="inbox-inner-content relative-pos">
                <div
                  className={`tub-session-card h_inherit ${
                    tubInboxList?.alphatub_content_result?.TubObjectType ===
                      "Sheet" && "addFilter p-1"
                  }`}
                >
                  {tubInboxList?.TubObjectType === "Card" ||
                  tubInboxList?.TubObjectType === "Assignment" ? (
                    getTubObjectCardDeMix(
                      tubInboxList?.alphatub_content_result?.data,
                      2
                    )
                  ) : (
                    <div className="row flex-wrap no-gutters cursor-pointer h_inherit">
                      {tubInboxList?.alphatub_content_result?.data.map(
                        (dt, i) => (
                          <div
                            key={i}
                            className="col-2 p-1 position-relative"
                            onClick={() =>
                              toggleImagesPopup(
                                tubInboxList?.alphatub_content_result?.data,
                                i
                              )
                            }
                          >
                            <img
                              src={dt.stencilImage?.original}
                              className="inbox-pending-stencilImage "
                              alt="alphatub"
                            />
                            <RejectedPendingObject
                              rejectedObjects={rejectedObjects}
                              _id={dt._id}
                              className=""
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
                <div className="row align-items-center no-gutters tub_inbox_std">
                  <img
                    src={
                      tubInboxList?.Parent_Details != null &&
                      tubInboxList?.Parent_Details.Profile_img != null &&
                      tubInboxList?.Parent_Details.Profile_img?.original !=
                        undefined &&
                      tubInboxList?.Parent_Details.Profile_img?.original !== ""
                        ? tubInboxList?.Parent_Details.Profile_img?.original
                        : tubInboxList?.SharedByTeacherId?.Profile_img
                            ?.original != undefined &&
                          tubInboxList?.SharedByTeacherId?.Profile_img
                            ?.original !== ""
                        ? tubInboxList?.SharedByTeacherId?.Profile_img?.original
                        : require("../../assets/images/noImage.png")
                    }
                    className="whbr-60"
                    alt="alphatub"
                  />
                  <p className="object_student_name pl-2">
                    {tubInboxList?.Parent_Details?.First_name
                      ? tubInboxList?.Parent_Details?.First_name
                      : tubInboxList?.SharedByTeacherId?.First_name}{" "}
                    {tubInboxList?.RelationWithParent !== 'Self' ? `( ${
                      tubInboxList?.RelationWithParent
                        ? tubInboxList?.RelationWithParent
                        : capitalize(tubInboxList?.contentType)
                    } )` : ''}
                  </p>
                </div>
              </div>
              <div className="row no-gutters mt-2 justify-content-between align-items-center">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <p className="alphatub-card-h2">
                      {tubInboxList?.TubObjectName}
                    </p>
                    <p className="f-14 tub-day pt-1">
                      {timeSince(tubInboxList?.createdAt, false)}
                    </p>
                  </div>
                  <p className="f-14 tub-content-3">
                    {showmore
                      ? tubInboxList?.alphatub_content_result?.tags &&
                        tubInboxList?.alphatub_content_result?.tags.map(
                          (tg) => " #" + tg
                        )
                      : tubInboxList?.alphatub_content_result?.tags &&
                        tubInboxList?.alphatub_content_result?.tags
                          .slice(0, 2)
                          .map((tg) => " #" + tg)}
                  </p>
                  {tubInboxList?.alphatub_content_result?.tags &&
                  tubInboxList?.alphatub_content_result?.tags.length > 2 ? (
                    <p
                      className="show_more"
                      onClick={() => toggleShowMore(!showmore)}
                    >
                      {showmore ? "show less" : "show more"}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="inbox_pending_right_container">
              <div className="pt-30">
                <div className="d-flex align-items-center justify-content-center column-direction">
                  <img
                    className="whbr-75 studentBorder"
                    // src={
                    //   tubInboxList?.StudentId != undefined &&
                    //   tubInboxList?.StudentId.profilePic?.original !== ""
                    //     ? tubInboxList?.StudentId.profilePic?.original
                    //     : require("../../assets/images/noImage.png")
                    // }
                    src={
                      tubInboxList?.SharedByTeacherId &&
                      tubInboxList?.SharedByTeacherId?.Profile_img?.original !== ""
                        ? tubInboxList?.SharedByTeacherId?.Profile_img?.original || tubInboxList?.StudentId.profilePic?.original
                        : require("../../assets/images/noImage.png")
                      }
                    alt="alphatub"
                  />
                  <p className="f-18 tub-content-3">
                    {tubInboxList?.StudentId?.firstName}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="f-18 tub-content-4">
                    {tubInboxList?.TubObjectName}{" "}
                    {tubInboxList?.TubObjectType === "Card"
                      ? "TUB Card"
                      : tubInboxList?.TubObjectType === "Sheet"
                      ? "TUB Sheet"
                      : "TUB Mix"}{" "}
                    Recieved From{" "}
                    <span className="tub-content-3">
                      {/* {tubInboxList?.Parent_Details?.First_name}{" "} */}
                      {tubInboxList?.StudentId?.firstName}{" "}
                      {(tubInboxList?.StudentId?.firstName && tubInboxList?.RelationWithParent !== 'Self') ? "’s" : ""}
                    </span>{" "}
                    {(tubInboxList?.StudentId?.firstName && tubInboxList?.RelationWithParent !== 'Self')
                     ? (tubInboxList?.RelationWithParent
                      ? tubInboxList?.RelationWithParent
                      : capitalize(tubInboxList?.contentType)) : ''}
                  </p>
                </div>
                {/* <div className='mt-4'>
                                        <p className="f-14 tub-content-2">{getApprovedCount(data.TubObjectId.data)} / {data.TubObjectId.data.length} Objects Pending For Approval</p>
                                    </div> */}
              </div>
              <div className="mt-3 mb-3">
                <div className={`mt-4 w-230 position_relative`}>
                  <ButtonType3
                    btnName="Add To Session"
                    disabled={false}
                    disabledClassName="buttonType3disabled"
                    className={`bg-white py-2 ButtonType3 cursor-pointer`}
                    onClick={() => triggerPopup("showDatePopup", tubInboxList)}
                  />
                </div>
                <div className="mt-4 w-230">
                  <ButtonType1
                    btnName="Delete"
                    className="bg-white py-2 buttonType4 cursor-pointer"
                    onClick={() => deleteTubObject(tubInboxList)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* create session date popup */}
      <CreateSessionDatePopup
        showDatePopup={showDatePopup}
        triggerPopup={triggerPopup}
        addToCreateSession={addToCreateSession}
        session={session}
      />

      {showImagesPopup && (
        <Modal
          isOpen={showImagesPopup}
          toggle={() => setShowImagesPopup(!showImagesPopup)}
          className="pop-up max-800 inbox_image_popup"
        >
          <ModalBody>
            <div className="inbox_Left" onClick={() => clickPrev()}>
              <img src={LeftArrow} />
            </div>
            <Carousel
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              emulateTouch={true}
              showArrows={false}
              selectedItem={currentImageToShow}
              className="reject_popup_slider"
            >
              {objectImagesList &&
                objectImagesList.map((item) => (
                  <div>
                    <div className="position_relative">
                      <img
                        src={item.alphabetImage?.original}
                        className="br-10"
                        alt="alphatub"
                      />
                      {type == 1 && (
                        <>
                          <RejectedPendingObject
                            rejectedObjects={rejectedObjects}
                            _id={item._id}
                            className="br-10"
                          />
                          {item["isSelected"] ? (
                            <div className="col-3 d-flex justify-content-end tub_inbox_discard">
                              <ButtonType1
                                btnName="Unreject"
                                className="py-2 buttonType6 cursor-pointer"
                                onClick={() => discardContent(item, false)}
                              />
                            </div>
                          ) : (
                            <div className="col-3 d-flex justify-content-end tub_inbox_discard">
                              <ButtonType1
                                btnName="Reject"
                                className="bg-white py-2 buttonType4 cursor-pointer"
                                onClick={() => discardContent(item, true)}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="row no-gutters align-items-center mt-1">
                      <div className="col-8">
                        <p className="tubInbox_pp_nm">{item.imageTitle}</p>
                        <p className="tubInbox_pp_tg">#{item.tags[0]}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
            <div className="inbox_Right" onClick={() => clickNext()}>
              <img src={RightArrow} />
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
