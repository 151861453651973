import { loaders, main } from "../service/actionType";
import { API } from "aws-amplify";
import toast from "../helpers/Toast";
import { ENVIRONMENT } from "../common/constants";
import { accessHeaders } from "../helpers";
import _ from "lodash";

export const fetchGraphData =
  (index, apiname, noOfDays, filterPayload, tabFilter) =>
  async (dispatch, getState) => {
    let category = localStorage.getItem("schoolCategory");
    try {
      let { allGrpahsList } = getState().analytics;
      let { dataToPersist, tubGallery } = getState();
      let selectedClass = sessionStorage.getItem("selectedClass");
      let classId = selectedClass
        ? selectedClass
        : dataToPersist?.selectedClass?.value;
      let params = {
        classId: classId,
        schoolId: sessionStorage.getItem("schoolId"),
      };
      if (filterPayload !== null && filterPayload.gameFilter !== undefined) {
        params["gameFilter"] = filterPayload.gameFilter;
      } else if (
        filterPayload !== null &&
        filterPayload.contentFilter !== undefined
      ) {
        params["contentFilter"] = filterPayload.contentFilter;
      }

      if (!_.isEmpty(noOfDays)) {
        params["noOfDays"] = noOfDays;
      }

      if (!_.isEmpty(noOfDays)) {
        params["filter"] = tabFilter;
      }

      allGrpahsList[index]["loading"] = true;

      dispatch({ type: main.ANALYTICS_GRAPHS_LIST, payload: allGrpahsList });
      let url = `v3.8/tubanalytics/${apiname}`;
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: params,
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        let axis = {
          x_axis: [],
          y_axis: [],
        };
        allGrpahsList[index]["loading"] = false;

        if (index == 0) {
          allGrpahsList[index]["graphData"] = {
            percentage:
              response.data.percentage != null ? response.data.percentage : 0,
            Total_Curriculum_Words: response.data.Total_Curriculum_Words,
            Total_Explored: response.data.Total_Explored,
          };
        } else if (index == 1) {
          if (!_.isEmpty(response.data)) {
            axis["y_axis_1"] = [];
            axis["y_axis_2"] = [];
            axis["y_axis_3"] = [];

            response.data.map((rs, index) => {
              axis.x_axis.push(rs.fname);

              if (!_.isEmpty(rs.userData)) {
                let formattedData = _.groupBy(rs.userData, "relation");

                // father
                if (formattedData["Father"] != undefined) {
                  let fCount = formattedData["Father"].find(
                    (ft) => ft.withUserPlayedCount > 0
                  );
                  if (!_.isEmpty(fCount)) {
                    axis.y_axis.push(fCount.withUserPlayedCount);
                  } else {
                    axis.y_axis.push(0);
                  }
                } else {
                  axis.y_axis.push(0);
                }

                if (formattedData["Mother"] != undefined) {
                  let mCount = formattedData["Mother"].find(
                    (ft) => ft.withUserPlayedCount > 0
                  );
                  if (mCount != undefined) {
                    axis["y_axis_1"].push(mCount.withUserPlayedCount);
                  } else {
                    axis["y_axis_1"].push(0);
                  }
                } else {
                  axis["y_axis_1"].push(0);
                }

                if (formattedData["Guardian"] != undefined) {
                  let noOfGuardians = formattedData["Guardian"].length;
                  if (noOfGuardians === 1) {
                    axis["y_axis_2"].push(
                      formattedData["Guardian"][0]["withUserPlayedCount"]
                    );
                    axis["y_axis_3"].push(0);
                  } else if (noOfGuardians === 2) {
                    axis["y_axis_2"].push(
                      formattedData["Guardian"][0]["withUserPlayedCount"]
                    );
                    axis["y_axis_3"].push(
                      formattedData["Guardian"][1]["withUserPlayedCount"]
                    );
                  }
                } else {
                  axis["y_axis_2"].push(0);
                  axis["y_axis_3"].push(0);
                }
              }
            });
            allGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 2) {
          if (!_.isEmpty(response.data)) {
            response.data.map((rs) => {
              axis.x_axis.push(rs.firstName);
              axis.y_axis.push(parseInt(rs.count));
            });
            allGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 3) {
          if (!_.isEmpty(response.data)) {
            response.data.map((rs) => {
              axis.x_axis.push(rs.firstName);
              axis.y_axis.push(parseInt(rs.percentage));
            });
            allGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 4) {
          if (!_.isEmpty(response.data)) {
            response.data.map((rs) => {
              axis.x_axis.push(rs.date);
              axis.y_axis.push(parseInt(rs.avgScore));
            });
            allGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 5) {
          if (!_.isEmpty(response.data)) {
            axis["data"] = [];
            response.data.map((rs) => {
              axis.x_axis.push(rs._id);
              axis.y_axis.push(parseInt(rs.count));
              axis["data"].push({
                name: rs._id,
                count: rs.count,
              });
            });
            allGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 6) {
          if (!_.isEmpty(response.data)) {
            let sortedList = _.sortBy(response.data, "firstName");

            axis["data"] = [];
            axis["y_axis_2"] = [];

            sortedList.map((rs) => {
              axis.x_axis.push(rs.firstName);
              axis.y_axis.push(parseInt(rs.total_Explored));
              axis["y_axis_2"].push(parseInt(rs.totalCorrect));
            });

            const studentsLeft = tubGallery.rightSidebarStudentsList.filter(
              ({ firstName: id1 }) =>
                !response.data.some(({ firstName: id2 }) => id2 === id1)
            );

            if (studentsLeft.length > 0) {
              studentsLeft.map((rs) => {
                axis.x_axis.push(rs.firstName);
                axis.y_axis.push(0);
                axis["y_axis_2"].push(0);
              });
            }
            allGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 7) {
          if (!_.isEmpty(response.data)) {
            let sortedList = _.sortBy(response.data, "firstName");
            sortedList.map((rs) => {
              axis.x_axis.push(rs.firstName);
              axis.y_axis.push(parseInt(rs.Accuracy));
            });

            const studentsLeft = tubGallery.rightSidebarStudentsList.filter(
              ({ firstName: id1 }) =>
                !response.data.some(({ firstName: id2 }) => id2 === id1)
            );

            if (studentsLeft.length > 0) {
              studentsLeft.map((rs) => {
                axis.x_axis.push(rs.firstName);
                axis.y_axis.push(0);
              });
            }

            allGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 8) {
          if (!_.isEmpty(response.data)) {
            axis.x_axis.push(
              parseInt(response?.data?.curriculum_Choice),
              parseInt(response?.data?.parent_Choice),
              parseInt(response?.data?.teacher_Choice)
            );
            axis.y_axis.push(
              "Curriculum Choice",
              `${
                category === "TUBclass Main"
                  ? "Parent Choice"
                  : "Student Choice"
              }`,
              "Teacher Choice"
            );
            allGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 9) {
          if (!_.isEmpty(response.data)) {
            response.data.map((rs) => {
              axis.y_axis.push(rs.relation);
              axis.x_axis.push(parseInt(rs.withUserPlayedCount));
            });
            allGrpahsList[index]["graphData"] = axis;
          }
        } else if (index === 10) {
          if (!_.isEmpty(response.data)) {
            axis["y_axis_2"] = [];
            response.data.map((rs) => {
              axis.x_axis.push(`${rs.fname}`);
              axis.y_axis.push(parseInt(rs.totalPlayCount));
              axis.y_axis_2.push(parseInt(rs.tubInboxShares));
            });
            allGrpahsList[index]["graphData"] = axis;
          }
        }
        dispatch({ type: main.ANALYTICS_GRAPHS_LIST, payload: allGrpahsList });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({ type: main.RESET_GRAPHS });
    }
  };
export const fetchStudentGraphData =
  (index, apiname, noOfDays, filterPayload, tabFilter, studentId) =>
  async (dispatch, getState) => {
    try {
      let category = localStorage.getItem("schoolCategory");
      let { studentsGrpahsList } = getState().analytics;
      let { dataToPersist } = getState();
      let selectedClass = sessionStorage.getItem("selectedClass");
      let classId = selectedClass
        ? selectedClass
        : dataToPersist?.selectedClass?.value;
      let params = {
        classId: classId,
        schoolId: sessionStorage.getItem("schoolId"),
        studentId: studentId,
      };
      if (filterPayload !== null && filterPayload.gameFilter !== undefined) {
        params["gameFilter"] = filterPayload.gameFilter;
      } else if (
        filterPayload !== null &&
        filterPayload.contentFilter !== undefined
      ) {
        params["contentFilter"] = filterPayload.contentFilter;
      }

      if (!_.isEmpty(noOfDays)) {
        params["noOfDays"] = noOfDays;
      }

      if (!_.isEmpty(noOfDays)) {
        params["filter"] = tabFilter;
      }

      studentsGrpahsList[index]["loading"] = true;

      dispatch({
        type: main.STUDENT_ANALYTICS_GRAPHS_LIST,
        payload: studentsGrpahsList,
      });
      let url = `v3.8/tubanalytics/${apiname}`;
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: params,
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        studentsGrpahsList[index]["loading"] = false;
        let axis = {
          x_axis: [],
          y_axis: [],
        };
        let res = response.data;
        if (index == 0) {
          axis.x_axis = ["Completed", "Pending"];
          axis.y_axis = [res.percentage, 100 - res.percentage];
          studentsGrpahsList[index]["graphData"] = axis;
        } else if (index == 1) {
          studentsGrpahsList[index]["graphData"]["percentage"] = parseInt(
            res.percentage
          );
        } else if (index == 2) {
          if (!_.isEmpty(res)) {
            res.map((rs) => {
              axis.x_axis.push(rs.date);
              axis.y_axis.push(parseInt(rs.avgScore));
            });
            studentsGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 3) {
          if (!_.isEmpty(res.userData)) {
            res.userData.map((rs) => {
              axis.x_axis.push(parseInt(rs.withUserCreatedCount));
              axis.y_axis.push(rs.relation);
            });
            studentsGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 4) {
          if (!_.isEmpty(res.userData)) {
            res.userData.map((rs) => {
              axis.x_axis.push(parseInt(rs.withUserPlayedCount));
              axis.y_axis.push(rs.relation);
            });
            studentsGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 5) {
          if (!_.isEmpty(res)) {
            axis["x_axis_1"] = [];
            res.map((rs) => {
              axis.x_axis.push(parseInt(rs.total_Explored));
              axis["x_axis_1"].push(parseInt(rs.totalRight));
              axis.y_axis.push(rs.wordName);
            });
            studentsGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 6) {
          if (!_.isEmpty(res)) {
            axis["x_axis_1"] = [];
            res.map((rs) => {
              axis.x_axis.push(parseInt(rs.total_Explored));
              axis["x_axis_1"].push(parseInt(rs.totalRight));
              axis.y_axis.push(rs._id);
            });
            studentsGrpahsList[index]["graphData"] = axis;
          }
        } else if (index == 7) {
          if (!_.isEmpty(res)) {
            axis.x_axis.push(
              parseInt(res?.curriculum_Choice),
              parseInt(res?.parent_Choice),
              parseInt(res?.teacher_Choice)
            );
            axis.y_axis.push("Curriculum Choice",  category === 'TUBclass Main' ?  "Parent Choice" : "Student Choice", "Teacher Choice");
            studentsGrpahsList[index]["graphData"] = axis;
          }
        }
        // else if (index == 8) {
        //     if (!_.isEmpty(res)) {
        //         axis.x_axis.push(parseInt(res?.curriculum_Choice), parseInt(res?.parent_Choice), parseInt(res?.total_Words))

        //         studentsGrpahsList[index]['graphData'] = axis
        //     }
        // } else if (index == 9) {
        //     if (!_.isEmpty(res)) {
        //         axis.x_axis.push(parseInt(res?.curriculum_Choice), parseInt(res?.parent_Choice), parseInt(res?.total_Words))

        //         studentsGrpahsList[index]['graphData'] = axis
        //     }
        // }
        dispatch({
          type: main.STUDENT_ANALYTICS_GRAPHS_LIST,
          payload: studentsGrpahsList,
        });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({ type: main.RESET_GRAPHS });
    }
  };

export const resetGraphs = () => async (dispatch, getState) => {
  let allGrpahsList = [
    {
      loading: false,
      graphData: [],
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
        y_axis_1: [],
        y_axis_2: [],
        y_axis_3: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
        data: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
        y_axis_2: [],
        data: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
    {
      loading: false,
      graphData: {
        x_axis: [],
        y_axis: [],
      },
      gameFilter: null,
      contentFilter: null,
    },
  ];
  dispatch({ type: main.ANALYTICS_GRAPHS_LIST, payload: allGrpahsList });
};
