import { React, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as tubgallery from 'actions/tubgallery';
import * as curriculumAction from 'actions/curriculum';
import * as sessionAction from 'actions/session';
import { bindActionCreators } from "redux";
import { workingDaysOptions } from 'helpers/variables';
import moment from 'moment'
import { differenceInCalendarDays } from 'date-fns';
import { API } from 'aws-amplify';
import { ENVIRONMENT } from 'common/constants';
import { loaders, main } from 'service/actionType';
import toast from 'helpers/Toast';
import Wrapper from 'Hoc/wrapper';
import { sampleAssignmentTrackTableColumns } from 'helpers/tableDataMapper';
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import FormInput from 'components/inputs/FormInput';
import { history } from 'helpers';

const TrackAssignmentFunction = (props) => {
    const [filterOptions] = useState([
        {
            label: 'All Assignments',
            value: '1'
        },
        {
            label: 'Active Assignments',
            value: '2'
        },
        {
            label: 'Closed Assignments',
            value: '3'
        }
    ])
    const [filter, setFilter] = useState('1')

    // notify notificaiton
    const notify = (row) => {
        let payload = {
            assignmentId: row?._id,
            teacherUserName: localStorage.getItem("username")
        }
        props.curriculumAction.notifyToAll(payload)
    }

    const onChangeFilter = (name, value) => {
        let { copyOfTrackAllAsignments } = props.curriculum
        if(value == '1'){
            props.dispatch({ type: main.TRACK_ASSIGNMENT_TABLE_LIST, payload: copyOfTrackAllAsignments })
        } else if(value == '2'){
            let activeass = copyOfTrackAllAsignments.filter(row => (moment(row?.end_Date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) )
            props.dispatch({ type: main.TRACK_ASSIGNMENT_TABLE_LIST, payload: activeass })
        } else if(value == '3'){
            let activeass = copyOfTrackAllAsignments.filter(row => !(moment(row?.end_Date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')))
            props.dispatch({ type: main.TRACK_ASSIGNMENT_TABLE_LIST, payload: activeass })
        }
        setFilter(value)
    }

    const getTableColumns = () => {
        let action = {
            text: "Action",
            dataField: "action",
            headerClasses: "columnHeader text-center",
            formatter: (cellContent, row, rowIndex) => {
                return (
                    <div className="cursor-pointer text-center">
                        {
                            moment(row?.end_Date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') 
                                ?
                                    row.submissions == row.studentCount
                                        ?
                                            <p className="fs-13">
                                                Closed
                                            </p>
                                        :
                                        <p 
                                            className="global_color fs-13"
                                            onClick={() => notify(row)}
                                        >
                                            Notify
                                        </p>
                                :
                                    <p className="fs-13">
                                        Closed
                                    </p>
                        }
                    </div>
                )
            }
        }
        return [...sampleAssignmentTrackTableColumns,action]
    }

    const redirect = (route) => {
        history.push(route)
    }

    return (
        <div className="row no-gutters">
            <div className="col-md-12 ">
                <div className="row no-gutters">
                    <div className="col-9 d-flex">
                        <img
                            src={require('assets/images/left-arrow.svg').default}
                            className="back-btn"
                            onClick={() => redirect('/dashboard/tubclass/assignment')}
                            alt="alphatub"
                        />
                        <p className="global_color privacy-content ml-3 cursor-pointer" onClick={() => redirect('/dashboard/tubclass/assignment')}>Back To Assignments</p>
                    </div>
                    <div className="col-3">
                        <p className="alphatub-card-h3 text-right">Today: {moment().format('DD MMM YYYY')}</p>
                    </div>
                </div>
                <div className="d-flex justify-space-between align-items-center assignment_track_header">
                    <div>
                        <h3 className="alp_text_4">Assignments List</h3>
                    </div>
                    <div >
                        <FormInput
                            type="select"
                            name="filter"
                            groupclassName="m-0"
                            className="assignment_track_filter border-none"
                            options={filterOptions}
                            onChange={(...params) => onChangeFilter(...params)}
                            value={filter}
                            placeholder='Select Filter'
                            disabled={false}
                        />
                    </div>
                </div>
                <div className="row no-gutters mt-2">
                    <div className="col-12">
                        <CustomBootstrapTable
                            rows={props.curriculum.trackAllAsignments}
                            columns={getTableColumns()}
                            isLoading={false}
                            keyField="id"
                            tableClass="assignment_track_table"
                            tableMainClass="assignment_track_table"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ tubGallery, myProfile, loader, session, curriculum, dataToPersist }) => {
    return {
        tubGallery,
        myProfile,
        loader,
        session,
        curriculum,
        dataToPersist
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        curriculumAction: bindActionCreators(curriculumAction, dispatch),
        sessionAction: bindActionCreators(sessionAction, dispatch),
        dispatch
    }
}

const TrackAssignment = Wrapper(connect(mapStateToProps, mapDispatchToProps)(TrackAssignmentFunction))

export { TrackAssignment }
