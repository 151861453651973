import { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export const HideOrUnhideContent = ({ createSession, hideImages }) => {
  const [isPopupOpen, setPopup] = useState(false);
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle>
          <BiDotsVerticalRounded size={20} color={"#949494"} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => createSession()}>Add</DropdownItem>
          <DropdownItem onClick={() => hideImages()}>Hide</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export const UnhideContent = ({ unHideImages }) => {
  const [isPopupOpen, setPopup] = useState(false);
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle>
          <BiDotsVerticalRounded size={20} color={"#949494"} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => unHideImages()}>Unhide</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export const HideContent = ({ hideImages }) => {
  const [isPopupOpen, setPopup] = useState(false);
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle>
          <BiDotsVerticalRounded size={20} color={"#949494"} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => hideImages()}>Hide</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};
