import moment from 'moment'
import { main } from '../service/actionType'

const initialState = {
    curriculumList: [],
    classProgramsList: [],
    galleryList: [],
    galleryListCopy: [],
    readingProgramList: [],
    curriculumAndProgramNames: [],
    assignmentDropdownList: [],
    changeAssignmentDropdowns: [],
    assignedData : {
        _id: null
    },
    assignmentCalendarData : {
        sessionDisableDatesList: [], 
        unAssignmentDates: [],
        assignmentDates: [],
        academicYear: null
    },
    getAssignmentQuery: {
        classId: sessionStorage.getItem('selectedClass'),
        schoolId: sessionStorage.getItem('schoolId'),
        date: moment(new Date()).startOf('day').format('YYYY-MM-DDT[00:00:00.000Z]')
    },
    assignmentTableList: [],
    trackAllAsignments: [],
    copyOfTrackAllAsignments: []
}

const curriculum =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.TRACK_ASSIGNMENT_TABLE_LIST: 
            return {
                ...state,
                trackAllAsignments: payload,
            }
        case main.COPYOF_TRACK_ASSIGNMENT_TABLE_LIST: 
            return {
                ...state,
                copyOfTrackAllAsignments: payload
            }
        case main.ASSIGNMENT_TABLE_LIST : 
            return {
                ...state,
                assignmentTableList: payload
            }
        case main.CLASS_PROGRAMS_LIST:
            return {
                ...state,
                classProgramsList: payload
            }
        case main.CLASS_CURRICULUM_LIST:
            return {
                ...state,
                classProgramsList: payload
            }
        case main.ASSIGNMENT_GALLERY_LIST:
            return {
                ...state,
                galleryList: payload,
                galleryListCopy: payload
            }
        case main.UPDATE_ASSIGNMENT_GALLERY_LIST:
            return {
                ...state,
                galleryList: payload
            }
        case main.READING_PROGRAM_LISTS:
            return {
                ...state,
                readingProgramList: payload
            }
        case main.CURRICULUM_AND_READING_PROGRAM: 
            return {
                ...state,
                curriculumAndProgramNames: payload
            }
        case main.ASSIGNMENTS_DROPDOWN_LIST: 
            return {
                ...state,
                assignmentDropdownList: payload
            }
        case main.CHANGE_ASSIGNMENTS_DROPDOWN_LIST: 
            return {
                ...state,
                changeAssignmentDropdowns: payload
            }
        case main.ASSIGNED_LIST: 
            return {
                ...state,
                assignedData: {
                    // ...state.assignedData,
                    ...payload
                }
            } 
        case main.CREATE_DEFAULT_ASSIGNED_LIST: 
            return {
                ...state,
                assignedData: null
            } 
        case main.CREATE_ASSIGNED_LIST: 
            return {
                ...state,
                assignedData: {
                    ...state.assignedData,
                    ...payload
                }
            } 
        case main.ASSIGNMENT_QUERY:
            return {
                ...state,
                getAssignmentQuery : {
                    ...state.getAssignmentQuery,
                    ...payload
                }
            }
        case main.ASSIGNMENT_CALENDAR_DATES_LIST: 
            return {
                ...state,
                assignmentCalendarData: {
                    ...state.assignmentCalendarData,
                    ...payload
                }
            }
        default: 
            return state
    }
}

export default curriculum;