import React, { Component } from 'react'
import { Container,Row,Col } from 'reactstrap'
import * as sessionAction from '../actions/session'
import * as ProfileActions from '../actions/myProfile'
import * as curriculumAction from '../actions/curriculum';
import { connect } from 'react-redux'
import { history } from '../helpers'
import { bindActionCreators } from 'redux'
import AuthLoader from '../components/loader/authloader'
import ClassPresentationHeader from '../components/Header/classPresentationHeader'

class ClassPresentationLayoutClass extends Component {
    state = {
        showDetails:true 
    }

    async componentDidMount() {
        let username = localStorage.getItem('username');
        let role = localStorage.getItem('role');
        
        // if(!username && !role) {
        //     history.push({pathname: "/"});
        // }
        this.props.sessionAction.checkLauchTubEnabled()

    }
    getClassName = () => {
        let loc = window.location.pathname
        if(loc == '/class/presentation/attendance' || loc == '/class/presentation/collage'){
            return 'align-self-start'
        } else return ''
    }
    render() {
        let { children, loader } = this.props
        return (
            <>
            <div className='dashboard_layout'>
                <div className={`p-0 overflow-hidden clp-bg min-h-100vh`}>
                    <ClassPresentationHeader {...this.props}/>
                    <Row className="flex-nowrap min-h-100vh align-items-center">
                        <Col auto className={this.getClassName()}>
                            { children }
                        </Col>
                    </Row>
                </div>
            </div>
            </>
        )
    }
}

let mapStateToProps = ({myProfile,loader, tubGallery, dataToPersist}) => {
    return {
        myProfile,
        tubGallery,
        loader,
        dataToPersist
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        ProfileActions: bindActionCreators(ProfileActions,dispatch),
        sessionAction: bindActionCreators(sessionAction,dispatch),
        curriculumAction: bindActionCreators(curriculumAction, dispatch)
    }

}

let ClassPresentationLayout = connect(mapStateToProps,mapDispatchToProps)(ClassPresentationLayoutClass)

export { ClassPresentationLayout }
