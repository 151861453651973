import Routes from './routes/index';
import { Provider } from "react-redux";
import { store } from "./service/store";
// import { NetworkDetector } from './components/internetDetector';
import { onMessageListener } from "./firebaseInit";
import Notifications from "./components/notification/Notifications";
import ReactNotificationComponent from "./components/notification/ReactNotification";
import React, { useState } from "react";
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './service/store'
function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  onMessageListener()
  .then((payload) => {
    setShow(true);
    setNotification({
      title: payload.notification.title,
      body: payload.notification.body,
    });
  })
  .catch((err) => console.log("failed: ", err));

  document.onkeydown = function(e) {
    if(e.key == 123) {
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.key == 'I'.charCodeAt(0)) {
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.key == 'C'.charCodeAt(0)) {
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.key == 'J'.charCodeAt(0)) {
       return false;
    }
    if(e.ctrlKey && e.key == 'U'.charCodeAt(0)) {
       return false;
    }
  }

  return (
    <>
      {show ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )}
      <Notifications />
      {/* <NetworkDetector /> */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
