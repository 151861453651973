import { main } from "../service/actionType";

const initialState = {};
const dataToPersist = (
  state = Object.assign({}, initialState),
  { type, payload }
) => {
  switch (type) {
    case main.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        cognitoUser: payload,
      };
    default:
      return state;
  }
};

export default dataToPersist;
