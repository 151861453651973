import { React, useEffect, useState } from "react";
import errorMsgs from "../../../../utils/errorMsgs";
import toast from "../../../../utils/Toast";
import * as curriculumAction from "../../../../actions/curriculum";
import { API } from "aws-amplify";
import { ENVIRONMENT } from "../../../../common/constants";
import { loaders, main } from "../../../../service/actionType";
import { CustomBootstrapTable } from "../../../../components/Table/CustomBootstrapTable";
import { ManageClassProgramColumns } from "../../../../helpers/tableDataMapper";
import { bindActionCreators } from "redux";
import { accessHeaders } from "../../../../helpers";
import { CommonDatePopup } from "../../../../components/tubClass/commonDatePopup";
import _ from "lodash";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import moment from "moment";

const ClassPrograms = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showDatePopup, triggerDatePopup] = useState(false);
  const [programData, setProgram] = useState(null);

  const getReadingPrItems = (rd) => {
    if (rd?.reading_Program_Hierarachy?.length > 0) {
      return (
        <div className="d-flex">
          <p className="curriculum_nm_f_1 fs-12">
            {rd?.reading_Program_Hierarachy}
          </p>
          <span className="curriculum_nm_f_2 fs-12 ml-1">({rd?.title})</span>
        </div>
      );
    } else {
      return <p></p>;
    }
  };

  const assignProgram = async (parsedDate) => {
    let payload = {
      reading_Program_Id: programData?._id,
      program_Choice: programData.data,
      assignment_Name: programData.title,
      program_Hierarachy: programData.reading_Program_Hierarachy,
      assignment_Number: 1,
      createdBy: programData.createdBy,
      createdBy: localStorage.getItem("username"),
      classId: props.selectedClass._id,
      schoolId: sessionStorage.getItem("schoolId"),
      start_Date: moment(parsedDate).format("YYYY-MM-DDT[00:00:00.000Z]"),
      teacher_Choice: programData.teacher_Choice,
    };
    try {
      let url = "assignment/v3.7/createUpdateAssignment";
      let myInit = {
        headers: accessHeaders(),
        body: payload,
      };
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        triggerDatePopup(false);
        await props.refreshProgramList();
        toast("Program assigned successfully", "success");
      } else {
        toast(response?.message, "error");
      }
    } catch (error) {
      console.log("error", error);
      toast("Alert! Content Already Assigned For This Date.", "error");
    }
  };

  const setReadingPr = (item) => {
    triggerDatePopup(true);
    setProgram(item);
  };
  const getClassName = () => {
    let classes = props.dataToPersist;
    if (!_.isEmpty(classes.selectedClass)) {
      return <p>{classes.selectedClass.label}</p>;
    } else return <p></p>;
  };

  return (
    <>
      <div className="row no-gutters mt-3">
        <div className="col-9 d-flex">
          <img
            src={
              require("../../../../assets/images/left_black_arrow.svg").default
            }
            className="back-btn"
            onClick={() => props.redirect(null, 1)}
            alt="alphatub"
          />
          <p
            className="privacy-content ml-3 cursor-pointer"
            onClick={() => props.redirect(null, 1)}
          >
            Back to Manage Class Programs
          </p>
        </div>
      </div>
      <div className="curriculum_reading_container">
        <div className="row no-gutters justify-space-between align-items-center">
          <div className="d-flex">
            <h1>
              {props?.selectedClass?.class_Name} (
              {props?.selectedClass?.section_Name})
            </h1>
            <p className="curriculum_nm_f_1 fs-15 pl-2">
              {!_.isEmpty(props.curriculum?.curriculumAndProgramNames)
                ? props.curriculum?.curriculumAndProgramNames
                    ?.reading_Program_Details?.program_Name
                : ""}
            </p>
          </div>
          <div>
            <h5>
              Total Words:{" "}
              {!_.isEmpty(props?.curriculum?.curriculumAndProgramNames)
                ? props?.curriculum?.curriculumAndProgramNames?.wordCount
                : 0}
            </h5>
          </div>
        </div>
        <div className="row">
          {props?.curriculum?.readingProgramList &&
            props?.curriculum?.readingProgramList?.readingProgramming?.map(
              (rd, i) => (
                <div className="col-12 mt-3" key={i}>
                  <div className="d-flex justify-space-between">
                    <div className="d-flex align-items-center">
                      {getReadingPrItems(rd)}
                      <p
                        className="ml-5 tb_f_1 fs-11 cursor-pointer"
                        onClick={() => setReadingPr(rd)}
                      >
                        {rd?.assigned != 0 ? `Assign again` : "Assign"}
                      </p>
                    </div>
                    <div>
                      {rd?.assigned > 0 ? (
                        <Dropdown
                          isOpen={i === activeIndex}
                          onMouseOver={() => setActiveIndex(i)}
                          onMouseLeave={() => setActiveIndex(null)}
                          className="curriculum_assigned_dropdown"
                          toggle={() => setActiveIndex(i)}
                        >
                          <DropdownToggle
                            className={"curriculum_nm_f_1 fs-11"}
                            onClick={() => setActiveIndex(i)}
                          >
                            {`Assigned ${rd.assigned}`}
                          </DropdownToggle>
                          <DropdownMenu className="">
                            {rd?.assigned_Dates?.map((op, i) => (
                              <DropdownItem className="">
                                {moment(op?.start_Date).format("DD MMM YYYY")}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      ) : (
                        <p className="curriculum_nm_f_1 fs-11">{""}</p>
                      )}
                    </div>
                  </div>
                  <div className="row column-gap-20 no-gutters mt-3">
                    {rd?.data?.map((ac) => (
                      <div className="assignment_content_container">
                        <div className="assignment_content_inner">
                          <img src={ac?.alphabetImage?.original} className="" />
                        </div>
                        <p>{ac?.imageTitle}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
        </div>
      </div>
      {/* Assign Assessment date popup */}
      <CommonDatePopup
        showDatePopup={showDatePopup}
        triggerPopup={triggerDatePopup}
        onClick={assignProgram}
        session={props.session}
        title={"Assign"}
      />
    </>
  );
};

export { ClassPrograms };
