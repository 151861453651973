module.exports = {
    TCW001: [
        "Hey Newcomer! \n Basic Information is required to enjoy alphaTUB",
        "Sorry Buddy,\n You can not leave these fields empty",
        "Oh Dear,\n You again missed some fields. Never mind, we will wait till you complete",
        "Hello Mate! \n You are doing the same mistake, still some fields information is missing"
    ],
    TCW002: [
        "Sorry Mate! \n This user is not known to the system.",
        "Oh Buddy, \n System did not recognise this email address.",
        "Hey Friend, \n Please enter a valid email adress.",
        "Hello Fellow, \n You are using a wrong email address. Are you forgetting something?"
    ],
    TCW006: [
        "Hello Friend! \n Seems you have entered incorrect credentials",
        "Oops, \n The credentials you entered did not match our records. Please  re-check and try again",
        "Sorry Friend, \n No account found with this credentials",
        "Hello Mate, \n You see \"to err is human\". Please enter a valid credentials to connect"
    ],
    TCW008: [
        "Hey TUB lover, \n New and Confirm Password must be same",
        "So Sorry, \n Your new password and confirm password do not match. Please  try again",
        "Oops, \n Your confirm password did not match new password again",
        "Dear Friend, \n The new password and confirm password you eneterd do not match. Please re-enter correctly"
    ],
    TCW031: [
        "Heads Up! \n You are using wrong pattern to enter your email. Please check it",
        "Hey Buddy, \n Email needs to be in this format. alphaTUB@alphaTUB.com",
        "Listen Friend, \n Use correct format while entering your email address",
        "Hey Mate, \n Email Id is not valid "
    ],
    TCW032: [
        "Heads Up! \n You are using wrong pattern to enter your password. Please reverify it",
        "Hey Buddy, \n Password needs to be minimum of 6 alpha numeric letters",
        "Listen Friend, \n Use correct format only while entering your password",
        "Hey Mate, \n This field accept alpha numeric data"
    ],
    TCW034: [
        "Hey Buddy, \n Captcha is required to accept to proceed further",
        "Sorry Buddy, \n You can not keep the captcha uncheck",
        "Oh Dear, \n You again missed to select captcha box. Never mind, we will wait till you complete",
        "Hello Mate! \n You are doing the same mistake, still captcha information is missing to accept"
    ],
    TCW016: [
        "Ooops, \n It seems you have used all licensed seats",
        "Hey Buddy, \n As per subscription plan, you do not have available seat to consume",
        "Listen Friend, \n Buy more licenses to give equal opportunity to all students in the classroom",
        "Hey Mate, \n Please, buy more license"
    ],
    TCW099: [
        "Oops, \n It seems you have entered more number of seats than the available seats."
    ],
    TCW037:[
        "Oops! \n Your access limit has been over. Due to consecutive failure attempts. Please retry the process after few minutes"
    ],
    TCW041:[
        "Oops! \n Your access limit has been over. Due to consecutive failure attempts. Please retry the Login process after few minutes"
    ],
    TCW100: [
        "Heads Up! \n You are using wrong pattern to enter your username. Please reverify it",
        "Hey Buddy, \n username needs to be minimum of 6 alpha numeric letters",
        "Listen Friend, \n Use correct format only while entering your username",
        "Hey Mate, \n This field accept alpha numeric data"
    ],
}