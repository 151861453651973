import React, { Component } from "react";
//import { Row, Col, Grid, Navbar } from "react-bootstrap";
import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import "../../assets/css/main.scss";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as galleryAction from "../../actions/tubgallery";
import * as analyticsAction from "../../actions/analytics";
import * as sessionAction from "../../actions/session";
import * as tubInboxAction from "../../actions/tubInbox";
import * as profileAction from "../../actions/myProfile";
import * as curriculumAction from "../../actions/curriculum";
import noImage from "../../assets/images/noImage.png";
import moment from "moment";
import FormInput from "../inputs/FormInput";
import { API } from "aws-amplify";
import { ENVIRONMENT } from "../../common/constants";
import { AppLoader } from "../loader/appLoader";
import { main } from "../../service/actionType";
import { accessHeaders } from "../../helpers";

class AdminHeaderClass extends Component {
  defaultQueryPayload = {
    search: null,
    startDate: null,
    endDate: null,
    sort: null,
    tubObjectType: null,
    classId: null,
    schoolId: null,
    studentId: null,
    activityType: null,
    menuIsOpened: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      // selectedClass: null,
      init: true,
      isNotificationListLoading: false,
      notificationList: [],
    };
  }

  getSessionList = async () => {
    let dataToPersist = this.props.dataToPersist;
    let selectedClass = sessionStorage.getItem("selectedClass");
    let queryPayload = {
      schoolId: sessionStorage.getItem("schoolId"),
      classId: selectedClass
        ? selectedClass
        : dataToPersist.selectedClass?.value,
      date: moment(new Date()).startOf("day").format("YYYY-MM-DD"),
    };
    await this.props.sessionAction.getTodayAndUpcomingSessionList(queryPayload);
  };
  getTubInboxList = async (tubstatus) => {
    let dataToPersist = this.props.dataToPersist;
    let selectedClass = sessionStorage.getItem("selectedClass");
    let queryPayload = {
      SchoolID: sessionStorage.getItem("schoolId"),
      ClassID: selectedClass
        ? selectedClass
        : dataToPersist.selectedClass?.value,
      TubObjectStatus: tubstatus,
    };
    await this.props.tubInboxAction.getTubInboxList(queryPayload);
  };
  handleToggle = () => {
    this.setState({
      menuIsOpened: !this.state.menuIsOpened,
    });
  };
  fetchClassData = async (classDetail) => {
    let detail = {
      label: classDetail.label,
      value: classDetail.value,
    };
    await this.props.galleryAction.changeClassInRedx(detail);
    let path = this.props.location.pathname;
    let dataToPersist = this.props.dataToPersist;
    let selectedClass = sessionStorage.getItem("selectedClass");
    switch (path) {
      case "/dashboard/settings/subscription":
        await this.props.subscriptionAction.getRolesDropdownList();
        await this.props.subscriptionAction.getTeamTableData();
        break;
      case "/dashboard/tubclass/assignment":
        let query = {
          classId: classDetail.value,
          schoolId: sessionStorage.getItem("schoolId"),
        };
        await this.props.dispatch({
          type: main.ASSIGNMENT_QUERY,
          payload: query,
        });
        const assignmentDateQuery = {
          schoolId: sessionStorage.getItem("schoolId"),
          month: parseInt(moment(new Date()).format("M")),
          classId: selectedClass
            ? selectedClass
            : dataToPersist.selectedClass?.value,
        };
        await this.props.curriculumAction.getAssingmentCalendarDatesByMonth(
          assignmentDateQuery
        );

        await this.props.curriculumAction.getAssignedList(false);
        await this.props.curriculumAction.getCurriculumAndProgramName();
        break;
      case "/dashboard/tubclass/tubinbox/pending":
        await this.getTubInboxList("Pending");
        break;
      case "/dashboard/tubclass/tubinbox/approved":
        await this.getTubInboxList("Approved");
        break;
      case "/dashboard/tubclass/tubinbox/rejected":
        await this.getTubInboxList("Rejected");
        break;
      case "/dashboard/tubclass/tubinbox/deleted":
        await this.getTubInboxList("Deleted");
        break;
      case "/dashboard/tubclass/tubinbox/disabled":
        await this.getTubInboxList("Disabled");
        break;
      case "/dashboard/tubclass/tubgallery":
        let queryPayload = {
          schoolId: sessionStorage.getItem("schoolId"),
          month: parseInt(moment(new Date()).format("M")),
          classId: selectedClass
            ? selectedClass
            : dataToPersist.selectedClass?.value,
        };
        await this.props.sessionAction.getSessionDatesList(queryPayload);
        let galleryqueryPayload = {
          schoolId: sessionStorage.getItem("schoolId"),
          classId: selectedClass
            ? selectedClass
            : dataToPersist.selectedClass?.value,
        };
        this.props.curriculumAction.getAssignmentDropdownList();
        await this.props.galleryAction.getGalleryList(galleryqueryPayload);
        // await this.props.galleryAction.getTeacherGalleryList(
        //   galleryqueryPayload
        // );
        break;
      case "/dashboard/tubclass/readingprogram":
        await this.props.curriculumAction.getCurriculumAndProgramName();
        await this.props.curriculumAction.getReadingProgramsList();
        break;
      case "/dashboard/tubclass/makeassignment":
        await this.props.curriculumAction.getCurriculumAndProgramName();
        await this.props.curriculumAction.getMakeAssignmentList();
        break;
      case "/dashboard/tubclass/session":
        let sessionDatesqueryPayload = {
          schoolId: sessionStorage.getItem("schoolId"),
          month: parseInt(moment(new Date()).format("M")),
          classId: selectedClass
            ? selectedClass
            : dataToPersist.selectedClass?.value,
        };
        const sessionAssignedQp = {
          schoolId: sessionStorage.getItem("schoolId"),
          classId:
            selectedClass != null
              ? selectedClass
              : dataToPersist.selectedClass?.value,
          date: moment(new Date()).startOf("day").format("YYYY-MM-DD"),
          month: parseInt(moment(new Date()).format("M")),
        };
        await this.props.sessionAction.getSessionAssignedDatesList(
          sessionAssignedQp
        );
        await this.props.sessionAction.getSessionDatesList(
          sessionDatesqueryPayload
        );
        await this.props.sessionAction.getCreatedSessionList(
          moment(new Date()).startOf("day").format("YYYY-MM-DD")
        );
        await this.props.curriculumAction.getCurriculumAndProgramName();
        await this.props.curriculumAction.getReadingProgramsList();
        this.getSessionList();
        this.props.curriculumAction.getAssignmentDropdownList();

        break;
      case "/dashboard/analytics/overall":
        await this.props.analyticsAction.resetGraphs();
        this.props.analyticsAction.fetchGraphData(
          0,
          "curriculumProgress",
          "30",
          null,
          null
        );
        this.props.analyticsAction.fetchGraphData(
          1,
          "parentEngagementMeterForHomeSchoolOverall",
          "30",
          null,
          1
        );
        this.props.analyticsAction.fetchGraphData(
          2,
          "assinmentCompletion",
          "30",
          null,
          1
        );
        this.props.analyticsAction.fetchGraphData(
          3,
          "avgScoreByStudentForHomeSchoolOverall",
          "30",
          null,
          1
        );
        this.props.analyticsAction.fetchGraphData(
          4,
          "classLearningProgressForHomeSchoolOverall",
          "30",
          null,
          1
        );
        this.props.analyticsAction.fetchGraphData(
          5,
          "socialAwernesReportForHomeSchoolOverall",
          "30",
          null,
          1
        );
        this.props.analyticsAction.fetchGraphData(
          6,
          "alphbaticExposureForOverAll",
          "30",
          null,
          1
        );
        this.props.analyticsAction.fetchGraphData(
          7,
          "wordsQuizAccuracyForOverAll",
          "30",
          null,
          1
        );
        this.props.analyticsAction.fetchGraphData(
          8,
          "voacabluaryReportByStudent",
          "30",
          null,
          1
        );
        this.props.analyticsAction.fetchGraphData(
          9,
          "userWiseContributionByOverAll",
          "30",
          null,
          1
        );
        this.props.analyticsAction.fetchGraphData(
          10,
          "studentWiseParentPlayAndShareContent",
          "30",
          null,
          1
        );
        break;
      case "/dashboard/analytics/curriculum":
        await this.props.analyticsAction.resetGraphs();
        this.props.analyticsAction.fetchGraphData(
          0,
          "engagementMeterForHomeSchoolOverall",
          "7",
          null,
          2
        );
        this.props.analyticsAction.fetchGraphData(
          1,
          "learningProgressForHomeSchoolOverall",
          "7",
          null,
          2
        );
        this.props.analyticsAction.fetchGraphData(
          2,
          "topPerformersForHomeSchoolOverall",
          "7",
          null,
          2
        );
        this.props.analyticsAction.fetchGraphData(
          3,
          "activeWordsCountForHomeSchoolOverall",
          "7",
          null,
          2
        );
        this.props.analyticsAction.fetchGraphData(
          4,
          "activeTagsCountForHomeSchoolOverall",
          "7",
          null,
          2
        );
        this.props.analyticsAction.fetchGraphData(
          5,
          "recentActivePlayersForHomeSchoolOverall",
          "7",
          null,
          2
        );
        break;
      case "/dashboard/analytics/personalized":
        await this.props.analyticsAction.resetGraphs();
        this.props.analyticsAction.fetchGraphData(
          0,
          "engagementMeterForHomeSchoolOverall",
          "7",
          null,
          3
        );
        this.props.analyticsAction.fetchGraphData(
          1,
          "learningProgressForHomeSchoolOverall",
          "7",
          null,
          3
        );
        this.props.analyticsAction.fetchGraphData(
          2,
          "topPerformersForHomeSchoolOverall",
          "7",
          null,
          3
        );
        this.props.analyticsAction.fetchGraphData(
          3,
          "activeWordsCountForHomeSchoolOverall",
          "7",
          null,
          3
        );
        this.props.analyticsAction.fetchGraphData(
          4,
          "activeTagsCountForHomeSchoolOverall",
          "7",
          null,
          3
        );
        this.props.analyticsAction.fetchGraphData(
          5,
          "recentActivePlayersForHomeSchoolOverall",
          "7",
          null,
          3
        );
        break;
      case "/dashboard/analytics/studentreport":
        await this.props.galleryAction.getStudentsList();
        break;
      default:
        break;
    }
    this.props.galleryAction.getStudentsList();
  };
  changeClass = (name, value) => {
    let dataToPersist = this.props.dataToPersist;
    let getClass = dataToPersist.classDropdownList.find(
      (cl) => cl.value == value
    );
    this.fetchClassData(getClass);
    window.location.reload();
    this.props.sessionAction.checkLauchTubEnabled();
  };
  signout = async () => {
    try {
      await Auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  getNotifications = async () => {
    let url = "common/tubNotifications";
    this.setState({ isNotificationListLoading: true });
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        classId: sessionStorage.getItem("selectedClass"),
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      this.setState({
        notificationList: response.data,
        isNotificationListLoading: false,
      });
    }
  };
  goToInbox = () => {
    this.props.sessionAction.selectedTubClassTab(2);
    this.getTubInboxList("Pending");
  };

  render() {
    let { dataToPersist, session } = this.props;
    let { isNotificationListLoading, notificationList } = this.state;
    let profile = JSON.parse(sessionStorage.getItem("profileDetail"));
    return (
      <Navbar expand="lg" className="navbar">
        <Container fluid>
          <Navbar.Brand className="prodName align-items-center">
            <img
              src={require("../../assets/images/Alogo.png")}
              className="logo"
              alt="alphatub"
            ></img>
            <span className="adminlogo-text ml-20">
              TUB Class{" "}
            </span>
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Brand className="basic-navbar-nav">
            <Nav className="align-items-center nav-search">
              <div className="nav_class_dropdown">
                <div className="relative-pos">
                  <FormInput
                    type="select"
                    className={` ${
                      session.isSelectedActivityTab ? "" : "bg-white"
                    } subscriptionborder form-control border-0 text-left shadow-sm px-4 topbarsearchinput`}
                    name="classId"
                    groupclassName="m-0"
                    options={dataToPersist.classDropdownList}
                    showExtra={true}
                    onChange={(...params) => this.changeClass(...params)}
                    value={dataToPersist.selectedClass?.value}
                    defaultSelect={
                      dataToPersist.classDropdownList.length > 0 ? false : true
                    }
                    placeholder="Select Class"
                    disabled={session.isSelectedActivityTab}
                    // showDownArrow={true}
                  />
                  <div className="search-icon">
                    <img
                      src={require("../../assets/images/dropdown.svg")}
                      className="logo"
                      alt="alphatub"
                    ></img>
                  </div>
                </div>
              </div>
            </Nav>
            <Nav className="align-items-center navtopright-content">
              <NavDropdown
                className="notification_type_drop"
                title={
                  <div className="topright-inner position_relative">
                    <img
                      src={require("../../assets/images/navbell.svg").default}
                      className="notificatioin-icon"
                      alt="alphatub"
                    ></img>
                    {/* <div className="notification_count">1</div> */}
                  </div>
                }
                // open={this.state.menuIsOpened}
                onToggle={() => this.getNotifications()}
              >
                <div className="notification_type">
                  <div className="notification_header"></div>
                  <div className="notification_body">
                    {isNotificationListLoading ? (
                      <AppLoader />
                    ) : (
                      notificationList &&
                      notificationList.map((nt) => (
                        <div className="notification_inner">
                          {nt.newStatus && (
                            <div className="buttonType1">New</div>
                          )}
                          <div className="notification_content">
                            <img
                              src={
                                nt.imageUrl?.original !== ""
                                  ? nt.imageUrl?.original
                                  : noImage
                              }
                              className="studentProfileImg"
                              alt="alphatub"
                            />
                            <div className="notification_headers">
                              <p>{nt.desc[0]}</p>
                              <div className="notification_time">
                                <p>
                                  {moment(nt.createdAt).format("DD MMM YYYY")}
                                </p>
                              </div>
                              <div className="notification_content_border"></div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </NavDropdown>
            </Nav>
            <Nav>
              <div className="topright-inner">
                <img
                  src={
                    profile != null &&
                    profile.profilePic != undefined &&
                    profile.profilePic != null &&
                    profile.profilePic?.original != undefined &&
                    profile.profilePic?.original != "" &&
                    profile.profilePic?.original != null
                      ? profile.profilePic?.original
                      : require("../../assets/images/noImage.png")
                  }
                  className="studentProfileImg"
                  alt="alphatub"
                ></img>
              </div>
            </Nav>
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = ({ myProfile, loader, session, dataToPersist }) => {
  return {
    myProfile,
    loader,
    session,
    dataToPersist,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    galleryAction: bindActionCreators(galleryAction, dispatch),
    sessionAction: bindActionCreators(sessionAction, dispatch),
    tubInboxAction: bindActionCreators(tubInboxAction, dispatch),
    profileAction: bindActionCreators(profileAction, dispatch),
    analyticsAction: bindActionCreators(analyticsAction, dispatch),
    curriculumAction: bindActionCreators(curriculumAction, dispatch),
    dispatch,
  };
};

const AdminHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminHeaderClass);

export default AdminHeader;
