import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  isEmptyOrUndefine,
  isValidEmailAddress,
} from "../../../utils/common.utils";
import { connect } from "react-redux";
import * as auth from "../../../actions/auth";
import { bindActionCreators } from "redux";
import "../../../assets/css/main.scss";
import errorMsgs from "../../../utils/errorMsgs";
import toast from "../../../utils/Toast";
import Cookies from "universal-cookie";
import FormInput from "../../../components/inputs/FormInput";
import _ from "lodash";
import Header from "../../../components/Header/header";
import AuthBg from "../../../assets/images/loginpageimage.png";
import { history } from "../../../helpers/index";
import { main } from "../../../service/actionType";

class LoginClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicDetail: {
        username: null,
        password: null,
      },
      errors: {
        username: null,
        password: null,
      },
      usernameErrorMessage: "",
      passwordErrorMessage: "",
      emptyBtnClicks: 0,
      emailValidBtnClicks: 0,
      captchaBtnClicks: 0,
      invalidUser: 0,
      isPasswordVisible: false,
      disableButton: false,
      isSetRememberPass: false,
      captchaValue: null,
    };
    this.reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    let { basicDetail } = this.state;
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
    const cookies = new Cookies();
    let cookieDetail = cookies.get("dontcrackme");
    if (
      !_.isEmpty(cookieDetail?.password) &&
      !_.isEmpty(cookieDetail?.username)
    ) {
      basicDetail["username"] = cookieDetail.username;
      basicDetail["password"] = cookieDetail.password;
      this.setState({
        basicDetail,
      });
    }
    localStorage.clear();
    this.props.dispatch({ type: main.RESET_PERSIST });
  }
  onExpired = () => {
    // let value = this.reCaptchaRef.current.getValue()
    this.setState({ captchaValue: null });
  };
  handleChange = (name, value) => {
    let { basicDetail, errors } = this.state;
    basicDetail[name] = value;
    errors[name] = undefined;
    this.setState({ basicDetail: basicDetail, errors: errors });
  };
  checkRequiredFields = () => {
    let {
      basicDetail,
      emptyBtnClicks,
      emailValidBtnClicks,
      captchaBtnClicks,
      captchaValue,
    } = this.state;
    if (
      isEmptyOrUndefine(basicDetail["username"]) ||
      isEmptyOrUndefine(basicDetail["password"])
    ) {
      let newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
      this.setState(
        {
          emptyBtnClicks: newBtnClicks,
        },
        () => this.showToast(newBtnClicks, 1)
      );
      return false;
    } else if (!isValidEmailAddress(basicDetail["username"])) {
      let newBtnClicks =
        emailValidBtnClicks === 4 ? 1 : emailValidBtnClicks + 1;
      this.setState(
        {
          emailValidBtnClicks: newBtnClicks,
        },
        () => this.showToast(newBtnClicks, 2)
      );
      return false;
    } else if (captchaValue === null) {
      let newBtnClicks = captchaBtnClicks === 4 ? 1 : captchaBtnClicks + 1;
      this.setState(
        {
          captchaBtnClicks: newBtnClicks,
        },
        () => this.showToast(newBtnClicks, 3)
      );
      return false;
    }
    return true;
  };
  showToast = (btnClicks, type) => {
    let errMsg = "";
    if (type === 1) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW001[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW001[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW001[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW001[3];
          break;
        default:
          errMsg = errorMsgs.TCW001[0];
          break;
      }
    } else if (type === 2) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW031[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW031[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW031[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW031[3];
          break;
        default:
          errMsg = errorMsgs.TCW001[0];
          break;
      }
    } else if (type === 3) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW034[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW034[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW034[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW034[3];
          break;
        default:
          errMsg = errorMsgs.TCW001[0];
          break;
      }
    }
    toast(errMsg, "error");
  };
  authenticate = () => {
    let { basicDetail, invalidUser } = this.state;
    let payload = {
      username: basicDetail.username,
      password: basicDetail.password,
    };
    invalidUser = invalidUser > 5 ? 1 : invalidUser;
    this.props.authAction.login(payload, invalidUser);
  };

  onChange = () => {
    let value = this.reCaptchaRef.current.getValue();
    this.setState({ captchaValue: value });
  };

  authenticateUser = () => {
    let { invalidUser } = this.state;
    try {
      if (this.checkRequiredFields()) {
        let invalidUsers = invalidUser > 4 ? 1 : invalidUser + 1;
        this.setState({ invalidUser: invalidUsers }, () => this.authenticate());
      }
    } catch (error) {
      console.log("error signing in", error);
    }
  };

  handleSetCookie = () => {
    let { isSetRememberPass, basicDetail } = this.state;
    if (isSetRememberPass) {
      const cookies = new Cookies();
      if (basicDetail["password"] != null) {
        cookies.set(
          "dontcrackme",
          {
            username: basicDetail["username"],
            password: basicDetail["password"],
          },
          { path: "/" }
        );
      }
    }
  };
  togglePassword = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  };
  handleCheckbox = () => {
    this.setState(
      {
        isSetRememberPass: !this.state.isSetRememberPass,
      },
      this.handleSetCookie
    );
  };
  navigate = (route) => {
    history.push(route);
  };

  render() {
    let { isPasswordVisible, isSetRememberPass, basicDetail } = this.state;
    return (
      <>
        <Header />
        <div className="maincontainer auth-background">
          <div className="container-fluid">
            <div className="row no-gutter justify-content-center">
              <div className="col-md-7 d-none d-lg-flex auth-bg">
                <div className="">
                  <img src={AuthBg} className="auth-bgImage" alt="alphatub" />
                </div>
              </div>
              <div className="col-md-5">
                <div className="h-100 d-flex align-items-center py-4">
                  <div className="col-lg-10 col-xl-8 mx-auto">
                    <h3 className="login-text-login">Login</h3>
                    <p className="text-muted mb-4 login-text-wlcm ">
                      Welcome to TUB class
                    </p>
                    <div className="mb-3">
                      <FormInput
                        id="username"
                        name="username"
                        label="Email ID"
                        type="email"
                        groupclassName="m-0"
                        labelClassName="mt-login"
                        value={basicDetail["username"]}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <div className="password-input">
                        <FormInput
                          name="password"
                          label="Enter Password"
                          className="m-0 pb-3"
                          labelClassName="mt-login"
                          value={basicDetail["password"]}
                          isPasswordVisible={isPasswordVisible}
                          togglePassword={this.togglePassword}
                          type="password"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className=" mb-3 text-align-left">
                      <input
                        type="checkbox"
                        id="check1"
                        name="isSetRememberPass"
                        className="remember-checkbox"
                        value={isSetRememberPass}
                        checked={isSetRememberPass}
                        onChange={() => this.handleCheckbox()}
                      />
                      <label
                        className="checkboxLabel cursor-pointer"
                        for="check1"
                      >
                        Remember Me
                      </label>
                    </div>
                    <div className="mb-3 mt-10 captcha_container">
                      <ReCAPTCHA
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        ref={this.reCaptchaRef}
                        onExpired={() => this.onExpired()}
                        onChange={() => this.onChange()}
                      />
                    </div>
                    <div className="proceed-button mtb-10">
                      <button
                        type="button"
                        className="w130-px btn btn-primary"
                        onClick={this.authenticateUser}
                      >
                        Proceed
                      </button>
                    </div>
                    <div className="mt-30">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6 alignself-flex-end">
                          <p
                            className="auth-toggle-link"
                            onClick={() => this.navigate("/forgotpassword")}
                          >
                            Forgot Password
                          </p>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6 text-right">
                          <h6 className="new_to">New to TUB Class?</h6>
                          <a
                            className="auth-toggle-link"
                            href="https://alphatub.com/offerings/tub-class/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Signup Instead
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    authAction: bindActionCreators(auth, dispatch),
    dispatch,
  };
};

export const Login = connect(null, mapDispatchToProps)(LoginClass);
