import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as oldUserAction from '../actions/oldUsers';
import * as myProfileAction from '../actions/myProfile';
import ButtonType3 from '../components/inputs/ButtonType3'
class SampleView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            disableBtn: false,
            isPrivacyPolicyAccepted: false,
            token: null
        }
    }
    componentDidMount() {
        this.props.profileAction.getProfileInformations()
        const token = this.props.match.params.id
        this.setState({
            token: token
        })
    }
    onChange = () => {
        this.setState({
            isPrivacyPolicyAccepted: !this.state.isPrivacyPolicyAccepted
        })
    }
    proceed = () => {
        let { token } = this.state
        this.props.oldUserAction.getUsersGalleryData(token)
        this.setState({
            disableBtn: true
        })
    }
    render() {
        let {tubGallery} = this.props
        let { disableBtn,isPrivacyPolicyAccepted }  = this.state
        return (
            <div className='container pt-3 pb-3'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="mt-2">
                            <h1 className="terms-heading">1. Migration Services</h1>
                            <p className="privacy-content mt-2">
                                1.1 These Terms and Conditions of Migration Services (“Migration Terms”), together with the alphaTUB Terms of Service for alphaTUB Mobile App 3.0 and alphaTUB Mobile 2.5 applicable to You (“alphaTUB Terms”) (collectively “Terms”) You previously agreed to when You downloaded the alphaTUB Mobile App 3.0, apply to the migration services provided by alphaTUB, the applicable alphaTUB entity (“alphaTUB” or “Us” or “Our” or “We”) - concerning all historical, personal and/or other data (“Migration Data”) that you (“You” or “Your”) provide to Us, which are currently hosted by a third party online cloud platform. Your Child Data will be migrated to Your existing account in alphaTUB Mobile App 3.5. Therefore, you are expected to exercise the option to migrate data from Mobile App 3.0 / 2.5 to Mobile App 3.5 constitutes use of the alphaTUB migration services provided in the link (Link…). In the event of inconsistencies/failure to exercise the option to migrate your data, alphaTUB does not take responsibility of loss of old data.
                            </p>
                            <p className='privacy-content mt-2'>
                                1.2 By using the Migration Services, You confirm that You acquainted yourself with and accepted these Migration Terms. Our Privacy Statement as set forth at Our website (https://alphatub.com) (“alphaTUB Privacy Statement”) shall apply to the processing of the Personal Data pursuant to the Migration Services.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">2. Overview of the Migration Services</h1>
                            <p className="privacy-content mt-2">
                                2.1 alphaTUB has built a migration utility which specialises in migration services, to provide the Migration Services on Your behalf. If You choose to use these Migration Services, You hereby authorize alphaTUB to migrate your data, by clicking on a link at alphaTUB.
                            </p>
                            <p className='privacy-content mt-2'>
                                2.2 If any amendment of the Migration Services is required by any applicable statutory or regulatory requirement or if any amendment desired by Us for technical and/or efficiency reasons does not materially affect the nature or quality of the Migration Services, alphaTUB has the right to have this amendment implemented.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">3. Our Responsibilities</h1>
                            <p className="privacy-content mt-2">
                                3.1 alphaTUB to take all reasonable endeavours to meet any processing or migration times, and failure to perform the Migration Services by such times will not give You the right to Migrate your data.
                            </p>
                            <p className='privacy-content mt-2'>
                                3.2 alphaTUB will take all reasonable endeavours to keep Your Migration Data confidential during the migration and any related support services provided to You.
                            </p>
                            <p className='privacy-content mt-2'>
                                3.3 alphaTUB shall comply with all applicable laws, statutes and regulations from time to time, including but not limited to anti-bribery laws.
                            </p>
                            <p className='privacy-content mt-2'>
                                3.4 alphaTUB requires that any employee and/or other third party associated with Us who is performing any part of the Migration Services does so only on the basis of a written contract which includes terms equivalent to those imposed by you on Us.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">4. Your Responsibilities</h1>
                            <p className="privacy-content mt-2">
                                4.1 Without prejudice to any of Your other responsibilities or obligations towards Us, the following will apply:
                            </p>
                            <div className='ml-4'>
                                <p className='privacy-content mt-2'>
                                    4.1.1 You are responsible for the timely response of Your Migration Data to alphaTUB.
                                </p>
                                <p className='privacy-content mt-2'>
                                    4.1.2 You are responsible for the content of Your Migration Data, such as but not limited to its legality, reliability, integrity, accuracy and quality;
                                </p>
                                <p className='privacy-content mt-2'>
                                    4.1.3 The Migration Data may contain personal data from You (‘Personal Data’). As regards any possible Personal Data which may be part of the Migration Data, alphaTUB will accept Your Migration Services instructions under the assumption that any third party (either a company or a private individual) will not object to the execution of our Migration Services, and You will indemnify alphaTUB against any privacy and/or any profanity related claims by third parties filed against alphaTUB based on the execution of Your Migration Services instructions.
                                </p>
                                <p className='privacy-content mt-2'>
                                    4.1.4 If applicable: There are no payment obligations to alphaTUB for the Migration Services when discharged.
                                </p>
                            </div>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">5. Data Protection</h1>
                            <p className="privacy-content mt-2">
                                5.1 All capitalized terms used in this Section, unless otherwise defined herein, have the meaning as defined in the relevant Data Protection Laws.
                            </p>
                            <p className='privacy-content mt-2'>
                                5.2 As regards any Personal Data which may be part of the Migration Data, once associated to the Migration Services, You are Data Controller of those Personal Data for Your own purposes and alphaTUB is Data Processor in its own right of those Personal Data for its own purposes, and You agree that the sole objective of the Migration Services is to migrate and subsequently place the Migration Data from alphaTUB 2.5/3.0 application in Your alphaTUB Mobile App 3.5 account. You will comply with the applicable obligations under the relevant Data Protections Laws. alphaTUB, the Processor will do likewise. All Personal Data will be processed in accordance with alphaTUB Privacy Statement.
                            </p>
                            <p className='privacy-content mt-2'>
                                5.3 As regards any Personal Data which may be part of the Migration Data, You confirm that You have the right:
                            </p>
                            <div className='ml-4'>
                                <p className='privacy-content mt-2'>
                                    5.3.1 to have any Personal Data migrated Your alphaTUB Mobile App 3.5 account.
                                </p>
                                <p className='privacy-content mt-2'>
                                    5.3.2 to disclose any Personal Data and any other portion of the Migration Data to alphaTUB, within the framework of the Migration Services, or in the event You require technical assistance from alphaTUB and
                                </p>
                                <p className='privacy-content mt-2'>
                                    5.3.3 to allow alphaTUB (and the subcontractors of the latter) to disclose any Personal Data to (i) any privacy law enforcement agency and (ii) any other party that has a right, according to applicable Data Protection Laws, to require the disclosure of its Personal Data.
                                </p>
                            </div>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">6. Disclaimer Of Warranties</h1>
                            <p className="privacy-content mt-2">
                                6.1 Your use of the migration services is entirely at your own risk. except as described in these terms, the migration services are provided "as is." to the maximum extent permitted by applicable law, alphatub, its affiliates, and its third party providers, licensors, distributors or suppliers (collectively,"suppliers") disclaim all warranties, express or implied, including any warranty that the services are fit for a particular purpose, title, merchantability, data loss, non- interference with or non-infringement of any intellectual property rights, or the accuracy, reliability, quality or content in or linked to the services. alphatub and its affiliates and suppliers do not warrant that the services are secure, free from bugs, viruses, interruption, errors, theft or destruction. if the exclusions for implied warranties do not apply to you, any implied warranties are limited to 60 days from the date of delivery of the services.
                            </p>
                            <p className='privacy-content mt-2'>
                                6.2 Alphatub, its affiliates and suppliers disclaim any representations or warranties that the rendering of the services to you, or the results therefrom will satisfy or ensure compliance with any legal obligations or laws or regulations.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">7. LIMITATION OF LIABILITY AND INDEMNITY</h1>
                            <p className="privacy-content mt-2">
                                7.1 To the maximum extent permitted by applicable law, the entire liability of alphatub, its affiliates and suppliers for all claims relating to this agreement shall be limited to the amount you paid for purchasing alphatub. you acknowledge that the allocation of risks in these terms reflects the absence of any compensation for the migration services. subject to applicable law, alphatub, its affiliates and suppliers are not liable for any of the following: (a) lost profits, lost savings, loss of reputation, loss of goodwill any other direct, indirect, special, incidental, punitive or consequential damages; (b) damages relating to failures of telecommunications, the internet, electronic communications, corruption, security, loss or theft of data, viruses, spyware, loss of business, revenue, profits or investment, or use of software or hardware that does not meet alphatub systems requirements. the above limitations apply even if alphatub and its affiliates and suppliers have been advised of the possibility of such damages. this agreement sets forth the entire liability of alphatub, its affiliates and your exclusive remedy with respect to the services and its use; all whether or not any damage claim are based on tort, warranty, contract or any other legal theory.
                            </p>
                            <p className='privacy-content mt-2'>
                                7.2 Any of Your claims must be submitted at alphaTUB within ninety (30) days of the date of the event giving rise to any such claim, and any lawsuit relative to such claim must be filed within TWO (2) months of the date You submitted Your claim at alphaTUB.
                            </p>
                            <p className='privacy-content mt-2'>
                                7.3 You agree to indemnify and hold alphaTUB and its Affiliates and Suppliers harmless from any and all direct and third party claims, liability and expenses, including reasonable attorneys' fees and costs, arising out of Your use of the Migration Services or breach of these Terms (collectively referred to as "Claims"). alphaTUB reserves the right, in its sole discretion and at its own expense, to assume the exclusive defence and control of any Claims. You agree to reasonably cooperate as requested by alphaTUB in the defence of any Claims.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">8. Intellectual property rights</h1>
                            <p className="privacy-content mt-2">
                                8.1 You will not own any intellectual property rights arising out of or in connection with the Migration Services, other than any possible intellectual property and/or other ownership rights regarding the content of the Migration Data itself.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">9. Entire Agreement / Severance</h1>
                            <p className="privacy-content mt-2">
                                9.1 These Terms constitute the entire agreement between You and alphaTUB regarding the Migration Services as well as any support by alphaTUB during Your efforts to have the Migration Services and any support by alphaTUB in the event that the Migration Services have not (yet) been finalised successfully, and therefore supersede all possible and/or existing previous oral and/or written agreements between You and alphaTUB regarding the Migration Services.
                            </p>
                            <p className="privacy-content mt-2">
                                9.2 You acknowledge that You have not relied on and shall have no rights or remedies in respect of any statements, promise, written or oral representations, understandings, assurance or warranty other than expressly set out in these Terms.
                            </p>
                            <p className="privacy-content mt-2">
                                9.3 If any part of these Terms is held by a court of competent jurisdiction to be unreasonable for any reason whatsoever, the validity of the remainder of these Terms will not be affected.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">10. Governing law and jurisdiction</h1>
                            <p className="privacy-content mt-2">
                                10.1 Our legal relationship with You is governed by INDIAN law, without regard to its conflicts of laws principles. We each irrevocably agree to submit all disputes arising out of, and in connection with, the Migration Services, these Terms and/or Our legal relationship with You to the exclusive jurisdiction of the INDIAN courts.
                            </p>
                            <p className="privacy-content mt-2">
                                10.2 We each irrevocably agree to waive any: (i) right to object (with respect to any proceedings) that such court does not have jurisdiction over (a) the substance of claims or disputes, or (b) a party, and (ii) claim that the proceedings have been brought in an inconvenient forum.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h1 className="terms-heading">11. Miscellaneous</h1>
                            <p className="privacy-content mt-2">
                                11.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any obligations pursuant to the Migration Services order which is caused by any act or event beyond Our reasonable control.
                            </p>
                            <p className="privacy-content mt-2">
                                11.2 Any variation of the provisions of these Terms will only apply if this has been confirmed in writing by You and alphaTUB.
                            </p>
                        </div>
                        <div className='mt-4'>
                            <input
                                type="checkbox"
                                id="check1"
                                name="privacy"
                                disabled={disableBtn}
                                className="remember-checkbox d-inline-block"
                                value={isPrivacyPolicyAccepted}
                                checked={isPrivacyPolicyAccepted}
                                onChange={() => this.onChange()}
                            />
                            <label className="fw-600 checkboxLabel cursor-pointer ml-3 d-inline" for="check1">I Agree to the Privacy Policy and Terms of service</label>
                        </div>
                        {isPrivacyPolicyAccepted &&
                            <div className="col-lg-3 col-4 mt-4 p-0">
                                <ButtonType3
                                    btnName="proceed"
                                    disabledClassName="buttonType3disabled"
                                    className='buttonType1 '
                                    disabled={disableBtn}
                                    onClick={() => this.proceed()}
                                />
                            </div>
                        }
                    </div>
                {/* {
                    tubGallery.oldUserGalleryData.length > 0 ?
                        tubGallery.oldUserGalleryData.map(item => <div className='old_gallery_image_container' onClick={() => this.handleImageClick(item)}>
                            <img src={item?.originalImage?.original} className='old_gallery_image' />
                            <h1 className='gallery_name_style'>{item.title}</h1>
                        </div>)
                        : <div>No Data available</div>
                } */}
                </div>
            </div>


        )
    }

}

const mapStateToProps = ({ myProfile, loader, tubGallery }) => {
    return {
        myProfile,
        loader,
        tubGallery
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        oldUserAction: bindActionCreators(oldUserAction, dispatch),
        profileAction: bindActionCreators(myProfileAction, dispatch),
        dispatch
    }
}

const Sample = connect(mapStateToProps, mapDispatchToProps)(SampleView)

export { Sample }



// export const alphatubUsernameValidCondition = (password) => {
//     // let isMatched = new RegExp("^[a-zA-Z0-9_]+$").test(password)
//     let isMatched = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*_).*$').test(password)
//     console.log("isMatched", isMatched)
//     if(isMatched && ( password.length >= 6 && password.length <= 30)){
//         let splittedPass = password.split('_')
//         if(splittedPass.length > 2) return false
//         else return true
//     } else return false
// }