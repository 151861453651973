import React, { useState } from 'react'

export const ShowMore = ({data, i}) => {
    const [ showMoreObject, setToggleShow] = useState({})

    const toggleShow = (index) => {
        if(showMoreObject.hasOwnProperty(index)){
            showMoreObject[index] = !showMoreObject[index]
        } else {
            showMoreObject[index] = true
        }
        setToggleShow({...showMoreObject})
    }
    return (
        <>
            <p className="f-14 tub-content-3">
            {
                ( showMoreObject[i] != undefined &&  showMoreObject[i] ) ?
                    data.TubObjectId?.tags && data.TubObjectId?.tags.map(tg => ' #' + tg)
                    :
                    data.TubObjectId?.tags && data.TubObjectId?.tags.slice(0,1).map(tg => ' #' + tg)
            }
            </p>
            {data.TubObjectId?.tags && data.TubObjectId?.tags.length > 2 ? <p className='show_more' onClick={() => toggleShow(i)}>{ ( showMoreObject[i] != undefined &&  showMoreObject[i] ) ? 'show less' : "show more"}</p> : ''}
        </>
    )
}