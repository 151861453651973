import { loaders } from '../service/actionType'

const initialState = {
    isAuthFetching : false,
    isAppFetching: false,
    isTubGalleryFetching: false,
    assignmentLoading: false,

    // session loaders
    createdSessionListCountLoading: false,
    sessionDatesLoading: false,
    sessionListLoading: false,
    sessionAssignedDatesFetching: false,
    
    // assignment loader 
    assignmentTableFetching: false,
    assignmentDatesFetching: false,
}

const loader =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case loaders.SESSION_ASSIGNED_DATES_FETCHING: 
            return {
                ...state,
                sessionAssignedDatesFetching: payload
            }
        case loaders.ASSIGNMENT_TABLE_LIST_FETCHING: 
            return {
                ...state,
                assignmentTableFetching: payload
            }
            break;
        case loaders.ASSIGNMENT_DATES_FETCHING: 
            return {
                ...state,
                assignmentDatesFetching: payload
            }
            break;
        case loaders.SESSION_LIST_FETCHING : 
            return {
                ...state,
                sessionListLoading: payload
            }
        case loaders.SESSION_DATES_FETCHING : 
            return {
                ...state,
                sessionDatesLoading: payload
            }
        case loaders.CREATED_SESSION_LIST_COUNT_FETCHING : 
            return {
                ...state,
                createdSessionListCountLoading: payload
            }
        case loaders.IS_ASSIGNMENT_LOADING: 
            return {
                ...state,
                assignmentLoading: payload
            }
        case loaders.IS_AUTH_FETCHING:
            return {
                ...state,
                isAuthFetching: payload
            }
        case loaders.IS_APP_FETCHING:
            return {
                ...state,
                isAppFetching: payload
            }
        case loaders.IS_TUB_GALLERY_FETCHING:
            return {
                ...state,
                isTubGalleryFetching: payload
            }
            default:
                return state
    }
}

export default loader;

