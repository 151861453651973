import React, { Component } from "react";
import { isEmptyOrUndefine, isValidEmailAddress, passwordMatchCondition, ConfirmPassword } from '../../../utils/common.utils';
import { Auth } from 'aws-amplify';
import "../../../assets/css/main.scss";
import { connect } from 'react-redux'
import errorMsgs from "../../../utils/errorMsgs";
import toast from "../../../utils/Toast";
import { loaders } from "../../../service/actionType";
import FormInput from "../../../components/inputs/FormInput";
import Header from "../../../components/Header/header";
import AuthBg from '../../../assets/images/loginpageimage.png';
import { history } from '../../../helpers/index'

class VerifyCodeClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyBtnClicks: 0,
      emailValidBtnClicks: 0,
      passwordCondBtnClicks: 0,
      comparePassBtnClicks: 0,
      username: '',
      vcode: null,
      verifyCode: false,
      password: '',
      confirmpassword:'',
      verificationCodeMessage: '',
      passwordErrorMessage: '',
      usernameErrorMessage: "",
      isConfirmPasswordVisible: false,
      isPasswordResetSuccessfull: false,
      isPasswordVisible: false,
      counter: 30
    };
    this.timer = setInterval(() => this.tick(), 1000);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let { username } = this.state;
    username = this.props.location.state;
    if (username) {
      this.setState({ username: username });
    }
    else{
      this.navigate("/login");
    }

  }
  tick() {
    const current = this.state.counter;
    if (current === 0) {
      this.transition();
    } else {
      this.setState({ counter: current - 1 }); 
    } 
  }

  transition() {
    clearInterval(this.timer);
  }
  showToast = (btnClicks, type) => {
    let errMsg = "";
    if (type === 1) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW001[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW001[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW001[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW001[3];
          break;
        default:
          errMsg = errorMsgs.TCW001[0];
          break;
      }
    } else if (type === 2) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW031[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW031[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW031[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW031[3];
          break;
        default:
          errMsg = errorMsgs.TCW031[0];
          break;
      }
    } else if (type === 3) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW032[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW032[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW032[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW032[3];
          break;
        default:
          errMsg = errorMsgs.TCW032[0];
          break;
      }
    } else if (type === 4) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW008[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW008[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW008[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW008[3];
          break;
        default:
          errMsg = errorMsgs.TCW008[0];
          break;
      }
    }
    toast(errMsg, "error")
  }
  checkRequiredFields = (type) => {
    let { emptyBtnClicks, emailValidBtnClicks, passwordCondBtnClicks, username, comparePassBtnClicks } = this.state
    if (type === 1) {
      if (isEmptyOrUndefine(username)) {
        let newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
        this.setState({
          emptyBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 1));

        return false;

      } else if (!isValidEmailAddress(username)) {
        let newBtnClicks = emailValidBtnClicks === 4 ? 1 : emailValidBtnClicks + 1;
        this.setState({
          emailValidBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 2));

        return false
      }
    } else if (type === 2) {
      if (isEmptyOrUndefine(this.state.vcode) || isEmptyOrUndefine(this.state.password)) {
        let newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
        this.setState({
          emptyBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 1));

        return false;
      } else if (!passwordMatchCondition(this.state.password)) {
        let newBtnClicks = passwordCondBtnClicks === 4 ? 1 : passwordCondBtnClicks + 1;
        this.setState({
          passwordCondBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 3));

        return false;
      } else if (!ConfirmPassword(this.state.password, this.state.confirmpassword)) {
        let newBtnClicks = comparePassBtnClicks === 4 ? 1 : comparePassBtnClicks + 1;
        this.setState({
          comparePassBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 4));

        return false;
      }
    }
    return true;
  }

  authenticateVerificationCode = async () => {
    const password = this.state.password;
    const verificationCode = this.state.vcode;
    if (this.checkRequiredFields(2)) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true })
      Auth.forgotPasswordSubmit(this.state.username, verificationCode, password)
        .then(data => {
          this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
          toast('Thank You! \n The Password has been updated successfully', "success");
          // this.setState({  verifyCode: false });
          this.navigate('/login');
        })
        .catch(err => {
          if (err && err.message) {
            if (err.message === 'Attempt limit exceeded, please try after some time.') {
              toast(errorMsgs.TCW037[0], "error");
              this.navigate('/login');
            }
            else if(err.message === 'Invalid verification code provided, please try again.'){
              toast('Invalid OTP, Please Try again!', 'error')
            } else {
              toast(err.message, "error");
            }
          }
          this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
        });
    }
  }
  togglePassword = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }
  toggleConfirmPassword = () => {
    this.setState({
      isConfirmPasswordVisible: !this.state.isConfirmPasswordVisible
    })
  }
  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }
  navigate = (route) => {
    history.push(route)
  }
  restartTimer = () => {
    this.timer = setInterval(() => this.tick(), 1000);
    this.setState({counter: 30})
  }
  sendVerificationCode = async () => {
    try {
      if (this.checkRequiredFields(1)) {
        let { username } = this.state
        this.restartTimer()
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true })
          Auth.forgotPassword(username)
            .then(data => {
              if (data) {
                this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
                toast('You will get an OTP in your email from alphaTUB to reset your password. \n Please check your inbox as well spam folder too.', 'success')
              }
            })
            .catch(err => {
              if (err && err.message) {
                if (err.message === 'Attempt limit exceeded, please try after some time.') {
                  toast(errorMsgs.TCW037[0], "error");
                } else { 
                  toast(err.message, "error")
                }
              }
              this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
              // this.setState({ verifyCode: false });
            });
      }
    } catch (error) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  }
  render() {
    let { isPasswordResetSuccessfull, isPasswordVisible, isConfirmPasswordVisible } = this.state
    return (
      <>
        <Header />
        <div className="maincontainer auth-background">
          <div className="container-fluid">
            <div className="row no-gutter justify-content-center">
              <div className="col-md-7 d-none d-lg-flex auth-bg">
                <div className="">
                  <img src={AuthBg} className="auth-bgImage" alt="alphatub"/>
                </div>
              </div>
              <div className="col-md-5">
                <div className="h-100 d-flex align-items-center py-4">
                  <div className="col-12">
                    {
                      isPasswordResetSuccessfull ?
                        <div className="col-lg-10 col-xl-8 mx-auto">
                          <>
                            <h3 className="login-text-login">Forgot Password</h3>
                            <div className="mt-4 mb-4">
                              <div className="success-div py-4 px-3">
                                <h4 className="headerSuccess text-center"> Thank you</h4>
                                <p className="bodySuccess text-center mt-3">
                                  The Password has been updated successfully.
                                </p>
                              </div>
                            </div>
                          </>
                        </div>
                        :
                        <>
                          <div className="col-lg-10 col-xl-10 mx-auto">
                            <>
                              <h3 className="login-text-login">Create a New Password</h3>
                              <p className="text-muted mb-4 light-text text-center">
                                Type and confirm a secure new password for your account.
                              </p>
                              <div className="row no-gutters center-content">
                                <div className="form-group mb-3 col-10">
                                  <FormInput
                                    name="vcode"
                                    type="number"
                                    label="Enter OTP"
                                    max={6}
                                    min={6}
                                    className="m-0"
                                    labelClassName="mt-login"
                                    value={this.state.vcode}
                                    onChange={this.handleChange}
                                  />
                                  {this.state.counter == 0 ?
                                    <p className="auth-toggle-link text-right" onClick={this.sendVerificationCode}>Resend OTP</p>
                                    :
                                    <p className="text-muted light-text text-right">Resent OTP in 00 : {this.state.counter}</p>
                                  }
                                </div>
                              </div>
                              <div className="row no-gutters center-content">
                                <div className="form-group mb-3 col-10">
                                  <FormInput
                                    name="password"
                                    label="New Password"
                                    className="m-0 pb-3"
                                    value={this.state.password}
                                    isPasswordVisible={isPasswordVisible}
                                    type="password"
                                    labelClassName="mt-login"
                                    togglePassword={this.togglePassword}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row no-gutters center-content">
                                <div className="form-group m-0 col-10">
                                  <FormInput
                                    name="confirmpassword"
                                    label="Confirm Password"
                                    className="m-0 pb-1"
                                    value={this.state.confirmpassword}
                                    isPasswordVisible={isConfirmPasswordVisible}
                                    type="password"
                                    labelClassName="mt-login"
                                    togglePassword={this.toggleConfirmPassword}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row no-gutters center-content">
                                <div className="col-10">
                                  <p className='passwordPolicyContent'>Please create a password with minimum of 6 alpha numeric characters.Which will contains atleast one capital letter, one small letter, one special symbol and one numeric digit</p>
                                </div>
                              </div>
                              <div className="form-group proceed-button mtb-30">
                                <button type="button" className="w130-px btn btn-primary" onClick={this.authenticateVerificationCode}>Proceed</button>
                              </div>
                            </>
                          </div>
                          <div className="col-lg-10 col-xl-8 mx-auto">
                              <div className="form-group mt-20">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 col-6 alignself-flex-end">
                                    <p className="auth-toggle-link" onClick={() => this.navigate("/login")}>Login</p>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-6 text-right">
                                    <h6 className="new_to">New to TUB Class?</h6>
                                    <a className="auth-toggle-link" href="https://alphatub.com/offerings/tub-class/" target="_blank" rel="noopener noreferrer">Signup Instead</a></div>
                                </div>
                              </div>
                            </div>
                        </>
                    }
                    {isPasswordResetSuccessfull ?
                      <div className="col-lg-10 col-xl-8 mx-auto">
                        <div className="form-group mt-20">
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6 alignself-flex-end">
                              <p className="auth-toggle-link" onClick={() => this.navigate("/login")}>Login</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      : <></>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const VerifyCode = connect(null, null)(VerifyCodeClass)