import { API } from 'aws-amplify';
import { loaders, main } from '../service/actionType';
import axios from 'axios';
import { ENVIRONMENT } from '../common/constants';
import JSZip from 'jszip'
import { saveAs } from 'file-saver';
import { Buffer } from 'buffer';

window.Buffer = Buffer;

export const getUsersGalleryData = (token) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true });
        let url = "common/galleryData";
        let myInit = {
            // headers: {
            //     Authorization: ''
            // },
            queryStringParameters: {
                username: token
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if (response.status === 200) {
            let filtered = response.data.map(dt => dt?.originalImage?.original)
            downloadAsZip(filtered)
            dispatch({ type: main.OLD_USER_GALLERY_DATA, payload: response.data })
        } else {
            dispatch({ type: main.OLD_USER_GALLERY_DATA, payload: [] })
        }
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })

    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
    }
}


function downloadAsZip(images) {
    // console.log("images", images)
    var urls = images
    var zip = new JSZip();
    var count = 0;
    var count2 = 0;
    var zipFilename = "zipFilename.zip";
    var nameFromURL = [];

    var the_arr = "";
    for (var i = 0; i< urls.length; i++){
        the_arr = urls[i].split('/');
        nameFromURL[i] = the_arr.pop();

    }

    urls.forEach(async function(url){
        var filename = nameFromURL[count2];
        count2++;
        // loading a file and add it in a zip file
        let binary = await getBinaryContent(url)
            
        zip.file(filename, binary, {binary:true});
        count++;
        if (count === urls.length) {
            zip.generateAsync({type:'blob'}).then(function(content) {
                saveAs(content, zipFilename);
            });
        }
    });
}


async function getBinaryContent(url){
    const response = await axios.get(url,  { responseType: 'arraybuffer' })
    return  Buffer.from(response.data, "utf-8")
}