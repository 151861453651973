import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai"

export const ArrowRight = ({ next,classname }) => {
    return (
        <div className={`${classname} clp_right_arrow_notch `} onClick={() => next()}>
            <AiFillCaretRight
                size={20}
                style={{height: '30px'}}
                color={'#FFFFFF'}
            />
        </div>
    )
}


export const ArrowLeft = ({ prev, classname }) => {
    return (
        <div className={`${classname} clp_left_arrow_notch`} onClick={() => prev()}>
            <AiFillCaretLeft
                size={20}
                color={'#FFFFFF'}
            />
        </div>
    )
}