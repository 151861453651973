import { loaders, main } from '../service/actionType';
import { API } from 'aws-amplify';
import toast from '../helpers/Toast';
import { ENVIRONMENT } from '../common/constants';
import { accessHeaders } from '../helpers';

export const getTeamTableData = () => async (dispatch, getState) => {
    try {
        let url = "team";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                SchoolID: sessionStorage.getItem('schoolId'),
                userName: localStorage.getItem("username")
            },
            
        }
        const response = await API.get(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            dispatch({ type: main.GET_SUBSCRIPTION_TEAM_DATA, payload: response.data })
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const getSubscriptionData = () => async (dispatch, getState) => {
    try {
        let url = "subscriptions/getSubscriptions";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if (response.status === 200 && response.data) {
            dispatch({ type: main.SUBSCRIPTION_INFO, payload: response.data })
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const getInvoicesList = () => async (dispatch, getState) => {
    try {
        let url = "v1/payment/history";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                school_id: sessionStorage.getItem('schoolId')
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if (response.status === 200 && response.data) {
            dispatch({ type: main.INVOICES_LIST, payload: response.data })
        } else {
            dispatch({ type: main.INVOICES_LIST, payload: [] })
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getRolesDropdownList = () => async (dispatch, getState) => {
    try {
        let url = "role/dropdown/list";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        }
        dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        const response = await API.get(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            dispatch({ type: main.ROLES_DROPDOWN_LIST, payload: response.data.rolesList })
            dispatch({ type: main.TABLEROLES_DROPDOWN_LIST, payload: response.data.tableRolesDropdown })
            dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
        }

    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}

export const sendInviteMail = (payload) => async (dispatch, getState) => {
    try {
        let url = "team/create";
        const myInit = {
            body: payload,
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            await getTeamTableData()(dispatch, getState)
            toast(response?.message, 'success');
            return true
        } else {
            toast("Heads Up! \n No team member is available, start creating your team", 'error')
        }
        // dispatch({ type : main.GET_SUBSCRIPTION_TEAM_DATA,payload:response })
    } catch (error) {
        toast(error?.response?.data?.message || error.message, 'error');
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}

export const resentMail = (payload) => async (dispatch, getState) => {
    try {
        let url = "team/resend/mail";
        const myInit = {
            body: payload,
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            await getTeamTableData()(dispatch, getState)
            toast(response?.message, 'success');
            return true
        } 
        // dispatch({ type : main.GET_SUBSCRIPTION_TEAM_DATA,payload:response })
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}

export const editTeamDetail = (payload) => async (dispatch, getState) => {
    try {
        let url = "team/edit";
        const myInit = {
            body: payload,
            headers: accessHeaders()
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            await getTeamTableData()(dispatch, getState)
            return true
        }
        // dispatch({ type : main.GET_SUBSCRIPTION_TEAM_DATA,payload:response })
    } catch (error) {
        console.log("error", error);
    }

}

export const acceptInvitation = (email) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let url = `team/accept/invitation?email=${email}`;
        const response = await API.get(ENVIRONMENT, url)
        if (response.status === 200) {
            dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            return true
        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}


// get payment status

export const getPaymentStatus = () => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let url = `v1/payment/paymentStatus`;
        const myInit = {
            headers: accessHeaders()
        };
        const response = await API.get(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}