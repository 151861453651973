import { main } from '../service/actionType'

const initialState = {
    classDropdownList : {
        teacherList: [],
        traineeList: []
    },
    classTableList: [],
    manageSeatAllocationList: []
}
const classes =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.CLASS_DROPDOWN_LIST:
            return {
                ...state,
                classDropdownList: {
                    teacherList: payload.teacherList,
                    traineeList: payload.traineeList
                }
            }
        case main.CLASS_TABLE_LIST:
            return {
                ...state,
                classTableList: payload
            } 
        case main.TABLEROLES_DROPDOWN_LIST :
            return {
                ...state,
                tableRolesDropdown: payload,
            } 
        case main.MANAGE_SEAT_ALLOCATION_LIST:
            return {
                ...state,
                manageSeatAllocationList: payload,
            } 
        default:
            return state
    }

}

export default classes;
