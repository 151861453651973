import { main } from '../service/actionType'

const initialState = {
    profileDetails: {
        SchoolName: null,
        First_name: '',
        Last_name: '',
        RoleID: '',
        isObjectUrlImage: false,
        Profile_img: {original : ''},
        Middle_name: '',
        AlternativeMobile: null,
        DOB: null,
        Qualification: null,
        Gender: null,
        TeacherID: null,
        Mobile: null,
        Country: null,
        District: null,
        State: null,
        Alt_Tel_Country_Code: null,
        Tel_Country_Code: null,
        classList: []
    },
    citiesList: [],
    statesList: [],
    countryList: []
}
const myProfile =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.UPDATE_PRIVACY_POLICY_ACCEPTED:
            return {
                ...state,
                profileDetails: {
                    ...state.profileDetails,
                    User: {
                        ...state.profileDetails.User,
                        isPrivacyPolicyAccepted: true
                    }
                }
            }
        case main.SAVE_PROFILE:
            return {
                ...state,
                profileDetails: payload
            }
        case main.UPDATE_PROFILE_PIC:
            return {
                ...state,
                profileDetails: {
                    ...state.profileDetails,
                    User: {
                        ...state.profileDetails.User,
                        Profile_img: payload.Profile_img,
                        First_name: payload.First_name
                    }
                }
            }
        case main.PROFILE_INFO:
            return {
                ...state,
                profileDetails: {
                    ...state.profileDetails,
                    ...payload
                }
            }
        case main.COUNTRY_LIST:
            return {
                ...state,
                countryList: payload
            }
        case main.CITIES_LIST:
            return {
                ...state,
                citiesList: payload
            }
        case main.STATES_LIST:
            return {
                ...state,
                statesList: payload
            }
        default:
            return state
    }

}

export default myProfile