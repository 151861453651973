import React, { Component } from "react";
import { isEmptyOrUndefine, isValidEmailAddress, passwordMatchCondition } from '../../../utils/common.utils';
import { Auth, API } from 'aws-amplify';
import "../../../assets/css/main.scss";
import { connect } from 'react-redux'
import errorMsgs from "../../../utils/errorMsgs";
import toast from "../../../utils/Toast";
import { loaders } from "../../../service/actionType";
import FormInput from "../../../components/inputs/FormInput";
import Header from "../../../components/Header/header";
import AuthBg from '../../../assets/images/loginpageimage.png';
import { history } from '../../../helpers/index'
import { ENVIRONMENT } from '../../../common/constants';

class ForgotPasswordClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyBtnClicks: 0,
      emailValidBtnClicks: 0,
      passwordCondBtnClicks: 0,
      username: '',
      vcode: null,
      verifyCode: false,
      password: '',
      verificationCodeMessage: '',
      passwordErrorMessage: '',
      usernameErrorMessage: "",
      isPasswordResetSuccessfull: false,
      isPasswordVisible: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    
  }
       
  showToast = (btnClicks, type) => {
    let errMsg = "";
    if (type === 1) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW001[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW001[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW001[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW001[3];
          break;
        default:
          errMsg = errorMsgs.TCW001[0];
          break;
      }
    } else if (type === 2) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW031[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW031[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW031[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW031[3];
          break;
        default:
          errMsg = errorMsgs.TCW031[0];
          break;
      }
    } else if (type === 3) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW032[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW032[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW032[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW032[3];
          break;
        default:
          errMsg = errorMsgs.TCW032[0];
          break;
      }
    }
    toast(errMsg, "error")
  }
  checkRequiredFields = (type) => {
    let { emptyBtnClicks, emailValidBtnClicks, passwordCondBtnClicks, username } = this.state
    if (type === 1) {
      if (isEmptyOrUndefine(username)) {
        let newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
        this.setState({
          emptyBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 1));

        return false;

      } else if (!isValidEmailAddress(username)) {
        let newBtnClicks = emailValidBtnClicks === 4 ? 1 : emailValidBtnClicks + 1;
        this.setState({
          emailValidBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 2));

        return false
      }
    } else if (type === 2) {
      if (isEmptyOrUndefine(this.state.vcode) || isEmptyOrUndefine(this.state.password)) {
        let newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
        this.setState({
          emptyBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 1));

        return false;
      } else if (!passwordMatchCondition(this.state.password)) {
        let newBtnClicks = passwordCondBtnClicks === 4 ? 1 : passwordCondBtnClicks + 1;
        this.setState({
          passwordCondBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 3));

        return false;
      }
    }
    return true;
  }

  sendVerificationCode = async () => {
    try {
      if (this.checkRequiredFields(1)) {
        let { username } = this.state
        this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true })
          Auth.forgotPassword(username)
            .then(data => {
              if (data) {
                this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
                toast('You will get an OTP in your email from alphaTUB to reset your password. \n Please check your inbox as well spam folder too.', 'success')
                history.push({
                  pathname: '/verifycode',
                  state: username // your data array of objects
                })
              }
            })
            .catch(err => {
              if (err && err.message) {
                if (err.message === 'Attempt limit exceeded, please try after some time.') {
                  toast(errorMsgs.TCW037[0], "error");
                } else { 
                  toast(err.message, "error")
                }
              }
              this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
              this.setState({ verifyCode: false });
            });
      }
    } catch (error) {
      this.props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false });
    }
  }

  togglePassword = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }
  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }
  navigate = (route) => {
    history.push(route)
  }
  render() {
    let {  username } = this.state
    return (
      <>
        <Header />
        <div className="maincontainer auth-background">
          <div className="container-fluid">
            <div className="row no-gutter justify-content-center">
              <div className="col-md-7 d-none d-lg-flex auth-bg">
                <div className="">
                  <img src={AuthBg} className="auth-bgImage" alt="alphatub"/>
                </div>
              </div>
              <div className="col-md-5">
                <div className="h-100 d-flex align-items-center py-4">
                  <div className="col-12">
                    <div className="col-lg-10 col-xl-8 mx-auto">
                      <>
                        <h3 className="login-text-login">Forget Password</h3>
                        <p className="text-muted mb-4 login-text-wlcm">
                          Enter Your Email ID to Get Pasword Reset OTP
                        </p>
                        {/* <div className="row no-gutters"> */}
                        {/* <div className="col-10 mx-auto"> */}
                        <div className="form-group mb-3">
                          <h3 className="mt-login">Email ID</h3>
                          <FormInput
                            name="username"
                            value={username}
                            className="m-0"
                            type="email"
                            labelClassName="mt-login"
                            onChange={this.handleChange}
                          />
                          <span className="error-msg">{this.state.usernameErrorMessage}</span>
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                        <div className="form-group proceed-button mtb-30">
                          <button type="button" className="w130-px btn btn-primary" onClick={this.sendVerificationCode}>Proceed</button>
                        </div>
                      </>
                    </div>
                    <div className="col-lg-10 col-xl-8 mx-auto">
                      <div className="form-group mt-20">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-6 alignself-flex-end">
                            <p className="auth-toggle-link" onClick={() => this.navigate("/login")}>Login</p>
                          </div>
                          <div className="col-md-6 col-sm-6 col-6 text-right">
                            <h6 className="new_to">New to TUB Class?</h6>
                            <a className="auth-toggle-link" href="https://alphatub.com/offerings/tub-class/" target="_blank" rel="noopener noreferrer">Signup Instead</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const ForgotPassword = connect(null, null)(ForgotPasswordClass)