import { React, Component } from "react";
import { connect } from "react-redux";
import * as gameAction from "../../../../../../actions/game";
import { bindActionCreators } from "redux";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GamePlayers } from "../gamePlayersList";
import { CreateModel } from "../../../../../../components/Model/createModel";
import ButtonType1 from "../../../../../../components/inputs/ButtonType1";
import { history } from "../../../../../../helpers";
import { main } from "../../../../../../service/actionType";

import ClassPresentationWrapper from "../../../../../../Hoc/wrapper";
import "./etub.css"; // Import your CSS file
import tubIcon from "../../../../../../assets/images/tubIcon.png";
import Alatter from "../../../../../../assets/images/firstlayer_latters/Alatter.png";
import Blatter from "../../../../../../assets/images/firstlayer_latters/blatter.png";
import clatter from "../../../../../../assets/images/firstlayer_latters/clatter.png";
import dlatter from "../../../../../../assets/images/firstlayer_latters/dlatter.png";
import elatter from "../../../../../../assets/images/firstlayer_latters/elatter.png";
import Flatter from "../../../../../../assets/images/firstlayer_latters/Flatter.png";
import Glatter from "../../../../../../assets/images/firstlayer_latters/Glatter.png";
import Hlatter from "../../../../../../assets/images/firstlayer_latters/Hlatter.png";
import Ilatter from "../../../../../../assets/images/firstlayer_latters/Ilatter.png";
import Jlatter from "../../../../../../assets/images/firstlayer_latters/Jlatter.png";
import Llatter from "../../../../../../assets/images/firstlayer_latters/Llatter.png";
import Klatter from "../../../../../../assets/images/firstlayer_latters/Klatter.png";
import Mlatter from "../../../../../../assets/images/firstlayer_latters/Mlatter.png";
import Nlatter from "../../../../../../assets/images/firstlayer_latters/Nlatter.png";
import Olatter from "../../../../../../assets/images/firstlayer_latters/Olatter.png";
import Platter from "../../../../../../assets/images/firstlayer_latters/Platter.png";
import Qlatter from "../../../../../../assets/images/firstlayer_latters/Qlatter.png";
import Rlatter from "../../../../../../assets/images/firstlayer_latters/Rlatter.png";
import Slatter from "../../../../../../assets/images/firstlayer_latters/Slatter.png";
import Tlatter from "../../../../../../assets/images/firstlayer_latters/Tlatter.png";
import Ulatter from "../../../../../../assets/images/firstlayer_latters/Ulatter.png";
import Vlatter from "../../../../../../assets/images/firstlayer_latters/Vlatter.png";
import Wlatter from "../../../../../../assets/images/firstlayer_latters/Wlatter.png";
import Xlatter from "../../../../../../assets/images/firstlayer_latters/Xlatter.png";
import Ylatter from "../../../../../../assets/images/firstlayer_latters/Ylatter.png";
import Zlatter from "../../../../../../assets/images/firstlayer_latters/Zlatter.png";
import pinA from "../../../../../../assets/images/secoundlayer_latters/pinA.png";
import pinB from "../../../../../../assets/images/secoundlayer_latters/pinB.png";
import pinC from "../../../../../../assets/images/secoundlayer_latters/pinC.png";
import pinD from "../../../../../../assets/images/secoundlayer_latters/pinD.png";
import pinE from "../../../../../../assets/images/secoundlayer_latters/pinE.png";
import pinF from "../../../../../../assets/images/secoundlayer_latters/pinF.png";
import pinG from "../../../../../../assets/images/secoundlayer_latters/pinG.png";
import pinH from "../../../../../../assets/images/secoundlayer_latters/pinH.png";
import pinI from "../../../../../../assets/images/secoundlayer_latters/pinI.png";
import pinJ from "../../../../../../assets/images/secoundlayer_latters/pinJ.png";
import pinK from "../../../../../../assets/images/secoundlayer_latters/pinK.png";
import pinL from "../../../../../../assets/images/secoundlayer_latters/pinL.png";
import pinM from "../../../../../../assets/images/secoundlayer_latters/pinM.png";
import pinN from "../../../../../../assets/images/secoundlayer_latters/pinN.png";
import pinO from "../../../../../../assets/images/secoundlayer_latters/pinO.png";
import pinP from "../../../../../../assets/images/secoundlayer_latters/pinP.png";
import pinQ from "../../../../../../assets/images/secoundlayer_latters/pinQ.png";
import pinR from "../../../../../../assets/images/secoundlayer_latters/pinR.png";
import pinS from "../../../../../../assets/images/secoundlayer_latters/pinS.png";
import pinT from "../../../../../../assets/images/secoundlayer_latters/pinT.png";
import pinU from "../../../../../../assets/images/secoundlayer_latters/pinU.png";
import pinV from "../../../../../../assets/images/secoundlayer_latters/pinV.png";
import pinW from "../../../../../../assets/images/secoundlayer_latters/pinW.png";
import pinX from "../../../../../../assets/images/secoundlayer_latters/pinX.png";
import pinY from "../../../../../../assets/images/secoundlayer_latters/pinY.png";
import pinZ from "../../../../../../assets/images/secoundlayer_latters/pinZ.png";
import wronggif from "../../../../../../assets/images/giphy.gif";
import correctgif from "../../../../../../assets/images/correctgif.gif";
import imageA from "../../../../../../assets/images/thirdlayer_latters/imageA.png";
import imageB from "../../../../../../assets/images/thirdlayer_latters/imageB.png";
import imageC from "../../../../../../assets/images/thirdlayer_latters/imageC.png";
import imageD from "../../../../../../assets/images/thirdlayer_latters/imageD.png";
import imageE from "../../../../../../assets/images/thirdlayer_latters/imageE.png";
import imageF from "../../../../../../assets/images/thirdlayer_latters/imageF.png";
import imageG from "../../../../../../assets/images/thirdlayer_latters/imageG.png";
import imageH from "../../../../../../assets/images/thirdlayer_latters/imageH.png";
import imageI from "../../../../../../assets/images/thirdlayer_latters/imageI.png";
import imageJ from "../../../../../../assets/images/thirdlayer_latters/imageJ.png";
import imageK from "../../../../../../assets/images/thirdlayer_latters/imageK.png";
import imageL from "../../../../../../assets/images/thirdlayer_latters/imageL.png";
import imageM from "../../../../../../assets/images/thirdlayer_latters/imageM.png";
import imageN from "../../../../../../assets/images/thirdlayer_latters/imageN.png";
import imageO from "../../../../../../assets/images/thirdlayer_latters/imageO.png";
import imageP from "../../../../../../assets/images/thirdlayer_latters/imageP.png";
import imageQ from "../../../../../../assets/images/thirdlayer_latters/imageQ.png";
import imageR from "../../../../../../assets/images/thirdlayer_latters/imageR.png";
import imageS from "../../../../../../assets/images/thirdlayer_latters/imageS.png";
import imageT from "../../../../../../assets/images/thirdlayer_latters/imageT.png";
import imageU from "../../../../../../assets/images/thirdlayer_latters/imageU.png";
import imageV from "../../../../../../assets/images/thirdlayer_latters/imageV.png";
import imageW from "../../../../../../assets/images/thirdlayer_latters/imageW.png";
import imageX from "../../../../../../assets/images/thirdlayer_latters/imageX.png";
import imageY from "../../../../../../assets/images/thirdlayer_latters/imageY.png";
import imageZ from "../../../../../../assets/images/thirdlayer_latters/imageZ.png";
class ETubClass extends Component {
  constructor(props) {
    super(props);
    this.initialState = [
      {
        id: "A",
        Backimage: imageA,
        content: Alatter,
        isreview: false,
        pinImg: pinA,
        fillColor: "#ED1C24",
      },
      {
        id: "B",
        Backimage: imageB,
        content: Blatter,
        isreview: false,
        pinImg: pinB,
        fillColor: "#00AEEF",
      },
      {
        id: "C",
        Backimage: imageC,
        content: clatter,
        isreview: false,
        pinImg: pinC,
        fillColor: "#FFF200",
      },
      {
        id: "D",
        Backimage: imageD,
        content: dlatter,
        isreview: false,
        pinImg: pinD,
        fillColor: "#623896",
      },
      {
        id: "E",
        Backimage: imageE,
        content: elatter,
        isreview: false,
        pinImg: pinE,
        fillColor: "#F15A29",
      },
      {
        id: "F",
        Backimage: imageF,
        content: Flatter,
        isreview: false,
        pinImg: pinF,
        fillColor: "#00A651",
      },
      {
        id: "G",
        Backimage: imageG,
        content: Glatter,
        isreview: false,
        pinImg: pinG,
        fillColor: "#ED1C24",
      },
      {
        id: "H",
        Backimage: imageH,
        content: Hlatter,
        isreview: false,
        pinImg: pinH,
        fillColor: "#00AEEF",
      },
      {
        id: "I",
        Backimage: imageI,
        content: Ilatter,
        isreview: false,
        pinImg: pinI,
        fillColor: "#FFF200",
      },
      {
        id: "J",
        Backimage: imageJ,
        content: Jlatter,
        isreview: false,
        pinImg: pinJ,
        fillColor: "#623896",
      },

      {
        id: "K",
        Backimage: imageK,
        content: Klatter,
        isreview: false,
        pinImg: pinK,
        fillColor: "#F15A29",
      },
      {
        id: "L",
        Backimage: imageL,
        content: Llatter,
        isreview: false,
        pinImg: pinL,
        fillColor: "#00A651",
      },
      {
        id: "M",
        Backimage: imageM,
        content: Mlatter,
        isreview: false,
        pinImg: pinM,
        fillColor: "#ED1C24",
      },
      {
        id: "N",
        Backimage: imageN,
        content: Nlatter,
        isreview: false,
        pinImg: pinN,
        fillColor: "#00AEEF",
      },
      {
        id: "O",
        Backimage: imageO,
        content: Olatter,
        isreview: false,
        pinImg: pinO,
        fillColor: "#FFF200",
      },
      {
        id: "P",
        Backimage: imageP,
        content: Platter,
        isreview: false,
        pinImg: pinP,
        fillColor: "#623896",
      },

      {
        id: "Q",
        Backimage: imageQ,
        content: Qlatter,
        isreview: false,
        pinImg: pinQ,
        fillColor: "#F15A29",
      },
      {
        id: "R",
        Backimage: imageR,
        content: Rlatter,
        isreview: false,
        pinImg: pinR,
        fillColor: "#00A651",
      },
      {
        id: "S",
        Backimage: imageS,
        content: Slatter,
        isreview: false,
        pinImg: pinS,
        fillColor: "#ED1C24",
      },
      {
        id: "T",
        Backimage: imageT,
        content: Tlatter,
        isreview: false,
        pinImg: pinT,
        fillColor: "#00AEEF",
      },
      {
        id: "U",
        Backimage: imageU,
        content: Ulatter,
        isreview: false,
        pinImg: pinU,
        fillColor: "#FFF200",
      },
      {
        id: "V",
        Backimage: imageV,
        content: Vlatter,
        isreview: false,
        pinImg: pinV,
        fillColor: "#623896",
      },
      {
        id: "W",
        Backimage: imageW,
        content: Wlatter,
        isreview: false,
        pinImg: pinW,
        fillColor: "#F15A29",
      },
      {
        id: "X",
        Backimage: imageX,
        content: Xlatter,
        isreview: false,
        pinImg: pinX,
        fillColor: "#00A651",
      },
      {
        id: "Y",
        Backimage: imageY,
        content: Ylatter,
        isreview: false,
        pinImg: pinY,
        fillColor: "#ED1C24",
      },
      {
        id: "Z",
        Backimage: imageZ,
        content: Zlatter,
        isreview: false,
        pinImg: pinZ,
        fillColor: "#00AEEF",
      },
    ];
    this.state = {
      playersList: [],
      selectedItem: 0,
      init: true,
      items: [...this.initialState],
      playAgain: [],
      animation: false,
      dragId: null,
      isdragCorrect: "",
      reveal: false,
      review: false,
      reviewImage: "",
      randomItems: this.getRandomIndexs(),
    };
  }

  getRandomIndexs = () => {
    const getrandomItems = [];
    const set = new Set();
    let randomIndex = Math.floor((Math.random() * 1000) % 26);
    if (set.size === 26) {
      return;
    }
    while (set.size !== 26) {
      while (set.has(randomIndex)) {
        randomIndex = Math.floor((Math.random() * 1000) % 26);
      }
      set.add(randomIndex);
      getrandomItems.push(randomIndex);
    }
    return getrandomItems;
  };

  // selectedOption = async (obj, option) => {
  //   let { game } = this.props;
  //   let lenghtOfQuestions = game.gameTypeFourQuestions.length;
  //   await this.props.gameAction.updateType4SelectedOption(obj, option);
  //   let questionsIndex = game.gameTypeFourQuestions.findIndex(
  //     (op) => op.constantId === obj.constantId
  //   );
  //   if (lenghtOfQuestions === questionsIndex + 1) {
  //     this.storeGameResults();
  //   } else {
  //     setTimeout(() => {
  //       this.setState({
  //         selectedItem: this.state.selectedItem + 1,
  //       });
  //     }, 1500);
  //   }
  // };

  quitGame = () => {
    this.storeGameResults();
    this.props.dispatch({ type: main.IS_SELECTED_BACK_BTN, payload: false });
    history.push("/class/presentation/home");
    this.props.dispatch({
      type: main.E_TUB,
      payload: [],
    });
    this.props.dispatch({ type: main.PLAYERS_LIST, payload: [] });
    this.props.dispatch({ type: main.SELECTED_PLAYERS_LIST, payload: null });
  };
  closeGame = () => {
    this.props.dispatch({ type: main.IS_SELECTED_BACK_BTN, payload: false });
  };

  handleDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", "");
    e.dataTransfer.setData("application/json", JSON.stringify({ id }));
    this.setState({ dragId: id });
  };
  storeGameResults = async () => {
    await this.props.gameAction.storeGameEtubResults("gameTypeFourQuestions");
    await this.props.gameAction.storeGameModes("play");
    await this.props.gameAction.getType4GameOptions();
  };
  updateCurrentSlide = async (index) => {
    await this.props.gameAction.changeToNextQuestion(index);
  };
  getFunction = async (updatedItem) => {
    let selectedMode = sessionStorage.getItem("selectedMode");
    try {
      const updatedItems = await updatedItem?.map((item, i) =>
        item.id === this.state.dragId ? { ...item } : item
      );
      this.setState({ items: updatedItems });
      this.setState({ randomItems: this.getRandomIndexs() });
      console.log(updatedItems, "updatedItems");
      const randomIndex = Math.floor(Math.random() * 25);
      if (selectedMode == 2 || selectedMode == 3) {
        this.updateCurrentSlide(randomIndex);
      }

      if (this.state.isdragCorrect === "Correct") {
        setTimeout(() => {
          this.setState({ isdragCorrect: "" });
        }, 1000);
      } else {
        setTimeout(() => {
          this.setState({ isdragCorrect: "" });
        }, 1000);
      }
    } catch (error) {
      console.log(error, "error updating");
    }
  };

  handleDragOver = (e, index) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  handleDrop = async (e, index) => {
    e.preventDefault();

    const droppedItem = JSON.parse(e.dataTransfer.getData("application/json"));


      // this.selectedOption();



    if (droppedItem.id === this.state.items[index].id) {
      const updatedItems = this.state.items?.map((item, i) =>
        i === index ? { ...item, image: null, newImg: item.image } : item
      );
      updatedItems[index].isCorrectAnswer = true; 
    //  console.log('THIS.ST')
      this.getFunction(updatedItems);
      await this.props.gameAction.updateType4SelectedOption({ id: droppedItem.id }, true);      
      this.setState({ isdragCorrect: "Correct" });
    } else {
      const updatedItems = this.state.items?.map((item, i) =>
        i === index
          ? {
              ...item,
              // image: this.state.items.find((item) => item.id === droppedItem.id)
              //   .image,
              image: item?.image,
              // newImg: this.state.items.find(
              //   (item) => item.id === droppedItem.id
              // ).image,
            }
          : item
      );
      updatedItems[index].isCorrectAnswer = false; 
      await this.props.gameAction.updateType4SelectedOption({ id: droppedItem.id }, false);      

      this.getFunction(updatedItems).then(() =>
        this.setState({ isdragCorrect: "wrong" })
      );
    }
  };

  handleReveal = () => {
    this.setState({ animation: true });
    setTimeout(() => {
      this.setState({ reveal: true });
    }, 1000);
  };

  handlePlayAgain = () => {
    this.setState({
      reveal: false,
      animation: false,
      review: false,
      reviewImage: "",
      items: [...this.state.playAgain],
    });
    const previousMode = sessionStorage.getItem("previousMode");
    console.log(previousMode, "previousMode");
  };
  handleReview = () => {
    // let selectedMode = sessionStorage.getItem("selectedMode");
    this.setState({
      review: true,
    });
    // const randomIndex = Math.floor(Math.random() * 25);
    // if (selectedMode == 2 || selectedMode == 3) {
    //   this.updateCurrentSlide(randomIndex);
    // }
  };
  handleReviewShow = (data) => {
    // console.log(data, "data");
    // let selectedMode = sessionStorage.getItem("selectedMode");
    const updatedItems = this.state.items?.map((item, i) =>
      item.id === data.id ? { ...item, isreview: true } : item
    );
  //  this.selectedOption();
    console.log(data, "datadata");
    this.setState({ items: updatedItems });
    this.setState({
      reviewImage: data,
    });
    const isreviwed = this.areallImagesViwed();

    console.log(isreviwed, "isreviwed");
    if (isreviwed == true) {
      this.setState({
        review: false,
      });
    }
    // const randomIndex = Math.floor(Math.random() * 25);
    // if (selectedMode == 2 || selectedMode == 3) {
    //   this.updateCurrentSlide(randomIndex);
    // }
  };
  areallImagesViwed = () => {
    return this.state.items?.every((item) => item?.isreview === true);
  };
  areAllImagesNull = () => {
    return this.state.items?.every((item) => item?.image === null);
  };
  componentDidMount() {
    console.log('THIS STATE ITEMS -->>', this.props?.game);
    const updatedArray = this.state.items.map((item) => {
      const correspondingItem = this.props?.game?.gameTypeFourQuestions.find(
        (item2) => item2.alphabetName === item.id
      );
      if (correspondingItem) {
        return {
          ...item,
          image: correspondingItem?.alphabetImage?.original,
          questionId: correspondingItem?.constantId,
          isOptionSelected: correspondingItem?.isOptionSelected,
          imageTitle: correspondingItem?.imageTitle,
        };
      }
      return item;
    });
    this.setState({ items: updatedArray });
    this.setState({ playAgain: updatedArray });
  }

  render() {
    const {
      items,
      isdragCorrect,
      reveal,
      animation,
      randomItems,
      reviewImage,
      review,
    } = this.state;
    let { game } = this.props;
    let selectedMode = sessionStorage.getItem("selectedMode");
    // let getCurrentPlayer;
    // if (game.currentQuestion != null) {
    //   getCurrentPlayer =
    //     game.questionMappedWithRandomPlayers &&
    //     game.questionMappedWithRandomPlayers.find(
    //       (qp) => qp.questionId === game.currentQuestion.questionId
    //     );
    // }
    let getCurrentPlayer;

    if (game.currentQuestion != null && !getCurrentPlayer) {
      const shuffledPlayers = game.questionMappedWithRandomPlayers.slice().sort(() => Math.random() - 0.5);
          getCurrentPlayer = shuffledPlayers.find(
        (qp) => qp.questionId === game.currentQuestion.questionId
      );
    }

    return (
      <div style={{ marginTop: "0px" }}>
        <>
          <div className="mt-5 ">
            <div className="mb-5">
              <div className="etub row d-flex align-items-center px-5 ">
                <div className="col-7">
                  <div className="LettersBox">
                    <div className="letters position-relative ">
                      <div className="tubicon">
                        <img src={tubIcon} />

                        <div className="d-flex justify-content-between   tubprofile">
                          <p className="fs-12 ms-4 ">
                            <figure className="avtar mb-0 mr-1">
                              <img
                                src={
                                  game?.activityDetail?.studentDetail
                                    ?.profilePic?.original
                                }
                              />{" "}
                            </figure>
                            <span className="">
                              {game?.activityDetail?.studentDetail?.firstName}
                            </span>
                          </p>
                          <p className="fs-12 " style={{ maxWidth: "50px" }}>
                            <span style={{ display: "inline-block" }}>
                              {game?.activityDetail?.ObjectName}
                            </span>
                          </p>
                        </div>
                      </div>

                      {items?.map((item, index) => (
                        <div
                          key={item.id}
                          onDragOver={(e) => this.handleDragOver(e, index)}
                          onDrop={(e) => this.handleDrop(e, index)}
                          className={` position-relative overflow-hidden alphabet`}
                          style={{
                            fontFamily: "sans-serif",
                            // padding: "10px",
                            fontWeight: "bold",
                            color: item.newImg ? item.fillColor : "#BFBFBF",
                          }}
                        >
                          <img
                            style={{
                              animation:
                                animation &&
                                `animation 0.3s  linear forwards ${
                                  index * 0.2
                                }s`,
                            }}
                            loading="lazy"
                            draggable={false}
                            className="no-pointer-events"
                            src={item.newImg ? item.pinImg : item.content}
                            alt={item.id}
                          />
                          <img
                            style={{
                              height: "100px",
                              width: "unset",
                              animation:
                                animation &&
                                `animation-reverse 0.3s  linear forwards ${
                                  index * 0.2
                                }s`,
                              background: item.newImg
                                ? `url(${item.newImg}) center/cover`
                                : "none",
                            }}
                            draggable={false}
                            onClick={() =>
                              this.handleReviewShow(item)
                            }
                            loading="lazy"
                            className="bg-alphabet"
                            src={item.Backimage}
                            alt={item.id}
                            role="button"
                          />
                        </div>
                      ))}
                      <div className="">
                        {isdragCorrect === "wrong" && (
                          <div className="dragged-image position-absolute top-50 start-50 translate-middle">
                            <img
                              src={wronggif}
                              alt="my-gif"
                              loading="lazy"
                              draggable={false}
                            />
                          </div>
                        )}

                        {isdragCorrect === "Correct" && (
                          <div className="dragged-image position-absolute top-50 start-50 translate-middle">
                            <img
                              src={correctgif}
                              alt="my-gif"
                              loading="lazy"
                              draggable={false}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 d-flex justify-content-center align-items-center">
                  <div
                    className={this.areAllImagesNull() ? "d-none" : "drap_img"}
                  >
                    <div
                      className={
                        this.areAllImagesNull() ? "d-none" : "image-container"
                      }
                      role="button"
                    >
                      {randomItems?.map((index) => {
                        const item = items[index];
                        return (
                          item?.image && (
                            <img
                              role="button"
                              key={item.id}
                              loading="lazy"
                              className="dragged-image"
                              src={item?.image}
                              alt={item.content}
                              draggable
                              onDragStart={(e) =>
                                this.handleDragStart(e, item.id)
                              }
                            />
                          )
                        );
                      })}
                    </div>
                  </div>

                  {review == true && (
                    <div className="w-100">
                      <div className={"review_images"}>
                        <div className={"image-container02"} role="button">
                          {reviewImage.newImg ? (
                            <img
                              role="button"
                              loading="lazy"
                              className="dragged-image"
                              src={reviewImage?.newImg}
                              alt={reviewImage.imageTitle}
                            />
                          ) : (
                            <div className="d-flex justify-content-center align-items-center dragged-image">
                              <p className="text-white">
                                Tap any Letter on the left to start review
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      {reviewImage.newImg && (
                        <div className="d-flex justify-content-between imageTitle ">
                          <p className="text-center img_Name">
                            {reviewImage.imageTitle}
                          </p>
                          <p className="text-center img_Name_lowercase">
                            {reviewImage.imageTitle}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {this.areAllImagesNull() &&
                    (reveal === false ? (
                      review == true ? null : (
                        <div className="reveal_outline">
                          <div className="d-flex flex-column w-25 ml-3   ">
                            <button
                              onClick={this.handleReveal}
                              className="revealbutton mb-3 text-white custom-btn "
                            >
                              Reveal
                            </button>
                            <button
                              onClick={this.handleReview}
                              className="doneOrQuitebutton custom-btn"
                              disabled={this.areallImagesViwed()}
                            >
                              {this.areallImagesViwed() ? "Reviewed" : "Review"}
                            </button>
                          </div>
                        </div>
                      )
                    ) : review == true ? null : (
                      <div className="reveal_outline">
                        <div className="d-flex flex-column w-25 ml-3">
                          <button
                            className="revealbutton  text-white custom-btn mb-3"
                            onClick={this.handlePlayAgain}
                          >
                            Play again
                          </button>
                          <button
                            className="doneOrQuitebutton custom-btn  "
                            onClick={
                              this.areallImagesViwed()
                                ? this.quitGame
                                : this.handleReview
                            }
                          >
                            {this.areallImagesViwed() ? "Quit" : "Review"}
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <GamePlayers
            selectedMode={selectedMode}
            {...this.props}
            currentPlayer={getCurrentPlayer}
          />
          {game.isSelectedGameBackBtn && (
            <CreateModel
              isOpen={game.isSelectedGameBackBtn}
              toggle={() => this.closeGame()}
              title={""}
            >
              <div className="alphatub-card-border"></div>
              <div className="row pt-3 pb-5">
                <div className="col-12">
                  <p className="f-16px alphatub-card-normal-p">
                    Are you sure, you want to quit the game ?
                  </p>
                </div>
              </div>
              <div className="alphatub-card-border"></div>
              <div className="row align-items-center mt-3 content-end">
                <div className="col-4">
                  <ButtonType1
                    btnName="Close"
                    className="popup-close-btn"
                    onClick={() => this.closeGame()}
                  />
                </div>
                <div className="col-4">
                  <ButtonType1
                    btnName="Confirm"
                    className="bg-tub c-white buttonType1 "
                    onClick={() => this.quitGame()}
                  />
                </div>
              </div>
            </CreateModel>
          )}
        </>
      </div>
    );
  }
}
const mapStateToProps = ({ game }) => {
  return {
    game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gameAction: bindActionCreators(gameAction, dispatch),
    dispatch,
  };
};

export const ETub = ClassPresentationWrapper(
  connect(mapStateToProps, mapDispatchToProps)(ETubClass)
);
