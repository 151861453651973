import {  main } from '../service/actionType'

const initialState = {
    activityList: [],
    activityDetail: null,
    game: {
        gameType: [],
        gameMode: []
    },
    selectedGame: null,
    selectedMode: null,
    playersList: [],
    gameSettingsData: null,
    gameTypeOneQuestions: [],
    gameTypeThreeQuestions: [],
    gameTypeTwoQuestions: [],
    gameTypeFourQuestions: [],
    selectedPlayersList:null,
    questionMappedWithRandomPlayers : [],
    currentQuestion: null,
    currentGroupPlayGroupIndex : 0,
    selectedGameDetail: {
        type: null,
        object: null,
        gameName: null,
        gameMode: null,
        typeOfObject: null
    },
    isSelectedGameBackBtn: false,
    gameStartTime: null,
    collageStudentsList: [],
    attendanceList: [],
    attendanceDetail: null
}
const game =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.ATTENDANCE_LIST : {
            return {
                ...state,
                attendanceList: payload
            }
        }
        case main.ATTENDANCE_DETAIL : {
            return {
                ...state,
                attendanceDetail: payload
            }
        }
        case main.COLLAGE_STUDENTS_LIST: {
            return {
                ...state,
                collageStudentsList: payload
            }
        }
        case main.IS_SELECTED_BACK_BTN : {
            return {
                ...state,
                isSelectedGameBackBtn: payload
            }
        }
        case main.USER_SELECTED_GAME : {
            return {
                ...state,
                selectedGameDetail: {
                    ...state.selectedGameDetail,
                    ...payload
                }
            }
        }
        case main.CURRENT_GROUP_PLAY_GROUP:
            return {
                ...state,
                currentGroupPlayGroupIndex: payload
            }
        case main.CURRENT_GAME_QUESTION:
            return {
                ...state,
                currentQuestion: payload
            }
        case main.QUESTION_MAPPED_WITH_RANDOM_PLAYERS:
            return {
                ...state,
                questionMappedWithRandomPlayers: payload
            }
        case main.MATCH_THE_GOOD_WORD_QUESTIONS:
            return {
                ...state,
                gameTypeThreeQuestions: payload
            }
        case main.E_TUB: 
            return {
                ...state,
                gameTypeFourQuestions: payload,
            }
        case main.MISSING_THE_LETTER_QUESTIONS:
            return {
                ...state,
                gameTypeTwoQuestions: payload
            }
            
        case main.MATCH_THE_OBJECT_QUESTIONS:
            return {
                ...state,
                gameTypeOneQuestions: payload
            }
        case main.SELECTED_PLAYERS_LIST:
            return {
                ...state,
                selectedPlayersList: payload
            }
        case main.GAME_SETTINGS_DATA:
            return {
                ...state,
                gameSettingsData: payload
            }
        case main.PLAYERS_LIST:
            return {
                ...state,
                playersList: payload
            }
        case main.GAME_ACTIVITY_LIST:
            return {
                ...state,
                activityList: payload
            }
        case main.GAME_ACTIVITY_DETAIL:
            return {
                ...state,
                activityDetail: payload
            }
        case main.GAME_TYPES_AND_MODES:
            return {
                ...state,
                game: {
                    gameType: payload[0].Data,
                    gameMode: payload[1].Data
                }
            }
        case main.SELECTED_GAME:
            return {
                ...state,
                selectedGame: payload
            }
        case main.SELECTED_MODE:
            return {
                ...state,
                selectedMode: payload
            }
        case main.SET_GAME_START_TIME:
            return {
                ...state,
                gameStartTime: payload
            }
        default:
            return state
    }

}

export default game;
