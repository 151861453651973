import moment from "moment";
import { React, Component } from "react";
class PurchasedCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionDetails: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.init) {
      return {
        subscriptionDetails: nextProps.subscriptionTeam.subscriptionInfo,
      };
    }
    return null;
  }
  render() {
    let available = 0;
    let { disabled } = this.props;
    if (this.props && this.props.subscriptionInfo) {
      available =
        this.props.subscriptionInfo.SeatsPurchased -
        this.props.subscriptionInfo.UsedSeats;
    }
    console.log(this.props.subscriptionInfo, "this.props.");
    let director = localStorage.getItem("schoolCategory");
    return (
      <>
        <div className="alphatub-card">
          <div className="alphatub-card-top-spacing">
            <div className="row no-gutters space-between">
              <div>
                <h3 className="alphatub-card-h3">Plan Type</h3>
              </div>
              <div>
                <p className="alphatub-card-bold-p text-end text-capitalize">
                  {director === "TUBclass Main"
                    ? "TUB Class"
                    : director === "TUBclass Lite"
                    ? "Lite"
                    : director.replace(/(\w{3})/, "$1 ")}
                </p>
                {/* <p className="alphatub-card-normal-p">Next Bill on 30 April 2022</p> */}
              </div>
            </div>
            {/* <br /> */}
            <div className="row no-gutters space-between mt-2">
              <div>
                <h3 className="alphatub-card-h3">Valid Until</h3>
              </div>
              <div>
                <p className="alphatub-card-bold-p text-end text-capitalize">
                  {this.props.subscriptionInfo == null
                    ? "-"
                    : moment(
                        this.props.subscriptionInfo?.license?.endDate
                      ).format("YYYY-MM-DD")}
                </p>
              </div>
            </div>
            <small className="text-muted" style={{ fontSize: "8px" }}>
              <upper className="text-danger">*</upper>Please contact alphaTUB to extend your plan validity.
            </small>
          </div>
          <div className="alphatub-card-border"></div>
          <div className="alphatub-card-bottom-spacing">
            <div className="row no-gutters space-between mt-2">
              <p className="alphatub-card-normal-p">Seats Purchased</p>
              <p className="alphatub-card-bold-p">
                {this.props.subscriptionInfo?.SeatsPurchased}
              </p>
            </div>
            <div className="row no-gutters space-between mt-2">
              <p className="alphatub-card-normal-p">Used Seats</p>
              <p className="alphatub-card-bold-p">
                {this.props.subscriptionInfo?.UsedSeats}
              </p>
            </div>
            <div className="row no-gutters space-between mt-2">
              <p className="alphatub-card-normal-p">Available Seats</p>
              <p className="alphatub-card-bold-p" style={{ color: "#F05A28" }}>
                {available}
              </p>
            </div>
            <div className="row no-gutters mt-2 center-content d-none">
              <a
                href="#?"
                className="btn btn-primary small-button"
                disabled={disabled}
                // target="_blank"
                onClick={() =>
                  this.props.triggerPopup("showBuyMoreSeatsPopup", true)
                }
                // rel="noopener noreferrer"
              >
                Buy More Seats
              </a>
            </div>
          </div>
          {/* <div className="alphatub-card-border"></div> */}
          {/* <div className="alphatub-card-bottom-spacing">
            <div className="row no-gutters space-between d-none">
              <p className="alphatub-card-normal-p">Invoices</p>
              <p
                className={
                  disabled ? "disabledText" : "alphatub-card-normal-link"
                }
                // className='disabledText'
                onClick={() =>
                  this.props.triggerPopup("showInvoicePopup", true)
                }
              >
                View all invoices
              </p>
            </div>
            <div className="row no-gutters space-between mt-2 d-none">
              <div className="row no-gutters">
                <p className="alphatub-card-normal-p">Recent Invoice</p>
                <p className="alphatub-card-normal-link pl-1">01 May 2021</p>
              </div>
              <p className="alphatub-card-bold-p">$ 2500</p>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

export default PurchasedCard;
