import { loaders, main } from '../service/actionType';
import { API } from 'aws-amplify';
import toast from '../helpers/Toast';
import { ENVIRONMENT } from '../common/constants';
import { accessHeaders } from '../helpers';


export const getTubInboxList = (queryPayload) => async (dispatch, getState) => {
    try {
        let url = "tubinbox/getTubInboxData";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                ...queryPayload
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        console.log('response.data',response.data)
        if(response.status === 200){
            dispatch({ type: main.TUB_INBOX_LIST, payload: response.data })
        } else {
            dispatch({ type: main.TUB_INBOX_LIST, payload: [] })
        }
    } catch (error) {
        console.log("error", error);
        toast(error.message, 'error');
    }

}

 