import { main } from '../service/actionType'

const initialState = {
    subscriptionData: [],
    rolesDropdown: [],
    tableRolesDropdown: [],
    subscriptionInfo: null,
    invoicesList: []
}
const subscriptionTeam =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.INVOICES_LIST :
            return {
                ...state,
                invoicesList: payload
            }
        case main.GET_SUBSCRIPTION_TEAM_DATA:
            return {
                ...state,
                subscriptionData: payload
            }
        case main.ROLES_DROPDOWN_LIST:
            return {
                ...state,
                rolesDropdown: payload,
            }
        case main.SUBSCRIPTION_INFO:
            return {
                ...state,
                subscriptionInfo: payload,
            }
        case main.TABLEROLES_DROPDOWN_LIST:
            return {
                ...state,
                tableRolesDropdown: payload,
            }
        case main.UPDATE_AVAILABLE_SEATS:
            return {
                ...state,
                subscriptionInfo: {
                    ...state.subscriptionInfo,
                    UsedSeats: payload
                },
            }
            
        default:
            return state
    }

}

export default subscriptionTeam