import { React, useEffect, useRef, useState } from "react";
import ButtonType1 from "../../../../../components/inputs/ButtonType1";
import FormInput from "../../../../../components/inputs/FormInput";
import moment from "moment";
import { AppLoader } from "../../../../../components/loader/appLoader";
import { ContentOriginalImage } from "../../../../../components/tubClass/contentOriginalImage";
import { ContentStudentDetail } from "../../../../../components/tubClass/contentStudentDetail";
import { ContentDetail } from "../../../../../components/tubClass/contentDetail";
import { ContentSelectionImagesSlider } from "../../../../../components/tubClass/contentSelectionImagesSlider";
import ScrollToTopButton from "../../../../../components/ScrollToTopButton";
import { AlphabetsFilter } from "../../../../../components/tubClass/alphabetsFilter";
import { EditCustomCollection } from "../../../../../components/tubClass/EditCustomCollection";
import { HideOrUnhideContent } from "../../../../../components/tubClass/hideOrUnhideContent";
import toast from "../../../../../helpers/Toast";
import { CustomSelectFilter } from "../../../../../components/filters/customSelectFilter";
import { ShowOnlyAssignment } from "../../../../../components/tubClass/showOnlyAssignment";
import { ReadingProgramContent } from "components/ReadingPrograms";
import NoImg from "../../../../../assets/images/noImage.png"


const Tubtypes = (props) => {
  const [loading, setLoading] = useState(false);
  const [activityList, setSession] = useState([]);
  const [showImagesSlider, setShowImagesSlider] = useState(false);
  const [objectImagesList, setImagesList] = useState([]);

  const mainRow = useRef();

  useEffect(() => {
    setSession(props.session.activityList);
  }, [props]);

  const onChange = async (type, alphabet) => {
    setLoading(true);
    await props.onChangeCustomOptions(type, alphabet);
    setLoading(false);
  };
  const toggleImagesPopup = (data) => {
    setShowImagesSlider(true);
    setImagesList(data.alphatub_content_result.data);
  };
  const getWidthOfRow = () => {
    return mainRow.current?.clientWidth;
  };

  const getActivityListLength = () => {
    let len =
      props.selectedContents &&
      props.selectedContents.filter((ac) => ac.isAdded);
    if (len.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const hideContent = async (data) => {
    setLoading(true);
    let query = {
      schoolID: data.SchoolID,
      classID: data.ClassID,
      contentId: data.TubObjectId,
      imageId: data?.originalImageId,
      isHidden: true,
    };
    await props.onHideImage(query);
    setLoading(false);
  };
  return (
    <>
      <div className="mb-2 col-12" ref={mainRow}>
        <div
          className="w_200px alp_dis_1 cursor-pointer"
          onClick={() => props.onNavigate(1)}
        >
          <img
            src={require("../../../../../assets/images/left-arrow.svg").default}
            className="back-btn"
            alt="alphatub"
          />
          <p className="global_color privacy-content pl_2_rem cursor-pointer">
            Back To My Session
          </p>
        </div>
      </div>
      {props.selectedActivity && props.selectedActivity.id === 7 ? (
        <ReadingProgramContent
          {...props}
          btnName="Add"
          showAssignmentPopup={false}
          addToCreateSession={props.addToCreateSession}
          isFromMysession={true}
        />
      ) : (
        <div className="col-12 ">
          <div className="bg-white py-2 row no-gutters align-items-center">
            <div
              className={`${
                props.selectedActivity.id === 7 ? "col-3" : "col-2"
              } px-2 `}
            >
              <p className="tub-content-2">
                Select{" "}
                {props.selectedActivity.id == 3
                  ? "Images"
                  : props.selectedActivity.id == 8
                  ? `an ${props.selectedActivity.title}`
                  : `a ${props.selectedActivity.title}`}
              </p>
            </div>
            {props.selectedActivity.id !== 4 ? (
              <div
                className={`${
                  props.selectedActivity.id === 8 ? "col-2" : "col-4"
                } relative_position`}
              >
                <FormInput
                  id="search"
                  name="search"
                  type="search"
                  placeholder="search..."
                  onChange={(...params) =>
                    props.onSearchTubActivities(...params)
                  }
                  value={props.queryPayload["search"]}
                  className="m-0"
                  groupclassName="search-type-1 text-left"
                />
                {props.closeSuggessionPopup && (
                  <div className="suggestion_container">
                    {props.suggestionsList.length > 0 ? (
                      props.suggestionsList.map((match, i) => (
                        <div key={i}>
                          <p
                            className="suggestion"
                            onClick={() =>
                              props.onSuggestionSelect(match.label)
                            }
                          >
                            {match.label}
                          </p>
                        </div>
                      ))
                    ) : (
                      <div>
                        <p className="no_suggestion">No suggestion found</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="col-4"></div>
            )}
            {props.selectedActivity.id !== 7 && (
              <div className={`col-3 px-3`}>
                <CustomSelectFilter
                  name="sort"
                  options={props.sortOptions}
                  placeholder={"Sort"}
                  onApplyFilter={(name, value) => onChange(name, value)}
                />
              </div>
            )}
            {props.selectedActivity.id !== 7 && (
              <div className={`col-3 px-3`}>
                <CustomSelectFilter
                  name="filterByDate"
                  options={props.filterOptions}
                  placeholder={"Select Filter"}
                  onApplyFilter={(...params) => onChange(...params)}
                />
              </div>
            )}
            {props.selectedActivity.id === 8 && (
              <div className={`col-2 px-3`}>
                <CustomSelectFilter
                  name="assignmentId"
                  options={props.curriculum.assignmentDropdownList}
                  placeholder={"Assignment #"}
                  onApplyFilter={(...params) => onChange(...params)}
                />
              </div>
            )}
          </div>
          {props.selectedActivity.type === 2 &&
            props.selectedActivity.id === 4 && (
              <AlphabetsFilter
                onChange={(...params) => onChange(...params)}
                selectedLetterIndexOnEdit={props.selectedLetterIndexOnEdit}
              />
            )}

          {loading ? (
            <AppLoader />
          ) : (
            <div className="row flex-wrap mt-3 gap-25 pb-150">
              {activityList &&
                activityList.map((data, index) => (
                  <div
                    className="col-lg-6 col-xl-3 col-md-6 col-sm-12 col-12"
                    key={index}
                  >
                    <div className="tub-bg-type-1">
                      <div className="p-2">
                        <div className="row no-gutters justify-content-end align-items-center tub-inner-heading pb-10">
                          <div className="col-4 ">
                            {(props.selectedActivity.type === 2 &&
                              data.isSelected) ||
                            data.isSessionCreated ? (
                              <ButtonType1
                                btnName="Added"
                                className="btn-bg1 h-20-lh-7 buttonType1"
                                onClick={() => null}
                              />
                            ) : (
                              <ButtonType1
                                btnName="Add"
                                className="btn-bg1 h-20-lh-7 buttonType1"
                                onClick={() =>
                                  props.addToCreateSession(data, index)
                                }
                              />
                            )}
                          </div>
                          {props.selectedActivity.type === 2 && (
                            <div className="col-1 hide_container">
                              <HideOrUnhideContent
                                createSession={() =>
                                  props.addToCreateSession(data, index)
                                }
                                hideImages={() => hideContent(data)}
                              />
                            </div>
                          )}
                        </div>
                        {props.selectedActivity.type === 1 ? (
                          <div className="relative-pos">
                            <div
                              className={`tub-session-card ${
                                data.TubObjectType === "Sheet" &&
                                "addFilter p-1"
                              }`}
                            >
                              <div className="row flex-wrap no-gutters">
                                <div
                                  className="col-12 w-100"
                                  onClick={() => toggleImagesPopup(data)}
                                >
                                  <ContentOriginalImage
                                    src={
                                      data.alphatub_content_result?.ObjectImage
                                        ?.original
                                    }
                                    className={`${
                                      data.TubObjectType === "Card" &&
                                      "card_img"
                                    }`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row align-items-center no-gutters inner-content-spnImg">
                              {/* helloAbassWork */}
                              <ContentStudentDetail
                              // src={
                              //   !data?.userDetails?.Profile_img?.original && !data?.studentDetail?.profilePic?.original ||
                              //   data?.studentDetail?.profilePic?.original === "" || null || undefined
                              //     ? NoImg
                              //     : data?.studentDetail?.profilePic?.original || data?.userDetails?.Profile_img?.original
                              // }
                                src={data?.alphatub_content_result?.ObjectType === "Sheet"? NoImg : data?.studentDetail?.profilePic?.original|| NoImg}
                                // src={data?.studentDetail?.profilePic?.original || data?.userDetails?.Profile_img?.original || NoImg}
                                //  src={ data.studentDetail &&
                                //   data.studentDetail?.profilePic?.original &&
                                //   data.studentDetail?.profilePic?.original !==
                                //     ""
                                //     ? data.studentDetail?.profilePic?.original
                                //     : data.studentDetail?.profilePic
                                //         ?.original == ""
                                //     ? require("../../../../../assets/images/noImage.png")
                                //     : data?.student_list &&
                                //       data?.student_list?.profilePic
                                //         ?.original &&
                                //       data.student_list.profilePic?.original !==
                                //         ""
                                //     ? data.student_list?.profilePic?.original
                                //     : data.student_list?.profilePic?.original ==
                                //       ""
                                //     ? require("../../../../../assets/images/noImage.png")
                                //     : data?.teacherDetails?.Profile_img
                                //         ?.original
                                //     ? data?.teacherDetails?.Profile_img
                                //         ?.original
                                //     : data?.userDetails?.Profile_img?.original
                                //     ? data?.userDetails?.Profile_img?.original
                                //     : data?.userDetails?.Profile_img
                                //         ?.original === "" ||
                                //       data?.student_list?.profilePic
                                //         ?.original === "" ||
                                //       data?.teacherDetails?.Profile_img
                                //         ?.original === "" ||
                                //       !data?.userDetails
                                //     ? require("../../../../../assets/images/noImage.png")
                                //     : require("../../../../../assets/images/Alogo.png")
                                // }
                                className="whbr-40"
                                studentName={
                                  data.studentDetail &&
                                  data.studentDetail.firstName
                                    ? data.studentDetail.firstName
                                    : data.TeacherName
                                    ? data.TeacherName
                                    : data?.StudentName
                                    ? data?.StudentName
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="relative-pos">
                            <div
                              className="type2ActivityImages"
                              id="type2ActivityImages"
                            >
                              <ContentOriginalImage
                                src={
                                  data.alphabetImage &&
                                  data.alphabetImage?.original !== ""
                                    ? data.alphabetImage?.original
                                    : require("../../../../../assets/images/noImage.png")
                                }
                                className="type2Image"
                              />
                            </div>
                            <div className="row align-items-center no-gutters inner-content-spnImg">
                              <ContentStudentDetail
                                src={
                                  data.profilePic &&
                                  data.profilePic?.original &&
                                  data.profilePic?.original !== ""
                                    ? data.profilePic?.original
                                    : require("../../../../../assets/images/noImage.png")
                                }
                                className="whbr-40"
                                studentName={
                                  data.firstName ? data.firstName : ""
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="row no-gutters mt-2 justify-content-between">
                          <ContentDetail
                            ObjectName={
                              props.selectedActivity.type === 1
                                ? data.alphatub_content_result &&
                                  data.alphatub_content_result.ObjectName
                                : data.imageTitle
                            }
                            createdAt={
                              (data.TubObjectType === "Card" ||
                                data.TubObjectType === "Sheet" ||
                                data.TubObjectType === "Assignment") &&
                              moment(data.createdAt).format("DD MMM YYYY")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          <ScrollToTopButton />
          {props.selectedActivity.id == 1 || props.selectedActivity.id == 2 ? (
            <></>
          ) : (
            <EditCustomCollection
              selectedContents={props.selectedContents}
              activityList={activityList}
              getActivityListLength={getActivityListLength}
              selectedActivity={props.selectedActivity}
              createSessionForType2={props.createSessionForType2}
              unSelectActivity={props.unSelectActivity}
              getWidthOfRow={getWidthOfRow}
            />
          )}
          <ContentSelectionImagesSlider
            showImagesSlider={showImagesSlider}
            objectImagesList={objectImagesList}
            triggerPopup={() => setShowImagesSlider(!showImagesSlider)}
          />
        </div>
      )}
    </>
  );
};

export default Tubtypes;
