import React from 'react';
import { FormGroup, Label, Button } from 'reactstrap'

const ButtonType2 = (props) => {
    return (
        <>
            {
                props.disabled ? 
                <div className={`buttonType3 disabled`}>
                    {props.btnName}
                </div>
                :
                <div className={`${props.className}`} onClick={() => props.onClick()}>
                    {props.btnName}
                </div>
            }
        </>
        
    )
}

export default ButtonType2;