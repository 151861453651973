import { useEffect, useState } from "react"
import { CreateModel } from "../Model/createModel"
import Calender from 'react-calendar';
import ButtonType1 from "../inputs/ButtonType1";
import { differenceInCalendarDays } from 'date-fns';

var days = 15
export const SendLaterDatePopup = ({showDatePopup,triggerPopup,onClick, session, title, mindate,maxdate}) => {
    const [minDate, setMinDate] = useState()
    const [maxDate, setMaxDate] = useState()
    const [contentSelectedDate, setContentSelectedDate] = useState(new Date())

    useEffect(() => {
        setMinDate(mindate != undefined ? new Date(mindate) : new Date())
        setMaxDate(maxdate != undefined ? new Date(maxdate) : null)
    }, [mindate, maxdate])
   
    const onChangeDate = (value) => {
        setContentSelectedDate(value)
    }
    return (
        <CreateModel
            isOpen={showDatePopup}
            toggle={() => triggerPopup(false)}
            title={title}
        >
            <div className="row pt-3 pb-5">
                <div className="col-12 session-calendar gallery-cal">
                    <Calender
                        onChange={(value) => onChangeDate(value)}
                        value={contentSelectedDate}
                        selectRange={false}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>
            </div>
            <div className="row align-items-center mt-3 content-end">
                <div className="col-4">
                    <ButtonType1
                        btnName="Close"
                        className='popup-close-btn'
                        onClick={() => triggerPopup(false)}
                    />
                </div>
                <div className="col-4">
                    <ButtonType1
                        btnName="Confirm"
                        className='bg-tub c-white buttonType1 '
                        onClick={() => onClick(contentSelectedDate)}
                    />
                </div>
            </div>
        </CreateModel>
    )
}