import _ from "lodash"
import { useEffect, useState } from "react"
import ReactSelectInput from 'react-select'

export const CustomSelectFilter = ({options,placeholder,name,onApplyFilter,onApplyTypeTwoFilter, isClearable}) => {
    const [value, setValue] = useState(null)
    
    const onChange = (name, value) => {
        setValue(value)
        if(value?.value == "HiddenImages" || value?.value == "Images"){
            onApplyTypeTwoFilter(name, value?.value, value?.type)
        } else {
            onApplyFilter(name, value?.value, value?.type)
        }
    }
    return (
        <div className="custom-select-filter">
            <ReactSelectInput
                classNamePrefix={`m-0`}
                name={name}
                options={options}
                placeholder={placeholder}
                value={value}
                onChange={(val) => onChange(name, val)}
                isMulti={false}
                isClearable={isClearable}
            />
        </div>
    )
}