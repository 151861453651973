import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

const CreateModel = ({
  isOpen,
  toggle,
  title,
  children,
  className,
  ...rest
}) => {
  return (
    <Modal className={`pop-up ${className}`} isOpen={isOpen} toggle={toggle}>
      <div className="py-2 px-4 popup-header-container">
        <div className="popup-header-close">
          <img
            src={require("../../assets/images/popupclose.png")}
            className=""
            onClick={toggle}
            alt="alphatub"
          />
        </div>
        <div>
          <h2 className="popup-header">{title}</h2>
        </div>
      </div>
      <ModalBody>{children}</ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};


export { CreateModel };
