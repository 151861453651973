import { React, useRef, useState } from "react";
import Calender from "react-calendar";
import Carousel from "react-grid-carousel";
import moment from "moment";
import { CreateModel } from "../../../../../components/Model/createModel";
import ButtonType1 from "../../../../../components/inputs/ButtonType1";
import RightArrow from "../../../../../assets/images/rightarrow.svg";
import NoImg from "../../../../../assets/images/noImage.png";
import LeftArrow from "../../../../../assets/images/leftarrow.svg";
import { ActivitiesPlusCard } from "../../../../../components/tubClass/activitesPlusCard";
import { ObjectCount } from "../../../../../components/tubClass/ObjectCount";
import { CreateSessionDatePopup } from "../../../../../components/tubClass/createSessionDatePopup";
import { ContentStudentDetail } from "../../../../../components/tubClass/contentStudentDetail";
import { ContentOriginalImage } from "../../../../../components/tubClass/contentOriginalImage";
import { ContentSelectionImagesSlider } from "../../../../../components/tubClass/contentSelectionImagesSlider";
import ScrollToTopButton from "../../../../../components/ScrollToTopButton";
var days = 15;

const CreateSession = (props) => {
  const mainRow = useRef();
  console.log(props, "CreateSession");
  const [maxDate] = useState(new Date(Date.now() + days * 24 * 60 * 60 * 1000));
  const [minDate] = useState(new Date(Date.now() - days * 24 * 60 * 60 * 1000));
  const [contentSelectedDate, setContentSelectedDate] = useState(
    props.sessionSelectedDate
  );
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedTub, setSelectedTub] = useState(null);
  const [deletedObjectName, setDeleteTubObjectName] = useState(null);
  const [showDatePopup, setShowDatePopup] = useState(false);
  const [saveDataToCreateSession, setSaveDataToCreateSession] = useState({});
  const [showImagesSlider, setShowImagesSlider] = useState(false);
  const [objectImagesList, setImagesList] = useState([]);
  const [activeStartDate, setActiveDate] = useState(null);

  const getWidthOfRow = () => {
    return mainRow.current?.clientWidth;
  };
  const changeSessionCalendarMonth = (value) => {
    setActiveDate(value.activeStartDate);
    props.onChangeSessionCalendarMonth(value.activeStartDate);
  };
  const onChangeDate = (value) => {
    let now = new Date();

    sessionStorage.setItem("session_date", moment(value).format("YYYY-MM-DD"));
    if (moment(value).format("YYYY-MM-DD") < moment(now).format("YYYY-MM-DD")) {
      props.sessionAction.toggleReviewTubClass(true);
    } else {
      props.sessionAction.toggleReviewTubClass(false);
    }
    setContentSelectedDate(value);
    getContentSelected(value);
  };
  const getContentSelected = (date) => {
    props.getCreatedSessionList(
      moment(date).startOf("day").format("YYYY-MM-DD")
    );
  };
  const triggerPopup = (item, data) => {
    if (item === "showDeletePopup") {
      let deletedObjectName;
      if (data != null)
        deletedObjectName =
          data.Session_Type === "Card"
            ? "TUB Card"
            : data.Session_Type === "Mix"
            ? "TUB Mix"
            : data.Session_Type === "Sheet"
            ? "TUB Sheet"
            : data.Session_Type === "Word"
            ? "Word"
            : data.Session_Type === "Tag"
            ? "Tag"
            : "Letter";
      setDeleteTubObjectName(deletedObjectName);
      setSelectedTub(data);
      setShowDeletePopup(!showDeletePopup);
    } else if (item === "showDatePopup") {
      if (data != null) {
        setSaveDataToCreateSession({
          ...saveDataToCreateSession,
          Class_Id: data.Class_Id,
          School_Id: data.School_Id,
          TubObjectId: data.TubObjectId,
          Session_Type: data.Session_Type,
          studentId: data.studentId,
        });
      }
      setShowDatePopup(!showDatePopup);
    } else if (item === "showImagesSlider") {
      setShowImagesSlider(false);
    }
  };
  const deleteTubObject = async () => {
    let payload = {
      _id: selectedTub._id,
    };
    await props.deleteTubObject(payload, contentSelectedDate);
    if (
      new Date(contentSelectedDate).toLocaleDateString() ==
      new Date().toLocaleDateString()
    ) {
      await props.sessionAction.checkLauchTubEnabled();
    }
    setShowDeletePopup(false);
  };
  const selectActivity = (activity) => {
    props.selectActivity(activity, contentSelectedDate);
  };
  const isDeleteDisabled = (item) => {
    let isExplored = item?.performance[0]?.data?.explore;
    // let isPlayed = item?.No_of_plays;
    let isPlayed = item?.No_of_plays;
    let now = new Date();
    if (contentSelectedDate.getDate() < now.getDate()) {
      return true;
    } else if (isExplored > 0 || isPlayed > 0) {
      return true;
    } else return false;
  };

  const addToSession = async (selectedDate) => {
    let time = selectedDate.toLocaleTimeString("it-IT");
    let payload = {
      ...saveDataToCreateSession,
      Session_Date: moment(selectedDate).format(`YYYY-MM-DD[T${time}.000Z]`),
    };
    await props.tubGallery.createSession(payload);
    await props.sessionAction.checkLauchTubEnabled();
  };
  const toggleImagesPopup = (data) => {
    setShowImagesSlider(true);
    setImagesList(data.alphatub_content_result.data);
  };
  const editContent = (item) => {
    let selectedContents = [].concat(item?.alphatub_content_result?.data);
    props.setSelectedContents(selectedContents, item);
  };

  const addCurrentDate = () => {
    let now = new Date();
    sessionStorage.setItem("session_date", moment(now).format("YYYY-MM-DD"));
  };
  console.log(props.session.createdSessionList, "props.sess");
  return (
    <>
      <div className="d-flex">
        <img
          src={require("../../../../../assets/images/left-arrow.svg").default}
          className="back-btn"
          onClick={() => props.onNavigate(1)}
          alt="alphatub"
        />
        <p
          className="global_color privacy-content ml-3 cursor-pointer"
          onClick={() => {
            props.onNavigate(1);
            addCurrentDate();
          }}
        >
          Back To My Session
        </p>
      </div>
      <div className="col-12 mt-2" ref={mainRow}>
        <div className="row no-gutters">
          <div className="col-md-4">
            <Calender
              onChange={(value) => onChangeDate(value)}
              value={contentSelectedDate}
              selectRange={false}
              onActiveStartDateChange={(value) =>
                changeSessionCalendarMonth(value)
              }
              tileClassName={props.tileClassName}
              activeStartDate={activeStartDate}
              className="assignment_calendar"
              maxDate={maxDate}
              tileDisabled={props.tileDisabled}
            />
          </div>

          <ActivitiesPlusCard
            createdSessionList={props.session.createdSessionList}
            selectActivity={selectActivity}
            contentSelectedDate={contentSelectedDate}
            tab={3}
          />
        </div>
        <div className="bg-white mt-4 py-4">
          <p className="tub-content-1 text-center">
            Content Selected for {moment().format("DD MMM YYYY")}
          </p>
        </div>
        {props.session.createdSessionList.length > 0 ? (
          props.session.createdSessionList &&
          props.session.createdSessionList.map((items, i) => (
            <div className="row no-gutters pt-3" key={i}>
              <div className="col-12">
                <p className="tub-content-2">
                  {items.sessionType === "Card"
                    ? "TUB Card"
                    : items.sessionType === "Mix"
                    ? "TUB Mix"
                    : items.sessionType === "Sheet"
                    ? "TUB Sheet"
                    : items.sessionType === "Word"
                    ? "Words"
                    : items.sessionType === "Tag"
                    ? "Tags"
                    : items.sessionType === "Assignment"
                    ? "Assignment"
                    : items.sessionType === "Letter"
                    ? "Letter"
                    : items.Session_Type === "Reading_Program"
                    ? "Reading program"
                    : ""}
                </p>
                <div
                  className="pt-3 pb-4 upcoming_session_container"
                  style={{ width: getWidthOfRow() }}
                >
                  <Carousel
                    cols={3}
                    rows={1}
                    gap={15}
                    showStatus={false}
                    emulateTouch={false}
                    showIndicators={false}
                    arrowRight={
                      <div className="session_arrow_right arrowRightStyles">
                        <img src={RightArrow} className="clp_arrow" />
                      </div>
                    }
                    arrowLeft={
                      <div
                        className="session_arrow_left
                            arrowLeftStyles"
                      >
                        <img src={LeftArrow} className="clp_arrow" />
                      </div>
                    }
                    responsiveLayout={[
                      {
                        breakpoint: 1250,
                        cols: 2,
                      },
                      {
                        breakpoint: 3000,
                        cols: 3,
                      },
                    ]}
                  >
                    {items.data &&
                      items.data.map((item, i) => (
                        <Carousel.Item key={i}>
                          <div
                            className={`tub-bg-type-1 p-2 position_relative ${
                              isDeleteDisabled() && "pt-3"
                            }`}
                          >
                            <div className="row no-gutters justify-flex-end px-2 tub-inner-heading pt-1">
                              <div className="col-3 pb-2">
                                <ButtonType1
                                  btnName="Add"
                                  className="btn-bg1 h-24-lh-12 buttonType1"
                                  onClick={() =>
                                    triggerPopup("showDatePopup", item)
                                  }
                                />
                              </div>
                              {(items.sessionType === "Word" ||
                                items.sessionType === "Letter" ||
                                items.sessionType === "Tag" ||
                                items.sessionType === "Mix") && (
                                <div className="ml-3 cursor-pointer w-6">
                                  <img
                                    src={
                                      require("../../../../../assets/images/edit_content.svg")
                                        .default
                                    }
                                    className="w-100"
                                    alt="alphatub"
                                    onClick={() => editContent(item)}
                                  />
                                </div>
                              )}
                              {!isDeleteDisabled(item) && (
                                <div
                                  className="w-6 cursor-pointer pb-2 ml-2"
                                  onClick={() =>
                                    triggerPopup("showDeletePopup", item)
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../../../assets/images/deleteSvg.svg")
                                        .default
                                    }
                                    className="w-100"
                                    alt="alphatub"
                                  />
                                </div>
                              )}
                            </div>
                            <div className={`relative-pos`}>
                              <div
                                className={`tub-session-card ${
                                  item.Session_Type === "Sheet" &&
                                  "addFilter p-1"
                                }`}
                              >
                                <div className="row flex-wrap p-1 no-gutters">
                                  {items.sessionType === "Word" ||
                                  items.sessionType === "Letter" ||
                                  items.sessionType === "Tag" ||
                                  items.sessionType === "Mix" ? (
                                    <div className="col-12 position_relative">
                                      {item.alphatub_content_result.data.map(
                                        (dt, i) => (
                                          <div
                                            className={`typeTwoImage`}
                                            onClick={() =>
                                              toggleImagesPopup(item)
                                            }
                                            key={i}
                                          >
                                            <ContentOriginalImage
                                              src={dt.alphabetImage?.original}
                                              className=""
                                            />
                                            {i === 1 && (
                                              <ObjectCount
                                                data={
                                                  item.alphatub_content_result
                                                    .data
                                                }
                                                className="clp_home_count"
                                              />
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      className="col-12"
                                      onClick={() => toggleImagesPopup(item)}
                                    >
                                      <ContentOriginalImage
                                        src={
                                          item.alphatub_content_result
                                            ?.ObjectImage?.original ||
                                          item?.alphatub_content_result?.data[0]
                                            ?.alphabetImage?.original
                                        }
                                        className={`minh_inherit ${
                                          (item.Session_Type === "Card" ||
                                            item.Session_Type ===
                                              "Assignment") &&
                                          "card_img"
                                        }`}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {(items.sessionType === "Card" ||
                                items.sessionType === "Sheet" ||
                                items.sessionType === "Reading_Program" ||
                                item.Session_Type === "Assignment") && (
                                <div className="row align-items-center no-gutters inner-content-spnImg">
                                  <ContentStudentDetail
                                    src={
                                      !item?.student_list?.profilePic
                                        ?.original ||
                                      item?.student_list?.profilePic
                                        ?.original === "" ||
                                      null ||
                                      undefined
                                        ? NoImg
                                        : item?.student_list?.profilePic
                                            ?.original
                                    }
                                    // item?.student_list?.profilePic === undefined ? require("../../../../../assets/images/noImage.png") : item?.student_list?.profilePic}
                                    // item.alphatub_content_result
                                    //   ?.purchaseType === 1
                                    //   ? require("../../../../../assets/images/Alogo.png")
                                    //   : item?.student_list &&
                                    //     item?.student_list?.profilePic
                                    //       ?.original &&
                                    //     item.student_list.profilePic
                                    //       ?.original !== ""
                                    //   ? item.student_list?.profilePic
                                    //       ?.original
                                    //   : item.student_list?.profilePic
                                    //       ?.original == "" ||
                                    //     item?.teacherDetails?.Profile_img
                                    //       ?.original == ""
                                    //   ? require("../../../../../assets/images/noImage.png")
                                    //   : item?.teacherDetails?.Profile_img
                                    //       ?.original
                                    //   ? item?.teacherDetails?.Profile_img
                                    //       ?.original
                                    //   : item?.userDetails?.Profile_img
                                    //       ?.original
                                    //   ? item?.userDetails?.Profile_img
                                    //       ?.original
                                    //   : item?.userDetails?.Profile_img
                                    //       ?.original === "" ||
                                    //     item?.student_list?.profilePic
                                    //       ?.original === "" ||
                                    //     item?.teacherDetails?.Profile_img
                                    //       ?.original === ""
                                    //   ? require("../../../../../assets/images/noImage.png")
                                    //   : require("../../../../../assets/images/Alogo.png")
                                    // }
                                    className="whbr-40"
                                    studentName={
                                      // item?.student_list?.firstName ||
                                      // item?.userDetails?.First_name
                                      item.alphatub_content_result
                                        ?.purchaseType === 1
                                        ? "alphaTUB Original"
                                        : item?.student_list?.firstName
                                        ? item?.student_list?.firstName
                                        : item?.TeacherName
                                        ? item?.TeacherName
                                        : item?.teacherDetails?.First_name
                                        ? item?.teacherDetails?.First_name
                                        : item?.userDetails?.First_name
                                        ? item?.userDetails?.First_name
                                        : "alphaTUB Original"
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <div className="row no-gutters mt-2 justify-content-between">
                              <p className="tub-session-category">
                                {item.alphatub_content_result.ObjectName}
                                <span className="tub-day cl_object_type">
                                  {" "}
                                  ({" "}
                                  {item.Session_Type === "Card"
                                    ? "TUB Cards"
                                    : item.Session_Type === "Sheet"
                                    ? "TUB Sheet"
                                    : item.Session_Type === "Mix"
                                    ? "TUB Mix"
                                    : item.Session_Type === "Word"
                                    ? "Word"
                                    : item.Session_Type === "Tag"
                                    ? "Tag"
                                    : item.Session_Type === "Letter"
                                    ? "Letter"
                                    : item.Session_Type === "Assignment"
                                    ? "Assignment"
                                    : item.Session_Type === "Reading_Program"
                                    ? "Reading Program"
                                    : ""}{" "}
                                  )
                                </span>
                              </p>
                              {(items.sessionType === "Card" ||
                                items.sessionType === "Sheet") && (
                                <p className="tub-day">
                                  {moment(item.updatedAt).format("DD MMM YYYY")}
                                </p>
                              )}
                            </div>
                          </div>
                        </Carousel.Item>
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="row no-gutters align-items-center center-content h-100px">
            <p className="tub-content-4">
              No Content for {moment(contentSelectedDate).format("DD MMM YYYY")}
            </p>
          </div>
        )}
        <ScrollToTopButton />
      </div>
      <ContentSelectionImagesSlider
        showImagesSlider={showImagesSlider}
        objectImagesList={objectImagesList}
        triggerPopup={triggerPopup}
      />
      {/* Delete Tub Object */}
      {showDeletePopup && (
        <CreateModel
          isOpen={showDeletePopup}
          toggle={() => triggerPopup("showDeletePopup", null)}
          title={"Confirm Deletion"}
        >
          <div className="alphatub-card-border"></div>
          <div className="row pt-3 pb-5">
            <div className="col-12">
              <p className="f-16px alphatub-card-normal-p">
                {/* Do you want to remove this {deletedObjectName} collection from
                the session ? */}
                Are you sure you want to delete your TUB content for this
                session?
              </p>
            </div>
          </div>
          <div className="alphatub-card-border"></div>
          <div className="row align-items-center mt-3 content-end">
            <div className="col-4">
              <ButtonType1
                btnName="Close"
                className="popup-close-btn"
                onClick={() => triggerPopup("showDeletePopup", null)}
              />
            </div>
            <div className="col-4">
              <ButtonType1
                btnName="Confirm"
                className="bg-tub c-white buttonType1 "
                onClick={() => deleteTubObject()}
              />
            </div>
          </div>
        </CreateModel>
      )}
      {/* date popup to create session*/}
      <CreateSessionDatePopup
        showDatePopup={showDatePopup}
        triggerPopup={triggerPopup}
        addToCreateSession={addToSession}
        session={props.session}
      />
    </>
  );
};

// const MainPage = connect(null, null)(MainPageClass)

export default CreateSession;
