import React from 'react';

const FilterButton = (props) => {
    return (
        <>
            <div className={`${props.className}`} onClick={() => props.onClick()}>
                {props.btnName}
            </div>
        </>
        
    )
}

export default FilterButton;