import { useEffect, useState } from "react";
import Carousel from "react-grid-carousel";
import _ from "lodash";
import moment from "moment";

export const ActivitiesPlusCard = ({
  createdSessionList,
  selectActivity,
  tab,
  contentSelectedDate,
}) => {
  const [plusCardActivity, setActivitiesList] = useState([
    {
      id: 7,
      type: 1,
      title: "Reading Programs",
      key: "Reading_Program",
      tab: 14,
      isHovered: false,
    },
    {
      id: 1,
      type: 1,
      title: "TUB Sheet",
      key: "Sheet",
      tab: 9,
      isHovered: false,
    },
    {
      id: 2,
      type: 1,
      title: "TUB Cards",
      key: "Card",
      tab: 10,
      isHovered: false,
    },
    {
      id: 3,
      type: 2,
      title: "TUB Mix",
      key: "Mix",
      tab: 11,
      isHovered: false,
    },
    {
      id: 8,
      type: 1,
      title: "Assignment",
      key: "Assignment",
      tab: 14,
      isHovered: false,
    },
    {
      id: 4,
      type: 2,
      title: "Letter",
      key: "Letter",
      tab: 12,
      isHovered: false,
    },
    {
      id: 5,
      type: 2,
      title: "Word",
      key: "Word",
      tab: 13,
      isHovered: false,
    },
    {
      id: 6,
      type: 2,
      title: "Tag",
      key: "Tag",
      tab: 14,
      isHovered: false,
    },
  ]);

  const hoverTubCircle = (i) => {
    plusCardActivity[i].isHovered = !plusCardActivity[i].isHovered;
    setActivitiesList([...plusCardActivity]);
  };
  const getSessionCount = (sessionList, activity) => {
    let getActivity =
      sessionList && sessionList.find((act) => act.sessionType == activity.key);
    if (!_.isEmpty(getActivity)) {
      return (
        <div className="session_count_container">
          <div className="session_count_inner">
            <p>{getActivity.data.length}</p>
          </div>
        </div>
      );
    } else return <></>;
  };
  return (
    <>
      <div className="col-md-8 session_activities">
        <div className="tub-card-1 h100">
          {tab == 3 && (
            <div>
              <p className="f-15 alphatub-card-bold-p">
                Content Selection for{" "}
                <span className="tub-color-1">
                  {moment(contentSelectedDate).format("DD MMM YYYY")}
                </span>
              </p>
            </div>
          )}
          <div className="mt-3">
            <div className="text-center">
              <p className="f-15 alphatub-card-bold-p">Add Activity</p>
            </div>
            <div className="row no-gutters">
              <div className="col-12">
                <div className="mt-4 row justify-content-around tub-acts">
                  <Carousel
                    cols={4}
                    rows={2}
                    gap={35}
                    loop={true}
                    hideArrow={true}
                  >
                    {plusCardActivity.map((act, i) => (
                      <Carousel.Item key={act.id}>
                        <div className="tub-activities">
                          <div
                            className={
                              act.isHovered
                                ? "hovered-tub-circle position_relative"
                                : "tub-circle position_relative"
                            }
                            onMouseOver={() => hoverTubCircle(i)}
                            onMouseOut={() => hoverTubCircle(i)}
                            onClick={() => selectActivity(act)}
                          >
                            <div
                              className={
                                act.isHovered ? "hovered-tub-plus" : "tub-plus"
                              }
                            >
                              +
                            </div>
                            {getSessionCount(createdSessionList, act)}
                          </div>
                          <p className="mt-3 f-14 alphatub-card-normal-p">
                            {act.title}
                          </p>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
