import { React, useEffect, useState } from "react";
import * as curriculumAction from "actions/curriculum";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { InnerAppLoader } from "components/loader/appLoader";
import { MakeAssignmentContent } from "components/MakeAssignment";

const MakeAssignmentFunction = (props) => {
  const [loading, setLoading] = useState(false);

  const getApis = async () => {
    setLoading(true);
    await props.curriculumAction.getCurriculumAndProgramName();
    await props.curriculumAction.getMakeAssignmentList(null);
    let dataToPersist = props.dataToPersist;
    let selectedClass = sessionStorage.getItem("selectedClass");
    let sessionDatesqueryPayload = {
      schoolId: sessionStorage.getItem("schoolId"),
      month: parseInt(moment(new Date()).format("M")),
      classId: selectedClass
        ? selectedClass
        : dataToPersist.selectedClass?.value,
    };
    await props.curriculumAction.getAssingmentCalendarDatesByMonth(
      sessionDatesqueryPayload
    );
    setLoading(false);
  };

  useEffect(() => {
    getApis();
  }, []);

  return (
    <>
      {loading ? (
        <InnerAppLoader />
      ) : (
        <MakeAssignmentContent
          {...props}
          btnName="Make Assignment"
          showAssignmentPopup={true}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  tubGallery,
  myProfile,
  loader,
  session,
  curriculum,
  dataToPersist,
}) => {
  return {
    tubGallery,
    myProfile,
    loader,
    session,
    curriculum,
    dataToPersist,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    curriculumAction: bindActionCreators(curriculumAction, dispatch),
    dispatch,
  };
};

const MakeAssignment = connect(
  mapStateToProps,
  mapDispatchToProps
)(MakeAssignmentFunction);

export { MakeAssignment };
