const AWS = require("aws-sdk");

const ROLES = {
  ADMIN: 1,
  MANAGER: 2,
  TEACHER: 3,
  TRAINEE: 4,
};

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const API_URL = process.env.REACT_APP_API_URL;
const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
  IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_ID,
});

module.exports = {
  ROLES,
  ENVIRONMENT,
  API_URL,
  cognitoidentityserviceprovider,
};
