import vector1Red from "../assets/images/vector-1-red.svg";
import vector2Red from "../assets/images/vector-2-red.svg";
import vector3Red from "../assets/images/vector-3-red.svg";
import TubPage from "../assets/images/TubPage.svg";
import TubPages from "../assets/images/TubPages.svg";
import vector4Red from "../assets/images/vector-4-red.svg";
import vector5Red from "../assets/images/vector-5-red.svg";
import vector6Red from "../assets/images/vector-6-red.svg";
import vector1Blue from "../assets/images/vector-1-blue.svg";
import vector2Blue from "../assets/images/vector-2-blue.svg";
import vector3Blue from "../assets/images/vector-3-blue.svg";
import vector4Blue from "../assets/images/vector-4-blue.svg";
import vector5Blue from "../assets/images/vector-5-blue.svg";
import vector6Blue from "../assets/images/vector-6-blue.svg";

let roleBasedAllLinks = {
  1: [
    {
      name: "TUB Class",
      key: "tubclass",
      image: vector1Red,
      selectedImage: vector1Blue,
      routeName: "/tubclass/assignment",
      pathname: "/tubclass",
      disabled: false,
    },
    {
      name: "TUB Analytics",
      image: vector2Red,
      key: "analytics",
      selectedImage: vector2Blue,
      routeName: "/analytics/overall",
      pathname: "/analytics",
      disabled: false,
    },
    // {
    //   name: "Notifications",
    //   image: vector3Red,
    //   key: "notifications",
    //   selectedImage: vector3Blue,
    //   routeName: "/notifications",
    //   pathname: "notifications",
    //   disabled: true,
    // },

    // {
    //   name: "TUB Page",
    //   image: vector6Red,
    //   key: "tubPage",
    //   selectedImage: vector6Blue,
    //   routeName: "/tubpage",
    //   pathname: "/tubpage",
    //   disabled: false,
    // },
    {
      name: "TUB Page",
      image: TubPages,
      key: "tubPage",
      selectedImage: TubPage,
      routeName: "/tubpage",
      pathname: "/tubpage",
      disabled: false,
    },
    {
      name: "Settings",
      image: vector4Red,
      key: "settings",
      selectedImage: vector4Blue,
      routeName: "/settings/profile",
      pathname: "/settings",
      disabled: false,
    },
    // {
    //   name: "Privacy & Terms",
    //   image: vector6Red,
    //   key: 'privacy',
    //   selectedImage: vector6Blue,
    //   routeName: "/privacy",
    //   pathname: '/privacy',
    //   disabled: false
    // },
    {
      name: "Logout",
      image: vector5Red,
      key: "logout",
      selectedImage: vector5Blue,
      routeName: "logout",
      pathname: "/login",
      disabled: false,
    },
  ],
  get: function (roleId) {
    if (this[roleId]) return this[roleId];
    else return [];
  },
};

export const getLinksBasedOnRoleId = (roleId = 1) => {
  // let path = history.location.pathname.split('/').pop()
  let filteredLinks;
  if (roleBasedAllLinks.get(roleId).length) {
    filteredLinks = roleBasedAllLinks.get(roleId);
    // let checkPathInLinks = filteredLinks.findIndex(data => data.pathname === path)
  }
  return filteredLinks;
};
