import { main } from '../service/actionType'

const initialState = {
    createdSessionList: [],
    sessionDisableDatesList: [],
    sessionList: [],
    upcommingSessionList: [],
    activityList: [],
    selectedTab: 1,
    isSessionAvailable: false,
    schoolAcademicYear: null,
    selectedActivity: null,
    activityListQueryPayload: {
        search: null,
        startDate: null,
        endDate: null,
        sort: null,
        tubObjectType: null,
        classId: null,
        schoolId: null,
        studentId: null,
        activityType: null
    },
    tubActivitySuggestionList: [],
    selectedLeftSideBar: 0,
    disableLaunchTubClass: false,
    isSelectedActivityTab: false,
    isReviewTubClassEnabled: false,
    sessionAssignedDates : {
        sessionPastDates: [], 
        sessionFutureDates: []
    },
}
const session =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.SESSION_ASSIGNED_DATES : 
            return {
                ...state,
                sessionAssignedDates: {
                    ...state.sessionAssignedDates,
                    ...payload
                }
            }
        case main.IS_SELECTED_ACTIVITY_TAB : 
            return {
                ...state,
                isSelectedActivityTab: payload
            }
        case main.DISABLE_LAUNCH_TUBCLASS : 
            return {
                ...state,
                disableLaunchTubClass: payload
            }
        case main.SELECTED_LEFT_SIDE_BAR: 
            return {
                ...state,
                selectedLeftSideBar: payload
            }
        case main.TUB_ACTIVITY_SUGGESTION_LIST : 
            return {
                ...state,
                tubActivitySuggestionList: payload
            }
        case main.ACTIVITY_LIST_QUERY_PAYLOAD : 
            return {
                ...state,
                activityListQueryPayload: {
                    ...state.activityListQueryPayload,
                    ...payload
                }
            }
        case main.SELECTED_ACTIVITY : 
            return {
                ...state,
                selectedActivity: payload
            }
        case main.IS_SESSION_PRESENT:
            return {
                ...state,
                isSessionAvailable: payload
            }
        case main.CREATED_SESSION_LIST:
            return {
                ...state,
                createdSessionList: payload
            }
        case main.SELECTED_TUB_CLASS_TABS:
            return {
                ...state,
                selectedTab: payload
            }
        case main.SESSION_DISABLE_DATES_LIST:
            return {
                ...state,
                sessionDisableDatesList: payload.sessionDisableDatesList,
                schoolAcademicYear: payload.academicYear
            }
        case main.SESSION_LIST: 
            return {
                ...state,
                sessionList: payload.sessionList,
                upcommingSessionList: payload.upcomingSessionList
            }
        case main.TUB_ACTIVITY_LIST:
            return {
                ...state,
                activityList: payload
            } 
        case main.TOGGLE_REVIEW_TUB_CLASS : 
            return {
                ...state,
                isReviewTubClassEnabled: payload != null ? payload === 'true' ? true : payload === 'false' ? false : payload : payload
            }
        default:
            return state
    }

}

export default session
