import React, { useState } from "react";

export const Letters = (props) => {
  const [selectedLetter, setSelectedLetter] = useState(null);

  const onChangeCustomOptions = (alphabet, index) => {
    setSelectedLetter(index);
    props.onChangeCustomOptions(alphabet, index);
  };
  return (
    <div className="row no-gutters align-items-center justify-between mt-2 mb-2">
      {props.alphabets.map((obj, i) => (
        <>
          {selectedLetter === i ? (
            <p
              className={
                "highlighted_letter global_color rejectAlphabet cursor-pointer "
              }
              onClick={() => onChangeCustomOptions(obj.alphabet, null)}
            >
              {obj.alphabet}
            </p>
          ) : (
            <p
              className={"rejectAlphabet cursor-pointer"}
              onClick={() => onChangeCustomOptions(obj.alphabet, i)}
            >
              {obj.alphabet}
            </p>
          )}
        </>
      ))}
    </div>
  );
};
