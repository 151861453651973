import { React, Component } from "react";
import { connect } from "react-redux";
import AssignmentCalendar from "./main/assignmentCalendar";
import { AssignmentView } from "./main/assigment";
import * as sessionAction from "actions/session";
import * as curriculumAction from "actions/curriculum";
import Wrapper from "Hoc/wrapper";
import { bindActionCreators } from "redux";
import { differenceInCalendarDays } from "date-fns";
import { Letters } from "components/tubClass/letter";
import { accessHeaders, ALPHABETS, history } from "helpers";
import { API } from "aws-amplify";
import { ENVIRONMENT } from "common/constants";
import { AssignmentGalleryList } from "components/tubClass/assigmentContent";
import { main } from "service/actionType";
import toast from "utils/Toast";
import _ from "lodash";
import moment from "moment";
import { sampleAssignmentScreenTableColumns } from "helpers/tableDataMapper";
import { workingDaysOptions } from "helpers/variables";
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";
import { InnerAppLoader } from "components/loader/appLoader";

class AssignmentMainClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 1,
      selectedActivity: null,
      workingDaysOptions: workingDaysOptions,
      selectedLetter: null,
      selectedGlIds: [],
      selectedDate: null,
      loading: false,
      getAssignmentQuery: {
        classId: null,
        schoolId: sessionStorage.getItem("schoolId"),
        date: moment(new Date())
          .startOf("day")
          .format("YYYY-MM-DDT[00:00:00.000Z]"),
      },
      isDisabledPastAssignmentBtns: false,
      refreshGallery: false,
      init: true,
      assignmentLoading: false,
      selectedCalendarDate: null,
      legends: [
        {
          color: "#00AFEE",
          text: "Submitted",
        },
        {
          color: "#F05A28",
          text: "Not Submitted",
        },
        {
          color: "#C4C4C4",
          text: "Not Connected",
        },
      ],
      activeStartDate: null,
      contentSelectedDate: null,
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const date = searchParams.get("date");
    if (date) {
      const dateObj = new Date(date);
      this.setState({
        contentSelectedDate: dateObj,
      });
    } else {
      this.setState({
        contentSelectedDate: new Date(),
      });
    }
  }
  setAssignmentQuery = (payload) => {
    let { dataToPersist, curriculum } = this.props;
    let isToDisable = false;
    let selectedClass = sessionStorage.getItem("selectedClass");
    let getAssignmentQuery = {
      schoolId: sessionStorage.getItem("schoolId"),
      classId: selectedClass
        ? selectedClass
        : dataToPersist.selectedClass?.value,
      date: moment(payload.date).format("YYYY-MM-DDT[00:00:00.000Z]"),
    };
    this.props.dispatch({
      type: main.ASSIGNMENT_QUERY,
      payload: getAssignmentQuery,
    });
    if (
      moment(payload.date).format("YYYY-MM-DD") <
      moment(new Date()).format("YYYY-MM-DD")
    ) {
      isToDisable = true;
    }
    this.setState(
      {
        selectedGlIds: [],
        selectedCalendarDate: payload.date,
        isDisabledPastAssignmentBtns: isToDisable,
      },
      () => this.getAssignmentAndTableData()
    );
  };
  getAssignmentAndTableData = async () => {
    let { isDisabledPastAssignmentBtns } = this.state;
    await this.props.curriculumAction.getAssignedList(
      isDisabledPastAssignmentBtns
    );
  };
  getAssignmentData = async () => {
    let { isDisabledPastAssignmentBtns } = this.state;
    await this.props.curriculumAction.getAssignedList(
      isDisabledPastAssignmentBtns
    );
  };
  selectActivity = (activity) => {
    this.setState({
      selectedActivity: activity,
      tab: 2,
    });
  };
  redirectToTab = (date) => {
    this.props.redirectTab(date);
  };

  filterLetter = (alphabet, index) => {
    let { curriculum } = this.props;
    if (index != null) {
      let filterResul = curriculum.galleryListCopy.filter(
        (gl) => gl.letter == alphabet
      );
      this.props.dispatch({
        type: main.UPDATE_ASSIGNMENT_GALLERY_LIST,
        payload: filterResul,
      });
    } else {
      this.props.dispatch({
        type: main.UPDATE_ASSIGNMENT_GALLERY_LIST,
        payload: curriculum.galleryListCopy,
      });
    }
  };

  checkEmptys = () => {
    let { assignedData } = this.props.curriculum;
    let success = false;
    if (
      _.isEmpty(assignedData.program_Choice) &&
      assignedData.teacher_Choice.length <= 1
    ) {
      toast("Please create assignment to send", "error");
    } else if (_.isEmpty(assignedData.assignment_Name)) {
      toast("Assignment Name Cannot be empty", "error");
    } else {
      success = true;
    }
    return success;
  };

  modifyPayloadBeforeUpdate = async () => {
      console.log(this.props.curriculum, "this.props.curriculum");
    let { assignedData, getAssignmentQuery, curriculumAndProgramNames } =
      this.props.curriculum;
    assignedData.teacher_Choice.pop();
    let payload = {
      curriculum_Id: !_.isEmpty(curriculumAndProgramNames)
        ? curriculumAndProgramNames?.curriculum_Details?.curriculum_Id
        : "",
      program_Hierarachy: !_.isEmpty(assignedData.program_Hierarachy)
        ? assignedData.program_Hierarachy
        : [],
      program_Choice: !_.isEmpty(assignedData.program_Choice)
        ? assignedData.program_Choice
        : [],
      createdBy: localStorage.getItem("username"),
      classId: getAssignmentQuery.classId,
      schoolId: getAssignmentQuery.schoolId,
      start_Date: getAssignmentQuery.date,
      end_Date:
        assignedData.end_Date ||
        moment(getAssignmentQuery.date).add(1, "days").format("YYYY-MM-DD"),
      teacher_Choice: assignedData.teacher_Choice,
      assignment_Name: assignedData.assignment_Name,
      isAssignmentSent: true,
    };
    if (!_.isEmpty(assignedData._id)) {
      payload["_id"] = assignedData._id;
    }
    try {
      let url = "assignment/v3.7/createUpdateAssignment";
      let myInit = {
        headers: accessHeaders(),
        body: payload,
      };
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        return response.data;
      } else {
        toast(response?.message, "error");
        return null;
      }
    } catch (error) {
      toast(error?.response?.data?.message, "error");
      return null;
    }
  };

  sendAssignment = async () => {
    let director = localStorage.getItem("schoolCategory");
    let { activeStartDate } = this.state;
    let { dataToPersist } = this.props;
    try {
      let noemptyChecks = this.checkEmptys();
      if (noemptyChecks) {
        let savedResponse = await this.modifyPayloadBeforeUpdate();
        if (!_.isEmpty(savedResponse)) {
          let url = "assignment/v3.7/sendAssignment";
          let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
              _id: savedResponse["_id"],
            },
          };
          if (director !== "TUBclass Lite"){
          const response = await API.get(ENVIRONMENT, url, myInit);
          if (response.status === 200) {
            toast("Assignment Sent Successfully", "success");
            await this.getAssignmentData();
            let selectedClass = sessionStorage.getItem("selectedClass");
            let sessionDatesqueryPayload = {
              schoolId: sessionStorage.getItem("schoolId"),
              month:
                activeStartDate != null
                  ? moment(activeStartDate).format("MM").toString()
                  : moment().format("MM").toString(),
              classId: selectedClass
                ? selectedClass
                : dataToPersist.selectedClass?.value,
            };
            this.getAssignmentCalendarData(sessionDatesqueryPayload);
            this.setState({
              selectedGlIds: [],
            });
          } else {
            toast("Try Again Or Call Admin", "error");
          }
        }
          this.props.curriculumAction.getAssignmentGalleryList();
        }
      }
    } catch (error) {
      toast(error?.response?.data?.message, "error");
      console.log("error", error?.response);
    }
  
  };

  getNoOfTeacherChoiceToAdd = (assignment) => {
    let noOfTeacherChoiceToAdd = 0;
    if (!_.isEmpty(assignment.program_Choice)) {
      let noOfProgramChoice = assignment.program_Choice.length;
      switch (noOfProgramChoice) {
        case 1:
          noOfTeacherChoiceToAdd = 8;
          break;
        case 2:
          noOfTeacherChoiceToAdd = 7;
          break;
        case 3:
          noOfTeacherChoiceToAdd = 6;
          break;
        case 4:
          noOfTeacherChoiceToAdd = 5;
          break;
        case 5:
          noOfTeacherChoiceToAdd = 4;
          break;
        case 6:
          noOfTeacherChoiceToAdd = 3;
          break;
        case 7:
          noOfTeacherChoiceToAdd = 2;
          break;
        case 8:
          noOfTeacherChoiceToAdd = 1;
          break;
        case 9:
          noOfTeacherChoiceToAdd = 0;
          break;
      }
    } else noOfTeacherChoiceToAdd = 9;
    return noOfTeacherChoiceToAdd;
  };

  checkForSameWordAdded = (item) => {
    let { assignedData } = this.props.curriculum;
    if (
      Array.isArray(assignedData.program_Choice) &&
      assignedData.program_Choice.length > 0
    ) {
      let checkWordAdded = assignedData.program_Choice.find(
        (pc) => pc.imageTitle == item.title
      );
      if (checkWordAdded) {
        return true;
      }
    }
    return false;
  };
  addToTeacherChoice = (item) => {
    let { selectedGlIds } = this.state;
    let { assignedData } = this.props.curriculum;
    let isSameWordAdded = this.checkForSameWordAdded(item);
    if (isSameWordAdded) {
      toast(`Same Word Cannot be Added`, "error");
    } else {
      let checkForAssAdded = assignedData.teacher_Choice.find(
        (tc) => tc._id == item._id
      );
      let totalItems =
        assignedData?.teacher_Choice?.length +
        assignedData?.program_Choice?.length;
      if (totalItems > 9 || assignedData?.program_Choice?.length == 9) {
        toast(`Only 9 words can be added`, "error");
      } else {
        if (!checkForAssAdded) {
          assignedData.teacher_Choice.unshift({
            alphabetImage: item?.originalImage,
            imageTitle: item.title,
            word_Classification: item.word_Classification,
            alphabetName: item?.title.substring(0, 1),
            word_Category: "createdBy_Alphatub",
            tags: item.tags,
            stencilImage: item?.originalImage,
            _id: item._id,
            isSelected: true,
            isDraft: false,
          });
          if (!selectedGlIds.includes(item._id)) {
            selectedGlIds.push(item._id);
          }
          this.props.dispatch({
            type: main.ASSIGNED_LIST,
            payload: assignedData,
          });
          this.setState({ selectedGlIds: selectedGlIds });
        }
      }
    }
  };

  removeTeacherContent = (item) => {
    let { selectedGlIds } = this.state;
    let { assignedData } = this.props.curriculum;
    let getIndexToRemove = assignedData["teacher_Choice"].findIndex(
      (td) => td._id == item._id
    );
    let updatedGlIds = selectedGlIds.filter((gl) => !(gl == item._id));
    assignedData["teacher_Choice"].splice(getIndexToRemove, 1);
    this.props.dispatch({ type: main.ASSIGNED_LIST, payload: assignedData });
    this.setState({ selectedGlIds: updatedGlIds });
  };

  removeAllGallerySelectedIds = () => {
    this.setState({
      selectedGlIds: [],
    });
  };

  updateAssignment = async (payload) => {
    try {
      let url = "assignment/v3.7/createUpdateAssignment";
      let myInit = {
        headers: accessHeaders(),
        body: payload,
      };
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        await this.getAssignmentData();
      } else {
        toast(response?.message, "error");
      }
    } catch (error) {
      toast(error?.response?.data?.message, "error");
    }
  };
  setAssignmentName = (value) => {
    let { assignedData } = this.props.curriculum;
    assignedData["assignment_Name"] = value;
    this.props.dispatch({ type: main.ASSIGNED_LIST, payload: assignedData });
  };

  // changeDate = (selectedDate) => {
  //     let {assignedData} = this.props.curriculum
  //     assignedData["end_Date"] = moment(selectedDate).add(1, 'd').format('YYYY-MM-DDT[00:00:00.000Z]')
  //     getAssignmentQuery = {
  //         ...getAssignmentQuery,
  //         date : moment(selectedDate).format('YYYY-MM-DD')
  //     }
  //     this.props.dispatch({type: main.ASSIGNED_LIST, payload: assignedData})
  //     this.sendAssignment()

  // }

  updateDeadline = (selectedDate) => {
    let { assignedData } = this.props.curriculum;
    assignedData["end_Date"] = selectedDate;
    this.props.dispatch({ type: main.ASSIGNED_LIST, payload: assignedData });
  };
  addWordToTeacherChoice = (payload) => {
    let { assignedData } = this.props.curriculum;
    let noOfTeacherChoiceToAdd = this.getNoOfTeacherChoiceToAdd(assignedData);
    if (assignedData.teacher_Choice.length <= noOfTeacherChoiceToAdd) {
      assignedData.teacher_Choice.unshift({
        alphabetImage: payload.alphabetImage,
        imageTitle: payload.imageTitle,
        alphabetName: payload?.imageTitle.substring(0, 1),
        word_Category: "createdBy_Alphatub",
        tags: payload.tags,
        stencilImage: payload.alphabetImage,
        isSelected: false,
        isDraft: false,
      });
      this.props.dispatch({ type: main.ASSIGNED_LIST, payload: assignedData });
    } else {
      toast(`Only ${noOfTeacherChoiceToAdd} Icons can be added`, "error");
    }
  };

  // send notificaiton
  sendNotification = async (row) => {
    let { assignedData } = this.props.curriculum;
    let payload = {
      assignmentId: assignedData?._id,
      studentId: row._id,
    };
    await this.props.curriculumAction.sendAssignmentNotification(payload);
    await this.props.curriculumAction.getAssignmentTableList();
  };
  // notify notificaiton
  notify = async (row) => {
    let { assignedData } = this.props.curriculum;
    let payload = {
      assignmentId: assignedData?._id,
      playerId: row.playerId,
      teacherUserName: localStorage.getItem("username"),
    };
    await this.props.curriculumAction.notifyAssignment(payload);
    await this.props.curriculumAction.getAssignmentTableList();
  };
  getTableColumns = () => {
    let { assignedData } = this.props.curriculum;
    let action = {
      text: "Action",
      dataField: "action",
      headerClasses: "columnHeader text-center",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="text-center">
            {row.submissions.length > 0 ? (
              <p className="fs-13 global_color">Submitted</p>
            ) : !_.isEmpty(assignedData) &&
              moment(assignedData?.end_Date).format("YYYY-MM-DD") >=
                moment().format("YYYY-MM-DD") ? (
              row.submissions.length == 0 && row.newStudent ? (
                <p
                  className="secondary_color fs-13 cursor-pointer"
                  onClick={() => this.sendNotification(row)}
                >
                  Send
                </p>
              ) : (
                <p
                  className="global_color fs-13 cursor-pointer"
                  onClick={() => this.notify(row)}
                >
                  Notify
                </p>
              )
            ) : (
              <p className="fs-13 secondary_color">Closed</p>
            )}
          </div>
        );
      },
    };
    return [...sampleAssignmentScreenTableColumns, action];
  };
  redirect = (route) => {
    history.push(route);
  };

  isSameDay = (disDate, date) => {
    return differenceInCalendarDays(disDate, date) === 0;
  };
  tileDisabled = ({ date, view }) => {
    let { curriculum } = this.props;
    let { workingDaysOptions } = this.state;

    let getDisableDates = [],
      filteredDays;
    if (curriculum.assignmentCalendarData.sessionDisableDatesList.length > 0) {
      getDisableDates =
        curriculum.assignmentCalendarData.sessionDisableDatesList.find(
          (dDate) => this.isSameDay(dDate, date)
        );
    }
    if (curriculum.assignmentCalendarData.schoolAcademicYear != null) {
      let filteredIndexes = workingDaysOptions
        .filter(
          (fl) =>
            !curriculum.assignmentCalendarData.academicYear?.days.includes(
              fl.value
            )
        )
        .map((f) => f.index);
      if (filteredIndexes.length > 0) {
        filteredDays = filteredIndexes.includes(date.getDay());
      }
    }
    if (curriculum.assignmentCalendarData.sessionDisableDatesList.length > 0) {
      return filteredDays || getDisableDates;
    } else {
      return filteredDays;
    }
  };

  tileContent = ({ date, view }) => {
    let { curriculum } = this.props;

    if (view === "month") {
      let getContent = curriculum.assignmentCalendarData.assignmentDates.find(
        (dDate) => this.isSameDay(dDate, date)
      );
      if (getContent) {
        return "assignment_present_date";
      }
      let isdisabled = [];
      isdisabled =
        curriculum.assignmentCalendarData.sessionDisableDatesList.find(
          (dDate) => this.isSameDay(dDate, date)
        );
      let getUnAssignedContent =
        curriculum.assignmentCalendarData.unAssignmentDates.find((dDate) =>
          this.isSameDay(dDate, date)
        );
      console.log(getUnAssignedContent, "getUnAssignedContent");
      if (getUnAssignedContent) {
        return "program_date";
      } else if (isdisabled) {
        return "disabled_Date";
      }
    }
  };

  onChangeMonth = async (value) => {
    let dataToPersist = this.props.dataToPersist;
    let selectedClass = sessionStorage.getItem("selectedClass");

    let sessionDatesqueryPayload = {
      schoolId: sessionStorage.getItem("schoolId"),
      month: moment(value.activeStartDate).format("MM").toString(),
      classId: selectedClass
        ? selectedClass
        : dataToPersist.selectedClass?.value,
    };
    this.setState(
      {
        activeStartDate: value.activeStartDate,
      },
      this.getAssignmentCalendarData(sessionDatesqueryPayload)
    );
  };

  getAssignmentCalendarData = (query) => {
    this.props.curriculumAction.getAssingmentCalendarDatesByMonth(query);
  };

  onChangeDate = async (date) => {
    let dataToPersist = this.props.dataToPersist;
    let selectedClass = sessionStorage.getItem("selectedClass");
    let queryPayload = {
      schoolId: sessionStorage.getItem("schoolId"),
      classId: selectedClass
        ? selectedClass
        : dataToPersist.selectedClass?.value,
      date: moment(date).startOf("day").format("YYYY-MM-DD"),
    };
    this.setState(
      {
        contentSelectedDate: date,
      },
      this.setAssignmentQuery(queryPayload)
    );
  };

  removeAssignment = async (payload) => {
    let { contentSelectedDate } = this.state;
    await this.updateAssignment(payload);
    await this.onChangeMonth({ activeStartDate: contentSelectedDate });
  };
  render() {
    let state = this.state;
    let { curriculum, loader } = this.props;
    let { assignedData } = this.props.curriculum;
    console.log(assignedData?.program_Choice?.length, "choiceeeeee");
    return (
      <>
        <div className="row no-gutters">
          <div className="col-12">
            <div className="row no-gutters">
              <div className="col-md-6">
                <p className="f-15 alphatub-card-bold-p">
                  {!_.isEmpty(curriculum.curriculumAndProgramNames)
                    ? curriculum.curriculumAndProgramNames?.curriculum_Details
                        ?.curriculum_Name
                    : ""}
                  <span className="optionalText">
                    {" "}
                    {!_.isEmpty(curriculum.curriculumAndProgramNames)
                      ? `[${curriculum.curriculumAndProgramNames?.reading_Program_Details?.program_Name}]`
                      : ""}{" "}
                  </span>
                </p>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <p className="f-15 alphatub-card-bold-p">
                  Total Words:{" "}
                  {!_.isEmpty(curriculum.curriculumAndProgramNames)
                    ? curriculum.curriculumAndProgramNames?.wordCount
                    : 0}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="row no-gutters">
              <div className="col-md-4">
                {loader.assignmentDatesFetching ? (
                  <InnerAppLoader />
                ) : (
                  <AssignmentCalendar
                    {...this.props}
                    redirectToTab={this.redirectToTab}
                    selectActivity={this.selectActivity}
                    tileDisabled={this.tileDisabled}
                    tileContent={this.tileContent}
                    changeMonth={this.onChangeMonth}
                    onChangeDate={this.onChangeDate}
                    contentSelectedDate={state.contentSelectedDate}
                    activeStartDate={state.activeStartDate}
                    getAssignmentData={this.getAssignmentData}
                    removeAllGallerySelectedIds={
                      this.removeAllGallerySelectedIds
                    }
                  />
                )}
              </div>
              <AssignmentView
                {...this.props}
                {...this.state}
                removeTeacherContent={this.removeTeacherContent}
                sendAssignment={this.sendAssignment}
                changeDate={this.changeDate}
                updateDeadline={this.updateDeadline}
                updateAssignment={this.updateAssignment}
                removeAssignment={this.removeAssignment}
                setAssignmentName={this.setAssignmentName}
                addWordToTeacherChoice={this.addWordToTeacherChoice}
              />
            </div>
          </div>
          {loader.assignmentTableFetching ? (
            <div className="col-12 mt-4">
              <InnerAppLoader />
            </div>
          ) : !_.isEmpty(this.props.curriculum.assignmentTableList) &&
            this.props.curriculum?.assignedData?.isAssignmentSent ? (
            <div className="assignment_status_container mt-4">
              <div className="d-flex justify-space-between">
                <p className="alp_text_5">
                  Status Assignment Number:{" "}
                  {this.props.curriculum.assignedData?.assignment_Number}
                </p>
                <p
                  className="alp_text_link cursor-pointer"
                  onClick={() =>
                    this.redirect("/dashboard/tubclass/trackassignment")
                  }
                >
                  See All Assignments
                </p>
              </div>
              <div className="d-flex justify-space-between my-3">
                <p className="alp_text_4">
                  Assignment{" "}
                  {this.props.curriculum.assignedData?.assignment_Number}
                </p>
                <div className="legend_container">
                  {state.legends.map((lg) => (
                    <div className="legend">
                      <p
                        className="legend_identifier"
                        style={{ backgroundColor: lg.color }}
                      ></p>
                      <p className="legend_text">{lg.text}</p>
                    </div>
                  ))}
                </div>
              </div>
              <CustomBootstrapTable
                rows={this.props.curriculum.assignmentTableList}
                columns={this.getTableColumns()}
                isLoading={this.props.loader.isTableDetailsFetching}
                keyField="_id"
                tableClass="assignment_track_table"
                tableMainClass="assignment_track_table"
              />
            </div>
          ) : (
            !_.isEmpty(this.props.curriculum?.assignedData) && (
              <>
                <div className="col-12">
                  <Letters
                    alphabets={ALPHABETS}
                    onChangeCustomOptions={this.filterLetter}
                  />
                </div>
                <div className="col-12 mt-2">
                  {curriculum.galleryList?.map((gl, i) => (
                    <div className="mt-2" key={i}>
                      <p className="gallery_letter">{gl.letter}</p>
                      <div className="mt-2">
                        <AssignmentGalleryList
                          {...this.state}
                          assignmentContent={gl.contents}
                          addToTeacherChoice={this.addToTeacherChoice}
                          removeAddedContent={this.removeTeacherContent}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  tubGallery,
  myProfile,
  loader,
  session,
  curriculum,
  dataToPersist,
}) => {
  return {
    tubGallery,
    myProfile,
    loader,
    session,
    curriculum,
    dataToPersist,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    curriculumAction: bindActionCreators(curriculumAction, dispatch),
    sessionAction: bindActionCreators(sessionAction, dispatch),
    dispatch,
  };
};

const Assignment = Wrapper(
  connect(mapStateToProps, mapDispatchToProps)(AssignmentMainClass)
);

export { Assignment };
