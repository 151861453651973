import { React, Component } from "react";
import { connect } from "react-redux";
import * as subscriptionAction from "../../../actions/subscriptionTeam";
import {
  isEmptyOrUndefine,
  isValidEmailAddress,
} from "../../../utils/common.utils";
import { bindActionCreators } from "redux";
import { Button, Table } from "reactstrap";
import FormInput from "../../../components/inputs/FormInput";
import PurchasedCard from "../../../components/subscriptionPlan/purchasedCard";
import errorMsgs from "../../../utils/errorMsgs";
import toast from "../../../utils/Toast";
import UpgradeSubscriptionPopup from "../../../components/subscriptionPlan/upgradeSubscriptionPopup";
import { Invoice } from "../../../components/Model/invoicePopup";
import { InvoiceColumns } from "../../../helpers/tableDataMapper";
import Wrapper from "../../../Hoc/wrapper";
import { accessHeaders } from "helpers";
import { API } from "aws-amplify";
import { ENVIRONMENT } from "common/constants";
import axios from "axios";

class SubscriptionClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: true,
      rolesDropdown: [],
      tableRolesDropdown: [],
      subscriptionDetails: null,
      inviteDetail: {
        Email: null,
        RoleID: null,
      },
      emptyBtnClicks: 0,
      emailValidBtnClicks: 0,
      showBuyMoreSeatsPopup: false,
      showInvoicePopup: false,
      tableLoading: false,
    };
  }

  componentDidMount() {
    this.getTeamsList();
    this.props.subscriptionTeam?.tableRolesDropdown.push({
      label: "Remove",
      value: "Remove",
    });
  }
  getTeamsList = async () => {
    this.setState({ tableLoading: true });
    await this.props.subscriptionAction.getSubscriptionData();
    this.setState({ tableLoading: false });
  };
  checkForApiTrigger = async (name, type) => {
    if (name === "showInvoicePopup" && type) {
      await this.props.getInvoicesList();
    }
  };
  triggerPopup = (name, type) => {
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => this.checkForApiTrigger(name, type)
    );
  };
  onChange = (name, value) => {
    let { inviteDetail } = this.state;
    inviteDetail[name] = value;
    this.setState({ inviteDetail: inviteDetail });
  };
  handleRemoveUser = async (row) => {
    try {
      let userid = row?.userId;
      let schoolId = JSON.parse(localStorage.getItem("schoolInfo"))._id;
      let url = `team/?userId=${userid}&schoolId=${schoolId}`;
      let myInit = {
        headers: accessHeaders(),
        //   body: payload,
      };
      const response = await API.del(ENVIRONMENT, url, myInit);
      if (response) {
        this.setState({ tableLoading: false });
        toast("Deleted successfully!", "success");
        this.getTeamsList();
      }
    } catch (error) {
      toast(error?.response?.data.message, "error");
      this.setState({ tableLoading: false });
    }
  };
  changeRoleOfTeam = async (name, value, row) => {
    let payload = {
      _id: row._id,
      RoleID: value,
      adminId: localStorage.getItem("userid"),
      adminName: "",
    };
    this.setState({ tableLoading: true });
    if (value === "R10") {
      payload["Status"] = false;
      payload["Invitation_Status"] = 3;
    } else if (value === "Remove") {
      this.handleRemoveUser(row);
    } else {
      await this.props.editTeamDetail(payload);
      this.setState({ tableLoading: false });
    }
  };
  checkRequiredFields = () => {
    let { inviteDetail, emptyBtnClicks, emailValidBtnClicks } = this.state;
    if (
      isEmptyOrUndefine(inviteDetail["Email"]) ||
      isEmptyOrUndefine(inviteDetail["RoleID"])
    ) {
      let newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
      this.setState(
        {
          emptyBtnClicks: newBtnClicks,
        },
        () => this.showToast(newBtnClicks, 1)
      );
      return false;
    } else if (!isValidEmailAddress(inviteDetail["Email"])) {
      let newBtnClicks =
        emailValidBtnClicks === 4 ? 1 : emailValidBtnClicks + 1;
      this.setState(
        {
          emailValidBtnClicks: newBtnClicks,
        },
        () => this.showToast(newBtnClicks, 2)
      );
      return false;
    }
    return true;
  };
  showToast = (btnClicks, type) => {
    let errMsg = "";
    if (type === 1) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW001[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW001[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW001[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW001[3];
          break;
        default:
          errMsg = errorMsgs.TCW001[0];
          break;
      }
    } else if (type === 2) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW031[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW031[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW031[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW031[3];
          break;
        default:
          errMsg = errorMsgs.TCW031[0];
          break;
      }
    }
    toast(errMsg, "error");
  };
  invitePerson = async () => {
    let { inviteDetail } = this.state;
    if (this.checkRequiredFields()) {
      let data = localStorage.getItem("schoolInfo");
      let schoolInfo = JSON.parse(data);
      inviteDetail.SchoolName = schoolInfo.SchoolName;
      this.setState({ tableLoading: true });
      let res = await this.props.sendInviteMail(inviteDetail);
      if (res) {
        this.setState({
          inviteDetail: {
            Email: "",
            RoleID: "",
          },
          tableLoading: false,
        });
      } else {
        this.setState({ tableLoading: false });
      }
    }
  };
  isDisabled = () => {
    let role = localStorage.getItem("role");
    let disabled = role === "R01" || role === "R02" ? false : true;
    return disabled;
  };
  resentRequest = async (row) => {
    let payload = {
      Email: row.Email,
    };
    this.setState({ tableLoading: true });
    await this.props.resentMail(payload);
    this.setState({ tableLoading: false });
  };
  getRowRole = (row) => {
    return row.RoleID === "R01"
      ? "Admin"
      : row.RoleID === "R02"
      ? "Manager"
      : row.RoleID === "R03"
      ? "Teacher"
      : row.RoleID === "R04"
      ? "Trainee"
      : row.RoleID === "R05"
      ? "Substitute Teacher"
      : row.RoleID === "R06"
      ? "Volunteer"
      : "";
  };
  render() {
    let { subscriptionTeam, loader } = this.props;
    let role = localStorage.getItem("role");
    let roleName =
      role === "R01"
        ? "Admin"
        : role === "R02"
        ? "Manager"
        : role === "R03"
        ? "Teacher"
        : "";
    let {
      inviteDetail,
      showBuyMoreSeatsPopup,
      showInvoicePopup,
      tableLoading,
    } = this.state;
    let disabled = this.isDisabled();
    let profile = JSON.parse(sessionStorage.getItem("profileDetail"));

    const handleSubscriptionUpgrade = async () => {
      try {
        let schoolId = JSON.parse(localStorage.getItem("schoolInfo"))._id;
        const payload = { schoolId: schoolId };
        let url = `admin-requests`;
        let myInit = {
          headers: accessHeaders(),
          body: payload,
        };
        const response = await API.post(ENVIRONMENT, url, myInit);
        if (response) {
          this.setState({ tableLoading: false });
          toast("Subscription upgraded successfully!", "success");
          this.getTeamsList();
        }
      } catch (error) {
        toast(error?.response?.data.message, "error");
        this.setState({ tableLoading: false });
      }
    };
    let director = localStorage.getItem("schoolCategory");
    console.log(subscriptionTeam, "subscriptionTeam");
    const roles = [
      { label: "Manager", value: "R02" },
      { label: "Teacher", value: "R03" },
      { label: "Trainee", value: "R04" },
      { label: "Volunteer", value: "R06" },
    ];

    const dropdownOptions = roles.map((role) => ({
      label: role.label,
      value: role.value,
    }));
    return (
      <>
        <div className="row no-gutters">
          {!disabled && (
            <div className="col-md-3">
              <div className="row no-gutters">
                <div className="col-12 col-md-11">
                  <PurchasedCard
                    disabled={disabled}
                    subscriptionInfo={subscriptionTeam.subscriptionInfo}
                    triggerPopup={this.triggerPopup}
                  />
                  {director === "TUBclass Lite" ? (
                    <div className="d-flex">
                      <Button
                        color="primary"
                        className="mt-2 mx-auto"
                        onClick={handleSubscriptionUpgrade}
                      >
                        Upgrade to TUB Class
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          <div className={disabled ? "col-md-12" : `col-md-9`}>
            <div className="team_right_content">
              <div className="row no-gutters">
                <div className="col-9">
                  <h3 className="login-text-login text-left">Your Team</h3>
                </div>
              </div>
              {!disabled && (
                <div className="row no-gutters mt-3">
                  <div className="col-10">
                    <div className="row no-gutters space-between">
                      <div className="col-6">
                        <FormInput
                          type="email"
                          placeholder={`Enter E-Mail Id`}
                          name="Email"
                          groupclassName="m-0"
                          className="subscriptionborder team-emailinput border-none"
                          disabled={disabled}
                          onChange={(...params) => this.onChange(...params)}
                          value={inviteDetail["Email"]}
                          // error={errors["campaignName"]}
                        />
                      </div>
                      <div className="col-6" style={{ position: "relative" }}>
                        <FormInput
                          type="select"
                          placeholder="Select Role"
                          name="RoleID"
                          groupclassName="m-0"
                          className="subscriptionborder m-0 team-roleinput border-none"
                          disabled={disabled}
                          options={dropdownOptions} // Pass the generated options here
                          onChange={(...params) => this.onChange(...params)}
                          value={inviteDetail["RoleID"]}
                          // showDownArrow={false}
                          // error={errors["campaignName"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="row justify-flex-end no-gutters">
                      <button
                        className="bg-white btn btn-primary small-button w-100px"
                        onClick={() => this.invitePerson()}
                        disabled={disabled}
                      >
                        Invite
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="row no-gutters mt-2">
                <div className="col-12">
                  <div className="table-responsive">
                    <Table
                      borderless
                      className={`table-theme subscription_table`}
                    >
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Name</th>
                          <th>Email Id</th>
                          <th></th>
                          <th className="text-center">Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableLoading ? (
                          <tr>
                            <td
                              className="py-3 fs-18 text-center empty"
                              colSpan="100"
                            >
                              Loading...
                            </td>
                          </tr>
                        ) : subscriptionTeam &&
                          subscriptionTeam.subscriptionData &&
                          subscriptionTeam.subscriptionData !==
                            "No Teams Data" &&
                          subscriptionTeam.subscriptionData.length > 0 ? (
                          <>
                            {subscriptionTeam.subscriptionData &&
                              subscriptionTeam.subscriptionData.map(
                                (row, i) => (
                                  <tr key={i}>
                                    <td>
                                      {i < 9 ? 0 : ""}
                                      {i === 0 ? 1 : i + 1}
                                    </td>
                                    <td>
                                      {row?.Invitation_Status === 1 ? (
                                        <p className="color-fade ">
                                          Invitation pending
                                        </p>
                                      ) : (
                                        <p className="">
                                          {row.firstName != undefined &&
                                          row.lastName != undefined
                                            ? row.firstName + " " + row.lastName
                                            : row.firstName != undefined
                                            ? row.firstName
                                            : "-"}
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {row?.Invitation_Status === 1 ? (
                                        <p className="color-fade">
                                          {row.Email}
                                        </p>
                                      ) : (
                                        row.Email
                                      )}
                                    </td>
                                    {row?.Invitation_Status === 1 &&
                                    !disabled ? (
                                      <td
                                        className="resend-request cursor-pointer"
                                        onClick={() => this.resentRequest(row)}
                                      >
                                        {"Resend Request"}
                                      </td>
                                    ) : (
                                      <td className="resend-request"></td>
                                    )}
                                    {row["RoleID"] == "R01" ? (
                                      <td className="text-center">
                                        <div>Admin</div>
                                      </td>
                                    ) : row["Email"] == profile?.Email &&
                                      row["RoleID"] == profile?.RoleID ? (
                                      <td className="text-center">
                                        <div>{this.getRowRole(row)}</div>
                                      </td>
                                    ) : (
                                      <td className="td-role">
                                        {/* <FormInput
                                          type="select"
                                          name="RoleID"
                                          groupclassName="m-0"
                                          className="subscriptionborder bg_transparent theme-border text-align-center border-none"
                                          options={
                                            subscriptionTeam?.tableRolesDropdown
                                          }
                                          onChange={(...params) =>
                                            this.changeRoleOfTeam(
                                              ...params,
                                              row
                                            )
                                          }
                                          value={row["RoleID"]}
                                          placeholder="Select Role"
                                          disabled={disabled}
                                          // showDownArrow={true}
                                        /> */}
                                        <FormInput
                                          type="select"
                                          name="RoleID"
                                          groupclassName="m-0"
                                          className="subscriptionborder bg_transparent theme-border text-align-center border-none"
                                          options={[
                                            { label: "Manager", value: "R02" },
                                            { label: "Teacher", value: "R03" },
                                            { label: "Trainee", value: "R04" },
                                            {
                                              label: "Volunteer",
                                              value: "R06",
                                            },
                                            {
                                              label: "Remove",
                                              value: "Remove",
                                            },
                                          ]}
                                          onChange={(...params) =>
                                            this.changeRoleOfTeam(
                                              ...params,
                                              row
                                            )
                                          }
                                          value={row.RoleID}
                                          placeholder="Select Role"
                                          disabled={disabled}
                                        />
                                      </td>
                                    )}
                                  </tr>
                                )
                              )}
                          </>
                        ) : (
                          <>
                            {loader.isLoading ? (
                              "tableLoading..."
                            ) : (
                              <tr>
                                <td>No Teams Found</td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <UpgradeSubscriptionPopup
            showBuyMoreSeatsPopup={showBuyMoreSeatsPopup}
            triggerPopup={() =>
              this.triggerPopup("showBuyMoreSeatsPopup", false)
            }
            subscriptionTeam={subscriptionTeam}
            page="subscription"
            {...this.props}
          />
        </div>
        <Invoice
          columns={InvoiceColumns}
          rows={subscriptionTeam.invoicesList}
          loader={loader}
          showInvoicePopup={showInvoicePopup}
          triggerPopup={this.triggerPopup}
        />
      </>
    );
  }
}

const mapStateToProps = ({ subscriptionTeam, loader, myProfile }) => {
  return {
    subscriptionTeam,
    loader,
    myProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(subscriptionAction, dispatch);
};

const Subscription = Wrapper(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionClass)
);

export { Subscription };
