import React, { useEffect, useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const checkIsActive = (selectedValue, activeTab) => {
  let checkIsArray = Array.isArray(selectedValue);
  if (checkIsArray) {
    return selectedValue.join("") === activeTab.join("");
  } else return selectedValue === activeTab;
};

const BasicTab = (props) => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs(props.tabs);
  });

  const setToggleOptions = (index, type) => {
    let newTabs = [...tabs];
    newTabs[index]["toggleDropdown"] = type;
    setTabs(newTabs);
  };

  const onChange = (index, i, value, route) => {
    let newTabs = [...tabs];
    newTabs[index]["spanText"] = newTabs[index]["options"][i]["spanText"];
    setTabs(newTabs);
    props.onChange(value, route);
  };

  return (
    <Nav tabs className={`${props.className}`}>
      {tabs.map((data, index) => (
        <>
          {data.options === undefined ? (
            data.roles.includes(props.role) ? (
              <NavItem className="col-md-3 p-0">
                <NavLink
                  className={
                    checkIsActive(data.value, props.activeTab) ? "active" : ""
                  }
                  onClick={() => props.onChange(data.value, data.route)}
                >
                  <span className="tab-text">{data.label}</span>
                  <span className="dp-spanText">{data?.spanText}</span>
                </NavLink>
              </NavItem>
            ) : (
              <NavItem className="col-md-3 p-0">
                <NavLink className={"disabled_tab"}>
                  <span className="tab-text">{data.label}</span>
                  <span className="dp-spanText">{data?.spanText}</span>
                </NavLink>
              </NavItem>
            )
          ) : data.roles.includes(props.role) ? (
            <Dropdown
              isOpen={data.toggleDropdown}
              onMouseOver={() => setToggleOptions(index, true)}
              onMouseLeave={() => setToggleOptions(index, false)}
              className="nav-item col-md-3 p-0 tab-dropdown"
              toggle={() => setToggleOptions(index, true)}
            >
              <DropdownToggle
                className={
                  checkIsActive(data.value, props.activeTab)
                    ? "tab-dropdown-btn tab-dropdown-btn-active"
                    : "tab-dropdown-btn"
                }
                onClick={() => onChange(index, 0, data.value, data.route)}
              >
                {data.label}
                <span className="dp-spanText">{data.spanText}</span>
              </DropdownToggle>
              <DropdownMenu className="tab-dropdown-menu">
                {data.options.map((op, i) => (
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => onChange(index, i, op.value, op.route)}
                  >
                    {op.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          ) : (
            <NavItem className="col-md-3 p-0">
              <NavLink className={"disabled_tab"}>
                <span className="tab-text">{data.label}</span>
                <span className="dp-spanText">{data?.spanText}</span>
              </NavLink>
            </NavItem>
          )}
        </>
      ))}
    </Nav>
  );
};

export default BasicTab;

BasicTab.defaultProps = {
  tabs: [],
  onChange: () => {},
  name: "",
  activeTab: [],
  className: "custom-tab-theme-1",
};
