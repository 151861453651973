import { useEffect, useState } from 'react';
import ButtonType1 from '../../../components/inputs/ButtonType1';
import Header from 'components/Header/header';
import { checkIsPrivacyAccepted, history } from 'helpers';

const Privacy = () => {
    const [isPrivacyPolicyAccepted, setIsPrivacyAccepted] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)

    
    useEffect(() => {
        const isPrivacyAccepted = checkIsPrivacyAccepted()
        if(isPrivacyAccepted){
            setIsBtnDisabled(true)
            setIsPrivacyAccepted(true)
        } else {
            setIsBtnDisabled(false)
            setIsPrivacyAccepted(false)
        }
    }, [])

    const acceptPrivacy = async () => {
        const isPrivacyAccepted = checkIsPrivacyAccepted()
        if(!isPrivacyAccepted){
            localStorage.setItem("isPrivacyPolicyAccepted", "true")
            history.goBack()
        }
    }

    return (
        <>
            <Header />
            <div className="maincontainer auth-background">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-11">
                            <div className="mt-2">
                                <h1 className="terms-heading">Terms of Service</h1>
                            </div>
                            <div className="pt-2 pb-2">
                                <h2 className='mt-2'>1. INTERPRETATION</h2>
                                <p className="mt-3 mb-3">The definitions and rules of interpretation in this condition apply in these conditions.</p>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">Company: alphaTUB Innovations Private Limited</p>
                                </div>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">Contract: any contract between the Company and You for the sale and purchase of any Products which shall incorporate these Terms</p>
                                </div>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">Delivery: delivery of the Products at the address specified in the Order.</p>
                                </div>
                                <div>
                                    <p className="privacy-content">
                                        IP Rights: alphaTUB' Innovation is embodied in its Intellectual Property, including Patents, Designs, Patents Pending,
                                        Trademarks, and Copyrights. Intellectual Property is the foundation and lifeline of our business and preservation of
                                        Intellectual Property is our highest priority. All intellectual property rights of any description in the Products including
                                        without limitation patents, rights to inventions, copyright and related rights, moral rights, trade marks, trade names and domain names,
                                        rights in get-up, rights in goodwill or to sue for passing off, rights in designs, rights in computer software, database rights, rights
                                        in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case whether registered
                                        or unregistered and including all applications (or rights to apply) for, and renewals or extensions of, such rights and all similar or equivalent
                                        rights or forms of protection which may now or in the future subsist in any part of the world. Refer to Section on Intellectual Property for further details.
                                    </p>
                                </div>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">
                                        Order: the offer made by You via the Website or Mobile App to purchase one or more Products from the Company including Pre-Orders where applicable.
                                    </p>
                                </div>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">
                                        Pre-Order: the offer made by You via the Website or Mobile App to purchase one or more Products that are not yet available for delivery from the Company.
                                    </p>
                                </div>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">
                                        Products: refers to only the hardware goods (such as TUB Board, Accessories or Printed Content), offered for sale via the Website as specified thereon, (each a "Product").
                                    </p>
                                </div>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">
                                        The Term Product does not includes any form of software, IP, mobile application or a reference to any type of software code, the same shall be subject to "End-Use-License-Agreement" for the software.
                                    </p>
                                </div>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">
                                        Terms: these terms and conditions of sale for customers purchasing via the website or Mobile Application.
                                    </p>
                                </div>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">
                                        Website: the website at www.alphatub.comand any other website published by the Company from time to time, the Term Website also includes alphaTUB Mobile Application.
                                    </p>
                                </div>
                                <div className="mt-4 mb-4">
                                    <p className="privacy-content">
                                        You: the person using the Website and/or making an Order to purchase any Products or the User of the Mobile App or Online-Services.
                                    </p>
                                </div>
                                <div className="privacy_end_border"></div>
                                <div className="row mt-4 align-items-center no-gutters">
                                    <div className="col-lg-10">
                                        <input
                                            type="checkbox"
                                            id="check1"
                                            name="privacy"
                                            className="remember-checkbox"
                                            disabled = {isBtnDisabled}
                                            value={isPrivacyPolicyAccepted}
                                            checked={isPrivacyPolicyAccepted}
                                            onChange={() => {
                                                setIsPrivacyAccepted(!isPrivacyPolicyAccepted)
                                            }}
                                        />
                                        <label className="fw-600 checkboxLabel cursor-pointer ml-2" for="check1">I Agree to the Privacy Policy and Terms of service</label>
                                    </div>
                                </div>
                                {!isBtnDisabled ?
                                    <div className='row justify-content-center mt-3'>
                                        <div className="col-lg-2 col-4 privacy_acc">
                                            <ButtonType1
                                                btnName="I Accept"
                                                className='buttonType1'
                                                disabled={!isPrivacyPolicyAccepted}
                                                onClick={() => acceptPrivacy()}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Privacy }