import React, { Component } from "react";
import { Navbar, Container, Nav,DropdownButton,Dropdown,NavDropdown } from 'react-bootstrap';
import { isEmptyOrUndefine } from '../../utils/common.utils';
import "../../assets/css/main.scss"
import { Auth } from 'aws-amplify';

export default class Header extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false };
  }

  signout = async () => {
    try {
      await Auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  componentDidMount() {
    const user = localStorage.getItem('CognitoIdentityServiceProvider.4rhnl49np9qostoq466bg8p69u.LastAuthUser');
    if (!isEmptyOrUndefine(user)) {
      this.setState({ isLoggedIn: true });
    }
  }

  render() {
    return (
      <Navbar expand="lg" className="auth-navbar">
        <Container fluid>
          <Navbar.Brand className="prodName align-items-center">
            <img
              src={require("../../assets/images/Alogo.png")}
              className="logo"
              alt="logo"
            ></img>
            <span className="logo-text ">TUB Class</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="ml-auto align-items-center">
              <Nav.Link href="https://alphatub.com/" className="nav-link-text static_nav" target="_blank" rel="noopener noreferrer">Home</Nav.Link>
              <Nav.Link href="https://alphatub.com/how-we-benefit/" className="nav-link-text static_nav" target="_blank" rel="noopener noreferrer">How We Benefit</Nav.Link>
              <NavDropdown 
                  className="collasible-nav-dropdown"
              >
                <NavDropdown.Item href="https://alphatub.com/how-we-benefit/personalized-learning/">Personalized Learning</NavDropdown.Item>
                <NavDropdown.Item href="https://alphatub.com/how-we-benefit/parental-engagement/">Parental Engagement</NavDropdown.Item>
                <NavDropdown.Item href="https://alphatub.com/how-we-benefit/insights-analytics/">Insights & Analytics</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="https://alphatub.com/offerings/" className="nav-link-text static_nav" target="_blank" rel="noopener noreferrer">What We Do!</Nav.Link>
              <Nav.Link href="https://alphatub.com/how-it-works/" className="nav-link-text static_nav" target="_blank" rel="noopener noreferrer">How It Works</Nav.Link>
              <Nav.Link href="https://alphatub.com/users/" className="nav-link-text static_nav" target="_blank" rel="noopener noreferrer">Who Can Use</Nav.Link>
              <NavDropdown 
                  className="collasible-nav-dropdown"
              >
                <NavDropdown.Item href="https://alphatub.com/parents/">Parents</NavDropdown.Item>
                <NavDropdown.Item href="https://alphatub.com/schools/">Schools</NavDropdown.Item>
                <NavDropdown.Item href="https://alphatub.com/users/child-care-centres/">Child Care Centers</NavDropdown.Item>
                <NavDropdown.Item href="https://alphatub.com/users/home-visitors/">Home Visitors</NavDropdown.Item>
                <NavDropdown.Item href="https://alphatub.com/users/language-centers/">Language Centers</NavDropdown.Item>
                <NavDropdown.Item href="https://alphatub.com/users/parent-teacher-association/">Parent Teacher Association</NavDropdown.Item>
                <NavDropdown.Item href="https://alphatub.com/users/administrators/">Administrators</NavDropdown.Item>
                <NavDropdown.Item href="https://alphatub.com/users/universities-colleges/">Universities & Colleges</NavDropdown.Item>

              </NavDropdown>
              <Nav.Link href="https://blog.alphatub.com/" className="nav-link-text static_nav" target="_blank" rel="noopener noreferrer">Blog</Nav.Link>
              <Nav.Link href="https://alphatub.com/shop" className="tubstore static_nav" target="_blank" rel="noopener noreferrer">TUB Store</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    );
  }
}