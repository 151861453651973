import { React, Component } from "react";
import { connect } from "react-redux";
import {
  ALL_CONTENTS_FILTERS,
  ALPHATUB_PRODUCTS_OPTIONS,
  ANALYTICS_DATE_FILTER,
  GAMES_OPTIONS,
} from "../../../helpers/variables";
import FormInput from "../../../components/inputs/FormInput";
import {
  ST_LEARGNING_CURVE,
  ST_ACTIVE_LIST,
  ST_TOP_SCORE_CONTENT,
  ST_ACTIVE_TAGS_COUNT,
  ST_RECENTLY_PLAYED_CONTENT,
  ST_ENGAGEMENT_METER_COLUMNS,
  SOCIAL_AWARENESS_REPORT_COLUMNS,
} from "../../../helpers/tableDataMapper";
import { CustomBootstrapTable } from "../../../components/Table/CustomBootstrapTable";
import { AppLoader } from "../../../components/loader/appLoader";
import * as analyticsAction from "../../../actions/analytics";
import { bindActionCreators } from "redux";
import Stackedbarchart from "../../../components/charts/apex/stackedbarchart";
import SplineArea from "../../../components/charts/apex/SplineArea";
import Barchart from "../../../components/charts/apex/barchart";
import PieChart from "../../../components/charts/apex/PieChart";
import { ChartBaseComponent } from "../../../components/charts/chartBaseComponent";
import _, { noop } from "lodash";
import { Card, CardBody } from "reactstrap";
import Wrapper from "../../../Hoc/wrapper";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DateRangeFilterPopup } from "components/tubClass/dateRangeFilterPopup";
import { CustomSelectFilter } from "components/filters/customSelectFilter";
import moment from "moment";
import ReactSelect from "react-select";
import Apaexlinecolumn from "components/charts/apex/apaexlinecolumn";
import HorizontalBarchart from "components/charts/apex/horizonatalBarchart";

class OverallClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentOptions: ALL_CONTENTS_FILTERS,
      gamesOptions: GAMES_OPTIONS,
      filterOptions: ANALYTICS_DATE_FILTER,
      alphatubProductsOption: ALPHATUB_PRODUCTS_OPTIONS,
      noOfDays: "30",
      isScroll: false,
      showFilterDatePopup: false,
      selectedDateRange: null,
      selectedFilter: ANALYTICS_DATE_FILTER[0],
    };
  }
  componentDidMount() {
    this.setStickyTabs();
  }
  triggerAllApis = (noOfDays) => {
    let { allGrpahsList } = this.props.analytics;
    noOfDays = noOfDays.toString();
    this.props.analyticsAction.fetchGraphData(
      0,
      "curriculumProgress",
      null,
      null,
      null
    );
    this.props.analyticsAction.fetchGraphData(
      1,
      "parentEngagementMeterForHomeSchoolOverall",
      noOfDays,
      {
        contentFilter: allGrpahsList[1].contentFilter,
        gameFilter: allGrpahsList[1].gameFilter,
      },
      1
    );
    this.props.analyticsAction.fetchGraphData(
      2,
      "assinmentCompletion",
      noOfDays,
      {
        contentFilter: allGrpahsList[2].contentFilter,
        gameFilter: allGrpahsList[2].gameFilter,
      },
      1
    );
    this.props.analyticsAction.fetchGraphData(
      3,
      "avgScoreByStudentForHomeSchoolOverall",
      noOfDays,
      {
        contentFilter: allGrpahsList[2].contentFilter,
        gameFilter: allGrpahsList[2].gameFilter,
      },
      1
    );
    this.props.analyticsAction.fetchGraphData(
      4,
      "classLearningProgressForHomeSchoolOverall",
      noOfDays,
      {
        contentFilter: allGrpahsList[2].contentFilter,
        gameFilter: allGrpahsList[2].gameFilter,
      },
      1
    );
    this.props.analyticsAction.fetchGraphData(
      5,
      "socialAwernesReportForHomeSchoolOverall",
      noOfDays,
      {
        contentFilter: allGrpahsList[2].contentFilter,
        gameFilter: allGrpahsList[2].gameFilter,
      },
      1
    );
    this.props.analyticsAction.fetchGraphData(
      6,
      "alphbaticExposureForOverAll",
      noOfDays,
      {
        contentFilter: allGrpahsList[2].contentFilter,
        gameFilter: allGrpahsList[2].gameFilter,
      },
      1
    );
    this.props.analyticsAction.fetchGraphData(
      7,
      "wordsQuizAccuracyForOverAll",
      noOfDays,
      {
        contentFilter: allGrpahsList[2].contentFilter,
        gameFilter: allGrpahsList[2].gameFilter,
      },
      1
    );
    this.props.analyticsAction.fetchGraphData(
      8,
      "voacabluaryReportByStudent",
      noOfDays,
      {
        contentFilter: allGrpahsList[2].contentFilter,
        gameFilter: allGrpahsList[2].gameFilter,
      },
      1
    );
    this.props.analyticsAction.fetchGraphData(
      9,
      "userWiseContributionByOverAll",
      noOfDays,
      {
        contentFilter: allGrpahsList[2].contentFilter,
        gameFilter: allGrpahsList[2].gameFilter,
      },
      1
    );
  };

  unselectSelectedValue = (type) => {
    if (type == "full") {
      this.setState(
        {
          noOfDays: "",
        },
        this.triggerAllApis("")
      );
    }
  };
  onChangeDateFilter = (name, value) => {
    if (value?.value == "customDates") {
      this.setState({
        selectedDateRange: null,
        showFilterDatePopup: true,
        selectedFilter: value,
      });
    } else {
      this.setState(
        {
          noOfDays: value?.value,
          selectedFilter: value,
          selectedDateRange: null,
        },
        this.triggerAllApis(value?.value)
      );
    }
  };
  setStickyTabs = () => {
    let isScroll = false;
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10) {
        isScroll = true;
      } else isScroll = false;
      this.setState({
        isScroll: isScroll,
      });
    });
  };

  triggerPopup = (val, data) => {
    if (val === "showFilterDatePopup") {
      this.unselectSelectedValue("filterByDate");
    }
    this.setState({
      [val]: !this.state[val],
    });
  };

  onChangeFilterDate = (ranges) => {
    let startDate = moment(ranges.startDate);
    let endDate = moment(ranges.endDate);
    let diff = moment.duration(endDate.diff(startDate)).asDays();
    this.setState(
      {
        noOfDays: diff,
        selectedDateRange: ranges,
        showFilterDatePopup: false,
      },
      this.triggerAllApis(diff)
    );
  };

  render() {
    let state = this.state;
    let { allGrpahsList } = this.props.analytics;
    const category = localStorage.getItem("schoolCategory");
    const isTubClassSeniorCategory = category === 'TUBclass Senior';
    const isTubClassLiteCategory = category === 'TUBclass Lite';
    return (
      <>
        <div
          className={`analytics_ovl_cl_main justify-flex-end ${
            state.isScroll ? "sticky_bx_shadow" : ""
          }`}
        >
          <div className="col-md-2 col-5 px-3">
            <div className="custom-select-filter">
              <ReactSelect
                classNamePrefix={`m-0`}
                name={"filterByDate"}
                options={state.filterOptions}
                placeholder={"Select Filter"}
                value={state.selectedFilter}
                onChange={(val) => this.onChangeDateFilter("filterByDate", val)}
                isClearable={false}
              />
            </div>
            {state.selectedDateRange != null && (
              <p className="selected_date_range_filter">
                ({state.selectedDateRange?.startDate} -{" "}
                {state.selectedDateRange.endDate})
              </p>
            )}
          </div>
        </div>

        <div className="mt-3 py-1">
          {/* Curriculum Progress */}
          <div className="report row no-gutters">
            {allGrpahsList[0].loading ? (
              <Skeleton height="200px" width="100%" />
            ) : (
              <div className="col-12">
                <Card>
                  <CardBody className="d-flex">
                    <div className="col-3 p-0">
                      <div className="report_heading">
                        <p>Curriculum Progress</p>
                      </div>
                    </div>
                    <div className="col-9">
                      {allGrpahsList[0]?.graphData.percentage !== 0 ? (
                        <>
                          <div>
                            <div className="curriculum_progress d-flex">
                              <div
                                className="first_half position-relative"
                                style={{
                                  width: `${allGrpahsList[0]?.graphData?.percentage}%`,
                                }}
                              >
                                <p className="position-absolute ml-1">
                                  {allGrpahsList[0]?.graphData.percentage !=
                                  null
                                    ? `${allGrpahsList[0]?.graphData.percentage}% Completed`
                                    : ""}
                                </p>
                              </div>
                              <div
                                className="second_half"
                                style={{
                                  width: `${
                                    100 -
                                    allGrpahsList[0]?.graphData?.percentage
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="d-flex justify-flex-end mt-1">
                            <p className="report_gp_1_total_count">
                              {allGrpahsList[0]?.graphData?.Total_Explored} /{" "}
                              {
                                allGrpahsList[0]?.graphData
                                  ?.Total_Curriculum_Words
                              }{" "}
                              words
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="flex_all_property mt-2">
                          <p>No Data Found</p>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
          </div>
          {/*Enivronmental Awareness report*/}
          <ChartBaseComponent
            loading={allGrpahsList[5].loading}
            title={"Environmental Awareness Report"}
            report_number={"0006"}
            children={
              <div className="d-flex">
                <div className="col-md-6 col-12">
                  <PieChart
                    x_axis={allGrpahsList[5].graphData?.x_axis}
                    y_axis={allGrpahsList[5].graphData?.y_axis}
                    showLegend={false}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <div className="analytics_content_list">
                    <div className="report_type">
                      <p>List of Top Tags Exposure</p>
                    </div>
                    <div className="col-12">
                      <CustomBootstrapTable
                        rows={allGrpahsList[5].graphData.data}
                        columns={SOCIAL_AWARENESS_REPORT_COLUMNS}
                        isLoading={false}
                        keyField="id"
                        tableClass="custom-row-class"
                        tableMainClass="analytics_table"
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          />

          {/* Student Wise Parental Engagement */}
          {!(isTubClassSeniorCategory || isTubClassLiteCategory) && <ChartBaseComponent
            loading={allGrpahsList[1].loading}
            title={"Parental Engagement by Relationship"}
            report_number={"0002"}
            children={
              <Stackedbarchart
                colors={["#00e396", "#775dd0", "#ED7D2B", "#00ADEF"]}
                y_axis_title="Number Of Games"
                x_axis_title="Students"
                showToolBar={false}
                series={[
                  {
                    name: "Father",
                    data: allGrpahsList[1].graphData?.y_axis,
                  },
                  {
                    name: "Mother",
                    data: allGrpahsList[1].graphData?.y_axis_1,
                  },
                  {
                    name: "Guardian 1",
                    data: allGrpahsList[1].graphData?.y_axis_2,
                  },
                  {
                    name: "Guardian 2",
                    data: allGrpahsList[1].graphData?.y_axis_3,
                  },
                ]}
                x_axis={allGrpahsList[1].graphData?.x_axis}
              />
            }
          />}
          {!isTubClassLiteCategory && <ChartBaseComponent
            loading={allGrpahsList[10]?.loading}
            title={
              isTubClassSeniorCategory ? "Student Engagement Based on Play and Share Count" : "Parental Engagement Based on Play & Share Count"
            }
            report_number={"0010"}
            children={
              <Apaexlinecolumn
                x_axis={allGrpahsList[10]?.graphData?.x_axis}
                y_axis={allGrpahsList[10]?.graphData?.y_axis}
                y_axis_2={allGrpahsList[10]?.graphData?.y_axis_2}
                y_axis_title={"Number of Plays and Shares"}
                x_axis_title={"Students"}
                showToolBar={false}
                name_2={"Shares"}
                name={"Play Count"}
                stacked={true}
                dataEnabledOffsetY={-14}
                dataEnabledOffsetX={0}
                legendPosition={"top"}
                horizontalAlign={"right"}
                colors={["#00ADEF", "#ED7D2B"]}
                offsetY={parseInt(state.noOfDays) >= 30 ? 90 : 90}
              />
            }
          />}
          {/* Assignment Completion Graph */}
          {!isTubClassLiteCategory && <ChartBaseComponent
            loading={allGrpahsList[2].loading}
            title={"Assignment Completion Graph"}
            report_number={"0003"}
            children={
              <Barchart
                x_axis={allGrpahsList[2].graphData?.x_axis}
                y_axis={allGrpahsList[2].graphData?.y_axis}
                y_axis_title={"Number of Assignments Completed"}
                x_axis_title={"Students"}
                showToolBar={false}
                name={"No of Assignments completed"}
                colors={["#00ADEF"]}
              />
            }
          />}
          {/* Average Score of each Child for particular period */}
          <ChartBaseComponent
            loading={allGrpahsList[3].loading}
            title={"Average Score of Each Child For Particular Period"}
            report_number={"0004"}
            children={
              <Barchart
                x_axis={allGrpahsList[3].graphData?.x_axis}
                y_axis={allGrpahsList[3].graphData?.y_axis}
                y_axis_title={"Average Score"}
                name={"Average Score"}
                showToolBar={false}
                x_axis_title={"Students"}
                colors={["#00ADEF"]}
              />
            }
          />

          {/* Alphabetic Awareness (Student wise) Based on Exposure vs Accuracy (Column Chart - apex chart)  */}
          <ChartBaseComponent
            loading={allGrpahsList[6]?.loading}
            title={
              "Alphabetic Awareness (Student wise) Based on Exposure vs Accuracy"
            }
            report_number={"0006"}
            children={
              <Apaexlinecolumn
                x_axis={allGrpahsList[6]?.graphData?.x_axis}
                y_axis={allGrpahsList[6]?.graphData?.y_axis}
                y_axis_2={allGrpahsList[6]?.graphData?.y_axis_2}
                y_axis_title={"Number Of Words"}
                x_axis_title={"Student"}
                showToolBar={false}
                name_2={"Accuracy"}
                name={"Exposure"}
                dataEnabledOffsetY={-14}
                dataEnabledOffsetX={0}
                legendPosition={"top"}
                horizontalAlign={"right"}
                colors={["#00ADEF", "#ED7D2B"]}
                offsetY={parseInt(state.noOfDays) >= 30 ? 90 : 90}
              />
            }
          />
          {/* Picture Word Association Based on Quiz Data */}
          <ChartBaseComponent
            loading={allGrpahsList[7].loading}
            title={"Picture Word Association Based on Quiz Data"}
            report_number={"0007"}
            children={
              <Barchart
                x_axis={allGrpahsList[7].graphData?.x_axis}
                y_axis={allGrpahsList[7].graphData?.y_axis}
                y_axis_title={"Accuracy"}
                name={"Accuracy"}
                showToolBar={false}
                x_axis_title={"Students"}
                colors={["#00ADEF"]}
              />
            }
          />
          {/* Vocabulary rEPORT */}
          <div className="row no-gutters mt-4 br-5">
            <div className="col-12 report">
              <Card>
                <CardBody>
                  {allGrpahsList[8]?.loading ? (
                    <Skeleton
                      style={{ marginTop: 15 }}
                      height="350px"
                      width="100%"
                    />
                  ) : (
                    <div className="row no-gutters justify-space-between">
                      <div className={!isTubClassLiteCategory ? "col-5" : "col-12"}>
                        <div className="row no-gutters">
                          <div className="col-12">
                            <div className="report_heading">
                              <p>{"Home - School Vocabulary Report"}</p>
                            </div>
                            <div className="main_graph mt-2">
                              <p className="horizontal_chrt_title"></p>
                              <HorizontalBarchart
                                series={[
                                  {
                                    name: "No Of Words",
                                    data: allGrpahsList[8].graphData?.x_axis,
                                  },
                                ]}
                                y_axis={allGrpahsList[8].graphData?.y_axis}
                                colors={[
                                  "#00ADEF",
                                  "#00e396",
                                  "#ff4560",
                                  "#775dd0",
                                ]}
                                showToolBar={false}
                                y_axis_title=""
                                x_axis_title="No Of Words"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                       {!isTubClassLiteCategory && <div className="col-6">
                        <div className="row no-gutters">
                          <div className="col-12">
                            <div className="report_heading">
                              <p>{"User Wise Contribution Report"}</p>
                            </div>
                            <div className="main_graph">
                              <p className="horizontal_chrt_title"></p>
                              <HorizontalBarchart
                                series={[
                                  {
                                    name: "No Of Topics",
                                    data: allGrpahsList[9].graphData?.x_axis,
                                  },
                                ]}
                                y_axis={allGrpahsList[9].graphData?.y_axis}
                                colors={[
                                  "#00ADEF",
                                  "#00e396",
                                  "#ff4560",
                                  "#775dd0",
                                ]}
                                name={"Games Played"}
                                showToolBar={false}
                                x_axis_title="No Of Topics"
                              />
                            </div>
                          </div>
                        </div>
                      </div>}
                      </div>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
          {/* Learning Progress of a Whole class  */}
          <ChartBaseComponent
            loading={allGrpahsList[4].loading}
            title={"Learning Progress of a Whole class Over a Period of Time"}
            report_number={"0005"}
            children={
              <SplineArea
                x_axis={allGrpahsList[4].graphData?.x_axis}
                y_axis={allGrpahsList[4].graphData?.y_axis}
                y_axis_title={"Average Score"}
                x_axis_title={"Date"}
                showToolBar={true}
                offsetY={parseInt(state.noOfDays) >= 30 ? 135 : 90}
              />
            }
          />
        </div>

        {/* Filter date popup*/}
        <DateRangeFilterPopup
          showFilterDatePopup={state.showFilterDatePopup}
          triggerPopup={this.triggerPopup}
          onChangeFilterDate={this.onChangeFilterDate}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  myProfile,
  loader,
  classes,
  analytics,
  dataToPersist,
}) => {
  return {
    myProfile,
    loader,
    classes,
    analytics,
    dataToPersist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    analyticsAction: bindActionCreators(analyticsAction, dispatch),
    dispatch,
  };
};

const Overall = Wrapper(
  connect(mapStateToProps, mapDispatchToProps)(OverallClass)
);

export { Overall };
