import _ from 'lodash'

export const RejectedPendingObject = ({rejectedObjects, _id, className}) => {
    return (
        <>
        {(!_.isEmpty(rejectedObjects) && rejectedObjects[_id]) ?
            <>
                <div className={`rejected_inbox_pending_container ${className}`}></div>
                <div className='rejected_inbox_content'>
                    <img
                        src={require('../../assets/images/inbox_reject.png')}
                        className="wrong-answer-img"
                        alt="alphatub"
                    />
                </div>
            </>
            :
            <></>
        }
        </>
    )
}