import { createStore,applyMiddleware } from 'redux'
import {reducers} from '../reducer'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key : "dataToPersist",
    storage: storage,
    whitelist: ['dataToPersist']
}

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer,applyMiddleware(thunk))

let persistor = persistStore(store);

export { store, persistor}


// import { createStore,applyMiddleware } from 'redux'
// import { reducers } from '../reducer'
// import thunk from 'redux-thunk'

// export const store = createStore(reducers,applyMiddleware(thunk))

 