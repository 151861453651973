import React from "react";
import {
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import DatePicker from "react-date-picker";
import visibilitypassword from "../../../assets/images/visibilitypassword.png";
import hiddenpassword from "../../../assets/images/hiddenpassword.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
let defaultMobileNumberPrefix = ["91", "81"];

const FormInput = ({ type, ...rest }) => {
  const handleNumberChange = (event) => {
    let { value } = event.target;
    value = value.replace(/[^0-9]/gi, "");
    let { min = 0, max } = rest;
    if (rest.max && value)
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    return value;
  };
  const handleDecimalinput = (event) => {
    let { value } = event.target;
    let validInput = new RegExp(/^\d*\.?\d*$/).test(value);

    if (validInput) return value;
  };
  console.log(rest.error, "rest.error");
  const getInput = () => {
    switch (type) {
      case "email":
        return (
          <FormGroup className={`pb-3 ${rest.groupclassName}`}>
            {rest.label != undefined && (
              <Label for="exampleEmail" className={rest.labelClassName}>
                {rest.label}
              </Label>
            )}
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type="text"
                  name={rest.name}
                  max={rest.max}
                  // pattern="[^\s]+"
                  className={`input-bg ${rest.className}`}
                  disabled={rest.disabled}
                  placeholder={rest.placeholder}
                  value={rest.value ? rest.value.replace(/\s/g, "") : ""}
                  onChange={(e) => rest.onChange(e.target.name, e.target.value)}
                />
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "percent":
        return (
          <FormGroup className={`pb-3 ${rest.className}`}>
            <Label for="exampleEmail" className={rest.labelClassName}>
              {rest.label}
            </Label>
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type="number"
                  name={rest.name}
                  max={rest.max}
                  className="input-bg"
                  min={rest.min}
                  disabled={rest.disabled}
                  value={parseInt(rest.value) > -1 ? rest.value : ""}
                  onChange={(e) =>
                    rest.onChange(e.target.name, handleNumberChange(e))
                  }
                />
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "percentageWithIcon":
        return (
          <FormGroup className={`pb-3 ${rest.className}`}>
            <Label for="exampleEmail" className={rest.labelClassName}>
              {rest.label}
            </Label>
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type="text"
                  name={rest.name}
                  className="input-bg"
                  max={100}
                  disabled={rest.disabled}
                  value={parseInt(rest.value) > -1 ? rest.value : ""}
                  onChange={(e) =>
                    rest.onChange(e.target.name, handleNumberChange(e))
                  }
                ></Input>
                <i
                  className="fa fa-percent percentageWithIcon-icon"
                  aria-hidden="true"
                ></i>
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );

      case "number":
        return (
          <FormGroup className={`pb-3 ${rest.className}`}>
            <Label for="exampleEmail" className={rest.labelClassName}>
              {rest.label}
              {rest.isOptional ? (
                <span className="text-yellow">&nbsp;( Optional )</span>
              ) : (
                ""
              )}
            </Label>
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type="text"
                  className="input-bg"
                  name={rest.name}
                  maxLength={rest.max}
                  disabled={rest.disabled}
                  value={parseInt(rest.value) > -1 ? rest.value : ""}
                  onChange={(e) =>
                    rest.onChange(
                      e.target.name,
                      e.target.value.replace(/[^0-9]/gi, "")
                    )
                  }
                />
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "only-number":
        return (
          <FormGroup className={`pb-3 ${rest.className}`}>
            <Label for="exampleEmail" className={rest.labelClassName}>
              {rest.label}
              {rest.isOptional ? (
                <span className="text-yellow">&nbsp;( Optional )</span>
              ) : (
                ""
              )}
            </Label>
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type="text"
                  className="input-bg"
                  name={rest.name}
                  maxLength={rest.max}
                  disabled={rest.disabled}
                  value={parseInt(rest.value) > -1 ? rest.value : ""}
                  onChange={(e) => rest.onChange(e.target.name, e.target.value)}
                />
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "decimal":
        return (
          <FormGroup className={`pb-3 ${rest.className}`}>
            <Label for="exampleEmail" className={rest.labelClassName}>
              {rest.label}
            </Label>
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type="text"
                  className="input-bg"
                  name={rest.name}
                  maxLength={rest.max}
                  disabled={rest.disabled}
                  value={
                    rest.value && parseFloat(rest.value) > -1 ? rest.value : ""
                  }
                  onChange={(e) =>
                    rest.onChange(e.target.name, handleDecimalinput(e))
                  }
                />
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "mobile":
        return (
          <FormGroup className={`${rest.className}`}>
            <Label for="exampleEmail" className={rest.labelClassName}>
              {rest.label}
            </Label>
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type="text"
                  className="input-bg"
                  name={rest.name}
                  maxLength={"10"}
                  disabled={rest.disabled}
                  value={
                    rest.value && !isNaN(rest.value) ? Number(rest.value) : ""
                  }
                  onChange={(e) => {
                    rest.onChange(
                      e.target.name,
                      e.target.value.replace(/[^0-9]/gi, "")
                    );
                  }}
                />
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "select":
        return (
          <FormGroup className={rest.groupclassName}>
            {rest.label != undefined && rest.label != "" && (
              <Label for={rest.name} className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="text-yellow">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <div className="custom-select-caret column-direction">
              <CustomInput
                disabled={rest.disabled}
                onChange={(e) => rest.onChange(e.target.name, e.target.value)}
                type="select"
                className={`cursor-pointer input-bg ${rest.className} ${
                  rest.disabled
                    ? ""
                    : rest.value && rest.selectType == 1
                    ? ""
                    : "customInputSelect"
                }`}
                id="selectinput"
                value={rest.value}
                name={rest.name}
                defaultValue=""
              >
                {rest.defaultSelect ? (
                  <option value="" hidden>
                    {rest.placeholder}
                  </option>
                ) : (
                  ""
                )}

                {rest.options.map((data, i) => (
                  <option key={i} value={data.value} disabled={data.disabled}>
                    {data.label}
                  </option>
                ))}
              </CustomInput>
              {rest.value && rest.selectType == 1 && (
                <div
                  className="close_input_select"
                  onClick={() => rest.unselectSelectedValue()}
                >
                  <FontAwesomeIcon icon={faClose} />
                </div>
              )}
              <strong className="text-danger small">
                {rest.error ? rest.error[0] : ""}
              </strong>
            </div>
            {rest.optionalRightLabel ? (
              <Label className="ml-2">{rest.optionalRightLabel}</Label>
            ) : (
              ""
            )}
          </FormGroup>
        );
      case "password":
        return (
          <FormGroup className={`${rest.className}`}>
            <Label for="exampleEmail" className={rest.labelClassName}>
              {rest.label}
            </Label>
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type={rest.isPasswordVisible ? "text" : "password"}
                  name={rest.name}
                  className="input-bg"
                  // max={100}
                  disabled={rest.disabled}
                  value={rest.value}
                  onChange={(e) => rest.onChange(e.target.name, e.target.value)}
                ></Input>
                <div className="password-img">
                  <img
                    src={
                      rest.isPasswordVisible
                        ? visibilitypassword
                        : hiddenpassword
                    }
                    alt="alphatub"
                    className="password-icon"
                    onClick={() => rest.togglePassword()}
                  />
                </div>
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "text":
        return (
          <FormGroup className={`${rest.className}`}>
            {rest.label != undefined && rest.label != "" && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="optionalText">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <Col className="p-0">
              <Input
                type="text"
                name={rest.name}
                value={rest.value}
                disabled={rest.disabled}
                maxLength={rest.maxLength}
                className={`input-bg ${rest.groupclassName}`}
                placeholder={rest.placeholder}
                onChange={(e) => rest.onChange(e.target.name, e.target.value)}
              />
              {/* .replace(/[^a-zA-Z\s]/g, "") */}
              {rest.bottomOptionText != undefined && (
                <span className="optionalText float-right pt-1">
                  &nbsp; {rest.bottomOptionText}
                </span>
              )}
            </Col>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
            <small>{rest?.spantext}</small>
          </FormGroup>
        );
      case "text-without-space":
        return (
          <FormGroup className={`${rest.className}`}>
            {rest.label != undefined && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="text-yellow">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <Col className="p-0">
              <Input
                type="text"
                name={rest.name}
                value={rest.value}
                disabled={rest.disabled}
                maxLength={rest.maxLength}
                className={`input-bg ${rest.groupclassName}`}
                placeholder={rest.placeholder}
                onChange={(e) =>
                  rest.onChange(
                    e.target.name,
                    e.target.value.replace(/\s/g, "")
                  )
                }
              />
              {/* .replace(/[^a-zA-Z\s]/g, "") */}
            </Col>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "checkbox":
        return (
          <>
            <div className="d-flex align-items-center">
              <Input
                type="checkbox"
                name={rest.name}
                value={rest.value}
                disabled={rest.disabled}
                checked={rest.checked}
                className={`input-bg ${rest.className}`}
                placeholder={rest.placeholder}
                onChange={(e) => rest.onChange(e.target.name, e.target.value)}
              />
              <Label for="exampleEmail" className={rest.radiolabelClass}>
                {rest.radiolabel}
              </Label>
            </div>
          </>
        );

      case "search":
        return (
          <FormGroup className={`${rest.className}`}>
            {rest.label != undefined && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="text-yellow">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <div className="relative-pos">
              <Col className="p-0">
                <Input
                  type="text"
                  name={rest.name}
                  value={rest.value}
                  disabled={rest.disabled}
                  maxLength={rest.maxLength}
                  className={`input-bg ${rest.groupclassName}`}
                  placeholder={rest.placeholder}
                  onChange={(e) => rest.onChange(e.target.name, e.target.value)}
                />
                {/* .replace(/[^a-zA-Z\s]/g, "") */}
                <div className="search-icon">
                  <img
                    src={require("../../../assets/images/searchicon.png")}
                    className="logo"
                  />
                </div>
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "no-special-character":
        return (
          <FormGroup className="pb-3 mb-0">
            <Label>
              {rest.label}
              {rest.isOptional ? (
                <span className="text-yellow">&nbsp;( Optional )</span>
              ) : (
                ""
              )}
            </Label>
            <Col className="p-0">
              <Input
                type="text"
                name={rest.name}
                value={rest.value}
                className="input-bg"
                disabled={rest.disabled}
                maxLength={rest.maxLength}
                placeholder={rest.placeholder}
                onChange={(e) =>
                  rest.onChange(
                    e.target.name,
                    e.target.value.replace(/[^a-z0-9]/gi, "")
                  )
                }
              />
              {/* .replace(/[^a-zA-Z\s]/g, "") */}
            </Col>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "only-text":
        return (
          <FormGroup className={`pb-3 mb-0 ${rest.className}`}>
            {rest.label != undefined && rest.label != "" && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="optionalText">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <Col className="p-0">
              <Input
                type="text"
                name={rest.name}
                value={rest.value}
                disabled={rest.disabled}
                maxLength={rest.maxLength}
                placeholder={rest.placeholder}
                className="input-bg"
                onChange={(e) =>
                  rest.onChange(
                    e.target.name,
                    e.target.value.replace(/[^a-zA-Z\s]/g, "")
                  )
                }
              />
              {rest.bottomOptionText != undefined && (
                <span className="optionalText float-right pt-1">
                  &nbsp; {rest.bottomOptionText}
                </span>
              )}
            </Col>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "only-text-with-comma":
        return (
          <FormGroup className={`pb-3 mb-0 ${rest.className}`}>
            {rest.label != undefined && rest.label != "" && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="optionalText">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <Col className="p-0">
              <Input
                type="text"
                name={rest.name}
                value={rest.value}
                disabled={rest.disabled}
                maxLength={rest.maxLength}
                placeholder={rest.placeholder}
                className="input-bg"
                onChange={(e) =>
                  rest.onChange(
                    e.target.name,
                    e.target.value.replace(/[^a-zA-Z\s]/g, "")
                  )
                }
              />
              {rest.bottomOptionText != undefined && (
                <span className="optionalText float-right pt-1">
                  &nbsp; {rest.bottomOptionText}
                </span>
              )}
            </Col>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "no-special-character":
        return (
          <FormGroup className="pb-3 mb-0">
            <Label className={rest.labelClassName}>
              {rest.label}
              {rest.isOptional ? (
                <span className="text-yellow">&nbsp;( Optional )</span>
              ) : (
                ""
              )}
            </Label>
            <Col className="p-0">
              <Input
                type="text"
                name={rest.name}
                value={rest.value}
                disabled={rest.disabled}
                maxLength={rest.maxLength}
                className="input-bg"
                placeholder={rest.placeholder}
                onChange={(e) =>
                  rest.onChange(
                    e.target.name,
                    e.target.value.replace(/[^a-z0-9]/gi, "")
                  )
                }
              />
              {/* .replace(/[^a-zA-Z\s]/g, "") */}
            </Col>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "textarea":
        return (
          <FormGroup className={`pb-3 ${rest.className}`}>
            {rest.label != undefined && rest.label != "" && (
              <>
                <Label for="exampleEmail" className={rest.labelClassName}>
                  {rest.label}
                </Label>
                {rest.isOptional ? (
                  <span className="optional-text text-yellow">
                    &nbsp;( Optional )
                  </span>
                ) : (
                  ""
                )}
              </>
            )}
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type="textarea"
                  name={rest.name}
                  value={rest.value}
                  className="input-bg"
                  disabled={rest.disabled}
                  readOnly={rest.readOnly}
                  placeholder={rest.placeholder}
                  maxLength={rest.maxLength}
                  onChange={(e) => rest.onChange(e.target.name, e.target.value)}
                />
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "file":
        return (
          <FormGroup className="mb-3">
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <div>
                  <Input type="file" {...rest} />
                </div>
              </Col>
            </div>
          </FormGroup>
        );
      case "radio":
        return (
          <>
            {
              <div className="d-flex column-direction align-items-center">
                <Input
                  // id={ `${rest.value}`}
                  type="radio"
                  name={rest.name}
                  checked={rest.checked}
                  disabled={rest.disabled}
                  value={rest.value}
                  onChange={(e) => rest.onChange(rest.name, rest.value)}
                />
                <Label className="cursor-pointer">{rest.radiolabel}</Label>
              </div>
            }
          </>
        );
      case "time":
        return (
          <FormGroup className="pb-3">
            <Label for="exampleEmail">{rest.label}</Label>
            {rest.isOptional ? (
              <span className="optional-text text-yellow">
                &nbsp;( Optional )
              </span>
            ) : (
              ""
            )}
            <div className="mobile-user d-flex w-100">
              <Col className="p-0">
                <Input
                  type="time"
                  name={rest.name}
                  value={rest.value}
                  disabled={rest.disabled}
                  className="input-bg"
                  readOnly={rest.readOnly}
                  placeholder={rest.placeholder}
                  maxLength={rest.maxLength}
                  onChange={(e) => rest.onChange(e.target.name, e.target.value)}
                />
              </Col>
            </div>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      case "detail":
        return (
          <FormGroup>
            <Label>{rest.label}</Label>
            <div className="bg-text p-1">
              <ul>
                {rest.details &&
                  rest.details.map((pr, i) => (
                    <li className="pt-1">
                      {i + 1}. {pr.productName} x {pr.noOfItemsAdded}{" "}
                      {pr.quantityType}
                    </li>
                  ))}
              </ul>
            </div>
          </FormGroup>
        );
      case "date-picker":
        return (
          <FormGroup className={`${rest.className}`}>
            <Label>
              {rest.label}
              {rest.isOptional ? (
                <span className="text-yellow">&nbsp;( Optional )</span>
              ) : (
                ""
              )}
            </Label>
            <Col className="p-0">
              <DatePicker
                className="input-bg border-0 w-100 border-radius-25rem form-control"
                placeholder={rest.placeholder}
                name={rest.name}
                onChangeRaw={(value) => rest.onChangeRaw(rest.name, value)}
                onChange={(value) => rest.onChange(rest.name, value)}
                value={rest.value}
                maxDate={rest.maxDate}
                minDate={rest.minDate}
              />
            </Col>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
      default:
        return (
          <FormGroup>
            <Label for={rest.name}>
              {rest.label}
              {rest.isOptional ? (
                <span className="text-yellow">&nbsp;( Optional )</span>
              ) : (
                ""
              )}
            </Label>
            <Input
              type={type}
              name={rest.name}
              className="input-bg"
              value={rest.value}
              disabled={rest.disabled}
              maxLength={rest.maxLength}
              onChange={(e) => rest.onChange(e.target.name, e.target.value)}
            />
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </FormGroup>
        );
    }
  };

  return getInput();
};

export default FormInput;

FormInput.defaultProps = {
  onChange: () => {},
  type: "text",
  options: [],
  defaultSelect: true,
  isOptional: false,
};
