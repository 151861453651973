import moment from "moment"
import React from "react"
import ReactApexChart from "react-apexcharts"

const Spinearea = (props) => {
  const series = [
    {
      name: "Average Score",
      data: props.y_axis,
    }
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    chart: {
      toolbar: {
        show: true,
        tools: {
          zoom: false,
          zoomin: true,
          zoomout: true,
          download: false,
          selection: false,
          pan: false,
          reset: false
        }
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        },
        initialAnimation: {
          enabled: true
        }
      }
    },
    plotOptions: {
      area: {
          fillTo: 'origin',
      }
  },
    noData: {
      text: "No data Found",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#7f7575",
        fontSize: '14px',
        fontFamily: 'Poppins'
      }
    },
    colors: props.colors,
    xaxis: {
      sorted: true,
      type: 'category',
      categories: props.x_axis,
      "title": {
        "text": props.x_axis_title,
        "style": {
            cssClass: 'x_axis_label'
        },
        offsetY: props.offsetY
      },
      labels: {
        trim: true,
        formatter: (val) => {
          return moment(val).format('DD-MMM')
        },
        style: {
          color: '#000000',
          cssClass: 'x_axis_content'
        },
        "trim": true,
        "offsetY": -2
      },
      tooltip: {
        enabled: true,
        offsetY: 100,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: -5,
        offsetY: -1
      }
    },
    yaxis: {
      labels: {
        "offsetX": -4
      },
      title: {
        text: props.y_axis_title,
        style: {
          color: '#000000',
          cssClass: 'y_axis_label'
        }
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: -2
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0.5
      },
    },
    grid: {
      show: true,
      borderColor: "#E0E0E0",
      strokeDashArray: 3,
      padding: {
        left: 10,
        bottom: 0
      }
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return val
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
    markers: {
      size: 1,
      strokeColors: '#000000',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
    }
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="450"
    />
  )
}

export default Spinearea
