import { main } from '../service/actionType'

const initialState = {
    invitationDetails: null,
}
const invitation =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.INVITATION_INFO:
            return {
                ...state,
                invitationDetails: payload
            }
        default:
            return state
    }

}
export default invitation;
