
import React, { useEffect, useState, useRef } from "react";
import {  Col } from "reactstrap";
import { toggleRootClassName, history } from '../../../helpers'
import { connect } from 'react-redux'
import "../../../assets/css/main.scss";
import vectorLaunchclass from '../../../assets/images/Vector.png'
import noImage from '../../../assets/images/noImage.png'
import { useCustomEventListener } from 'react-custom-events';
import moment from 'moment';
import { main } from "../../../service/actionType";

const Sidebar = (props) => {
  const [selectStudent, setSelectedStudent] = useState(null)
  const [ profileDetail, setProfileDetail] = useState({label: null , profilePic: {original: null}})
  const [ sessionSelectedDate, setSessionSelectedDate] = useState(new Date())
  const [ disableLaunch, disableLaunchTubclass] = useState(false)
  const { innerWidth: width } = window;
  const [height, setHeight] = useState(0)
  const rightScrollBar = useRef(null)
  const sideBar = useRef(null)
  const launchTubRef = useRef(null)

  useEffect(() => {
    // if(props.session.selectedTab === 3 && selectStudent === null){
    //   setSelectedStudent(null)
    // } else if(props.session.selectedTab !== 3) {
    //   setSelectedStudent(null)
    // }
    let profile = JSON.parse(sessionStorage.getItem('profileDetail'))
    setProfileDetail({...profile})
  }, [selectStudent, props])
  const redirectTo = (pathName) => {
    if(width <= 1199) {
      toggleRootClassName("open-sidebar")
    }
    history.push({
      pathname: pathName,
      state: {
        from: 'dashboard'
      }
    })
  }
  const triggerRespectiveTabApi = async (student) => {
    let selectedClass = sessionStorage.getItem('selectedClass')
    let { dataToPersist } = props
    let payload = {
      schoolId : sessionStorage.getItem('schoolId'),
      classId :  selectedClass != null ? selectedClass : dataToPersist.selectedClass?.value
    }
    
    if(props.session.selectedTab == 7) {
      if(student) payload.studentId = student._id
      else payload.studentId = null
      
      await props.galleryAction.getGalleryList(payload)
    } else {
      let newquery = {
        ...props.session.activityListQueryPayload
      }
      if(student) newquery.studentId = student._id
      else newquery.studentId = null
      await props.dispatch({ type: main.ACTIVITY_LIST_QUERY_PAYLOAD, payload: newquery})
      await props.sessionAction.getSelectedActivityList()
    }
  }
  const selectedStudent = async (student) => {
     if(selectStudent != null && selectStudent._id === student._id){
      setSelectedStudent(null)
      triggerRespectiveTabApi(null)
    }  else {
      setSelectedStudent(student)
      triggerRespectiveTabApi(student)
      
    }
  }
  const getHeightOfRightStudentsList = () => {
    if(props.tubGallery.rightSidebarStudentsList.length > 0) { 
        return sideBar.current?.clientHeight 
    }
  }
  useCustomEventListener('SESSION_SELECTED_DATE', (value) => {
    setSessionSelectedDate(value);
  });
  // useCustomEventListener('DISABLE_LAUNCH_TUBCLASS', (value) => {
  //   disableLaunchTubclass(value)
  // })
  const checkToRedirect = async () => {
    let attendanceTakenForaDay = await props.gameAction.checkAttendanceTaken()
    if(attendanceTakenForaDay){
      redirectTo("/class/presentation/home")
    } else {
      redirectTo("/class/presentation/attendance")
    }
  }
  const getLocation = () => {
    let path = window.location.pathname 
    if( path == '/dashboard/analytics/overall' ||
        path == '/dashboard/analytics/studentreport' 
      ){
        return false
      } else return true
  }
  console.log(props.session,"isReviewTubClassEnabled",props.session?.upcommingSessionList[0]?.date);
  let session_date = sessionStorage.getItem("session_date");

  const upcomingSessionDate = new Date(props.session?.upcommingSessionList[0]?.date);
  const sessionDate = new Date(session_date);
  const isReviewTubClassEnabled = upcomingSessionDate >= sessionDate;
  console.log(props.session?.upcommingSessionList[0]?.date, "isReviewTubClassEnabled", session_date, isReviewTubClassEnabled);
  
  return (
    <>
      <Col md="auto" className="right-side-nav p-0 rightsidebar" id="rightsidenav">
        <aside className="w-100 d-flex flex-column">
          <ul className="w-100 list-unstyled rightbarinner flex-grow-1 " ref={sideBar}>
            <li className={`nav-list-menu  text-center`}>
              {getLocation() ?
                (props.session.isSessionAvailable && isReviewTubClassEnabled && !props.session.isReviewTubClassEnabled) ?
                  <div className="launch-class" onClick={() => checkToRedirect() }>
                    <img src={vectorLaunchclass} className="launch-class-img" alt="alphatub" />
                    <h5>Launch</h5>
                    <p>TUB Class</p>
                  </div>
                : props.session.isReviewTubClassEnabled ? 
                    props.session.isSessionAvailable ?
                    <div className="launch-class" onClick={() => redirectTo("/class/presentation/home")}>
                      <img src={vectorLaunchclass} className="launch-class-img" alt="alphatub" />
                      <h5>Review</h5>
                      <p>TUB Class</p>
                    </div>
                    :
                    <div className="disable_launch launch-class">
                      <img src={vectorLaunchclass} className="launch-class-img" alt="alphatub"/>
                      <h5>Review</h5>
                      <p>TUB Class</p>
                    </div>
                :
                  <div className="disable_launch launch-class">
                    <img src={vectorLaunchclass} className="launch-class-img" alt="alphatub"/>
                    <h5>Launch</h5>
                    <p>TUB Class</p>
                  </div>
                  :
                  <></>
              }
            </li>
            <div ref={launchTubRef}>
              <div className="text-center">
                <h5 className="right-tubtext">Class TUBs</h5>
              </div>
            </div>
            {/* {props.tubGallery.rightSidebarStudentsList.length > 0 && */}
            <div 
              className="rightScrollBar mt10"
              ref={rightScrollBar}
              style={{height: "80%"}}
              // style={{height: getHeightOfRightStudentsList()}}
            >
                <div className="righttub-members">
                  <img 
                    src={(profileDetail != null && profileDetail.profilePic != undefined && profileDetail.profilePic != null && profileDetail.profilePic?.original != undefined && profileDetail.profilePic?.original != '' && profileDetail.profilePic?.original != null)  ? profileDetail.profilePic?.original : noImage} 
                    className="studentProfileImg"
                    alt="alphatub"
                  />
                  <h5>{profileDetail?.label}</h5>
                </div>
              {props.tubGallery.rightSidebarStudentsList && props.tubGallery.rightSidebarStudentsList.map(tb => (
                ( props.session.selectedTab === 7 || props.session.isSelectedActivityTab) ?
                  <div className="righttub-members mt-2 cursor-pointer" onClick={() => selectedStudent(tb)}>
                    <img 
                      src={tb.profilePic?.original ? tb.profilePic?.original : noImage} 
                      className={`${(selectStudent != null && selectStudent._id == tb._id) ? "active_student_image" : ""} right_sidebar_student_image `}
                      alt="alphatub"
                    />
                    <h5>{tb.firstName ? tb.firstName : '-'}</h5>
                  </div>
                  :
                  <div className="righttub-members mt-2 cursor-pointer">
                    <img 
                      src={tb.profilePic?.original ? tb.profilePic?.original : noImage} 
                      className={`right_sidebar_student_image `}
                      alt="alphatub"
                    />
                    <h5>{tb.firstName ? tb.firstName : '-'}</h5>
                  </div>
              ))}
            </div>
            {/* } */}
          </ul>
        </aside>
      </Col>
    </>
  );
}

const mapStateToProps = ({ loader, profile , session}) => {
  return ({
    loader,
    profile,
    session
  })
}

export default connect(mapStateToProps, null)(Sidebar)
