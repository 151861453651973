import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBD4tIf8NomLCwmo6yzfU6k1fpVDhTI0X4",
    authDomain: "alphatub-54002.firebaseapp.com",
    projectId: "alphatub-54002",
    storageBucket: "alphatub-54002.appspot.com",
    messagingSenderId: "299282704895",
    appId: "1:299282704895:web:e9aff5faaf9a1aefef9fca",
    measurementId: "G-D2VZWMGL4W"
  };

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const publicKey = "BNXTcksPKZXe9Mfi1KEKP3iUYwrZRIN1JVjsFO0N_In_TEDJAnGQxsv1c9UhYsWD-BHaGQ2bj5R7m0Nue4Kux7w";

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });