import { loaders, main } from "../service/actionType";
import { API } from "aws-amplify";
import toast from "../helpers/Toast";
import { ENVIRONMENT } from "../common/constants";
import moment from "moment";
import {
  accessHeaders,
  getETubTotalRightWrong,
  getPlayerIdWhoAnswered,
  getPlayResult,
  getTotalRightOrWrong,
  history,
} from "../helpers/index";
import _ from "lodash";

export const getGameTypesAndModes = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let url = "common/GameTypesAndModes";
    let myInit = {
      headers: accessHeaders(),
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      dispatch({ type: main.GAME_TYPES_AND_MODES, payload: response.data });
    } else dispatch({ type: main.GAME_TYPES_AND_MODES, payload: [] });
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};
export const selectedGame = (id) => async (dispatch, getState) => {
  sessionStorage.setItem("selectedGame", id);
  dispatch({ type: main.SELECTED_GAME, payload: id });
};
export const selectedMode = (id) => async (dispatch, getState) => {
  sessionStorage.setItem("selectedMode", id);
  sessionStorage.setItem("previousMode", id);
  dispatch({ type: main.SELECTED_MODE, payload: id });
};

export const getActivityList = () => async (dispatch, getState) => {
  try {
    let { dataToPersist } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let session_date = sessionStorage.getItem("session_date");
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let url = "game/activity/list";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        schoolId: sessionStorage.getItem("schoolId"),
        classId: selectedClass
          ? selectedClass
          : dataToPersist.selectedClass.value,
        date:
          session_date != null ? session_date : moment().format("YYYY-MM-DD"),
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      const data = {
        data: response.data.result,
        isHoliday: response?.data?.isHoliday,
      };
      dispatch({
        type: main.GAME_ACTIVITY_LIST,
        payload: data,
      });
    } else dispatch({ type: main.GAME_ACTIVITY_LIST, payload: [] });
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};

export const selectedActivityId = (id) => async (dispatch, getState) => {
  dispatch({ type: main.SELECTED_ACTIVITY_ID, payload: id });
};
export const getDetailActivity = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let selectedClass = sessionStorage.getItem("selectedClass");
    let { dataToPersist } = getState();
    let selectedActivity = JSON.parse(
      sessionStorage.getItem("selectedTubActivity")
    );
    console.log(selectedActivity, "selectedActivity");
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let url = "game/activity/detail";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        id: selectedActivity._id,
        studentId: selectedActivity.studentId,
        classId: selectedClass
          ? selectedClass
          : dataToPersist.selectedClass.value,
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      sessionStorage.setItem("selectedObject", response.data.ObjectType);
      dispatch({ type: main.GAME_ACTIVITY_DETAIL, payload: response.data });
    } else dispatch({ type: main.GAME_ACTIVITY_DETAIL, payload: null });
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};

export const getPlayersList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { dataToPersist } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let url = "student/v3.6/getStudentAttendance";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        classId: selectedClass
          ? selectedClass
          : dataToPersist?.selectedClass?.value,
        schoolId: sessionStorage.getItem("schoolId"),
        toDayDate: moment().format("YYYY-MM-DD"),
        activeList: true,
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      dispatch({ type: main.PLAYERS_LIST, payload: response.data });
    } else dispatch({ type: main.PLAYERS_LIST, payload: [] });
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};

export const getAllPlayersList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { dataToPersist } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let url = "game/players/list";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        classId: selectedClass
          ? selectedClass
          : dataToPersist?.selectedClass?.value,
        schoolId: sessionStorage.getItem("schoolId"),
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      dispatch({ type: main.PLAYERS_LIST, payload: response.data });
    } else dispatch({ type: main.PLAYERS_LIST, payload: [] });
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};

export const selectedIndividualPlayer = (id) => async (dispatch, getState) => {
  let { game } = getState();
  game.playersList.map((pl, index) => {
    if (pl._id === id) {
      pl.isSelected = !pl.isSelected;
    } else {
      pl.isSelected = false;
    }
  });
  dispatch({ type: main.PLAYERS_LIST, payload: game.playersList });
};

export const storeGameSettings = (path, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { game, dataToPersist } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let schoolId = sessionStorage.getItem("schoolId");
    let gameType = sessionStorage.getItem("selectedGame");
    let gameMode = sessionStorage.getItem("selectedMode");
    let selectedPlayers;
    if (gameMode == 4) {
      selectedPlayers = game.playersList.map((pl) => ({
        ...pl,
        isSelected: true,
      }));
    } else {
      selectedPlayers = game.playersList.filter((pl) => pl.isSelected == true);
    }
    let requestPayload = {
      classId: selectedClass
        ? selectedClass
        : dataToPersist?.selectedClass?.value,
      schoolId: schoolId,
      playType: parseInt(gameType),
      playMode: parseInt(gameMode),
      student_list: selectedPlayers,
      totalStudentCount: selectedPlayers.length,
      gameSettingType: "Manual",
      // createdBy: localStorage.getItem('username')
    };
    if (parseInt(gameMode) === 3) {
      requestPayload.groupCount = data.groupCount;
      requestPayload.perGroupStudentCount = data.perGroupStudentCount;
      requestPayload.group_details = data.group_details;
    }
    let url = "session/storeGameSettings";
    let myInit = {
      headers: accessHeaders(),
      body: requestPayload,
    };
    const response = await API.post(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      sessionStorage.setItem("gameSettingId", response.data._id);
      dispatch({ type: main.SET_GAME_START_TIME, payload: new Date() });
      dispatch({ type: main.GAME_SETTINGS_DATA, payload: response.data });
      history.push({
        pathname: path,
      });
    } else {
      dispatch({ type: main.GAME_SETTINGS_DATA, payload: null });
      history.push({
        pathname: path,
      });
    }
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};

const generateRandomPlayer = (
  players,
  noOfPlayers,
  playersMappedWithQuestions
) => {
  let randPlayer = players[Math.floor(Math.random() * noOfPlayers)];
  let checkIsPlayerPresent = playersMappedWithQuestions.filter(
    (ft) => ft.playerId == randPlayer._id
  );
  if (checkIsPlayerPresent.length > 0) {
    generateRandomPlayer(players, noOfPlayers, playersMappedWithQuestions);
  } else {
    return randPlayer;
  }
};
export const getMatchTheObjectQuestions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { game } = getState();
    let selectedActivity = JSON.parse(
      sessionStorage.getItem("selectedTubActivity")
    );
    let gameSettingId = sessionStorage.getItem("gameSettingId");
    let url = "game/v3.6/typeOne/options";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        id: selectedActivity._id,
        gameSettigsId: gameSettingId,
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      dispatch({
        type: main.MATCH_THE_OBJECT_QUESTIONS,
        payload: response.data.questions,
      });
      dispatch({
        type: main.SELECTED_PLAYERS_LIST,
        payload: response.data.players,
      });
      let selectedMode = sessionStorage.getItem("selectedMode");
      let questions = response.data.questions;
      if (selectedMode == 2) {
        let noOfQuestions = questions.length;
        let players = response.data.players.student_list;
        let playersMappedWithQuestions = [];
        let newPlayers = [...players];
        for (let i = 0; i < noOfQuestions; i++) {
          let randomIndex = Math.floor(Math.random() * newPlayers.length);
          let randomVal = newPlayers[randomIndex];
          newPlayers.splice(randomIndex, 1);
          playersMappedWithQuestions.push({
            questionId: questions[i].constantId,
            alphabetName: questions[i].alphabetName,
            playerId: randomVal._id,
            playerName: randomVal.label,
          });
          if (newPlayers.length == 0) {
            newPlayers = [...players];
          }
        }
        dispatch({
          type: main.CURRENT_GAME_QUESTION,
          payload: playersMappedWithQuestions[0],
        });
        dispatch({
          type: main.QUESTION_MAPPED_WITH_RANDOM_PLAYERS,
          payload: playersMappedWithQuestions,
        });
      } else if (selectedMode == 3) {
        dispatch({ type: main.CURRENT_GROUP_PLAY_GROUP, payload: 0 });
      }
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    }
  } catch (error) {
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};

export const changeToNextQuestion = (index) => async (dispatch, getState) => {
  let { game } = getState();
  let selectedMode = sessionStorage.getItem("selectedMode");
  let selectedGame = sessionStorage.getItem("selectedGame");
  if (selectedMode == 2) {
    let currentQuestion = game.currentQuestion;
    let playersMappedWithQuestions = game.questionMappedWithRandomPlayers;
    let nextQuestion = playersMappedWithQuestions[index];
    dispatch({ type: main.CURRENT_GAME_QUESTION, payload: nextQuestion });
  } else if (selectedMode == 3) {
    let groups = game.selectedPlayersList.group_details;
    let questions = game.gameTypeOneQuestions;
    let currentGroupIndex = game.currentGroupPlayGroupIndex;
    if (groups.length == currentGroupIndex + 1) {
      currentGroupIndex = 0;
    } else {
      currentGroupIndex = currentGroupIndex + 1;
    }
    dispatch({
      type: main.CURRENT_GROUP_PLAY_GROUP,
      payload: currentGroupIndex,
    });
  }
};
export const updateSelectedOption =
  (obj, option) => async (dispatch, getState) => {
    let { game } = getState();
    game.gameTypeOneQuestions &&
      game.gameTypeOneQuestions.map((qp) => {
        if (qp.constantId === obj.constantId) {
          qp.isOptionSelected = true;
          qp.options.map((op) => {
            if (op._id === option._id) {
              op.isSelected = true;
            }
            // else op.isSelected = false
          });
        }
        // else qp.isOptionSelected = false
      });

    dispatch({
      type: main.MATCH_THE_OBJECT_QUESTIONS,
      payload: game.gameTypeOneQuestions,
    });
  };
export const getType2GameOptions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { game } = getState();
    let selectedActivity = JSON.parse(
      sessionStorage.getItem("selectedTubActivity")
    );
    let gameSettingId = sessionStorage.getItem("gameSettingId");
    let url = "game/v3.6/typeTwo/options";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        id: selectedActivity._id,
        gameSettigsId: gameSettingId,
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      dispatch({
        type: main.MISSING_THE_LETTER_QUESTIONS,
        payload: response.data.questions,
      });
      dispatch({
        type: main.SELECTED_PLAYERS_LIST,
        payload: response.data.players,
      });
      let selectedMode = sessionStorage.getItem("selectedMode");
      let questions = response.data.questions;
      if (selectedMode == 2) {
        let noOfQuestions = questions.length;
        let players = response.data.players.student_list;
        let playersMappedWithQuestions = [];
        let newPlayers = [...players];
        for (let i = 0; i < noOfQuestions; i++) {
          let randomIndex = Math.floor(Math.random() * newPlayers.length);
          let randomVal = newPlayers[randomIndex];
          newPlayers.splice(randomIndex, 1);
          playersMappedWithQuestions.push({
            questionId: questions[i].constantId,
            alphabetName: questions[i].alphabetName,
            playerId: randomVal._id,
            playerName: randomVal.label,
          });
          if (newPlayers.length == 0) {
            newPlayers = [...players];
          }
        }
        dispatch({
          type: main.CURRENT_GAME_QUESTION,
          payload: playersMappedWithQuestions[0],
        });
        dispatch({
          type: main.QUESTION_MAPPED_WITH_RANDOM_PLAYERS,
          payload: playersMappedWithQuestions,
        });
      } else if (selectedMode == 3) {
        dispatch({ type: main.CURRENT_GROUP_PLAY_GROUP, payload: 0 });
      }
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    }
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};
export const updateType2SelectedOption =
  (obj, option) => async (dispatch, getState) => {
    let { game } = getState();
    game.gameTypeTwoQuestions &&
      game.gameTypeTwoQuestions.map((qp) => {
        if (qp.constantId === obj.constantId) {
          qp.isOptionSelected = true;
          qp.wordObj[qp.isEmptyLetterIndex].letter = option.letter;
          qp.wordObj[qp.isEmptyLetterIndex]["isCorrectOption"] =
            option.isCorrectAnswer;
          qp.options.map((op) => {
            if (op._id === option._id) {
              op.isSelected = true;
            }
          });
        }
      });
    dispatch({
      type: main.MISSING_THE_LETTER_QUESTIONS,
      payload: game.gameTypeTwoQuestions,
    });
  };

export const getType3GameOptions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { game } = getState();
    let selectedActivity = JSON.parse(
      sessionStorage.getItem("selectedTubActivity")
    );
    let gameSettingId = sessionStorage.getItem("gameSettingId");
    let url = "game/v3.6/typeThree/options";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        id: selectedActivity._id,
        gameSettigsId: gameSettingId,
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      dispatch({
        type: main.MATCH_THE_GOOD_WORD_QUESTIONS,
        payload: response.data.questions,
      });
      dispatch({
        type: main.SELECTED_PLAYERS_LIST,
        payload: response.data.players,
      });
      let selectedMode = sessionStorage.getItem("selectedMode");
      let questions = response.data.questions;
      if (selectedMode == 2) {
        let noOfQuestions = questions.length;
        let players = response.data.players.student_list;
        let playersMappedWithQuestions = [];
        let newPlayers = [...players];
        for (let i = 0; i < noOfQuestions; i++) {
          let randomIndex = Math.floor(Math.random() * newPlayers.length);
          let randomVal = newPlayers[randomIndex];
          newPlayers.splice(randomIndex, 1);
          playersMappedWithQuestions.push({
            questionId: questions[i].constantId,
            alphabetName: questions[i].alphabetName,
            playerId: randomVal._id,
            playerName: randomVal.label,
          });
          if (newPlayers.length == 0) {
            newPlayers = [...players];
          }
        }
        dispatch({
          type: main.CURRENT_GAME_QUESTION,
          payload: playersMappedWithQuestions[0],
        });
        dispatch({
          type: main.QUESTION_MAPPED_WITH_RANDOM_PLAYERS,
          payload: playersMappedWithQuestions,
        });
      } else if (selectedMode == 3) {
        dispatch({ type: main.CURRENT_GROUP_PLAY_GROUP, payload: 0 });
      }
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};

export const getType4GameOptions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { game } = getState();
    let selectedActivity = JSON.parse(
      sessionStorage.getItem("selectedTubActivity")
    );
    let gameSettingId = sessionStorage.getItem("gameSettingId");
    let url = "game/v3.6/typeThree/options";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        id: selectedActivity._id,
        gameSettigsId: gameSettingId,
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      dispatch({
        type: main.E_TUB,
        payload: response.data.questions,
      });
      dispatch({
        type: main.SELECTED_PLAYERS_LIST,
        payload: response.data.players,
      });
      let selectedMode = sessionStorage.getItem("selectedMode");
      let questions = response.data.questions;
      if (selectedMode == 2) {
        let noOfQuestions = questions.length;
        let players = response.data.players.student_list;
        let playersMappedWithQuestions = [];
        let newPlayers = [...players];
        for (let i = 0; i < noOfQuestions; i++) {
          let randomIndex = Math.floor(Math.random() * newPlayers.length);
          let randomVal = newPlayers[randomIndex];
          newPlayers.splice(randomIndex, 1);
          playersMappedWithQuestions.push({
            questionId: questions[i].constantId,
            alphabetName: questions[i].alphabetName,
            playerId: randomVal._id,
            playerName: randomVal.label,
          });
          if (newPlayers.length == 0) {
            newPlayers = [...players];
          }
        }
        dispatch({
          type: main.CURRENT_GAME_QUESTION,
          payload: playersMappedWithQuestions[0],
        });
        dispatch({
          type: main.QUESTION_MAPPED_WITH_RANDOM_PLAYERS,
          payload: playersMappedWithQuestions,
        });
      } else if (selectedMode == 3) {
        dispatch({ type: main.CURRENT_GROUP_PLAY_GROUP, payload: 0 });
      }
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};

export const updateType3SelectedOption =
  (obj, option) => async (dispatch, getState) => {
    let { game } = getState();
    game.gameTypeThreeQuestions &&
      game.gameTypeThreeQuestions.map((qp) => {
        if (qp.constantId === obj.constantId) {
          qp.isOptionSelected = true;
          qp.options.map((op) => {
            if (op._id === option._id) {
              op.isSelected = true;
            }
          });
        }
      });
    dispatch({
      type: main.MATCH_THE_GOOD_WORD_QUESTIONS,
      payload: game.gameTypeThreeQuestions,
    });
  };



export const updateType4SelectedOption =
  (obj, correctOption) => async (dispatch, getState) => {
    let { game } = getState();
    game.gameTypeFourQuestions &&
      game.gameTypeFourQuestions.map((qp) => {
        if (qp.alphabetName === obj.id) {
             qp.isOptionSelected = true;
             qp.isSelected = true;
             if (correctOption && qp.isCorrectAnswer !== false) {
              qp.isCorrectAnswer = true;
            } else {
              qp.isCorrectAnswer = false;
            }
        }
        return qp;
      });
    dispatch({
      type: main.E_TUB,
      payload: game.gameTypeFourQuestions,
    });
  };
export const storeGameResults =
  (gameQuestions) => async (dispatch, getState) => {
    try {
      dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
      let { game, dataToPersist } = getState();
      let playResults = [];
      let selectedGame = sessionStorage.getItem("selectedGame");
      let selectedMode = sessionStorage.getItem("selectedMode");
      let playersSelected,
        typeOfGame = game[gameQuestions];
      if (selectedMode == 2) {
        playersSelected = game.questionMappedWithRandomPlayers;
      } else if (selectedMode == 1) {
        playersSelected = game.selectedPlayersList.student_list;
      } else if (selectedMode == 3) {
        let groupCount = game.selectedPlayersList.groupCount;
        let startOfGroup = 0;
        for (let i = 0; i < typeOfGame.length; i++) {
          let group_detail =
            game.selectedPlayersList.group_details[startOfGroup];
          for (
            let k = 0;
            k < game.selectedPlayersList.perGroupStudentCount;
            k++
          ) {
            playResults.push({
              alphabetName: game[gameQuestions][i].alphabetName,
              imageTitle:
                selectedGame == "1"
                  ? game[gameQuestions][i].alphabetName
                  : selectedGame == "2"
                  ? game[gameQuestions][i].words
                  : selectedGame == "3"
                  ? game[gameQuestions][i].imageTitle
                  : "",
              imageUrl: game[gameQuestions][i].alphabetImage,
              answerType: getPlayResult(game[gameQuestions][i].options),
              answeredBy: group_detail["data"][k]._id,
              answerByGroupName: group_detail["groupName"],
            });
          }
          startOfGroup = startOfGroup + 1;
          if (startOfGroup == groupCount) {
            startOfGroup = 0;
          }
        }
      }
      if (selectedMode == 2 || selectedMode == 1) {
        game[gameQuestions] &&
          game[gameQuestions].map((gm, i) => {
            playResults.push({
              alphabetName: gm.alphabetName,
              imageTitle:
                selectedGame == "1"
                  ? gm?.alphabetName
                  : selectedGame == "2"
                  ? gm?.words
                  : selectedGame == "3"
                  ? gm?.imageTitle
                  : "",
              imageUrl: gm.alphabetImage,
              answerType: getPlayResult(gm.options),
              answeredBy: getPlayerIdWhoAnswered(
                playersSelected,
                selectedMode,
                i
              ),
            });
          });
      }
      let now = new Date();
      let start = new Date(game?.gameStartTime);
      let selectedClass = sessionStorage.getItem("selectedClass");
      let selectedActivity = JSON.parse(
        sessionStorage.getItem("selectedTubActivity")
      );
      let schoolId = sessionStorage.getItem("schoolId");
      // let selectedPlayers = game.playersList.filter(pl => pl.isSelected == true)
      let gameTotals = getTotalRightOrWrong(game[gameQuestions]);
      let requestPayload = {
        classId: selectedClass
          ? selectedClass
          : dataToPersist?.selectedClass?.value,
        schoolId: schoolId,
        gameMode: "Play",
        gameSettingId: game?.gameSettingsData?._id,
        contentId: selectedActivity?._id,
        startDateTime: start.getTime(),
        endDateTime: now.getTime(),
        totalPlayTime: now.getTime() - start.getTime(),
        gameCoordinatedBy: localStorage.getItem("userid"),
        playedDataResults: playResults,
        totalWrong: gameTotals.totalWrong,
        totalRight: gameTotals.totalRight,
      };
      let url = "session/storeSessionResults";
      let myInit = {
        headers: accessHeaders(),
        body: requestPayload,
      };
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        toast("Successfully Finished The Game", "success");
        dispatch({ type: main.GAME_SETTINGS_DATA, payload: response.data });
        history.push({
          pathname: "/class/presentation/home",
        });
      } else {
        dispatch({ type: main.GAME_SETTINGS_DATA, payload: null });
        history.push({
          pathname: "/class/presentation/home",
        });
      }
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
      dispatch({ type: main.MISSING_THE_LETTER_QUESTIONS, payload: [] });
      dispatch({ type: main.MATCH_THE_GOOD_WORD_QUESTIONS, payload: [] });
      dispatch({ type: main.MATCH_THE_OBJECT_QUESTIONS, payload: [] });
      dispatch({ type: main.PLAYERS_LIST, payload: [] });
      dispatch({ type: main.SELECTED_PLAYERS_LIST, payload: null });
    } catch (error) {
      console.log("error", error);
      // toast(error.message, 'error');
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    }
  };

export const storeGameEtubResults =
  (gameQuestions) => async (dispatch, getState) => {
    try {
      dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
      let { game, dataToPersist } = getState();
      let playResults = [];
      let selectedGame = sessionStorage.getItem("selectedGame");
      let selectedMode = sessionStorage.getItem("selectedMode");
      let playersSelected,
        typeOfGame = game[gameQuestions];
      if (selectedMode == 2) {
        playersSelected = game.questionMappedWithRandomPlayers;
      } else if (selectedMode == 1) {
        playersSelected = game.selectedPlayersList.student_list;
      } else if (selectedMode == 3) {
        let groupCount = game.selectedPlayersList.groupCount;
        let startOfGroup = 0;
        for (let i = 0; i < typeOfGame.length; i++) {
          let group_detail =
            game.selectedPlayersList.group_details[startOfGroup];
          for (
            let k = 0;
            k < game.selectedPlayersList.perGroupStudentCount;
            k++
          ) {
            playResults.push({
              alphabetName: game[gameQuestions][i].alphabetName,
              imageTitle:
                selectedGame == "1"
                  ? game[gameQuestions][i].alphabetName
                  : selectedGame == "2"
                  ? game[gameQuestions][i].words
                  : selectedGame == "3"
                  ? game[gameQuestions][i].imageTitle
                  : "",
              imageUrl: game[gameQuestions][i].alphabetImage,
              answerType: game[gameQuestions][i].isCorrectAnswer ? 3 : 2,
              answeredBy: group_detail["data"][k]._id,
              answerByGroupName: group_detail["groupName"],
            });
          }
          startOfGroup = startOfGroup + 1;
          if (startOfGroup == groupCount) {
            startOfGroup = 0;
          }
        }
      }
      if (selectedMode == 2 || selectedMode == 1) {
        game[gameQuestions] &&
          game[gameQuestions].map((gm, i) => {
            playResults.push({
              alphabetName: gm.alphabetName,
              imageTitle:
                selectedGame == "1"
                  ? gm?.alphabetName
                  : selectedGame == "2"
                  ? gm?.words
                  : selectedGame == "3"
                  ? gm?.imageTitle
                  : "",
              imageUrl: gm.alphabetImage,
              answerType: gm.isCorrectAnswer ? 3 : 2,
              answeredBy: getPlayerIdWhoAnswered(
                playersSelected,
                selectedMode,
                i
              ),
            });
          });
      }
      let now = new Date();
      let start = new Date(game?.gameStartTime);
      let selectedClass = sessionStorage.getItem("selectedClass");
      let selectedActivity = JSON.parse(
        sessionStorage.getItem("selectedTubActivity")
      );
      let schoolId = sessionStorage.getItem("schoolId");
      // let selectedPlayers = game.playersList.filter(pl => pl.isSelected == true)
      let gameTotals = getETubTotalRightWrong(game[gameQuestions]);
      let requestPayload = {
        classId: selectedClass
          ? selectedClass
          : dataToPersist?.selectedClass?.value,
        schoolId: schoolId,
        gameMode: "Play",
        gameSettingId: game?.gameSettingsData?._id,
        contentId: selectedActivity?._id,
        startDateTime: start.getTime(),
        endDateTime: now.getTime(),
        totalPlayTime: now.getTime() - start.getTime(),
        gameCoordinatedBy: localStorage.getItem("userid"),
        playedDataResults: playResults,
        totalWrong: gameTotals.totalWrong,
        totalRight: gameTotals.totalRight,
      };
      let url = "session/storeSessionResults";
      let myInit = {
        headers: accessHeaders(),
        body: requestPayload,
      };
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200) {
        toast("Successfully Finished The Game", "success");
        dispatch({ type: main.GAME_SETTINGS_DATA, payload: response.data });
      } else {
        dispatch({ type: main.GAME_SETTINGS_DATA, payload: null });
        history.push({
          pathname: "/class/presentation/home",
        });
      }
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
      dispatch({ type: main.MISSING_THE_LETTER_QUESTIONS, payload: [] });
      dispatch({ type: main.MATCH_THE_GOOD_WORD_QUESTIONS, payload: [] });
      dispatch({ type: main.MATCH_THE_OBJECT_QUESTIONS, payload: [] });
      dispatch({ type: main.PLAYERS_LIST, payload: [] });
      dispatch({ type: main.SELECTED_PLAYERS_LIST, payload: null });
    } catch (error) {
      console.log("error", error);
      // toast(error.message, 'error');
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    }
  };

export const storeGameModes = (gameMode) => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { dataToPersist } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let schoolId = sessionStorage.getItem("schoolId");
    let selectedActivity = JSON.parse(
      sessionStorage.getItem("selectedTubActivity")
    );
    let requestPayload = {
      classId: selectedClass
        ? selectedClass
        : dataToPersist?.selectedClass?.value,
      schoolId: schoolId,
      gameMode: gameMode,
      contentId: selectedActivity._id,
      startDateTime: Date.now(),
      endDateTime: Date.now(),
      gameCoordinatedBy: localStorage.getItem("userid"),
    };
    let url = "session/storeSessionResults";
    let myInit = {
      headers: accessHeaders(),
      body: requestPayload,
    };
    const response = await API.post(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    } else {
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    }
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};

export const getReviewTubClass = () => async (dispatch, getState) => {
  let getReview = sessionStorage.getItem("reviewTubClass");
  dispatch({ type: main.TOGGLE_REVIEW_TUB_CLASS, payload: getReview });
};

// attendance
export const getClassAttandanceList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { dataToPersist } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let url = "student/v3.6/getStudentAttendance";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        schoolId: sessionStorage.getItem("schoolId"),
        classId: selectedClass
          ? selectedClass
          : dataToPersist?.selectedClass?.value,
        toDayDate: moment().format("YYYY-MM-DD"),
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (
      response.status === 200 &&
      !_.isEmpty(response.data) &&
      response.data.student_list.length > 0
    ) {
      dispatch({
        type: main.ATTENDANCE_LIST,
        payload: response.data.student_list,
      });
      dispatch({ type: main.ATTENDANCE_DETAIL, payload: response.data });
    } else {
      await getStudentsOfTheClass()(dispatch, getState);
    }
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    await getStudentsOfTheClass()(dispatch, getState);
  }
};

export const getStudentsOfTheClass = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { dataToPersist } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let url = "game/players/list";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        classId: selectedClass
          ? selectedClass
          : dataToPersist?.selectedClass?.value,
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      let sortedList = _.sortBy(response.data, "label");
      dispatch({ type: main.ATTENDANCE_LIST, payload: sortedList });
    } else dispatch({ type: main.ATTENDANCE_LIST, payload: [] });
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};
export const checkAttendanceTaken = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { dataToPersist } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let url = "student/v3.6/getStudentAttendance";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        schoolId: sessionStorage.getItem("schoolId"),
        classId: selectedClass
          ? selectedClass
          : dataToPersist?.selectedClass?.value,
        toDayDate: moment().format("YYYY-MM-DD"),
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (
      response.status === 200 &&
      !_.isEmpty(response.data) &&
      response.data.student_list.length > 0
    ) {
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
      return true;
    } else {
      dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
      return false;
    }
  } catch (error) {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
    return false;
  }
};

export const saveStudentAttendance = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { dataToPersist, game } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let url = "student/v3.6/studentAttendance";
    let myInit = {
      headers: accessHeaders(),
      body: {
        schoolId: sessionStorage.getItem("schoolId"),
        classId: selectedClass
          ? selectedClass
          : dataToPersist?.selectedClass?.value,
        _id: game?.attendanceDetail?._id,
        student_list: game?.attendanceList,
        date: moment().format("YYYY-MM-DD"),
      },
    };
    const response = await API.post(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      // dispatch({ type: main.ATTENDANCE_LIST, payload: response.data })
    } else {
      // dispatch({ type: main.ATTENDANCE_LIST, payload: [] })
    }
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};
export const getCollagePlayersList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_APP_FETCHING, payload: true });
    let { dataToPersist } = getState();
    let selectedClass = sessionStorage.getItem("selectedClass");
    let url = "student/v3.6/getStudentAttendance";
    let myInit = {
      headers: accessHeaders(),
      queryStringParameters: {
        schoolId: sessionStorage.getItem("schoolId"),
        classId: selectedClass
          ? selectedClass
          : dataToPersist?.selectedClass?.value,
        toDayDate: moment().format("YYYY-MM-DD"),
        activeList: true,
      },
    };
    const response = await API.get(ENVIRONMENT, url, myInit);
    if (response.status === 200) {
      if (!_.isEmpty(response.data) && response.data.length > 0) {
        let collagePlayers = [];
        response.data.map((clp) => {
          if (!_.isEmpty(clp?.profilePic?.original)) {
            collagePlayers.push({
              source: clp?.profilePic?.original,
              width: Math.floor(Math.random() * 1),
              height: Math.floor(Math.random() * 1),
            });
          }
        });
        dispatch({ type: main.COLLAGE_STUDENTS_LIST, payload: collagePlayers });
      }
    } else dispatch({ type: main.COLLAGE_STUDENTS_LIST, payload: [] });
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  } catch (error) {
    console.log("error", error);
    // toast(error.message, 'error');
    dispatch({ type: main.COLLAGE_STUDENTS_LIST, payload: [] });
    dispatch({ type: loaders.IS_APP_FETCHING, payload: false });
  }
};
