import { loaders, main } from '../service/actionType';
import { API } from 'aws-amplify';
import toast from '../helpers/Toast';
import { ENVIRONMENT } from '../common/constants';
import { accessHeaders } from '../helpers';

export const getClassTableList = (queryPayload) => async (dispatch, getState) => {
    try {
        let url = "class/getClasses";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                status: queryPayload.status?.value,
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        dispatch({ type: main.CLASS_TABLE_LIST, payload: response.data })
    } catch (error) {
        console.log("error", error);
    }

}

export const createClass = (payload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true })
        let url = "class/create";
        const myInit = {
            body: payload,
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            let queryPayload = {
                status: {
                    value: "All"
                }
            }
            await getClassTableList(queryPayload)(dispatch, getState)
            dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
            toast(response.message, 'success');
        }
        else {
            dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
            toast(response.message, 'error');
        }
    } catch (error) {
        toast(error?.response?.data?.message, 'error')
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
    }

}

export const editClass = (payload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true })
        let url = "class/edit";
        const myInit = {
            body: payload,
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            let queryPayload = {
                status: {
                    value: "All"
                }
            }
            await getClassTableList(queryPayload)(dispatch, getState)
            dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
            toast(response.message, 'success');
        }
        else {
            dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
            toast(response.message, 'error');
        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
    }

}
export const updateClass = (payload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let url = "class/edit";
        const myInit = {
            body: payload,
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            toast(response.message, 'success');
        }
        else {
            dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
            toast(response.message, 'error');
        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}

export const saveSchoolCalendar = (payload) => async (dispatch, getState) => {
    try {
        let url = "class/saveSchoolCalendar";
        const myInit = {
            body: payload,
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            toast(response.message, 'success');
        }
        else {
            toast(response.message, 'error');
        }
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}


export const saveSchoolAcademicYear = (payload) => async (dispatch, getState) => {
    try {
        let url = "class/saveAcademicYear";
        const myInit = {
            body: payload,
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            toast(response.message, 'success');
        }
        else {
            toast(response.message, 'error');
        }
    } catch (error) {
        console.log("error", error);
    }

}


export const getManageSeatAllocation = () => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_APP_FETCHING, payload: true })
        let url = "class/getManageSeatAllocation";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        dispatch({ type: main.MANAGE_SEAT_ALLOCATION_LIST, payload: response.data })
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    } catch (error) {
        console.log("error", error);
        dispatch({ type: loaders.IS_APP_FETCHING, payload: false })
    }

}



export const saveTeacherCalendar = (payload) => async (dispatch, getState) => {
    try {
        let url = "student/saveTeacherCalendar";
        const myInit = {
            body: payload,
            headers: accessHeaders(),
            queryStringParameters: {
                classId: payload.classId,
                userName: localStorage.getItem("username")
            },
        };
        const response = await API.post(ENVIRONMENT, url, myInit)
        if (response.status === 200) {
            toast(response.message, 'success');
        }
        else {
            // toast(response.message, 'error');
        }
    } catch (error) {
        console.log("error", error);
    }

}