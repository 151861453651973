import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/css/main.scss";
import './assets/fonts/icomoon/style.css'
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import { ToastContainer } from 'react-toastify';
import { ENVIRONMENT, API_URL } from './common/constants';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

Amplify.configure(config);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: ENVIRONMENT,
        endpoint: API_URL,
      },
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
      <App />
      <ToastContainer limit={1} theme={"colored"}/>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
