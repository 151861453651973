import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import errorMsgs from "../../../../utils/errorMsgs";
import toast from "../../../../utils/Toast";
import * as curriculumAction from '../../../../actions/curriculum';
import { API } from 'aws-amplify';
import { ENVIRONMENT } from '../../../../common/constants';
import { bindActionCreators } from "redux";
import { ClassesList } from './classesList';
import { ClassPrograms } from './classProgram';
import { accessHeaders } from '../../../../helpers';
import {AppLoader} from '../../../../components/loader/appLoader';


const CurriculumView = (props) => {
    const [tabs, redirectToTab] = useState(1)
    const [data, setProgramList] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedClass, setSelectedClass] = useState({_id: ''})

    useEffect(() => {
        getManagePrList()
        // props.curriculumAction.getReadingProgramData('633335e087047a525cc73020')
    }, [])
    
    const getManagePrList = async () => {
        try {
            setLoading(true)
            let url = "class/v3.7/classReadingProgramList";
            const myInit = {
                headers: accessHeaders(),
                queryStringParameters: {
                    SchoolId: sessionStorage.getItem('schoolId'),
                    username: localStorage.getItem("username")
                },
            };
            const response = await API.get(ENVIRONMENT, url, myInit)
            if (response.status === 200) {
                setProgramList(response.data)
                setLoading(false)
            } else {
                setProgramList([])
                setLoading(false)
            }
        } catch (error) {
            setProgramList([])
            setLoading(false)
            toast(error?.message, "error")
        }
    }

    const getTab2Data = async (data) => {
        setSelectedClass({...selectedClass, ...data})
        await props.curriculumAction.getReadingProgramData(data?.reading_Program_Id, data?._id)
    }

    const refreshProgramList = async () => {
        await props.curriculumAction.getReadingProgramData(selectedClass?.reading_Program_Id, selectedClass?._id)
    }

    const redirect = async (data, tab) => {
        redirectToTab(tab)
        if(tab == 2){
            getTab2Data(data)
        } 
    } 
    return (
        <>
            {tabs === 1 ?
                loading  ?
                    <AppLoader />
                    :
                    <ClassesList 
                        {...props}
                        data={data}
                        redirect = {redirect}
                        getTab2Data={getTab2Data}
                    />
                :
            tabs === 2 ?
                    <ClassPrograms 
                        {...props}
                        selectedClass = {selectedClass}
                        redirect = {redirect}
                        refreshProgramList = {refreshProgramList}

                    />
                : 
                <></>
            }
        </>
    )
}

const mapStateToProps = ({ curriculum, loader,dataToPersist,classes,session }) => {
    return {
        curriculum,
        dataToPersist,
        classes,
        loader,
        session
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        curriculumAction: bindActionCreators(curriculumAction, dispatch),
        dispatch
    }
}

const  Curriculum = connect(mapStateToProps, mapDispatchToProps)(CurriculumView)

export { Curriculum }