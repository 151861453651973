import { toast as Toast } from 'react-toastify';

export default function toast(message,type="info"){
    Toast[type](message,{
        position: "top-right",
        theme: 'light',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false
    })
}