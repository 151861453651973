import React, { Component } from 'react'
import Header from '../components/Header/header'
import AuthLoader from '../components/loader/authloader'
import { connect } from 'react-redux'

class StaticLayoutClass extends Component {
    render() {
        let { children, loader } = this.props
        return (
            <>
                {loader.isAuthFetching && 
                    <AuthLoader />
                }
                {children}
            </>
        )
    }
}

let mapStateToProps = ({loader}) => {
    return {
        loader,
    }
}



let StaticLayout = connect(mapStateToProps,null)(StaticLayoutClass)

export { StaticLayout }