import React, { useState } from 'react'
import FormInput from '../inputs/FormInput'

export const ShowOnlyAssignment = (props) => {

    return (
        <>
            <div className='alp_dis_1 w_175px ml-auto cursor-pointer' onClick={() => props.showOnlyAssignment()}>
                <FormInput 
                    type="checkbox"
                    name="isSelectedOnlyAssignment"
                    value={props.isSelectedOnlyAssignment}
                    onChange={(...params) => props.showOnlyAssignment()}
                    checked = {props.isSelectedOnlyAssignment}
                    radiolabel={'Show only Assigments'}
                    className="checkbox_color"
                    radiolabelClass = "alp_text_failure_1"
                />
            </div>
        </>
    )
}