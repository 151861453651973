import React, {useState} from 'react';
import ScrollToTopBtn from '../../assets/images/up_arrow.svg'

const ScrollToTopButton = () =>{
    const [visible, setVisible] = useState(false)
    
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 250){
        setVisible(true)
      } 
      else if (scrolled <= 250){
        setVisible(false)
      }
    };
    
    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
      });
    };
    
    window.addEventListener('scroll', toggleVisible);
    
    return (
        <>
        {visible &&
            <div
                onClick={() => scrollToTop()}
                className="fixed_scrollbtn"
            >
                <img src={ScrollToTopBtn} />
            </div>
        }
        </>
    );
  }
    
  export default ScrollToTopButton;