import { main } from '../service/actionType'

const initialState = {
    classDropdownList: [],
    selectedClass: null
}
const dataToPersist =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.USER_CLASS_DROPDOWN_LIST:
            return {
                ...state,
                classDropdownList: payload
            }
        case main.SELECTED_CLASS:
            return {
                ...state,
                selectedClass: payload
            }
        case main.RESET_PERSIST:
            return {
                ...state,
                classDropdownList: [],
                selectedClass: null
            }
        default:
            return state
    }

}

export default dataToPersist