import React, { useState } from 'react'
import { SORT_OPTIONS } from '../../helpers/variables'
import { CustomSelectFilter } from '../filters/customSelectFilter'
import FilterButton from '../inputs/FilterButton'

export const PendingInboxFilter = ({getInboxPendingList}) => {
    const [filters, setFilters] = useState([
        {
            btnName: 'All',
            TubObjectType: '',
            isSelected: true
        },
        {
            TubObjectType: 'Assignment',
            btnName: 'Assignments',
            isSelected: false
        },
        {
            TubObjectType: 'Sheet',
            btnName: 'TUB Sheets',
            isSelected: false
        },
        {
            TubObjectType: 'Card',
            btnName: 'TUB Cards',
            isSelected: false
        }
    ])
    const [sortOptions] = useState(SORT_OPTIONS)

    const onClick = (index) => {
        let queryPayload = {
            "TubObjectType" : filters[index]?.TubObjectType
        }
        filters.map((fl, i) => {
            if(i == index){
                fl.isSelected = !fl.isSelected
            } else {
                fl.isSelected = false
            }
        })
        setFilters([...filters])
        getInboxPendingList(queryPayload)
    }
    
    const onChange = (name, value) => {
        let queryPayload = {
            [name]: value
        }
        getInboxPendingList(queryPayload)
    }
    return (
        <>
            <div className="bg-white py-2-half row no-gutters align-items-center">
                {filters.map((filter, i) => (
                    <div className='col-2 px-3'>
                        <FilterButton 
                            btnName={filter.btnName}
                            isSelected={filter.isSelected}
                            className={`text-center filterBtn cursor-pointer ${filter.isSelected ? 'selected_filter' : 'borderBg-type-1'}`}
                            onClick={() => onClick(i)}
                        />
                    </div>
                ))}
                <div className="col-2 px-3 ml-auto ">
                    <CustomSelectFilter 
                        name="sort"
                        options={sortOptions}
                        placeholder={'Sort'}
                        onApplyFilter={(name, value) => onChange(name, value)}
                    />
                </div>
            </div>
        </>
    )
}