import { React, Component } from "react";
import Calender from "react-calendar";
import moment from "moment";
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import FormInput from "../../../../components/inputs/FormInput";
import "./calendar.css";
class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeMonth = (value) => {
    this.props.changetheMonth(moment(value.activeStartDate).format("MM"));
  };

  render() {
    let {
      completeTeacherCalendarList,
      schoolAcademicYear,
      disabledDates,
      teacherCalendarDates,
      isStudentPage,
      calendarCompleteList,
      isShowToggler,
      selectedDateRange,
      toggleDate,
      disabled,
      subscriptionTeam,
      reason,
    } = this.props;
    return (
      <>
        {isStudentPage ? (
          <>
            <Calender
              onChange={(value) => this.props.onChangeDate(value)}
              value={selectedDateRange}
              selectRange={true}
              minDate={schoolAcademicYear?.start_Date}
              maxDate={schoolAcademicYear?.end_Date}
              tileClassName={({ date, view }) => {
                if (
                  teacherCalendarDates.find(
                    (x) =>
                      moment(x).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY")
                  )
                ) {
                  return "highlight";
                }
              }}
              className="classes_calendar"
              onActiveStartDateChange={(value) => this.changeMonth(value)}
              tileDisabled={this.props.studentCalendarTileDisabled}
            />

            {isShowToggler && (
              <div className="mt-2 row no-gutters space-between">
                <div>
                  <h4>
                    {moment(selectedDateRange[0]).format("DD-MM-YYYY")} -{" "}
                    {moment(selectedDateRange[1]).format("DD-MM-YYYY")}
                  </h4>
                  <FormInput
                    type="text"
                    placeholder="Reason"
                    name="reason"
                    className="mt-2 mb-0"
                    onChange={(...params) =>
                      this.props.onChangeReason(...params)
                    }
                    value={reason}
                    // disabled={disabled}
                  />
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          color="primary"
                          checked={toggleDate}
                          onChange={() =>
                            this.props.saveTeacherCalendar("add", null)
                          }
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </div>
            )}
            {completeTeacherCalendarList &&
              completeTeacherCalendarList.length > 0 &&
              completeTeacherCalendarList.map((date, i) => (
                <div className="mt-3 row no-gutters space-between" key={i}>
                  <div>
                    <h4>
                      {moment(date.dates[0]?.holiday_Dates).format(
                        "DD-MM-YYYY"
                      )}{" "}
                      -{" "}
                      {moment(
                        date.dates[date.dates.length - 1]?.holiday_Dates
                      ).format("DD-MM-YYYY")}
                    </h4>
                    {date.reason && (
                      <p className="fs-10 optionalText mt-1">
                        Reason - {date.reason}
                      </p>
                    )}
                    <p className="fs-10 optionalText mt-1">
                      (Not Available for TUB Class)
                    </p>
                  </div>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            color="primary"
                            // disabled={disabled}
                            checked={!date?.toggle}
                            onChange={() =>
                              this.props.saveTeacherCalendar("edit", date)
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <>
            <Calender
              onChange={(value) => this.props.onChangeDate(value)}
              value={selectedDateRange}
              selectRange={true}
              minDate={schoolAcademicYear?.start_Date}
              maxDate={schoolAcademicYear?.end_Date}
              tileClassName={({ date, view }) => {
                if (
                  disabledDates.find(
                    (x) =>
                      moment(x).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY")
                  )
                ) {
                  return "highlight";
                }
              }}
              className="classes_calendar"
              onActiveStartDateChange={(value) => this.changeMonth(value)}
              tileDisabled={this.props.tileDisabled}
            />

            {isShowToggler && (
              <div className="mt-2 row no-gutters space-between">
                <div>
                  <h4>
                    {moment(selectedDateRange[0]).format("DD-MM-YYYY")} -{" "}
                    {moment(selectedDateRange[1]).format("DD-MM-YYYY")}
                  </h4>
                  <FormInput
                    type="text"
                    placeholder="Reason"
                    name="reason"
                    className="mt-2 mb-0"
                    onChange={(...params) =>
                      this.props.onChangeReason(...params)
                    }
                    value={reason}
                    // disabled={disabled}
                  />
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          color="primary"
                          checked={toggleDate}
                          onChange={() => this.props.saveCalendar("add", null)}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </div>
            )}
            {calendarCompleteList &&
              calendarCompleteList.length > 0 &&
              calendarCompleteList.map((date, i) => (
                <div className="mt-3 row no-gutters space-between" key={i}>
                  <div>
                    <h4>
                      {moment(date.dates[0]?.holiday_Dates).format(
                        "DD-MM-YYYY"
                      )}{" "}
                      -{" "}
                      {moment(
                        date.dates[date.dates.length - 1]?.holiday_Dates
                      ).format("DD-MM-YYYY")}
                    </h4>
                    {date.reason && (
                      <p className="fs-10 optionalText mt-1">
                        Reason - {date.reason}
                      </p>
                    )}
                    <p className="fs-10 optionalText mt-1">
                      (Not Available for TUB Class)
                    </p>
                  </div>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            color="primary"
                            // disabled={disabled}
                            checked={!date?.toggle}
                            onChange={() =>
                              this.props.saveCalendar("edit", date)
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              ))}
          </>
        )}
      </>
    );
  }
}

// const Calendar = connect(null, null)(CalenderClass)

export default Calendar;
