import { formatDate, formatDateWithText, history } from "./index";
import _ from "lodash";
import moment from "moment";

export const ClassTableColumns = [
  {
    text: "Sr. No",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Class Name",
    dataField: "class_Name",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return row.class_Name + " (" + row.section_Name + ")";
    },
    sort: true,
  },
  {
    text: "Teachers",
    dataField: "teachers",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return (
        <div>
          {row.teachers && row.teachers.length > 1 ? (
            <div className="position-relative">
              <div className="class_table_teachers">
                <p>{row.teachers[0]?.label}</p>
                <p className="ml-2 extra_teachers">
                  +{row.teachers.length - 1}
                  <div class="class_teachers_tooltip">
                    {row.teachers.slice(1, row.teachers.length).map((rw) => (
                      <span>{rw.label}</span>
                    ))}
                  </div>
                </p>
              </div>
            </div>
          ) : (
            <p>{row.teachers.map((rw) => rw.label).join(",")}</p>
          )}
        </div>
      );
    },
  },
  {
    text: "Allocated Seats",
    dataField: "allocated_Seats",
    headerClasses: "columnHeader text-center",
    formatter: (cellContent, row, rowIndex) => {
      return <p className="text-center">{cellContent}</p>;
    },
    sort: true,
  },
  {
    text: "Program",
    dataField: "program",
    formatter: (cellContent, row, rowIndex) => {
      return row?.reading_Program_Id?.program_Name;
    },
    headerClasses: "columnHeader",
    sort: true,
  },
];

export const SeatsAllocationTableColumns = [
  {
    text: "Sr. No",
    dataField: "sno",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Class Name",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return (
        <p>
          {row?.class_Name}{" "}
          {!_.isEmpty(row.section_Name) ? `(${row.section_Name})` : ""}
        </p>
      );
    },
    sort: true,
  },
  {
    text: "Total Students",
    dataField: "total_Student",
    headerClasses: "columnHeader",
  },
  {
    text: "Allocated Seats",
    dataField: "allocated_Seats",
    headerClasses: "columnHeader",
    sort: true,
  },
  // {
  //   text: "Used Seats",
  //   dataField: "used_Seats",
  //   headerClasses: "columnHeader",
  //   sort: true
  // },
];

export const ManageClassProgramColumns = [
  {
    text: "Sr. No",
    dataField: "sno",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Class Name",
    headerClasses: "columnHeader",
    dataField: "class_Name",
    formatter: (cellContent, row, rowIndex) => {
      return (
        <p>
          {row?.class_Name}{" "}
          {!_.isEmpty(row.section_Name) ? `(${row.section_Name})` : ""}
        </p>
      );
    },
    sort: true,
  },
  {
    text: "Program",
    dataField: "program_Name",
    headerClasses: "columnHeader",
  },
  {
    text: "Number Of Words",
    dataField: "wordCount",
    headerClasses: "columnHeader text-center",
    formatter: (cellContent, row, rowIndex) => {
      return <p className="text-center">{cellContent}</p>;
    },
    sort: true,
  },
];

export const StudentsTableColumns = [
  {
    text: "Sr. No",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return (
        <p className={`${row.status == "InActive" && "disabled_student"}`}>
          {`${beforeDigt}` + (rowIndex + 1)}
        </p>
      );
    },
  },
  {
    text: "Name",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let director = localStorage.getItem("schoolCategory");

      return (
        <div className="flex">
          <p
            className={`student_fname ${
              row.status == "InActive" && "disabled_student"
            }`}
          >
            {row.firstName + " " + row.lastName}
          </p>
          {row.status == "Active" && row.parentAcceptance ? (
            <p className="notApproved ml-2 mt-1">
              {row.parentAcceptance === 1
                ? director === "TUBclass Lite"
                  ? ""
                  : "(Approval Pending)"
                : row.parentAcceptance === 3
                ? director === "TUBclass Lite"
                  ? ""
                  : "(Rejected)"
                : ""}
            </p>
          ) : (
            <></>
          )}
        </div>
      );
    },
  },
  {
    text: "Student ID",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return (
        <p className={`${row.status == "InActive" && "disabled_student"}`}>
          {row?.assignedStudentId}
        </p>
      );
    },
  },
];

export const InvoiceColumns = [
  {
    text: "Date",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return formatDate(cellContent);
    },
  },
  {
    text: "Invoice Number",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return <p>{row.InvNo != undefined ? row.InvNo : "-"}</p>;
    },
    sort: true,
  },
  {
    text: "Amount",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return <p>$ {parseInt(row.InvAmt)}</p>;
    },
    sort: true,
  },
  {
    text: "Status",
    dataField: "payment_status",
    headerClasses: "columnHeader",
    sort: true,
  },
  // {
  //   text: "Details",
  //   dataField: "allocated_Seats",
  //   headerClasses: "columnHeader",
  //   sort: true

  // },
];

export const sampleAssignmentScreenTableColumns = [
  {
    text: "Student",
    dataField: "firstName",
    headerClasses: "columnHeader",
    sort: true,
  },
  {
    text: "Submissions",
    headerClasses: "columnHeader",
    dataField: "submissions",
    formatter: (cellContent, row, rowIndex) => {
      return (
        <p className="">{cellContent.length == 0 ? "0" : cellContent.length}</p>
      );
    },
  },
  {
    text: "Mother",
    dataField: "Mother",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let findMother = row.submissions.find(
        (sb) => sb.RelationWithParent == "Mother"
      );
      return (
        <p
          className={`
          assignment_content_identifier 
          ${
            row.submissions.length == 0
              ? "secondary_bg"
              : findMother
              ? "global_primary_bg"
              : "tertiary_bg"
          }
        `}
        ></p>
      );
    },
  },
  {
    text: "Father",
    dataField: "wordCount",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let findFather = row.submissions.find(
        (sb) => sb.RelationWithParent == "Father"
      );
      return (
        <p
          className={`
          assignment_content_identifier 
          ${
            row.submissions.length == 0
              ? "secondary_bg"
              : findFather
              ? "global_primary_bg"
              : "tertiary_bg"
          }
        `}
        ></p>
      );
    },
  },
  {
    text: "Relative/s",
    dataField: "wordCount",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let findRelative = row.submissions.find(
        (sb) => sb.RelationWithParent == "Guardian"
      );
      return (
        <p
          className={`
          assignment_content_identifier 
          ${
            row.submissions.length == 0
              ? "secondary_bg"
              : findRelative
              ? "global_primary_bg"
              : "tertiary_bg"
          }
        `}
        ></p>
      );
    },
  },
];

export const sampleAssignmentTrackTableColumns = [
  {
    text: "#",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Name",
    headerClasses: "columnHeader",
    dataField: "assignment_Name",
    sort: true,
    formatter: (cellContent, row, rowIndex) => {
      return (
        <p
          className="global_color cursor-pointer"
          onClick={() =>
            history.push(
              `/dashboard/tubclass/assignment?date=${formatDate(
                row.start_Date
              )}`
            )
          }
        >
          {cellContent}
        </p>
      );
    },
  },
  {
    text: "Status",
    dataField: "status",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return (
        <p
          className={`
          assignment_content_identifier 
          ${
            moment(row?.end_Date).format("YYYY-MM-DD") >=
            moment().format("YYYY-MM-DD")
              ? row.submissions == row.studentCount
                ? "secondary_bg"
                : "global_primary_bg"
              : "secondary_bg"
          }`}
        ></p>
      );
    },
  },
  {
    text: "Submissions",
    dataField: "submissions",
    headerClasses: "columnHeader",
    sort: true,
    formatter: (cellContent, row, rowIndex) => {
      return (
        <p className="assignment_tracker_table_text_1">
          {row.submissions} / {row.studentCount}
        </p>
      );
    },
  },
  {
    text: "Start Date",
    dataField: "createdAt",
    sort: true,
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return (
        <p className="assignment_tracker_table_text_1">
          {formatDateWithText(cellContent)}
        </p>
      );
    },
  },
  {
    text: "Sent Date",
    dataField: "start_Date",
    sort: true,
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return (
        <p className="assignment_tracker_table_text_1">
          {formatDateWithText(cellContent)}
        </p>
      );
    },
  },
  {
    text: "Deadline",
    dataField: "end_Date",
    sort: true,
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return (
        <p className="assignment_tracker_table_text_1">
          {formatDateWithText(cellContent)}
        </p>
      );
    },
  },
];

export const AnalyticsColumns = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Word",
    dataField: "word",
    headerClasses: "columnHeader",
  },
  {
    text: "Right Answers",
    dataField: "answers",
    headerClasses: "columnHeader",
  },
  {
    text: "Last Played On",
    dataField: "lastPlayed",
    headerClasses: "columnHeader",
  },
];

export const ST_ENGAGEMENT_METER_COLUMNS = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Players",
    dataField: "fname",
    headerClasses: "columnHeader",
  },
  {
    text: "Games Played",
    dataField: "totalPlayCouont",
    headerClasses: "columnHeader",
  },
  {
    text: "Engaged Users",
    dataField: "totalPlayCouont",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return row.userData && row.userData.length > 0 ? row.userData.length : 0;
    },
  },
  {
    text: "Details",
    dataField: "details",
    headerClasses: "columnHeader",
  },
];
export const ST_LEARGNING_CURVE = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Date",
    dataField: "date",
    headerClasses: "columnHeader",
  },
  {
    text: "Class Average Score",
    dataField: "avgScore",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return row.avgScore.toFixed(2);
    },
  },
];
export const ST_ACTIVE_LIST = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Players",
    dataField: "fname",
    headerClasses: "columnHeader",
  },
  {
    text: "Right Answered Words",
    dataField: "wordsCount",
    headerClasses: "columnHeader",
  },
  {
    text: "Details",
    dataField: "details",
    headerClasses: "columnHeader",
  },
];
export const SOCIAL_AWARENESS_REPORT_COLUMNS = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Tag",
    dataField: "name",
    headerClasses: "columnHeader",
  },
  {
    text: "Exposure",
    dataField: "count",
    headerClasses: "columnHeader",
  },
];
export const ST_ACTIVE_TAGS_COUNT = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Tag",
    dataField: "fname",
    headerClasses: "columnHeader",
  },
  {
    text: "Exposure",
    dataField: "tagsCount",
    headerClasses: "columnHeader",
  },
];
export const ST_TOP_SCORE_CONTENT = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Players",
    dataField: "contentName",
    headerClasses: "columnHeader",
  },
  {
    text: "Highest Score",
    dataField: "avgScore",
    headerClasses: "columnHeader",
  },
  {
    text: "Content",
    dataField: "contentName",
    headerClasses: "columnHeader",
  },
  {
    text: "Game",
    dataField: "contentType",
    headerClasses: "columnHeader",
  },
];
export const ST_AVG_SCORE_CONTENT = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Content",
    dataField: "contentName",
    headerClasses: "columnHeader",
  },
  {
    text: "content Type",
    dataField: "contentType",
    headerClasses: "columnHeader",
  },
  {
    text: "Average Score",
    dataField: "avgScore",
    headerClasses: "columnHeader",
  },
];
export const ST_MOST_PLAYED_CONTENT = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Content",
    dataField: "contentName",
    headerClasses: "columnHeader",
  },
  {
    text: "content Type",
    dataField: "contentType",
    headerClasses: "columnHeader",
  },
  {
    text: "Games Played",
    dataField: "gamesPlayed",
    headerClasses: "columnHeader",
  },
];
export const ST_RECENTLY_PLAYED_CONTENT = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Players",
    dataField: "fname",
    headerClasses: "columnHeader",
  },
  {
    text: "Last Played Content",
    dataField: "contentName",
    headerClasses: "columnHeader",
  },
  {
    text: "content Type",
    dataField: "contentType",
    headerClasses: "columnHeader",
  },
  {
    text: "Score",
    dataField: "score",
    headerClasses: "columnHeader",
  },
  {
    text: "Last Played On",
    dataField: "lastDate",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      return formatDate(cellContent);
    },
  },
];
export const ST_TIME_SPENT_ON_GAMES = [
  {
    text: "Pos.",
    dataField: "id",
    headerClasses: "columnHeader",
    formatter: (cellContent, row, rowIndex) => {
      let beforeDigt = rowIndex < 9 ? "0" : "";
      return `${beforeDigt}` + (rowIndex + 1);
    },
  },
  {
    text: "Game",
    dataField: "game",
    headerClasses: "columnHeader",
  },
  {
    text: "Time Spent",
    dataField: "contentType",
    headerClasses: "columnHeader",
  },
  {
    text: "Last Played On",
    dataField: "lastPlayed",
    headerClasses: "columnHeader",
  },
];
export const reportToParentsLeftColumn = [
  {
    text: "Word",
    dataField: "word",
    headerClasses: "columnHeader",
  },
  {
    text: "Right Answers",
    dataField: "right_answers",
    headerClasses: "columnHeader",
  },
];

export const reportToParentsRightColumn = [
  {
    text: "Topics/Tags",
    dataField: "topics",
    headerClasses: "columnHeader",
  },
  {
    text: "Interactions",
    dataField: "interactions",
    headerClasses: "columnHeader",
  },
];
export const staticAnalyticsRows = [
  {
    id: "01",
    word: "Apple",
    answers: 17,
    lastPlayed: "5 days ago",
  },
  {
    id: "01",
    word: "Apple",
    answers: 17,
    lastPlayed: "5 days ago",
  },
  {
    id: "01",
    word: "Apple",
    answers: 17,
    lastPlayed: "5 days ago",
  },
  {
    id: "01",
    word: "Apple",
    answers: 17,
    lastPlayed: "5 days ago",
  },
  {
    id: "01",
    word: "Apple",
    answers: 17,
    lastPlayed: "5 days ago",
  },
  {
    id: "01",
    word: "Apple",
    answers: 17,
    lastPlayed: "5 days ago",
  },
  {
    id: "01",
    word: "Apple",
    answers: 17,
    lastPlayed: "5 days ago",
  },
];
export const reportToParentsLeftRows = [
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
  {
    word: "Apple",
    right_answers: 17,
  },
];
export const reportToParentsRightRows = [
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
  {
    topics: "Things Around Us",
    interactions: 17,
  },
];
