import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as subscriptionAction from '../../actions/subscriptionTeam';

const PaymentSuccessFunc = (props) => {

  const getApis = () => {
    props.getPaymentStatus()
  }
  useEffect(() => {
      getApis()
  }, []);

  return (
        <div className='payment_status_container'>
          <img src={require('assets/images/ordersuccess.png')} alt='success'/>
          <p className='txt'>Payment Success</p>
        </div>
  )
};

const mapStateToProps = ({ subscriptionTeam, loader }) => {
  return {
      subscriptionTeam,
      loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(subscriptionAction, dispatch)
};

const PaymentSuccess = connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessFunc)

export { PaymentSuccess }