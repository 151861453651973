import React, { useEffect, useRef } from 'react';

const MyComponent = (data) => {
  const htmlFormString = data?.data
  const formRef = useRef(null);

  useEffect(() => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlFormString;

    const formElement = tempDiv.querySelector('form');

    formRef.current.appendChild(formElement);

    formElement.submit();

    return () => {
      tempDiv.remove();
    };
  }, []);

  return <div ref={formRef}></div>;
};

export default MyComponent;