import moment from "moment";
import { React, Component, useState } from "react";
import { ALPHABET_ICON_MAPPING } from "../../helpers/variables";
import ButtonType1 from "../inputs/ButtonType1";
import FormInput from "../inputs/FormInput";
import { CreateModel } from "../Model/createModel";
import validate from "../../helpers/validation";
import toast from "../../helpers/Toast";
import { history } from "helpers";

export const AssignmentGalleryList = (props) => {
  return (
    <div className="row column-gap-20 no-gutters">
      {props.assignmentContent.map((ac) => (
        <div
          className="assignment_content_container cursor-pointer"
          onClick={() => props.addToTeacherChoice(ac)}
        >
          <div className="assignment_content_inner">
            <img src={ac?.originalImage?.original} className="" />
          </div>
          <p>{ac.title}</p>
          {props.selectedGlIds.includes(ac._id) ? (
            <div className="assignment_selected cursor-pointer">
              <img src={require("../../assets/images/selectedPlayer.png")} />
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  );
};

export const AssignmentScreenContent = (props) => {
  return (
    <div className="col-9 mx-auto p-0">
      <div className="row column-gap-20 row-gap-10 no-gutters">
        {props.assignmentContent &&
          props.assignmentContent.map((ac) => (
            <div className="assignment_content_container">
              <div className="assignment_content_inner">
                <img src={ac?.alphabetImage?.original} className="" />
              </div>
              <p>{ac.imageTitle}</p>
              {/* {ac.selected &&
                            <div className='assignment_selected'>
                                <img src={require('../../assets/images/as_selected.png')} />
                            </div>
                        } */}
            </div>
          ))}
      </div>
    </div>
  );
};

export const TeacherAssignmentContent = (props) => {
  const [newAddWordsPopup, toggleAddNewWordsPopup] = useState(false);
  const [wordDetail, setWordDetail] = useState({
    imageTitle: null,
    tags: null,
    description: null,
    alphabetImage: null,
  });
  const [errors, setErrors] = useState({
    imageTitle: null,
    tags: null,
    description: null,
    alphabetImage: null,
  });

  const onChangeWordDetail = (name, value) => {
    setWordDetail({ ...wordDetail, [name]: value });
  };

  const submitNewWords = () => {
    let validation = {
      ...inputValidations,
    };
    const notValid = validate(wordDetail, validation);
    if (notValid) {
      setErrors({
        ...errors,
        ...notValid,
      });
    } else {
      let payload = {
        ...wordDetail,
        tags: wordDetail.tags.split(","),
        alphabetImage: {
          original:
            ALPHABET_ICON_MAPPING[
              wordDetail.imageTitle.charAt(0).toUpperCase()
            ],
        },
      };
      props.addWordToTeacherChoice(payload);
      toggleAddNewWordsPopup(false);
      setWordDetail({
        ...wordDetail,
        imageTitle: "",
        tags: "",
        description: "",
        alphabetImage: "",
      });
    }
  };

  const checkToToggleNewWords = () => {
    if (
      parseInt(props.assignmentContent.teacher_Choice.length) +
        parseInt(props.assignmentContent.program_Choice.length) <=
      9
    ) {
      toggleAddNewWordsPopup(true);
    } else {
      toast(`Only 9 words can be added`, "error");
    }
  };

  const scrollDown = () => {
    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });
    // history.push("makeassignment");
  };

  return (
    <>
      <div className="col-9 mx-auto p-0">
        <div className="row column-gap-20 row-gap-20 no-gutters">
          {props.assignmentContent?.teacher_Choice &&
            props.assignmentContent?.teacher_Choice.map((ac, i) =>
              ac.isDraft ? (
                <div
                  className="assignment_content_container cursor-pointer"
                  onClick={() => scrollDown()}
                >
                  <div className="assignment_content_inner">
                    <img src={ac?.alphabetImage?.original} className="" />
                  </div>
                  <p>{ac.imageTitle}</p>
                </div>
              ) : (
                <div className="assignment_content_container" key={i}>
                  <div className="assignment_content_inner">
                    <img src={ac?.alphabetImage?.original} className="" />
                  </div>
                  <p>{ac.imageTitle}</p>
                  {ac.isSelected && (
                    <div
                      className="assignment_selected cursor-pointer"
                      onClick={() => props.removeTeacherContent(ac)}
                    >
                      <img
                        src={require("../../assets/images/as_selected.png")}
                      />
                    </div>
                  )}
                </div>
              )
            )}
        </div>
      </div>
      <CreateModel
        isOpen={newAddWordsPopup}
        toggle={() => toggleAddNewWordsPopup(!newAddWordsPopup)}
        title={`Add Words`}
        className="mx-600"
      >
        <div className="row">
          <div className="col-12">
            <FormInput
              type="only-text"
              label=""
              name="imageTitle"
              className="mb-2"
              placeholder="Enter Word"
              onChange={(...params) => onChangeWordDetail(...params)}
              value={wordDetail["imageTitle"]}
              error={errors["imageTitle"]}
            />
          </div>
          <div className="col-12">
            <FormInput
              type="only-text-with-comma"
              label=""
              name="tags"
              className="mb-3"
              placeholder="Enter Tags"
              onChange={(...params) => onChangeWordDetail(...params)}
              value={wordDetail["tags"]}
              bottomOptionText={"Enter maximum 5 tags seperated by comma"}
              error={errors["tags"]}
            />
          </div>
          <div className="col-12">
            <FormInput
              type="textarea"
              label=""
              className="pb-2"
              placeholder="Enter Description"
              name="description"
              onChange={(...params) => onChangeWordDetail(...params)}
              value={wordDetail["description"]}
              // error={studentCreatePlError["dob"]}
            />
          </div>
        </div>
        <div className="row align-items-center mt-3 content-end">
          <div className="col-3">
            <ButtonType1
              btnName="Close"
              className="popup-close-btn"
              onClick={() => toggleAddNewWordsPopup(!newAddWordsPopup)}
            />
          </div>
          <div className="col-3">
            <ButtonType1
              btnName="Confirm"
              className="buttonType1"
              onClick={() => submitNewWords()}
            />
          </div>
        </div>
      </CreateModel>
    </>
  );
};

let inputValidations = {
  imageTitle: {
    presence: {
      allowEmpty: false,
      message: "^Word can't be blank",
    },
  },
  tags: {
    presence: {
      allowEmpty: false,
      message: "^Tags can't be blank",
    },
  },
};
export const ChangeAssignmentScreenContent = (props) => {
  return (
    <div className="col-10">
      <div className="row column-gap-20 row-gap-10 no-gutters justify-content-center">
        {props.assignmentContent &&
          props.assignmentContent.map((ac) => (
            <div className="assignment_content_container">
              <div className="assignment_content_inner">
                <img src={ac?.alphabetImage?.original} className="" />
              </div>
              <p>{ac.imageTitle}</p>
              {/* {ac.selected &&
                            <div className='assignment_selected'>
                                <img src={require('../../assets/images/as_selected.png')} />
                            </div>
                        } */}
            </div>
          ))}
      </div>
    </div>
  );
};
