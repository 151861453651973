import React, { useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Button } from 'react-bootstrap';

const limit = 10;
const chartSliceFn = (array = [], page) => array.slice((page - 1) * limit, page * limit);


const Barchart = (props) => {
  const [page, setPage] = useState(1);
  const totalLength = Math.max(...[props.y_axis?.length, props.x_axis?.length]) || 0;
  const series = [
    {
      name: props.name,
      data: chartSliceFn(props.y_axis, page),
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: props.showToolBar,
      },
      "animations": {
        "enabled": true,
        "easing": "swing"
      },
      "background": "#FFFFFF",
      "dropShadow": {
        "enabled": true,
        "top": 1,
        "left": 1,
        "blur": 4,
        "color": "#000",
        "opacity": 0.1
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        borderRadiusApplication: 'end',
        backgroundBarColors: '#000000',
        "colors": {
          "backgroundBarColors": [
            "#f1f2f3"
          ],
          "backgroundBarOpacity": 0
        },
        dataLabels: {
          position: "top"
        },
      }
    },
    xaxis: {
      sorted: true,
      type: 'category',
      categories:  chartSliceFn(props.x_axis, page),
      "title": {
        "text": props.x_axis_title,
        "style": {
          cssClass: 'x_axis_label'
        },
        offsetY: 90
      },
      labels: {
        trim: true,
        formatter: (val) => {
          return val
        },
        style: {
          color: '#000000',
          cssClass: 'x_axis_content'
        },
        "trim": true,
        "offsetY": -2
      },
      tooltip: {
        enabled: true,
        offsetY: 100,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: -1
      }
    },
    yaxis: {
      title: {
        text: props.y_axis_title,
        style: {
          color: '#000000',
          cssClass: 'y_axis_label'
        }
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: -2
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0.5
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -14,
      style: {
        colors: ["#000"],
        fontSize: '10px',
        fontFamily: 'Poppins'
      }
    },
    noData: {
      text: "No data Found",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#7f7575",
        fontSize: '14px',
        fontFamily: 'Poppins'
      }
    },
    colors: props.colors,
    grid: {
      show: true,
      borderColor: "#E0E0E0",
      strokeDashArray: 3,
      padding: {
        left: 13,
        bottom: 0
      }
    },
    fill: {
      opacity: 1,
      type: 'solid'
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "right",
      offsetX: 0,
      offsetY: 0,
      showForNullSeries: false,
      showForZeroSeries: false
    },
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'right',
      justifyItems: 'right',
      gap: '5px',
    }}>
      <ReactApexChart options={options} series={series} type="bar" height="400" />
      <div style={{
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
      }}>
        <Button disabled={page === 1} onClick={() => {
          if (page > 1) {
            setPage(() => page - 1);
          }
        }}>
          {'<'}
        </Button>
        <Button disabled={(Math.ceil(totalLength / limit) === page)} onClick={() => {
          setPage(() => page + 1);
        }}>
          {'>'}
        </Button>
      </div>
    </div>
  )
}

export default Barchart
