import React from 'react';

export const AppLoader = ({ className }) => {
    return ( 
        <>
            <div className='app-loader d-flex align-items-center justify-content-center'>
                <div className={`loader`}>
                    <div className="lds-ripple">
                        <img src={require('../../assets/images/Alogo.png')} className="logo_loader" />
                    </div>
                </div>
            </div>
        </>
    );
}


export const InnerAppLoader = ({ className }) => {
    return ( 
        <>
            <div className='inner_app_loader'>
                <div className={`loader`}>
                    <div className="lds-ripple">
                        <img src={require('../../assets/images/Alogo.png')} className="logo_loader" />
                    </div>
                </div>
            </div>
        </>
    );
}