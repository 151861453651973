import React from 'react';
import { FormGroup, Label, Button } from 'reactstrap'

const ButtonType1 = (props) => {
    return (
        <>
            {
                props.disabled ? 
                <div className={`buttonType2Disabled ${props.disabledClassName}`}>
                    {props.btnName}
                </div>
                :
                <div className={`${props.className}`} onClick={() => props.onClick()}>
                    {props.btnName}
                </div>
            }
        </>
        
    )
}

export default ButtonType1;