import { main } from '../service/actionType'

const initialState = {
    tubGalleryList: [],
    rightSidebarStudentsList: [],
    oldUserGalleryData: []
}
const tubGallery =  (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.TUB_GALLERY_LIST:
            return {
                ...state,
                tubGalleryList: payload
            }
        case main.RIGHT_SIDEBAR_STUDENTS_LIST:
            return {
                ...state,
                rightSidebarStudentsList: payload
            }
        case main.OLD_USER_GALLERY_DATA :
            return {
                ...state,
                oldUserGalleryData: payload
            }  
        default:
            return state
    }

}
export default tubGallery;
