
export const ContentDetail = ({ObjectName, createdAt}) => {
    return (
        <>
            <div className="col-8">
                <p className="tub-session-category">{ObjectName}</p>
            </div>
            <div className="col-4">
                <p className="tub-day text-right">{createdAt}</p>
            </div>
        </>
    )
}