import ButtonType1 from "components/inputs/ButtonType1";
import { ContentDetail } from "components/tubClass/contentDetail";
import { ContentStudentDetail } from "components/tubClass/contentStudentDetail";
import { differenceInCalendarDays } from "date-fns";
import moment from "moment";
import { React } from "react";

const totalNoOfObjects = 9;

export const ReadingProgramView = ({
  btnName,
  setReadingPr,
  readingPr,
  index,
  isFromMysession,
}) => {
  const getData = (data) => {
    const elements = [];
    const noOfItems = data.length;
    const len = totalNoOfObjects - noOfItems;
    const incs = len === 0 ? noOfItems : noOfItems + len;

    return (
      <div className="reading_program_images_container_">
        <div className={`content_inner ${"even-color"}`}>
          <div className="relative-pos">
            <div className="row align-items-center no-gutters inner-content-spnImg">
              <div className="d-flex w-100">
                <ContentStudentDetail
                  src={require("../../assets/images/Alogo.png")}
                  className="whbr-40"
                  studentName={"alphaTUB Original"}
                />
              </div>
            </div>
          </div>
          <img
            src={data?.ObjectImage?.original}
            // className=""
            className="w-100 "
          />

          <div className="row no-gutters mt-2 justify-content-between">
            <ContentDetail
              ObjectName={`${data.ObjectType === "Letter" ? "Letter" : ""} ${
                data.ObjectName
              }`}
              createdAt={`${
                data.ObjectType === "Card" || data.ObjectType === "Sheet"
                  ? moment(data.updatedAt).format("DD MMM YYYY")
                  : ""
              }`}
            />
          </div>
        </div>
      </div>
    );
  };
  console.log(btnName, "btnName");
  // const isSameDay = (value) => {
  //   // return differenceInCalendarDays(disDate, date) === 0;
  //   return moment(value).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD");
  // };
  return (
    <div className="tub-bg-type-1 p-2">
      {btnName !== null ? (
        <>
          <div className="row no-gutters justify-content-between align-items-center px-2 tub-inner-heading pt-10">
            <div className="ml-auto mb-2">
              {readingPr?.hasTodaySession && isFromMysession == true ? (
                <ButtonType1
                  btnName={"Added"}
                  className="btn-bg1 h-24-lh-12 buttonType1"
                  onClick={() => null}
                />
              ) : (
                <ButtonType1
                  btnName={btnName}
                  className="btn-bg1 h-24-lh-12 buttonType1"
                  onClick={() => setReadingPr(readingPr, index)}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className=" no-gutters align-items-center">
        {" "}
        {getData(readingPr)}
      </div>
      <div className="row no-gutters mt-2 justify-content-between">
        <ContentDetail ObjectName={readingPr?.title} createdAt={null} />
      </div>
    </div>
  );
};
