import React, { Component } from "react";
//import { Row, Col, Grid, Navbar } from "react-bootstrap";
import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import { isEmptyOrUndefine } from "../../utils/common.utils";
import "../../assets/css/main.scss";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { main } from "../../service/actionType";
import { bindActionCreators } from "redux";
import * as galleryAction from "../../actions/tubgallery";
import moment from "moment";
import ExpandSvg from "../../assets/images/expand.svg";
import ExpandRevert from "../../assets/images/expand_revert.svg";
import { useCustomEventListener } from "react-custom-events";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import _ from "lodash";
class AdminHeaderClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: true,
      isFullScreenEnabled: false,
    };
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      // this.toggleFullscreen()
      event.preventDefault();
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  fetchClassData = async (classdetail) => {
    let queryPayload = {
      schoolId: sessionStorage.getItem("schoolId"),
      classId: classdetail?.value,
    };
    await this.props.galleryAction.changeClassInRedx(classdetail);
    this.props.galleryAction.getGalleryList(queryPayload);
    this.props.galleryAction.getStudentsList();
  };
  // changeClass = (classDetail) => {
  //   this.setState({selectedClass: classDetail},() => this.fetchClassData(classDetail))
  // }
  signout = async () => {
    try {
      await Auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  goBack = (history) => {
    let path = "";
    if (
      window.location.pathname === "/class/presentation/play/matchtheobject" ||
      window.location.pathname === "/class/presentation/play/missingletter" ||
      window.location.pathname ===
        "/class/presentation/play/matchthegoodword" ||
      window.location.pathname === "/class/presentation/play/etub"
    ) {
      this.props.dispatch({ type: main.IS_SELECTED_BACK_BTN, payload: true });
      // history.goBack(-1)
    } else {
      switch (window.location.pathname) {
        case "/class/presentation/attendance":
          path = "/class/presentation/home";
          break;
        case "/class/presentation/collage":
          path = "/class/presentation/home";
          break;
        case "/class/presentation/home":
          path = "/dashboard/tubclass/session";
          break;
        case "/class/presentation/detail/view":
          path = "/class/presentation/home";
          break;
        case "/class/presentation/explore":
          path = "/class/presentation/detail/view";
          break;
        case "/class/presentation/select/game":
          path = "/class/presentation/detail/view";
          break;
        case "/class/presentation/select/individual/players":
          path = "/class/presentation/select/game";
          break;
        case "/class/presentation/select/random/players":
          path = "/class/presentation/select/game";
          break;
        case "/class/presentation/select/group/players":
          path = "/class/presentation/select/game";
          break;
        default:
          path = "/class/presentation/home";
      }
      history.push(path);
    }
  };
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }

  toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
      this.setState({
        isFullScreenEnabled: true,
      });
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      this.setState({
        isFullScreenEnabled: false,
      });
    }
  };

  redirectTo = (screen) => {
    this.props.history.push(`/class/presentation/${screen}`);
  };
  render() {
    let { session, game } = this.props;
    let { isFullScreenEnabled } = this.state;
    let profile = JSON.parse(sessionStorage.getItem("profileDetail"));
    let session_date = sessionStorage.getItem("session_date");
    if (_.isEmpty(session_date))
      session_date = moment(new Date()).format("DD MMM YYYY");
    else session_date = moment(session_date).format("DD MMM YYYY");

    return (
      <Navbar expand="lg" className="navbar clp-bg">
        <Container fluid>
          <Navbar.Brand className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <img
                src={require("../../assets/images/classbackbtn.png")}
                className="logo classBackBtn cursor-pointer"
                onClick={() => this.goBack(this.props.history)}
              ></img>

              <img
                src={require("../../assets/images/Alogo.png")}
                className="logo ml-3"
              ></img>
            </div>
            <div className="ml-20 position-relative">
              <span className={`adminlogo-text`}>TUB Class</span>
              {window.location.pathname === "/class/presentation/home" ||
              window.location.pathname === "/class/presentation/detail/view" ? (
                <p className="clp-tub-day">{session_date}</p>
              ) : window.location.pathname ===
                  "/class/presentation/play/matchtheobject" ||
                window.location.pathname ===
                  "/class/presentation/play/missingletter" ||
                window.location.pathname ===
                  "/class/presentation/play/matchthegoodword" ||
                window.location.pathname === "/class/presentation/play/etub" ? (
                <div className="mt-2 position-absolute">
                  {game.selectedGameDetail.object != null &&
                    game.selectedGameDetail.object != "" && (
                      <p className="color-white clp-tub-day mb-1">
                        Play -{" "}
                        {game.selectedGameDetail.typeOfObject === "Letter" &&
                          "Letter"}{" "}
                        {game.selectedGameDetail.object}{" "}
                      </p>
                    )}
                  {!_.isEmpty(game.selectedGameDetail.gameName) ? (
                    <span className="clp-tub-day">
                      (
                      {`${game.selectedGameDetail.gameName} - ${game.selectedGameDetail.gameMode}`}
                      ){" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                window.location.pathname === "/class/presentation/explore" && (
                  <div className="mt-2 position-absolute">
                    {!_.isEmpty(game.selectedGameDetail.object) ? (
                      <p className="color-white clp-tub-day mb-1">
                        Explore - {game.selectedGameDetail.object}
                        <span className="clp-tub-day">
                          {" "}
                          (
                          {game.selectedGameDetail.typeOfObject === "Card"
                            ? "TUB Cards"
                            : game.selectedGameDetail.typeOfObject === "Mix"
                            ? "TUB Mix"
                            : game.selectedGameDetail.typeOfObject === "Sheet"
                            ? "TUB Sheet"
                            : game.selectedGameDetail.typeOfObject === "Word"
                            ? "Word Collection"
                            : game.selectedGameDetail.typeOfObject === "Tag"
                            ? "Tag Collection"
                            : game.selectedGameDetail.typeOfObject ===
                              "Assignment"
                            ? "Assignment"
                            : game.selectedGameDetail.typeOfObject ===
                              "Reading_Program"
                            ? "Reading Program"
                            : ""}
                          ){" "}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )
              )}
            </div>
          </Navbar.Brand>
          {window.location.pathname === "/class/presentation/home" && (
            <Nav className="align-items-center nav-class-title">
              <div className="searchbox">
                <h1 className="clp-class-name">Welcome to TUB Class </h1>
                {/* // Welcome To Teacher {profile?.label}’s Class */}
              </div>
            </Nav>
          )}
          <Navbar.Collapse
            id="basic-navbar-nav flex-12"
            className="justify-content-end"
          >
            <Nav className="align-items-center">
              <div
                type="button"
                className="mr-4 expand_clp"
                onClick={() => {
                  this.toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                {!isFullScreenEnabled ? (
                  <img src={ExpandSvg} />
                ) : (
                  <img src={ExpandRevert} />
                )}
              </div>
              <Nav className="align-items-center clp_header_dropdown">
                {!session.isReviewTubClassEnabled &&
                (window.location.pathname === "/class/presentation/home" ||
                  window.location.pathname ===
                    "/class/presentation/detail/view" ||
                  window.location.pathname ===
                    "/class/presentation/attendance" ||
                  window.location.pathname ===
                    "/class/presentation/collage") ? (
                  <UncontrolledDropdown>
                    <DropdownToggle className="">
                      <div className="topright-inner">
                        <img
                          src={
                            profile.profilePic?.original != undefined &&
                            profile.profilePic?.original != "" &&
                            profile.profilePic?.original != null
                              ? profile.profilePic?.original
                              : require("../../assets/images/noImage.png")
                          }
                          className="studentProfileImg"
                        ></img>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => this.redirectTo("attendance")}
                      >
                        Class Attendance
                      </DropdownItem>
                      {/* <DropdownItem onClick={() => this.redirectTo('collage')}>Collage</DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  <div className="topright-inner">
                    <img
                      src={
                        profile.profilePic?.original != undefined &&
                        profile.profilePic?.original != "" &&
                        profile.profilePic?.original != null
                          ? profile.profilePic?.original
                          : require("../../assets/images/noImage.png")
                      }
                      className="studentProfileImg"
                    ></img>
                  </div>
                )}
              </Nav>
            </Nav>
            {/* {(!session.isReviewTubClassEnabled)?
              <Nav className="align-items-center clp_header_dropdown">
                <UncontrolledDropdown>
                  <DropdownToggle>
                      <BiDotsVerticalRounded size={25} color={"#ffffff"} />
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem onClick={() => this.redirectTo('attendance')}>Class Attendance</DropdownItem>
                      <DropdownItem onClick={() => this.redirectTo('collage')}>Collage</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              :
              <></>
            } */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = ({ myProfile, loader, game, session }) => {
  return {
    myProfile,
    loader,
    game,
    session,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    galleryAction: bindActionCreators(galleryAction, dispatch),
    dispatch,
  };
};

const ClassPresentationHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminHeaderClass);

export default ClassPresentationHeader;
