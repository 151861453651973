import { React, Component } from 'react';
import { connect } from 'react-redux'
import ButtonType1 from '../../../../../components/inputs/ButtonType1'
import { CustomBootstrapTable } from '../../../../../components/Table/CustomBootstrapTable';
import { SeatsAllocationTableColumns } from '../../../../../helpers/tableDataMapper'
import { API } from 'aws-amplify';
import { ENVIRONMENT } from '../../../../../common/constants';
import { loaders, main } from '../../../../../service/actionType';
import _ from 'lodash';
import toast from '../../../../../helpers/Toast';
import {Input } from 'reactstrap';
import { accessHeaders } from '../../../../../helpers';
import {AppLoader} from '../../../../../components/loader/appLoader';

class ManageSeatAllocationClass extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            manageSeatAllocationList: [],
            available: null,
            isTableDetailsFetching: false,
            loading: false,
            usedSeats: null
        };
    }
  
    componentDidMount() {
        this.getManageSeatAllocation()
    }
    getManageSeatAllocation = async () => {
        let {subscriptionTeam} = this.props
        this.setState({loading: true})
        let url = "class/getManageSeatAllocation";
        let myInit = {
            headers: accessHeaders(),
            queryStringParameters: {
                userName: localStorage.getItem("username")
            }
        }
        const response = await API.get(ENVIRONMENT, url, myInit);
        if(response.status === 200) {
            let available , usedSeats;
            if (subscriptionTeam && subscriptionTeam.subscriptionInfo){
                available = subscriptionTeam.subscriptionInfo.SeatsPurchased - subscriptionTeam.subscriptionInfo.UsedSeats
                usedSeats = subscriptionTeam.subscriptionInfo.UsedSeats
            }
            this.setState({
                manageSeatAllocationList: response.data,
                usedSeats : usedSeats,
                available: available,
                loading:false
            })
        }
    }
    onChange = (e, row) => {
        let {manageSeatAllocationList, available} = this.state
        manageSeatAllocationList.map(ml => {
            if( ml._id === row._id) {
                // if(parseInt(e.target.value) <= parseInt(available)) {
                    ml.noOfAdditionalSeat = e.target.value
                // } 
                // else if(parseInt(e.target.value) < (-(row.allocated_Seats))){
                //     ml.noOfAdditionalSeat = (-row.allocated_Seats)
                // }
            }
        })
        this.setState({
            manageSeatAllocationList : manageSeatAllocationList
        })
    }
    getTableColumns = () => {
        let { available } = this.state
        let addSeats = {
            text: "Add Seats",
            dataField: "addSeats",
            headerClasses: "columnHeader text-center",
            style:{ display: "flex", justifyContent: 'center', alignItems: 'center'},
            formatter: (cellContent, row, rowIndex) => {
                return (
                    <Input
                        type="number"
                        name={"noOfAdditionalSeat"}
                        max={available}
                        min={-(row.allocated_Seats)}
                        className="p-2 m-0 manageseat_addseat"
                        value={row.noOfAdditionalSeat == null ? null : parseInt(row.noOfAdditionalSeat)}
                        onChange={(e) => this.onChange(e, row)}
                    />
                )
            },
            sort: true
        }

        let action = {
            text: "Action",
            dataField: "action",
            headerClasses: "columnHeader text-center",
            formatter: (cellContent, row, rowIndex) => {
                return (
                    <ButtonType1 
                        btnName="Add"
                        className='buttonType1 w-120px mlr-auto'
                        onClick={() => this.addSeats(row)}
                        disabled={false}
                    />
                )
            }
        }
        return [...SeatsAllocationTableColumns,addSeats,action]

    }
    checkSeatsAddedIsValid = (row) => {
        let { available } = this.state
        if(parseInt(row.noOfAdditionalSeat) > parseInt(available)){
            toast('Upgrade your subscription plan to add seats or additional seats should be equal to the available seats', 'error')
            return false
        } else if(parseInt(row.noOfAdditionalSeat) < (-(row.allocated_Seats))){
            toast('cannot Reduce seats which is lesst than allocated seats', 'error')
            return false
        } 
        // else if((parseInt(row.allocated_Seats) > 50) || ((parseInt(row.allocated_Seats) + parseInt(row.noOfAdditionalSeat)) > 50)){
        //     toast('Only 50 seats can be added', 'error')
        //     return false
        // } 
        return true
    }
    addSeats = async (row) => {
        if(!_.isEmpty(row.noOfAdditionalSeat)){
            if(this.checkSeatsAddedIsValid(row)){
                let payload = {
                    allocated_Seats: row.allocated_Seats,
                    additionalSeats: row.noOfAdditionalSeat
                }
                let url = "class/manageseat/modify";
                let myInit = {
                    body: payload,
                    headers: accessHeaders(),
                    queryStringParameters: {
                        classId: row._id,
                        userName: localStorage.getItem("username")
                    },
                }
                const response = await API.post(ENVIRONMENT, url, myInit);
                if(response.status === 200) {
                    this.props.dispatch({type: main.UPDATE_AVAILABLE_SEATS, payload: parseInt(this.state.usedSeats) + parseInt(payload.additionalSeats)})
                    this.setState({
                        available: parseInt(this.state.available) - parseInt(payload.additionalSeats)
                    }, this.getManageSeatAllocation)
                    
                } 
            } 
        } else {
            toast('Please Enter a valid number of seats', 'error')
        }
    }
    render() {
        let {isTableDetailsFetching, manageSeatAllocationList, available, loading} = this.state
        let columns = this.getTableColumns()
        return (
            <>
                {loading ?
                    <AppLoader />
                    :
                    <div className="row no-gutters">
                        <div className="col-md-12 ">
                            <div className="row no-gutters">
                                <div className="col-9 d-flex">
                                    <img 
                                        src={require('assets/images/left-arrow.svg').default} 
                                        className="back-btn" 
                                        onClick={() => this.props.onNavigate(1, null)}
                                        alt="alphatub"
                                    />
                                    <p className="global_color privacy-content ml-3 cursor-pointer" onClick={() => this.props.onNavigate(1, null)}>Back To Classes</p>
                                </div>
                                <div className="col-3">
                                    <p className="alphatub-card-h3 text-right">Available Seats: {available}</p>
                                </div>
                            </div>
                            <div className="row no-gutters mt-3">
                                <div className="col-9">
                                    <h3 className="login-text-login text-left">Manage Seats Allocation</h3>
                                </div>
                            </div>
                            <div className="row no-gutters mt-3">
                                <div className="col-12">
                                    <CustomBootstrapTable 
                                        rows = {manageSeatAllocationList}
                                        columns = {columns}
                                        isLoading={isTableDetailsFetching}
                                        keyField = "shopId"
                                        tableClass="manageseats_table"
                                        tableMainClass="manageSeatsTable"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}


const mapStateToProps = ({ subscriptionTeam, loader }) => {
    return {
        subscriptionTeam,
        loader
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        // profileAction: bindActionCreators(myProfileAction, dispatch),
        dispatch
    }
}

const ManageSeatAllocation = connect(mapStateToProps, mapDispatchToProps)(ManageSeatAllocationClass)

export {ManageSeatAllocation};

