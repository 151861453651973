import moment from "moment";

let settingsRoutePrefix = "/dashboard/settings";
let tubclassRoutePrefix = "/dashboard/tubclass";
let analyticsRoutePrefix = "/dashboard/analytics";

export const DASHBOARD_TABS = [
  {
    label: "My Profile",
    value: [1],
    route: settingsRoutePrefix + "/profile",
    roles: ["R01", "R02", "R03", "R04", "R05", "R06", "R07"],
  },
  {
    label: "Classes",
    value: [2],
    route: settingsRoutePrefix + "/class",
    roles: ["R01", "R02", "R03", "R04", "R05", "R06", "R07"],
  },
  {
    label: "Subscription & Team",
    value: [3],
    route: settingsRoutePrefix + "/subscription",
    roles: ["R01", "R02", "R03"],
  },
  {
    label: "Curriculum",
    value: [4],
    route: settingsRoutePrefix + "/curriculum",
    roles: ["R01", "R02", "R03"],
  },
];

export const TUB_CLASS_TABS = [
  {
    label: "Reading Program",
    value: [1],
    route: tubclassRoutePrefix + "/readingprogram",
    id: "1",
    type: 1,
    toggleDropdown: false,
    roles: ["R01", "R02", "R03", "R04", "R05", "R06", "R07"],
    options: [
      {
        spanText: "",
        label: "Reading Program",
        route: tubclassRoutePrefix + "/readingprogram",
        id: "2",
        value: [2],
      },
      {
        spanText: "",
        label: "Make Assignment",
        route: tubclassRoutePrefix + "/makeassignment",
        id: "3",
        value: [3],
      },
      {
        spanText: "",
        label: "Teacher Choice Assignment",
        route: tubclassRoutePrefix + "/assignment",
        id: "4",
        value: [4],
      },
      {
        spanText: "",
        label: "Track Assignment",
        route: tubclassRoutePrefix + "/trackassignment",
        id: "5",
        value: [5],
      },
    ],
  },
  {
    label: "TUB Inbox",
    value: [2],
    spanText: "(Pending)",
    toggleDropdown: false,
    route: tubclassRoutePrefix + "/tubinbox/pending",
    roles: ["R01", "R02", "R03"],
    id: "2",
    type: 2,
    options: [
      {
        spanText: "(Pending)",
        label: "Pending Approvals",
        route: tubclassRoutePrefix + "/tubinbox/pending",
        id: "PENDING",
        value: [2],
      },
      {
        spanText: "(Approved)",
        label: "Approved",
        route: tubclassRoutePrefix + "/tubinbox/approved",
        id: "APPROVED",
        value: [3],
      },
      {
        spanText: "(Rejected)",
        label: "Rejected",
        id: "REJECTED",
        route: tubclassRoutePrefix + "/tubinbox/rejected",
        value: [4],
      },
      {
        spanText: "(Deleted)",
        label: "Deleted",
        id: "DELETED",
        route: tubclassRoutePrefix + "/tubinbox/deleted",
        value: [5],
      },
      {
        spanText: "(Disabled)",
        label: "Disabled",
        id: "DISABLED",
        route: tubclassRoutePrefix + "/tubinbox/disabled",
        value: [6],
      },
    ],
  },
  {
    label: "TUB Gallery",
    value: [7],
    route: tubclassRoutePrefix + "/tubgallery",
    roles: ["R01", "R02", "R03", "R04", "R05", "R06", "R07"],
  },
  {
    label: "My Sessions",
    value: [8],
    route: tubclassRoutePrefix + "/session",
    roles: ["R01", "R02", "R03", "R04", "R05", "R06", "R07"],
  },
];
export const TUB_PAGE_TABS = [
  {
    label: "TUB Page",
    value: [1],
    route: "/tubpage",
    roles: ["R01", "R02", "R03", "R04", "R05", "R06", "R07"],
  },
];
export const ANALYTICS_TABS = [
  {
    label: "Overall",
    value: [1],
    route: analyticsRoutePrefix + "/overall",
    roles: ["R01", "R02", "R03", "R04", "R05", "R06", "R07"],
  },
  // {
  //   label: "Curriculum",
  //   value: [2],
  //   roles: [],
  // },
  // {
  //   label: "Personalized",
  //   value: [3],
  //   roles: [],
  // },
  {
    label: "Student Reports",
    value: [4],
    route: analyticsRoutePrefix + "/studentreport",
    roles: ["R01", "R02", "R03", "R04", "R05", "R06", "R07"],
    // options: [
    //     {
    //         spanText: "",
    //         label: "Individual Reports",
    //         id: "IND_REPORT",
    //         value: [4]
    //     },
    // {
    //     spanText: "",
    //     label: "Reports To Parents",
    //     id: "REPORT_TO_PARENTS",
    //     value: [5]
    // }
    // ]
  },
];

export const SORT_OPTIONS = [
  // {
  //     label: "Most Played",
  //     value: "1",
  //     id: 1
  // },
  // {
  //     label: "Least Played",
  //     value: "2",
  //     id: 2
  // },
  {
    label: "Latest First",
    value: "1",
    id: 3,
  },
  {
    label: "Oldest First",
    value: "2",
    id: 4,
  },
  // {
  //     label: "Most Scored",
  //     value: "5",
  //     id: 5
  // },
  // {
  //     label: "Least Scored",
  //     value: "6",
  //     id: 6
  // },
  {
    label: "A to Z",
    value: "3",
    id: 7,
  },
  {
    label: "Z to A",
    value: "4",
    id: 8,
  },
];

export const GALLERY_SORT_OPTIONS = [
  {
    label: "Latest First",
    value: "3",
    id: 3,
  },
  {
    label: "Oldest First",
    value: "4",
    id: 4,
  },
  {
    label: "A to Z",
    value: "7",
    id: 7,
  },
  {
    label: "Z to A",
    value: "8",
    id: 8,
  },
];
export const FILTER_OPTIONS = [
  {
    label: "Last 28 Days",
    value: "Last 28 Days",
    startDate: moment().subtract(28, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    id: 1,
  },
  {
    label: "Last 14 Days",
    value: "Last 14 Days",
    startDate: moment().subtract(14, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    id: 2,
  },
  {
    label: "Last 07 Days",
    value: "Last 07 Days",
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    id: 4,
  },
  {
    label: "Yesterday",
    value: "Yesterday",
    startDate: moment().subtract(1, "d").startOf("d").format("YYYY-MM-DD"),
    endDate: moment().subtract(1, "d").endOf("d").format("YYYY-MM-DD"),
    id: 5,
  },
  {
    label: "Today",
    value: "Today",
    startDate: moment().startOf("d").format("YYYY-MM-DD"),
    endDate: moment().endOf("d").format("YYYY-MM-DD"),
    id: 6,
  },
  {
    label: "Custom Dates",
    value: "Custom Dates",
    id: 7,
  },
];

export const ANALYTICS_DATE_FILTER = [
  {
    label: "Last 30 Days",
    value: "30",
    id: 5,
  },
  {
    label: "Last 7 Days",
    value: "7",
    id: 1,
  },
  // {
  //     label: "Last Week",
  //     value: "14",
  //     id: 4
  // },
  // {
  //     label: "Last Month",
  //     value: "60",
  //     id: 6
  // },
  {
    label: "Custom Dates",
    value: "customDates",
    id: 7,
  },
];

export const ALPHATUB_PRODUCTS_OPTIONS = [
  {
    label: "TUB App Only",
    value: "1",
    id: 1,
  },
  {
    label: "TUB Class Only",
    value: "2",
    id: 2,
  },
  {
    label: "TUB App + TUB Class",
    value: "3",
    id: 4,
  },
];
export const ALL_CONTENTS_FILTERS = [
  {
    label: "Tub Sheet",
    value: "Sheet",
    id: 1,
  },
  {
    label: "Tub Card",
    value: "Card",
    id: 2,
  },
  {
    label: "Tub Mix",
    value: "Mix",
    id: 3,
  },
  {
    label: "Letters",
    value: "Letter",
    id: 4,
  },
  {
    label: "Words",
    value: "Word",
    id: 5,
  },
  {
    label: "Tags",
    value: "Tag",
    id: 6,
  },
];

export const GAMES_OPTIONS = [
  {
    label: "Wats The Good word",
    value: "goodword",
    id: 1,
  },
  {
    label: "Match The Object",
    value: "matchtheobject",
    id: 2,
  },
  {
    label: "Find The Missing Letter",
    value: "missingletter",
    id: 3,
  },
  {
    label: "e-TUB",
    value: "etub",
    id: 4,
  },
];

export const ALPHABET_ICON_MAPPING = {
  A: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/A58512625c.png",
  B: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/B58513b889.png",
  C: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/C58514cb35.png",
  D: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/D5851576db.png",
  E: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/E585162046.png",
  F: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/F585169d52.png",
  G: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/G5851738b6.png",
  H: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/H58517c782.png",
  I: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/I585184873.png",
  J: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/J58518b7ec.png",
  K: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/K5851952ab.png",
  L: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/L58519c85a.png",
  M: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/M5851a515b.png",
  N: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/N5851abb42.png",
  O: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/O5851b3c22.png",
  P: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/P5851bb655.png",
  Q: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/Q5851c5e20.png",
  R: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/R5851d0672.png",
  S: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/S5851dbb25.png",
  T: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/T5851e4741.png",
  U: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/U5851ee11c.png",
  V: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/V58520649d.png",
  W: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/W58520e435.png",
  X: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/X585216b1e.png",
  Y: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/Y58521e300.png",
  Z: "https://alphatubbucket.s3.ap-south-1.amazonaws.com/picInApps/IMG/original/Z5852267e8.png",
};

export const workingDaysOptions = [
  {
    label: "S",
    value: "Sun",
    checked: false,
    index: 0,
  },
  {
    label: "M",
    value: "Mon",
    checked: false,
    index: 1,
  },
  {
    label: "T",
    value: "Tue",
    checked: false,
    index: 2,
  },
  {
    label: "W",
    value: "Wed",
    checked: false,
    index: 3,
  },
  {
    label: "T",
    value: "Thu",
    checked: false,
    index: 4,
  },
  {
    label: "F",
    value: "Fri",
    checked: false,
    index: 5,
  },
  {
    label: "S",
    value: "Sat",
    checked: false,
    index: 6,
  },
];

export const TUB_ACTIVITIES = [
  {
    id: 1,
    type: 1,
    title: "TUB Sheet",
    key: "Sheet",
    tab: 9,
    isHovered: false,
  },
  {
    id: 2,
    type: 1,
    title: "TUB Cards",
    key: "Card",
    tab: 10,
    isHovered: false,
  },
  {
    id: 3,
    type: 2,
    title: "TUB Mix",
    key: "Mix",
    tab: 11,
    isHovered: false,
  },
  {
    id: 4,
    type: 2,
    title: "Letter",
    key: "Letter",
    tab: 12,
    isHovered: false,
  },
  {
    id: 5,
    type: 2,
    title: "Word",
    key: "Word",
    tab: 13,
    isHovered: false,
  },
  {
    id: 6,
    type: 2,
    title: "Tag",
    key: "Tag",
    tab: 14,
    isHovered: false,
  },
];
