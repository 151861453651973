import { React, useRef, useState, useEffect, useCallback } from "react";
import ChangePassword from "../../../components/change-password/change-password";
import { alphatubUsernameValidCondition } from "../../../utils/common.utils";
import { connect } from "react-redux";
import FormInput from "../../../components/inputs/FormInput";
import validate from "../../../helpers/validation";
import { isObject } from "validate.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as myProfileAction from "../../../actions/myProfile";
import * as subscriptionAction from "../../../actions/subscriptionTeam";
import { bindActionCreators } from "redux";
import errorMsgs from "../../../utils/errorMsgs";
import { API } from "aws-amplify";
import toast from "../../../utils/Toast";
import { Country, State, City } from "country-state-city";
import ReactSelect from "../../../components/inputs/ReactSelect";
import {
  getCititesList,
  getRoleFromRoleId,
  getStatesList,
  accessHeaders,
} from "../../../helpers";
import moment from "moment";
import { main, loaders } from "../../../service/actionType";
import { ENVIRONMENT } from "../../../common/constants";
import { Label } from "reactstrap";
import _ from "lodash";
import Geocode from "react-geocode";
import ImageCrop from "../../../components/cards/ImageCrop";
import { CreateModel } from "../../../components/Model/createModel";
import "react-image-crop/dist/ReactCrop.css";
import Wrapper from "../../../Hoc/wrapper";
import MyComponent from "./sample";

Geocode.setApiKey("");

const ProfileView = (props) => {
  const [showCropPopup] = useState(false);
  const [changePassword] = useState(false);
  const [profileDetails, setProfile] = useState({
    SchoolName: null,
    First_name: "",
    Last_name: "",
    RoleID: "",
    isObjectUrlImage: false,
    Profile_img: {
      original: "",
    },
    Middle_name: "",
    AlternativeMobile: null,
    DOB: null,
    Qualification: null,
    Gender: null,
    TeacherID: null,
    Mobile: "",
    Country: null,
    District: null,
    State: null,
    Alt_Tel_Country_Code: null,
    Tel_Country_Code: null,
    classList: [],
  });
  const [dupProfile, setDupProfile] = useState({
    SchoolName: null,
    First_name: "",
    Last_name: "",
    RoleID: "",
    isObjectUrlImage: false,
    Profile_img: {
      original: "",
    },
    Middle_name: "",
    AlternativeMobile: null,
    DOB: null,
    Qualification: null,
    Gender: null,
    TeacherID: null,
    Mobile: "",
    Country: null,
    District: null,
    State: null,
    Alt_Tel_Country_Code: null,
    Tel_Country_Code: null,
    classList: [],
  });
  const [errors, setErrors] = useState({
    SchoolName: null,
  });
  const [genderOptions] = useState([
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Other",
      value: "Other",
    },
  ]);
  const [affiliationList] = useState([
    {
      label: "CBSE",
      value: "CBSE",
    },
    {
      label: "State Board",
      value: "State Board",
    },
  ]);
  const [countryList, setCountries] = useState([]);
  const [statesList, setStates] = useState([]);
  const [districtList, setDistricts] = useState([]);
  const [btnClicks] = useState(0);
  const [username, setUserName] = useState(null);
  const [isUserAvailable, setIsUsernameavailable] = useState(null);
  const [emptyBtnClicks, setEmptyBtnClicks] = useState(0);
  const [userNameBtnClicks] = useState(0);
  const [unCroppedImage] = useState("");
  const [latitude] = useState(null);
  const [longitude] = useState(null);
  const [loadedImage] = useState(null);
  const [crop] = useState({
    aspect: 16 / 9,
  });
  const [finalImage] = useState(null);

  useEffect(() => {
    console.log(
      "profileDetails",
      profileDetails,
      props.myProfile.profileDetails
    );
    // if(JSON.stringify(props.myProfile.profileDetails) != JSON.stringify(profileDetails)){
    setProfile(props.myProfile.profileDetails);
    setDupProfile(props.myProfile.profileDetails);
    setCountries(props.myProfile.countryList);
    setStates(props.myProfile.statesList);
    setDistricts(props.myProfile.citiesList);

    // }
  }, [props]);

  const removePhoto = () => {
    // (async () => {
    setProfile({
      ...profileDetails,
      isObjectUrlImage: false,
      Profile_img: { original: "" },
    });
    // await new Promise((resolve) => setTimeout(resolve, 600));

    // })()
  };
  useEffect(() => {
    if (
      dupProfile["Profile_img"]["original"] !=
      profileDetails["Profile_img"]["original"]
    ) {
      saveProfile();
    }
  }, [profileDetails, dupProfile]);

  const saveProfile = async () => {
    let validation = {
      ...inputValidations,
      ...(profileDetails["Country"] != null && statesList.length === 0
        ? { State: undefined }
        : 0),
      ...(profileDetails["Country"] != null && statesList.length === 0
        ? { District: undefined }
        : 0),
    };
    const notValid = validate(profileDetails, validation);
    if (notValid) {
      setErrors({
        ...errors,
        ...notValid,
      });
    } else {
      if (checkUserNameValidity()) {
        let payload = {
          username: localStorage.getItem("username"),
          user: {
            First_name: profileDetails?.First_name,
            Last_name: profileDetails?.Last_name,
            Middle_name: profileDetails?.Middle_name,
            Email: profileDetails?.Email,
            RoleID: profileDetails?.RoleID,
            Mobile: profileDetails?.Mobile.slice(
              profileDetails?.Tel_Country_Code.length
            ),
            Tel_Country_Code: profileDetails?.Tel_Country_Code,
            Alt_Tel_Country_Code: profileDetails?.Alt_Tel_Country_Code,
            Profile_img: profileDetails?.Profile_img,
            AlternativeMobile: profileDetails?.AlternativeMobile.slice(
              profileDetails?.Alt_Tel_Country_Code?.length
            ),
            DOB: profileDetails?.DOB,
            Qualification: profileDetails?.Qualification,
            TeacherID: profileDetails?.TeacherID,
            Gender: profileDetails?.Gender,
            UserName: profileDetails.UserName,
          },
          School: {
            SchoolName: profileDetails?.SchoolName,
            Country: profileDetails?.Country,
            State: {
              label: profileDetails?.State?.label,
              value: profileDetails?.State?.value,
            },
            District: {
              label: profileDetails?.District?.label,
              value: profileDetails?.District?.value,
            },
            SchoolAddr1: profileDetails?.SchoolAddr1,
            SchoolAddr2: profileDetails?.SchoolAddr2,
            SchoolEmailID: profileDetails?.SchoolEmailID,
            SchoolMobileNo: profileDetails?.SchoolMobileNo,
            AffiliatedID: profileDetails?.AffiliatedID,
            ZipCode: profileDetails?.ZipCode,
            curriculum_Id: profileDetails?.curriculum_Id?.value,
          },
        };
        await props.profileAction.createOrUpdateProfile(payload);
        await props.profileAction.getSchoolBasedClassDropdown();
      }
    }
  };

  const getBase64Image = async (img, calback) => {
    let reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function () {
      calback(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const replacePhoto = async (file) => {
    let formData = new FormData();
    formData.append("image", file);

    try {
      let url = "user/uploadImage";
      const myInit = {
        body: formData,
        headers: accessHeaders(),
      };
      // props.dispatch({type: loaders.IS_AUTH_FETCHING, payload: true })
      const response = await API.post(ENVIRONMENT, url, myInit);
      if (response.status === 200 && response.message !== "Invalid Image.") {
        setProfile({
          ...profileDetails,
          isObjectUrlImage: true,
          Profile_img: { original: response.data?.originalImage?.original },
        });
        // props.dispatch({type: loaders.IS_AUTH_FETCHING, payload: false })
        return true;
      } else {
        toast(response?.message, "error");
        // props.dispatch({type: loaders.IS_AUTH_FETCHING, payload: false })
        return false;
      }
    } catch (error) {
      toast("Error processing request", "error");
      // props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
      return false;
    }
  };
  const role = localStorage.getItem("role");
  const showToast = (btnClicks, type) => {
    let errMsg = "";
    if (type === 1) {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW001[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW001[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW001[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW001[3];
          break;
        default:
          errMsg = errorMsgs.TCW001[0];
          break;
      }
    } else {
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW100[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW100[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW100[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW100[3];
          break;
        default:
          errMsg = errorMsgs.TCW100[0];
          break;
      }
    }
    toast(errMsg, "error");
  };
  const getStates = async (country) => {
    let statesList = await getStatesList(country);
    setStates(statesList);
  };
  const getdistrictsList = async (state) => {
    let disList = await getCititesList(state);
    setDistricts(disList);
  };
  const onChange = async (name, value, id) => {
    let profile = { ...profileDetails };
    let error = { ...errors };

    if (name === "Country") {
      profile["State"] = null;
      profile["District"] = null;
      profile["ZipCode"] = "";
      profile[name] = value;
      error[name] = undefined;

      getStates(value);
    } else if (name === "State") {
      profile["District"] = null;
      profile["ZipCode"] = "";
      profile[name] = value;
      error[name] = undefined;

      getdistrictsList(value);
    } else if (name === "District") {
      profile[name] = value;
      profile["ZipCode"] = "";
      error[name] = undefined;

      getZipcodeFromDistrict(value);
    } else if (name === "DOB" && value != null) {
      let checkForMinimumAge = moment().diff(value, "years");

      if (checkForMinimumAge >= 13 || role == "R01") {
        profile[name] = value;
        error[name] = undefined;
      } else {
        toast(
          "User needs to be minimum of 13 years old to access the TUB Class",
          "error"
        );
      }
    } else if (name === "username") {
      setUserName({ username: value });
    } else {
      profile[name] = value;
      error[name] = undefined;
    }

    setProfile({ ...profileDetails, ...profile });
    setErrors({ ...errors, ...error });
  };
  const getZipcodeFromDistrict = (district) => {
    Geocode.fromAddress(district.label).then(
      (response) => {
        const addressComponents = response.results[0].address_components;
        for (let p = addressComponents.length - 1; p >= 0; p--) {
          if (addressComponents[p].types.indexOf("postal_code") != -1) {
            let pincode = addressComponents[p].long_name;
            setProfile({ ...profileDetails, ZipCode: pincode });
            setErrors({ ...errors, ZipCode: undefined });
          } else {
            const { lat, lng } = response.results[0].geometry.location;
            getZipCodeFromLatLng(lat, lng);
          }
        }
      },
      (error) => {
        console.error("error", error);
      }
    );
  };
  const getZipCodeFromLatLng = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const addressComponents = response.results[0].address_components;
        for (let p = addressComponents.length - 1; p >= 0; p--) {
          if (addressComponents[p].types.indexOf("postal_code") != -1) {
            let pincode = addressComponents[p].long_name;
            setProfile({ ...profileDetails, ZipCode: pincode });
            setErrors({ ...errors, ZipCode: undefined });
          }
        }
      },
      (error) => {
        console.error("error", error);
      }
    );
  };
  const onChangeMobile = (name, countryCodeName, value, data) => {
    let onlyPhNumber = value.slice(data.dialCode.length);
    value = data.dialCode + onlyPhNumber.replace(/^0+/, "");
    let prDetails = {
      ...profileDetails,
      [countryCodeName]: data.dialCode,
      [name]: value,
    };
    setProfile(prDetails);
    setErrors({ ...errors, [name]: undefined });
  };
  const isDisabled = () => {
    let role = localStorage.getItem("role");
    let disabled = role === "R01" || role === "R02" ? false : true;
    return disabled;
  };
  const checkUserNameValidity = () => {
    if (_.isEmpty(profileDetails.UserName)) {
      let newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
      setEmptyBtnClicks(newBtnClicks);
      showToast(newBtnClicks);
      setIsUsernameavailable(null);
      return false;
    } else if (!alphatubUsernameValidCondition(profileDetails.UserName)) {
      let newBtnClicks = userNameBtnClicks === 4 ? 1 : userNameBtnClicks + 1;
      setEmptyBtnClicks(newBtnClicks);
      showToast(newBtnClicks, 2);
      setIsUsernameavailable(null);
      return false;
    }
    return true;
  };
  const checkUserAvailability = async () => {
    if (checkUserNameValidity()) {
      // props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: true })
      let url = "user/validateusername";
      let myInit = {
        headers: accessHeaders(),
        queryStringParameters: {
          username: profileDetails.UserName,
        },
      };
      const response = await API.get(ENVIRONMENT, url, myInit);
      if (response.status === 200 && _.isEmpty(response.data)) {
        setIsUsernameavailable(true);
        // props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
      } else {
        setIsUsernameavailable(false);
        // props.dispatch({ type: loaders.IS_AUTH_FETCHING, payload: false })
      }
    }
  };

  let disabled = isDisabled();
  let email = sessionStorage.getItem("email");
  let roleId = localStorage.getItem("role");
  let roleName = getRoleFromRoleId(roleId);
  return (
    <>
      <div className="row no-gutters">
        <div className="col-md-3">
          <div className="row no-gutters center-content">
            <ImageCrop
              profileDetails={profileDetails}
              removePhoto={removePhoto}
              saveProfileImage={replacePhoto}
            />
            <div className="col-12 col-md-11 mt-3">
              <div className="alphatub-card">
                <div className="alphatub-card-top-spacing">
                  <h2 className="alphatub-card-h2">
                    {profileDetails?.First_name} {profileDetails?.Last_name}
                  </h2>
                </div>
                <div className="alphatub-card-border"></div>
                <div className="alphatub-card-bottom-spacing">
                  <div className="row no-gutters space-between">
                    <span className="profile_lt_label">Role:</span>
                    <p className="profile_lt_value">{roleName?.label}</p>
                  </div>
                  <div className="mt-1">
                    <span className="profile_lt_label float-left">
                      Your Classes:{" "}
                    </span>
                    <div>
                      {profileDetails?.classList.length > 0 ? (
                        profileDetails?.classList.map((cl) => (
                          <div>
                            <p className="profile_lt_value text-right">
                              {cl.class_Name}{" "}
                              {cl.section_Name ? `(${cl.section_Name})` : ""}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p className="profile_lt_value text-right">
                          No Classes
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9 profie_right_container">
          <div className="profile_right_inner">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="alphatub-card-h2">School Information</h3>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    label="School Name"
                    name="SchoolName"
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.SchoolName}
                    error={errors["SchoolName"]}
                    disabled={roleId == "R01" ? false : true}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    label="School Branch"
                    isOptional={true}
                    name="AffiliatedID"
                    placeholder={"Enter Branch"}
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.AffiliatedID}
                    // error={errors["AffiliatedID"]}
                    disabled={roleId == "R01" ? false : true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <FormInput
                    type="text"
                    label="Street"
                    name="SchoolAddr1"
                    isOptional={roleId == "R01" ? true : false}
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.SchoolAddr1}
                    error={errors["SchoolAddr1"]}
                    disabled={roleId == "R01" ? false : true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FormInput
                    type="text"
                    label="Street 2/Landmark"
                    name="SchoolAddr2"
                    isOptional={true}
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.SchoolAddr2}
                    error={errors["SchoolAddr2"]}
                    disabled={roleId == "R01" ? false : true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 custom-reselect">
                  <ReactSelect
                    label="Country"
                    name="Country"
                    options={countryList}
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.Country}
                    error={errors["Country"]}
                    disabled={roleId == "R01" ? false : true}
                    placeholder={"Select Your Country"}
                  />
                </div>
                {profileDetails["Country"] != undefined &&
                  statesList.length > 0 && (
                    <div className="col-md-6 custom-reselect">
                      <ReactSelect
                        label="State"
                        options={statesList}
                        name="State"
                        onChange={(...params) => onChange(...params, null)}
                        value={profileDetails?.State}
                        error={errors["State"]}
                        disabled={roleId == "R01" ? false : true}
                        placeholder={"Select Your State"}
                      />
                    </div>
                  )}
              </div>
              <div className="row">
                {profileDetails["Country"] != undefined &&
                  statesList.length > 0 && (
                    <div className="col-md-6 custom-reselect">
                      <ReactSelect
                        label="City"
                        options={districtList}
                        name="District"
                        onChange={(...params) => onChange(...params, null)}
                        value={profileDetails?.District}
                        error={errors["District"]}
                        disabled={roleId == "R01" ? false : true}
                        placeholder={"Select Your City"}
                      />
                    </div>
                  )}
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    label="Zip Code/Pin Code"
                    name="ZipCode"
                    max={10}
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.ZipCode}
                    error={errors["ZipCode"]}
                    disabled={roleId == "R01" ? false : true}
                  />
                </div>
              </div>
            </div>
            <div className="container mt-4">
              <div className="row">
                <div className="col-12">
                  <h3 className="alphatub-card-h2">Your Profile</h3>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    label="Username"
                    name="UserName"
                    className="mb-2"
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.UserName}
                  />
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary"
                        onClick={() => checkUserAvailability()}
                      >
                        Check Availability
                      </button>
                    </div>
                    <div className="col-md-6 flex-property">
                      <p className="username_available">
                        {isUserAvailable === null
                          ? ""
                          : isUserAvailable === true
                          ? "Available"
                          : "Not Available"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <p className="f-12 light-text">Choose Username</p>
                  </div>
                  <div className="mt-2">
                    <p className="f-12 light-text">
                      You can choose a username with minimum 6 and maximum 30
                      characters. Characters allowed: Alphabets (a-z, A-Z),
                      Numbers (0-9) and Symbol (_).
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="First Name"
                    name="First_name"
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.First_name}
                    error={errors["First_name"]}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Middle Name"
                    isOptional={true}
                    name="Middle_name"
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.Middle_name}
                    error={errors["Middle_name"]}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="only-text"
                    label="Last Name"
                    name="Last_name"
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.Last_name}
                    error={errors["Last_name"]}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <FormInput
                    type="date-picker"
                    label="Date of Birth"
                    name="DOB"
                    // className="form-control"
                    style={{ width: "100%" }}
                    isOptional={true}
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.DOB}
                    error={errors["DOB"]}
                    maxDate={new Date()}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="select"
                    label="Gender"
                    name="Gender"
                    placeholder="Select Gender"
                    options={genderOptions}
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.Gender}
                    error={errors["Gender"]}
                    showDownArrow={true}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="text"
                    label="Qualification (optional)"
                    name="Qualification"
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.Qualification}
                    error={errors["Qualification"]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <FormInput
                    type="text-without-space"
                    label="Teacher ID (optional)"
                    name="TeacherID"
                    maxLength={16}
                    onChange={(...params) => onChange(...params, null)}
                    value={profileDetails?.TeacherID}
                    error={errors["TeacherID"]}
                    disabled={disabled}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="text"
                    label="Role"
                    name="RoleID"
                    onChange={(...params) => onChange(...params, null)}
                    value={roleName?.label}
                    disabled={true}
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    type="email"
                    label="Email ID"
                    name="Email"
                    onChange={(...params) => onChange(...params, null)}
                    value={email}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 h-80">
                  <Label>Contact Number</Label>
                  <PhoneInput
                    country="in"
                    name="Mobile"
                    countryCodeEditable={false}
                    value={profileDetails?.Mobile?.replace(/^0+/, "")}
                    onChange={(phone, data) =>
                      onChangeMobile("Mobile", "Tel_Country_Code", phone, data)
                    }
                    placeholder=""
                  />

                  <strong className="text-danger small">
                    {errors["Mobile"] ? errors["Mobile"][0] : ""}
                  </strong>
                </div>
                <div className="col-md-6 h-80">
                  <Label>
                    Alternative Contact Number{" "}
                    <span className="optionalText">&nbsp;( Optional )</span>
                  </Label>
                  <PhoneInput
                    country="in"
                    name="AlternativeMobile"
                    countryCodeEditable={false}
                    value={profileDetails?.AlternativeMobile?.replace(
                      /^0+/,
                      ""
                    )}
                    onChange={(phone, data) =>
                      onChangeMobile(
                        "AlternativeMobile",
                        "Alt_Tel_Country_Code",
                        phone,
                        data
                      )
                    }
                  />
                  <strong className="text-danger small">
                    {errors["AlternativeMobile"]
                      ? errors["AlternativeMobile"][0]
                      : ""}
                  </strong>
                </div>
              </div>
            </div>
            <div className="container mt-4">
              <div className="row no-gutters">
                <div className="col-md-12 text-right">
                  <button
                    className="btn btn-primary w-200px"
                    onClick={() => saveProfile()}
                  >
                    {" "}
                    Save Profile
                  </button>
                </div>
              </div>
            </div>
            <ChangePassword></ChangePassword>
          </div>
        </div>
      </div>
      {/* <ImageCrop 
                    showCropPopup={showCropPopup}
                    src={unCroppedImage}
                    onCropComplete={this.onCropComplete}
                    toggleCropPopup={this.toggleCropPopup}
                /> */}
      {/* <CreateModel
                    isOpen={showCropPopup}
                    toggle={() => this.toggleCropPopup()}
                    title={"Crop Profile Photo"}
                    className="mx-800"
                >
                    <ReactCrop
                        src={unCroppedImage}
                        crop={crop}
                        onImageLoaded={(image) => this.setImage(image)}
                        onChange={c => this.setCrop(c)}
                        onComplete={(crop, percentageCrop) => this.onCropComplete(crop, percentageCrop)}
                    >
                        <img src={unCroppedImage} />
                    </ReactCrop>
                    <img src={finalImage} />
                </CreateModel> */}
    </>
  );
};

const mapStateToProps = ({ myProfile, loader }) => {
  return {
    myProfile,
    loader,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    profileAction: bindActionCreators(myProfileAction, dispatch),
    subscriptionAction: bindActionCreators(subscriptionAction, dispatch),
    dispatch,
  };
};

const Profile = Wrapper(
  connect(mapStateToProps, mapDispatchToProps)(ProfileView)
);

export { Profile };
let roleId = localStorage.getItem("role");
let inputValidations = {
  SchoolName: {
    presence: {
      allowEmpty: false,
      message: "^school Name can't be blank",
    },
  },
  SchoolAddr1: {
    presence: {
      allowEmpty: (roleId = "R01" ? true : false),
      message: "^SchohoolAddr1 can't be blank",
    },
  },
  // SchoolAddr2: {
  //     presence: {
  //         allowEmpty: false,
  //         message: "^SchoolAddr2 can't be blank"
  //     }
  // },
  District: {
    presence: {
      allowEmpty: false,
      message: "^District can't be blank",
    },
    object: "District",
  },
  ZipCode: {
    presence: {
      allowEmpty: false,
      message: "^ZipCode can't be blank",
    },
  },
  Country: {
    presence: {
      allowEmpty: false,
      message: "^Country can't be blank",
    },
    object: "Country",
  },
  State: {
    presence: {
      allowEmpty: false,
      message: "^Country can't be blank",
    },
    object: "State",
  },
  First_name: {
    presence: {
      allowEmpty: false,
      message: "^First_name can't be blank",
    },
  },
  Last_name: {
    presence: {
      allowEmpty: false,
      message: "^Last_name can't be blank",
    },
  },
  // DOB: {
  //   presence: {
  //     allowEmpty: false,
  //     message: "^DOB can't be blank",
  //   },
  // },
  Gender: {
    presence: {
      allowEmpty: false,
      message: "^Gender can't be blank",
    },
  },
  Mobile: {
    presence: {
      allowEmpty: false,
      message: "^Contact Number can't be blank",
    },
    // validateMobileNumber: 'Mobile'
  },
  // AlternativeMobile:  {
  //     presence: {
  //         allowEmpty: true,
  //         message: "^Alternative Number can't be blank"
  //     },
  // validateAlternativeMobileNumber: 'AlternativeMobile'
  // }

  // Email: {
  //     presence: {
  //         allowEmpty: true,
  //         message: "^Contact Number can't be blank"
  //     },
  //     validateAlternativeMobileNumber: 'AlternativeMobile'
  // }
};
