import { React, Component } from 'react';
import { connect } from 'react-redux'
import {  main } from '../../../../../../service/actionType';
import * as gameAction from '../../../../../../actions/game';
import { bindActionCreators } from "redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getGamePath } from '../../../../../../helpers/index'
import ButtonType1 from '../../../../../../components/inputs/ButtonType1';
import ClassPresentationWrapper from '../../../../../../Hoc/wrapper'

class SelectRandomPlayersClass extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            selectedItem: 0,
        };
    }

    selectedObject = (id) => {
        let { game } = this.props
        game.playersList.map((pl, index) => {
            if(pl._id === id){
                pl.isSelected = !pl.isSelected
            } 
        })
        this.props.dispatch({ type: main.PLAYERS_LIST, payload:  game.playersList})
    }
    selectAll = () => {
        let { game } = this.props
        game.playersList.map(pl => pl.isSelected = true )
        this.props.dispatch({type: main.PLAYERS_LIST, payload: game.playersList})
    }
    start = async () => {
        let selectedGame = sessionStorage.getItem('selectedGame')
        let path = getGamePath(parseInt(selectedGame))
        await this.props.gameAction.storeGameSettings(path, null)
    }
    render() {
        let {game} = this.props
        let nextBtnDisabled = false
        if(game.playersList.length > 0){
            let selectedPlList = game.playersList.filter(pl => pl.isSelected === true)
            if(selectedPlList.length > 0) {
                nextBtnDisabled = false
            } else {
                nextBtnDisabled = true
            }
        } else {
            nextBtnDisabled = true
        }
        return (
            <div className='clpContainer container'>
                <div className="row">
                    <div className="col-12">
                        <div className="mt-2">
                            <p className="clp_heading1 text-center">Select a Player for Random Play</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row no-gutters justify-content-center">
                            <div className="col-12">
                                <div className="row align-items-center justify-content-center mt-5">
                                    {game.playersList && game.playersList.map((dt, i) => (
                                        <div className='relative-pos' key={i}>
                                            <div className='cursor-pointer  game_player_img w-85 mt-3 text-center mlr-auto' onClick={() => this.selectedObject(dt._id)}>
                                                <div>
                                                    <img
                                                        src={(dt.profilePic != undefined && dt.profilePic?.original != undefined && dt.profilePic?.original != "") ? dt.profilePic?.original : require("../../../../../../assets/images/noImage.png")}
                                                        className="whbr-70"
                                                        alt="alphatub"
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <p className='clpSelectPlayer text-center'>{dt.label ? dt.label : '-'}</p>
                                                </div>
                                            </div>
                                            {dt.isSelected &&
                                                <div className="selectedPlayer">
                                                    <img
                                                        src={
                                                            require("../../../../../../assets/images/selectedPlayer.png")
                                                            
                                                        }
                                                        className=""
                                                        alt="alphatub"
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 fixedGameButtons">
                        <div className='row justify-flex-end'>
                            <div className="col-2">
                                <ButtonType1
                                    btnName="Select All"
                                    className='buttonType2'
                                    onClick={() => this.selectAll()}
                                    disabled={false}
                                />
                            </div>
                            <div className="col-2">
                                <ButtonType1
                                    btnName="Start"
                                    className='buttonType2'
                                    disabledClassName="l-lh-42"
                                    onClick={() => this.start()}
                                    disabled={nextBtnDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ game }) => {
    return {
        game
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        gameAction : bindActionCreators(gameAction, dispatch),
        dispatch
    }
};

export const SelectRandomPlayers = ClassPresentationWrapper(connect(mapStateToProps, mapDispatchToProps)(SelectRandomPlayersClass))

