import React, { Component,  } from "react";
import { Auth } from 'aws-amplify';
import { ConfirmPassword, isEmptyOrUndefine, passwordMatchCondition,isOldpasswordAndNewPasswordDifferent } from '../../utils/common.utils';
import "../../assets/css/main.scss";
import errorMsgs from "../../utils/errorMsgs";
import { history } from '../../helpers'
import toast from "../../utils/Toast"
import FormInput from "../inputs/FormInput";
import { Form } from "reactstrap";
export default class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      oldpassword: '', 
      newpassword: '', 
      confirmpassword: '', 
      oldPasswordMessage: '', 
      newPasswordMessage: '', 
      confirmPasswordMessage: '',
      emptyBtnClicks: 0,
      newpasswordCondBtnClicks : 0,
      passwordCondBtnClicks: 0,
      isConfirmPasswordVisible: false,
      isOldPasswordVisible: false,
      isNewPasswordVisible: false
    };
    this.handleChange = this.handleChange.bind(this);
  }
  togglePasswords = (name) => {
    this.setState({
      [name]: !this.state[name]
    })
  }
  handleChange(name, value) {
    this.setState({ [name] : value});
  }
  checkRequiredFields = () => {
    let { oldpassword, newpassword, confirmpassword, emptyBtnClicks, passwordCondBtnClicks, newpasswordCondBtnClicks } = this.state
    if (isEmptyOrUndefine(oldpassword) || isEmptyOrUndefine(newpassword) || isEmptyOrUndefine(confirmpassword)) {
        let newBtnClicks = emptyBtnClicks === 4 ? 1 : emptyBtnClicks + 1;
        this.setState({
          emptyBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 1));

        return false;
    } else if(!passwordMatchCondition(newpassword) || !passwordMatchCondition(confirmpassword)){
        let newBtnClicks = passwordCondBtnClicks === 4 ? 1 : passwordCondBtnClicks + 1;
        this.setState({
          passwordCondBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 2));

        return false;
    } else if(isOldpasswordAndNewPasswordDifferent(oldpassword, newpassword)){
        toast('Old Password and New Password should be different', "error")
        return false;
    } else if(!ConfirmPassword(newpassword, confirmpassword)){
        let newBtnClicks = newpasswordCondBtnClicks === 4 ? 1 : newpasswordCondBtnClicks + 1;
        this.setState({
          newpasswordCondBtnClicks: newBtnClicks
        }, () => this.showToast(newBtnClicks, 3));

        return false;
    }
    return true;
  }
  showToast = (btnClicks, type) => {
    let errMsg = "";
    if(type === 1){
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW001[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW001[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW001[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW001[3];
          break;
      }
    } else if(type === 2){
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW032[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW032[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW032[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW032[3];
          break;
      }
    } else if(type === 3){
      switch (btnClicks) {
        case 1:
          errMsg = errorMsgs.TCW008[0];
          break;
        case 2:
          errMsg = errorMsgs.TCW008[1];
          break;
        case 3:
          errMsg = errorMsgs.TCW008[2];
          break;
        case 4:
          errMsg = errorMsgs.TCW008[3];
          break;
      }
    }
    toast(errMsg, "error")
  }
  updatePassword = async () => {
    let { btnClicks } = this.state
    try {
      if (this.checkRequiredFields()) {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          const change_password = await Auth.changePassword(user, this.state.oldpassword, this.state.newpassword);
          if (change_password) {
            toast('Password updated successfuly', 'success')
            localStorage.clear();
            sessionStorage.clear();
            history.replace("/");
            await Auth.signOut({ global: true });
            
          }
        }
      }
    } catch (error) {
      console.log(error);
    }


  }

  render() {
    let state = this.state
    return (
      <>
        <>
          <div className="container mt-4">
            <div className="row">
              <div className="col-12">
                <h3 className="login-text-login text-left">Update Password</h3>
              </div>
              <div className="col-12">
                <form>
                  <div>
                    <div className="row">
                      <div className="col-md-4">
                        <FormInput
                          id="oldpassword"
                          name="oldpassword"
                          type="password"
                          label="Old Password"
                          placeholder="Enter your old pasword"
                          togglePassword={() => this.togglePasswords('isOldPasswordVisible')}
                          className="m-0"
                          isPasswordVisible={state.isOldPasswordVisible}
                          value={this.state.oldpassword}
                          onChange={this.handleChange}
                        />
                        <span>{this.state.oldPasswordMessage}</span>
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          name="newpassword"
                          type="password"
                          label="New Password"
                          placeholder="Enter your new password"
                          onChange={this.handleChange}
                          togglePassword={() => this.togglePasswords('isNewPasswordVisible')}
                          className="m-0"
                          isPasswordVisible={state.isNewPasswordVisible}
                          value={this.state.newpassword}
                        />
                        <span>{this.state.newPasswordMessage}</span>
                      </div>
                      <div className="col-md-4">
                        <FormInput
                          name="confirmpassword"
                          type="password"
                          label="Confirm Password"
                          placeholder="confirm your password"
                          onChange={this.handleChange}
                          togglePassword={() => this.togglePasswords('isConfirmPasswordVisible')}
                          className="m-0"
                          isPasswordVisible={state.isConfirmPasswordVisible}
                          value={this.state.confirmpassword}
                        />
                        <span>{this.state.confirmPasswordMessage}</span>
                      </div>
                    </div>
                    <div style={{ "height": "20px" }}>

                    </div>
                    <div className="row">
                      <div className="col-md-12 text-right">
                        <button type="button" className="w-200px btn btn-primary" onClick={this.updatePassword}>Update Password</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}